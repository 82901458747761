import React, { useEffect, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import './webnavbar.css'
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Tooltip as TooltipShadcn,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip"
import Typography from '@mui/material/Typography';
import Vector from '../../asets/webimages/home/Vector.png'
// import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import Divider from '@mui/material/Divider';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import IconButton from '@mui/material/IconButton';
// import icon1 from '../../asets/webimages/icon1.png'
// import icon11 from '../../asets/webimages/icon11.png'
// import dashboardicon from '../../asets/warning/Group 1000008415.svg'
// import icon2 from '../../asets/webimages/icon2.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import icon22 from '../../asets/webimages/icon22.png'
// import icon3 from '../../asets/webimages/icon3.png'
// import icon33 from '../../asets/webimages/icon33.png'
// import icon4 from '../../asets/webimages/icon4.png'
// import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
// import icon44 from '../../asets/webimages/icon44.png'
// import icon5 from '../../asets/webimages/icon5.png'
// import icon55 from '../../asets/webimages/icon55.png'
import MenuIcon from '@mui/icons-material/Menu';
// import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { LayoutDashboard, Bell, CirclePlus, Flag, User, Briefcase, Headset, UsersRound, NotebookPen } from 'lucide-react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Collapse, Menu, MenuItem, Tooltip } from '@mui/material';
import Cookies from 'js-cookie';
import { AuthContext } from '../../AuthProvider';
import Axios from '../../Axios';
import TopBanner from '../../components/topbanner/TopBanner';
import { ProfilePageContext } from '../../context/ProfilePageContext';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',

});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,

  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    // marginTop:'35px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    // marginTop:'35px',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



export default function WebNavbar() {
  const { overallPercentage, loadUser, accountPercentage, fbpagePercentage, businessPercentage, notificationPercentage } = useContext(ProfilePageContext);
  const [isProfileOpen, setIsProfileOpen] = React.useState(false)
  const theme = useTheme();
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(true);
  const { setUserToken, userToken, setallFbData, setCampaignInsitsData } = React.useContext(AuthContext)
  const [userInfo, setUserInfo] = React.useState([])
  const [headerName, setHeaderName] = React.useState("")
  const [isBack, setIsBack] = React.useState(true)
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [showbanner, setShowBanner] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation()

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };



  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  //end dropdown



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {

    Cookies.remove("userToken");
    setUserToken("");
    setallFbData([])
    setCampaignInsitsData([])
    setAnchorElUser(null);
    setTimeout(() => {
      navigate("/login");
    },);
  }

  //user
  const getUser = async () => {
    try {
      const res = await Axios("/user", {
        headers: {
          Authorization: userToken
        }
      })
      if (res.status === 200) {
        const data = res.data;
        setUserInfo(data?.user)
      }
    } catch (error) {
      console.log(error?.messsage)
    }
  }
  React.useEffect(() => {
    getUser()
  }, [])
  //console.log("userInfo",userInfo)
  const ItemArr = [
    { home: "Home", icon: <p className=' d-flex justify-content-center align-items-center mb-0' style={{}}><LayoutDashboard className='h-6 w-6' style={{ color: location.pathname === '/' ? 'white' : 'rgba(80, 80, 80, 1)' }} /></p>, url: "/" },
    { home: "Launch Ad", icon: <p className='d-flex justify-content-center align-items-center mb-0' style={{}}><CirclePlus className='h-6 w-6' style={{ color: location.pathname === '/property-details' ? 'white' : 'rgba(80, 80, 80, 1)' }} /></p>, url: "/property-details" },
    { home: "Leads", icon: <p className='d-flex justify-content-center align-items-center mb-0' style={{}}><UsersRound className='h-6 w-6' style={{ color: location.pathname === '/lead-management' ? 'white' : 'rgba(80, 80, 80, 1)' }} /></p>, url: "/lead-management" },
    { home: "Campaign", icon: <p className='d-flex justify-content-center align-items-center mb-0' style={{}}><Flag className='h-6 w-6' style={{ color: location.pathname === '/campaign-management' ? 'white' : 'rgba(80, 80, 80, 1)' }} /></p>, url: "/campaign-management" },
    { home: "LeapX Support", icon: <p className='d-flex justify-content-center align-items-center mb-0' style={{}}><Headset className='h-6 w-6' style={{ color: location.pathname === '/leapx-support' ? 'white' : 'rgba(80, 80, 80, 1)' }} /></p>, url: "/leapx-support" },
    //  {home:"Profile", icon:<p className='d-flex justify-content-center align-items-center mb-0' style={{width:'40px', height:'40px', backgroundColor:location.pathname==='/profile'?'#FAFAFA':'rgba(255, 255, 255, 0.25)', borderRadius:'6px'}}><img src={location.pathname==='/profile'?icon6:icon66} alt='icon' width="18px" height="18px" style={{color:'white'}} /></p>, url:"/profile"}
  ]

  const profiledropdownarr = [
    { menu: 'Profile', url: "/account-details" },
    { menu: 'Create Ad campaign', url: "/property-details" },
    { menu: 'Manage Ad campaign', url: "/campaign-management" },
    { menu: 'Manage Lead', url: "/lead-management" },
    { menu: 'Transactions', url: "/transactions" },
    { menu: 'LeapX Support', url: "/leapx-support" },

  ]

  React.useEffect(() => {
    if (location?.pathname === "/") {
      const name = `Welcome! ${userInfo?.name}`
      setHeaderName(name)
      setIsBack(false)
      setProfileOpen(false)
    } else if (location?.pathname === "/lead-management") {
      setHeaderName("Lead management")
      setIsBack(true)
      setProfileOpen(false)
    } else if (location?.pathname === "/campaign-management") {
      setHeaderName("Campaign management")
      setProfileOpen(false)
      setIsBack(true)
    } else if (location?.pathname === "/leapx-support") {
      setHeaderName("LeapX Support")
      setIsBack(true)
      setProfileOpen(false)
    } else if (location?.pathname === "/profile") {
      setHeaderName("Profile")

      setIsBack(true)
    } else if (location?.pathname === "/property-details" || location?.pathname === "/review-details" || location?.pathname === "/campaign-summary") {
      setHeaderName("Campaign Creation")
      setProfileOpen(false)
      setIsBack(false)
    } else if (location?.pathname === "/leads") {
      setHeaderName("Leads Page")
      setProfileOpen(false)
      setIsBack(true)
    } else if (location?.pathname === "/campaign-overview") {
      setHeaderName("Ad Campaign Overview")
      setProfileOpen(false)
      setIsBack(true)
    } else if (location?.pathname === "/transactions") {
      setHeaderName("Transactions")
      setProfileOpen(false)
      setIsBack(true)
    } else if (location?.pathname === "/draft-successful") {
      setHeaderName("Draft Successful")
      setIsBack(false)
      setProfileOpen(false)
    } else if (location?.pathname === "/payment-successful") {
      setIsBack(false)
    } else if (location?.pathname === "/account-details") {
      setProfileOpen(true)
      setIsBack(false)
    } else if (location?.pathname === "/facebook-page") {
      setProfileOpen(true)
      setIsBack(false)
    } else if (location?.pathname === "/business-details") {
      setProfileOpen(true)
      setIsBack(false)
    } else if (location?.pathname === "/notification-settings") {
      setProfileOpen(true)
      setIsBack(false)
    } else if (location?.pathname === "/") {
      setIsBack(false)
    } else if (location?.pathname === "/") {
      setProfileOpen(false)
    }

  }, [location?.pathname, userInfo?.name])

  const HandleOpenManu = () => {
    setIsProfileOpen(!isProfileOpen)
    navigate("/business-details")
  }


  const handleProfileClick = () => {
    setProfileOpen(!profileOpen);
    navigate("/account-details")
  }

  useEffect(() => {
    if (overallPercentage === 100) {
      setShowBanner(false)
    } else {
      setShowBanner(true)
    }
  }, [overallPercentage])

  // console.log("showbanner",overallPercentage)

  // const showbanner = true

  return (
    <>
      {loadUser ? '' : (<>{showbanner && <TopBanner accountPercentage={accountPercentage} fbpagePercentage={fbpagePercentage} businessPercentage={businessPercentage} notificationPercentage={notificationPercentage} />}</>)}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ backgroundColor: 'white', color: 'black', boxShadow: 'none', borderBottom: '2px solid #E1E1E180', top: showbanner ? '35px' : '0px' }} className='' open={open}>
          <Toolbar>
            <IconButton
              color="black"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">

              {isBack && <Button onClick={() => navigate(-1)}><KeyboardBackspaceIcon sx={{ color: 'black' }} /></Button>} {headerName}
            </Typography>

            {/* dropdown */}
            <Box className="" sx={{ marginLeft: 'auto' }}>
              <Tooltip title="Open settings" >
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, }}>
                  <Avatar alt={userInfo?.name} src={"/static/images/avatar/2.jpg"} sx={{ backgroundColor: 'rgba(102, 34, 204, 1)' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {profiledropdownarr.map((setting, idx) => (
                  <NavLink key={idx + 1} to={setting.url} >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography style={{ color: 'black', fontSize: '16px' }} textAlign="center">{setting.menu}</Typography>
                    </MenuItem>
                  </NavLink>
                ))}
                <MenuItem onClick={handleCloseUserMenu}>
                  <a href='https://www.leapx.ai/privacy-policy' target='_blank' rel='noreferrer' style={{ color: 'black', fontSize: '16px' }}><Typography textAlign="center">Privacy Policy</Typography></a>
                </MenuItem>
                <MenuItem onClick={handleLogout} className=''>
                  <Typography style={{ color: 'black', fontSize: '16px' }} textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>

            {/* dropdown  end*/}

          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{
          zIndex: '99999', backgroundColor: 'white', '& .MuiDrawer-paper': {
            marginTop: showbanner ? '35px' : '0px',
          },
        }} className='drawer-color'>
          <DrawerHeader className='' sx={{ paddingLeft: '' }}>
            <IconButton
              color="black"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className='leapxicon p-0 border'
              edge="start"
              sx={{
                color: 'white',
                marginRight: open ? '134px' : '0px',
                transition: '0.8s ease-in-out'
              }}
            >
              <div className='m-0 p-0  d-flex justify-content-center align-items-center' style={{ height: '42px', width: '42px', borderRadius: '50%', backgroundColor: '#62c' }}>
                <img src={Vector} alt="" className='img-fluid p-0 m-0' />
              </div>
              {/* <MenuIcon /> */}
            </IconButton>

            {!open ? (<>
              {/* <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
           
            edge="start"
            sx={{
             color:'white',
              ...(open && { display: 'none' }),
             
            }}
          >
            <div  className=' d-flex justify-content-center align-items-center' style={{height:'42px', width:'42px', borderRadius:'50%', backgroundColor:'rgba(255, 255, 255, 0.2)'}}>
              <img src={Vector} alt="" className='img-fluid' />
            </div>
          
          </IconButton> */}
            </>) : (<>


              <IconButton onClick={handleDrawerClose} sx={{ color: 'black', }}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>


            </>)}

          </DrawerHeader>
          <Divider />
          <List sx={{ border: '' }}>
            {/* {['Home', 'Lead', 'Camp', 'Overview'].map((text, index) => ( */}

            {ItemArr.map((text, index) => (

              <ListItem key={index + 1} disablePadding sx={{ display: 'block', paddingY: '4px' }} className='' style={{}} onClick={() => navigate(`${text.url}`)}>

                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    // position: 'relative', // Ensure that the context is set for z-index
                    // zIndex: 1,
                    background: location?.pathname === text?.url ? '#62c' : 'white',
                    ":hover": { backgroundColor: location?.pathname === text?.url ? '#62c' : '' }
                  }}
                >
                  <ListItemIcon

                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      marginBottom: '0px',
                      // position: 'relative', // Ensure that the context is set for z-index
                      // zIndex: 1

                    }}
                  >
                    {/* shadcn tooltip */}
                    {!open?<TooltipProvider >
                      <TooltipShadcn >
                        <TooltipTrigger className='p-0 m-0 ' asChild>
                          <span className='p-0 m-0'  > {text.icon}</span>
                        </TooltipTrigger>
                       {!open &&  <TooltipContent className="bg-[#505050] text-white">
                          {text.home}
                        </TooltipContent>} 
                       
                      </TooltipShadcn>
                    </TooltipProvider>:   <Tooltip title="Add" placement='top' arrow >{text.icon}</Tooltip>}



                  
                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  </ListItemIcon>
                  <ListItemText style={{ marginBottom: '0px' }} className='mb-1' primary={text.home} sx={{ opacity: open ? 1 : 0, color: location?.pathname === text?.url ? 'white' : 'rgba(80, 80, 80, 1)' }} />
                </ListItemButton>


              </ListItem>
            ))}
            <ListItem disablePadding sx={{ display: 'block', paddingY: '4px' }} className=''>
              <ListItemButton onClick={handleProfileClick} sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,

              }}  >
                <ListItemIcon sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  marginBottom: '0px'
                }}>
                  <p className='d-flex justify-content-center align-items-center mb-0' style={{}}>
                    {/* <img src={location.pathname==='/leapx-support'?icon5:icon55} alt='icon' width="18px" height="18px" style={{color:'white'}} /> */}

                    
                    {!open? <TooltipProvider>
                      <TooltipShadcn>
                        <TooltipTrigger className='p-0 m-0 ' asChild>
                          <span className='p-0 m-0'  > <User className='h-6 w-6' style={{ color: 'rgba(80, 80, 80, 1)' }} /></span>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#505050] text-white">
                          Profile
                        </TooltipContent>
                      </TooltipShadcn>
                    </TooltipProvider>:<Tooltip sx={{}} placement='top' arrow >{<User className='h-6 w-6' style={{color:'rgba(80, 80, 80, 1)'}} />}</Tooltip>}
                    
                   

                  </p>

                </ListItemIcon>

                <ListItemText className='mb-1' sx={{ color: 'rgba(80, 80, 80, 1)', marginBottom: '0px', opacity: open ? 1 : 0 }} primary="Profile" />
                {/* {profileOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />} */}
              </ListItemButton>
            </ListItem>
            <Collapse in={profileOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4, boxShadow: location?.pathname === "/account-details" ? 'rgba(0, 0, 0, 0.1) 0px 2px 4px' : 'none', backgroundColor: location?.pathname === "/account-details" ? '#62c' : '', ":hover": { backgroundColor: location?.pathname === "/account-details" ? '#62c' : '' } }} className='' onClick={() => navigate("/account-details")}>
                  <ListItemIcon>
                      

                      {!open?<TooltipProvider>
                      <TooltipShadcn>
                        <TooltipTrigger className='p-0 m-0 ' asChild>
                          <NotebookPen className='h-4 w-4' style={{ color: location?.pathname === '/account-details' ? 'white' : 'rgba(80, 80, 80, 1)' }} />
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#505050] text-white">
                          Account Details
                        </TooltipContent>
                      </TooltipShadcn>
                    </TooltipProvider>:<NotebookPen className='h-4 w-4' style={{ color: location?.pathname === '/account-details' ? 'white' : 'rgba(80, 80, 80, 1)' }} />}

                    




                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: { color: location?.pathname === "/account-details" ? 'white' : 'rgba(80, 80, 80, 1)', fontSize: '14px' } }} primary="Account Details" />
                </ListItemButton>

                <ListItemButton sx={{ pl: 4, boxShadow: location?.pathname === "/business-details" ? 'rgba(0, 0, 0, 0.1) 0px 2px 4px' : 'none', backgroundColor: location?.pathname === "/business-details" ? '#62c' : '', ":hover": { backgroundColor: location?.pathname === "/business-details" ? '#62c' : '' } }} className='' onClick={() => navigate("/business-details")}>
                  <ListItemIcon>

                    {!open?<TooltipProvider>
                      <TooltipShadcn>
                        <TooltipTrigger className='p-0 m-0 ' asChild>
                          <Briefcase className='h-4 w-4' style={{ color: location?.pathname === '/business-details' ? 'white' : 'rgba(80, 80, 80, 1)' }} sx={{}} />
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#505050] text-white">
                          Business Details
                        </TooltipContent>
                      </TooltipShadcn>
                    </TooltipProvider>
:<Briefcase className='h-4 w-4' style={{ color: location?.pathname === '/business-details' ? 'white' : 'rgba(80, 80, 80, 1)' }} sx={{}} />}
                    


                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: { color: location?.pathname === "/business-details" ? 'white' : 'rgba(80, 80, 80, 1)', fontSize: '14px' } }} primary="Business Details" />
                </ListItemButton>

                <ListItemButton onClick={() => navigate("/facebook-page")} sx={{ pl: 4, boxShadow: location?.pathname === "/facebook-page" ? 'rgba(0, 0, 0, 0.1) 0px 2px 4px' : 'none', backgroundColor: location?.pathname === "/facebook-page" ? '#62c' : '', ":hover": { backgroundColor: location?.pathname === "/facebook-page" ? '#62c' : '' } }}>
                  <ListItemIcon sx={{}} className=''>



                    {!open?<TooltipProvider>
                      <TooltipShadcn>
                        <TooltipTrigger className='p-0 m-0 ' asChild>
                          <FacebookRoundedIcon sx={{ color: location?.pathname === '/facebook-page' ? 'white' : 'rgba(80, 80, 80, 1)', width: '1rem', height: '1rem' }} className='' />
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#505050] text-white">
                          Facebook Page
                        </TooltipContent>
                      </TooltipShadcn>
                    </TooltipProvider>:<FacebookRoundedIcon sx={{ color: location?.pathname === '/facebook-page' ? 'white' : 'rgba(80, 80, 80, 1)', width: '1rem', height: '1rem' }} className='' />}
                    



                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: { color: location?.pathname === "/facebook-page" ? 'white' : 'rgba(80, 80, 80, 1)', fontSize: '14px' } }} primary="Facebook Page" />
                </ListItemButton>

                <ListItemButton onClick={() => navigate("/notification-settings")} sx={{ pl: 4, boxShadow: location?.pathname === "/notification-settings" ? 'rgba(0, 0, 0, 0.1) 0px 2px 4px' : 'none', backgroundColor: location?.pathname === "/notification-settings" ? '#62c' : '', ":hover": { backgroundColor: location?.pathname === "/notification-settings" ? '#62c' : '' } }}>
                  <ListItemIcon>

                    {!open?<TooltipProvider>
                      <TooltipShadcn>
                        <TooltipTrigger className='p-0 m-0 ' asChild>
                          <Bell className='h-4 w-4' style={{ color: location?.pathname === '/notification-settings' ? 'white' : 'rgba(80, 80, 80, 1)' }} sx={{ color: location?.pathname === '/notification-settings' ? 'white' : 'rgba(255, 255, 255, 0.25)' }} />
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#505050] text-white">
                          Notification Settings
                        </TooltipContent>
                      </TooltipShadcn>
                    </TooltipProvider>:<Bell className='h-4 w-4' style={{ color: location?.pathname === '/notification-settings' ? 'white' : 'rgba(80, 80, 80, 1)' }} sx={{ color: location?.pathname === '/notification-settings' ? 'white' : 'rgba(255, 255, 255, 0.25)' }} />}
                    


                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ style: { color: location?.pathname === "/notification-settings" ? 'white' : 'rgba(80, 80, 80, 1)', fontSize: '14px' } }} primary="Notification Settings" />
                </ListItemButton>


                <ListItemButton sx={{ visibility: 'hidden' }} >
                  <ListItemIcon>
                    <NotificationsOutlinedIcon sx={{ color: 'white' }} />
                  </ListItemIcon>
                  <ListItemText />
                </ListItemButton>

                {/* <ListItemButton sx={{visibility:'hidden'}} >
                <ListItemIcon>
                  <NotificationsOutlinedIcon  />
                </ListItemIcon>
                <ListItemText />
              </ListItemButton> */}

              </List>
            </Collapse>



          </List>
          <Divider />

        </Drawer>
        <Box component="main" className="" sx={{ flexGrow: 1, padding: '2px 0px', top: '35px', marginTop: showbanner ? '35px' : '0px' }}>
          <DrawerHeader />
          <Outlet />

        </Box>
      </Box>
    </>
  );
}