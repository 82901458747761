import React, { useState, useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Eye, EyeOff, MoveLeft } from 'lucide-react';
import { Input } from '../../../components/ui/input';
import { Button } from '../../../components/ui/button';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import Axios from '../../../Axios';
import { AuthContext } from '../../../AuthProvider';

// Define validation schema with Yup
const validationSchema = Yup.object({
    old_password: Yup.string('Enter your old password')
        .required('Old password is required')
        .min(8, 'Password should be of minimum 8 characters length')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    new_password: Yup.string('Enter your new password')
        .required('New password is required')
        .min(8, 'Password should be of minimum 8 characters length')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
});

function ResetPasswordCard({setShowRsetPasswordCard}) {
    const { userToken } = useContext(AuthContext);
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [passwordError, setPasswordError] = useState("")
    const handleClickShowPasswordOld = () => setShowPasswordOld(!showPasswordOld);
    const handleMouseDownPasswordOld = (event) => event.preventDefault();
 
    const handleClickShowPasswordNew = () => setShowPasswordNew(!showPasswordNew);
    const handleMouseDownPasswordNew = (event) => event.preventDefault();

    // Initialize Formik
    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
        },
        validationSchema,
        onSubmit: values => {
            // Handle form submission
            handleSubmit(values)
            console.log('Form values', values);
        },
    });

    const handleSubmit = async(values) => {
        const body={
            "oldPassword":values?.old_password,
            "newPassword":values?.new_password
          }
      try {
          const res = await Axios.post("/change-password", body, {
              headers:{
                  Authorization:userToken
              }
          } )
  
            if(res.status===200 && res?.data?.valid){
              
                hotTost.success(res?.data?.message)
                setShowRsetPasswordCard(false)
                
               
                
            //   alert(res?.data?.message)
            }else{
                hotTost.error(res?.data?.message)
                setShowRsetPasswordCard(true)
                const err = res?.data?.message?res?.data?.message:""
                setPasswordError(err)
            }

       } catch (error) {
        hotTost.error('Server error')
        setPasswordError("Server error")
          console.log(error)
          setShowRsetPasswordCard(true)
       }finally{

       }
          
    };


useEffect(()=>{
    setPasswordError("")
},[formik.values.new_password, formik.values.old_password])

    return (
        <>
         <Toaster position="top-right" />
        <div className="my-4 text-start">
        <Button onClick={()=>setShowRsetPasswordCard(false)} className="w-16 shadow-none h-8" variant="outline" size="icon">
      <MoveLeft className="" />
    </Button>

        </div>
        <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
                <label className="block ad-label">Old Password</label>
                <div className="relative">
                    <Input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type={showPasswordOld ? 'text' : 'password'}
                        name="old_password"
                        id="old_password"
                        placeholder="Enter Your Old Password"
                        value={formik.values.old_password}
                        className={`w-full h-11 mt-1 shadow-none ${formik.touched.old_password && formik.errors.old_password ? 'border-red-500' : ''}`}
                    />
                    <button
                        type="button"
                        onClick={handleClickShowPasswordOld}
                        onMouseDown={handleMouseDownPasswordOld}
                        className="absolute right-0 top-0 mt-2 mr-2"
                    >
                        {showPasswordOld ? <EyeOff /> : <Eye />}
                    </button>
                </div>
                {formik.touched.old_password && formik.errors.old_password && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.old_password}</p>
                )}
            </div>

            <div className="mb-4">
                <label className="block ad-label">New Password</label>
                <div className="relative">
                    <Input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type={showPasswordNew ? 'text' : 'password'}
                        name="new_password"
                        id="new_password"
                        placeholder="Enter Your New Password"
                        value={formik.values.new_password}
                        className={`w-full h-11 mt-1 shadow-none ${formik.touched.new_password && formik.errors.new_password ? 'border-red-500' : ''}`}
                    />
                    <button
                        type="button"
                        onClick={handleClickShowPasswordNew}
                        onMouseDown={handleMouseDownPasswordNew}
                        className="absolute right-0 top-0 mt-2 mr-2"
                    >
                        {showPasswordNew ? <EyeOff /> : <Eye />}
                    </button>
                </div>
                {formik.touched.new_password && formik.errors.new_password && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.new_password}</p>
                )}
            </div>
            <div className="mb-4">
                
                <Button type="submit" className="w-[170px] bg-[#62c] shadow-none hover:bg-[#62c]">
                    Reset Password
                </Button>
                {passwordError?<p className='m-0 mt-1 text-red-500 p-0 text-sm text-start'>{passwordError}</p>:''}
            </div>
            {/* <button type="submit" className="btn btn-primary">Reset Password</button> */}
        </form>
        </>

    );
}

export default ResetPasswordCard;
