import Navbar2 from "../../navbar/Navbar2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./leadpage.css";
import { Box, Button, Menu, MenuItem, Pagination, Skeleton, Stack } from "@mui/material";
// import LeadCard from "../../components/Cards/LeadCard";
import SortIcon from '@mui/icons-material/Sort';

import { Toast } from "primereact/toast";
import { Suspense, lazy, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { AuthContext } from "../../AuthProvider";
import Axios from "../../Axios";

import WebTableForLeads from "../../adesktop-webpage/web-leadspage/WebTableForLeads";

const LeadCard = lazy(() => import("../../components/Cards/LeadCard"));



const itemsPerPage = 10;


export default function LeadPage() {

  const navigate = useNavigate();
  //console.log(location.state);
  const { userToken } = useContext(AuthContext);
  const toastRef = useRef(null);
  // const [leadsData, setLeadsData] = useState([]);
  const [createdDate, setCreatedDate] = useState("")
  const [CampName, setCampName] = useState("")
  const [filterLeads, setFilterLeads] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [forFormLeads, setForFormLeads] = useState([])
  const [loadLeadData, setLoadLeadData] = useState(false)
  const [loadUpdateLead, setLoadUpdateLead] = useState(false)


  const { id } = useParams()
  const location = useLocation()
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatName = (name) => {

    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');

    trimmedName = trimmedName.replace(/_/g, ' ').trim();

    return trimmedName.replace(/\s+/g, ' ');
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }


  const getAllLeads = async (id) => {
    setLoadLeadData(true)
    try {
      const res = await Axios.get("/get-all-leads", {
        headers: {
          Authorization: userToken
        },
        params: {
          campaign_id: id,
        }
      })

      if (res.status === 200) {



        const data = res?.data?.message;

        if (data) {
          setCampName(data?.name)
          setCreatedDate(data?.createdAt)
          // console.log("leadvalue", leadvalue)
          if (data) {

            const leadsdata = [];
            data?.all_leads?.forEach((val) => {
              const leadsarray = []
              const allleads = [leadsarray, ...val]
              if (allleads?.length) {
                leadsdata.push(allleads)
              }
            })
            if (leadsdata?.length) {
              //  console.log("leadsdata", leadsdata)
            }
            const transformedLeads = data?.all_leads?.flat()?.map(lead => ({

              time: lead?.created_time,
              name: getFieldDataValue(lead, "full_name") || getFieldDataValue(lead, "question1"),
              phone: getFieldDataValue(lead, "phone_number") || getFieldDataValue(lead, "question3"),

              id: lead?.id,
              type: lead?.type ? lead?.type : '',
              comment: lead?.comment?.length > 0 ? lead?.comment : []
              // Add more properties as needed
            }));

            if (transformedLeads) {
              // console.log("transformedLeads", transformedLeads)
              setFilterLeads(transformedLeads)
            }
          }
        }

        function getFieldDataValue(lead, fieldName) {
          const field = lead.field_data.find(data => data?.name === fieldName);
          return field ? field.values[0] : null;
        }
        if (data) {

          const leadvalue = data?.all_leads?.flat()
          if (leadvalue) {


            let leadsResult = [];

            leadvalue.forEach((val) => {
              let innerlead = {}

              val?.field_data?.forEach((data) => {
                innerlead[data?.name] = data?.values[0];
                innerlead['created_time'] = val?.created_time;
                innerlead['id'] = val?.id;
                innerlead['comment'] = val?.comment;
              });
              if (Object.keys(innerlead)?.length) {
                leadsResult.push(innerlead)
              }
            });

            if (leadsResult?.length) {

              // Get a set of all unique keys in the array
              const allKeys = Array.from(new Set(leadsResult?.flatMap(obj => Object.keys(obj))));

              // Update each object to include all keys with values or null
              const updatedArray = leadsResult?.map(obj => {
                const updatedObj = { ...obj };
                allKeys.forEach(key => {
                  if (!obj.hasOwnProperty(key)) {
                    updatedObj[key] = null;
                  }
                });
                return updatedObj;
              });
              if (updatedArray?.length > 0) {
                setForFormLeads(updatedArray)
              }




            }
          }
        }

        // function getFieldDataValue(lead, fieldName) {
        // //  console.log("leadddd", lead)
        // const field = lead?.field_data?.find(data => data.name === fieldName);
        // return field ? field.values[0] : null;
        // }

        //  console.log("lead page", data?.all_leads?.length);
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoadLeadData(false)
    }
  }


  useEffect(() => {
   
    getAllLeads(location?.state)
  }, [id, location?.state]);
  


  //date formate
  // formate data
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Define months as an array
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    
    // Add ordinal suffix to the day
    const formattedDay = `${day}`;

    // Assemble the final formatted date
    const formattedDate = `${formattedDay} ${month} ${year}`;

    return formattedDate;
  }

  //  search and filter and pagination
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const SearchfilterData = () => {
    return filterLeads.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };



  const onFilterByDate = () => {
    // console.log("filterdata", filterLeads)
    const filterdate = [...filterLeads].sort((a, b) => new Date(b?.time) - new Date(a?.time))
    // console.log("filterbydata", filterdate)
    if (filterdate) {
      setFilterLeads(filterdate)
      handleClose()
    }
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = (searchTerm ? SearchfilterData() : filterLeads)?.slice(startIndex, endIndex);
  // pagination
  const handlePagination = (event, page) => {
    setCurrentPage(page);
    scrollToTop()
  }


  const handleLeadQuality = async (leadid, type, comment) => {
    setLoadUpdateLead(true)
    handleClose()
    const index = filterLeads.findIndex(item => item.id === leadid);


    const body = {
      leadId: leadid,

    }

    if (type) {
      body['type'] = type
    }
    if (comment) {
      body['comment'] = comment
    }

    try {
      const res = await Axios.post("/update-lead", body, {
        headers: {
          Authorization: userToken
        }
      })
      if (res.status === 200) {
        if (res?.data?.valid) {
          const newData = [...filterLeads]; // Create a copy of the array
          if (type) {
            newData[index].type = type; // Update the type
          }
          setFilterLeads(newData); // Update the state with the new array                     
        }
      }
    } catch (error) {
      console.log(error)

    } finally {
      setLoadUpdateLead(false)
    }
  }







  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="d-none d-md-block">
            <WebTableForLeads leadsdata={filterLeads} handleLeadQuality={handleLeadQuality} formatDate={formatDate} createdDate={createdDate} CampName={CampName} forFormLeads={forFormLeads} loadLeadData={loadLeadData} loadUpdateLead={loadUpdateLead} />
          </div>
        </div>
      </div>

      <div className="leadpage d-md-none" >
        <Toast ref={toastRef} />
        <div className="d-md-none"> <Navbar2 menu={true} /></div>
        <Box component={"div"} sx={{ paddingInline: "10px" }}>
          <div className="previous">
            <span
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
            </span>
            <h1>Leads</h1>
          </div>

          {/* filter */}
          <Box
            component={"div"}
            sx={{ display: "flex", alignItems: "center", gap: "1px", width: '100%' }}
          >
            <div className="searchBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M8.48307 9.85618C8.43311 9.80622 8.34711 9.79937 8.28574 9.84431C8.28574 9.84431 8.29167 9.84089 8.24011 9.87716C7.3892 10.4755 6.35211 10.8269 5.2329 10.8269C2.34296 10.8269 0 8.48423 0 5.59423C0 2.70422 2.3429 0.361328 5.2329 0.361328C8.1229 0.361328 10.4656 2.70422 10.4656 5.59423C10.4656 6.70794 10.1177 7.74072 9.52431 8.58912C9.48552 8.64478 9.48187 8.64866 9.48187 8.64866C9.43898 8.70683 9.44651 8.79603 9.49488 8.8444L9.80787 9.15739C9.85783 9.20736 9.95798 9.23359 10.0232 9.21899C10.0232 9.21899 10.0166 9.21831 10.0764 9.20895C10.5242 9.13983 10.9978 9.27785 11.3428 9.62278L12.978 11.258C13.5529 11.8327 13.5529 12.7646 12.978 13.3393C12.4033 13.9142 11.4714 13.9142 10.8968 13.3393L9.26151 11.704C8.91817 11.3609 8.77992 10.89 8.84676 10.4443C8.85612 10.3823 8.8568 10.3893 8.8568 10.3893C8.87323 10.3177 8.84448 10.2176 8.79612 10.1692L8.48307 9.85618ZM5.23291 8.86469C7.03904 8.86469 8.5034 7.40033 8.5034 5.5942C8.5034 3.78784 7.03904 2.32372 5.23291 2.32372C3.42655 2.32372 1.96242 3.78784 1.96242 5.5942C1.96242 7.40033 3.42655 8.86469 5.23291 8.86469Z"
                  fill="#949494"
                />
              </svg>
              <input type="text" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

            </div>

            {/* sorting by leads, date, spent */}

            <div className="p-0 m-0">
              <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ margin: '0px', padding: '0px' }}
                className="p-0 m-0"
              >
                <SortIcon sx={{ color: '#6622cc', margin: '0', padding: '0' }} />
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem sx={{ padding: '4px' }} onClick={onFilterByDate}>Leads by date</MenuItem>

              </Menu>
            </div>


          </Box>

          {loadLeadData ? (
            <>
              <div className='userDetails-skelton my-3'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton animation="wave" sx={{ border: '' }} variant="circular" width={40} height={40} />
                  <Box sx={{ width: '100%', marginLeft: '4px' }}>
                    <Skeleton variant="text" width="90%" sx={{ fontSize: '1rem', marginBottom: '4px' }} />
                    <Skeleton variant="text" width="90%" sx={{ fontSize: '1rem' }} />
                  </Box>
                </Box>
              </div>
            </>
          ) : <>

            <div className="userDetails" style={{ marginBlock: "20px", width: '100%' }}>
              <div className="left" style={{ width: '100%' }} >
                <div className="image">
                  <span
                    className="d-flex  align-items-center justify-content-center me-1"
                    style={{
                      height: "30px",
                      width: "30px",
                      backgroundColor: "#62c",
                      borderRadius: "50%",
                    }}
                  >
                    <span style={{ color: "white" }}>{CampName?.slice(0, 1)}</span>
                  </span>
                </div>
                <div className="user-details " >
                  <h2>{CampName?.length > 27 ? formatName(CampName).slice(0, 28) + '...' : formatName(CampName)}</h2>
                  {createdDate?.length > 0 && <p>{formatDate(createdDate)}</p>}
                  {/* <p>#{location?.state}</p> */}
                </div>
              </div>
              {/* <button >View Details</button> */}
            </div>
          </>
          }

          {/* Cards */}
          {loadLeadData ? <p>Loading...</p> : (<>
            <Stack direction={"column"} spacing={2}>


              <Suspense fallback="Loading...">
                {filterLeads?.length > 0 && currentItems?.map((val, idx) => (
                  <>
                    {/* {console.log("val",val)} */}
                    <LeadCard key={val._id} data={val} idx={idx} forFormLeads={forFormLeads} handleLeadQuality={handleLeadQuality} />
                  </>
                ))}
              </Suspense>
            </Stack>
          </>)}


        </Box>
        {filterLeads?.length > 0 && <div className='mt-3 mb-5'>
          <Pagination size="small" count={Math.ceil((searchTerm ? SearchfilterData() : filterLeads)?.length / itemsPerPage)} page={currentPage} color="secondary" onChange={handlePagination} />
        </div>}
      </div>




      {/* <BottomNavbar key={"somekey"} /> */}
    </>
  );
}
