import React from 'react'

import PropertyDetails from '../../pages/property-details/PropertyDetails'


function WebPropertyDetails() {
  const stepweb = [
    {
      label: 'Provide details',
      description: `Please upload official website link of the project, brochures and other pdfs related to the property you want to sell`,
    },
    {
      label: 'Review details',
      description:""
    },
    {
      label: 'Confirm & Pay',
      description: ``,
    },
  ];
  return (
    <>
     <div className="container-fluid m-0 p-0" style={{padding:'0', margin:'0px'}}>
        <div className="row m-0 p-0 ">
           
             {/* <div>
              <WebStepper steps={steps} active={1}/>
             </div> */}
             {/* right card */}
          
              <PropertyDetails/>
            
        </div>
     </div>
    </>
  )
}

export default WebPropertyDetails