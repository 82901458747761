import React, { useContext, useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import Cookies from "js-cookie";
import { AuthContext } from "../../AuthProvider";
import Axios from "../../Axios";
import PopupForSelectFbPages from "./PopupForSelectFbPages";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';


function ConnectToFbAndSelectPages({ handleUser, bgcolor }) {


  const [loggedIn, setLoggedIn] = useState(false);
  const [clientPages, setClientPages] = useState([]);
  const [token, setToken] = useState("");
  const [openLoadPagesPopup, setOpenLoadPagesPopup] = useState(false)
  const toast = useRef(null);
  const [buttonStatus, setButtonStatus] = useState({});
  const { userToken, setFbToken } = useContext(AuthContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isPageLoad, setIsPageLoad] = useState(false)
  //  for popup
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    handleUser()
    setOpen(false);
  };

  useEffect(() => {
    const loadFacebookSDK = () => {

      // Load the JavaScript SDK asynchronously
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;

      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      // Optional: Clean up the script on component unmount
      return () => {
        const fbSdkScript = document.getElementById("facebook-jssdk");
        if (fbSdkScript) {
          fbSdkScript.parentNode.removeChild(fbSdkScript);
        }
      };
    };

    window.fbAsyncInit = () => {
      // FB JavaScript SDK configuration and setup
      window.FB.init({
        appId: "1052762895750334",
        // appId: "700560265020731",
        cookie: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    loadFacebookSDK();
  }, []);

  const launchFBE = () => {
    if (window.FB) {
      window.FB.login(
        function (response) {
          // console.log(response);
          if (response.authResponse) {
            const accessToken = response?.authResponse?.accessToken;
            setFbToken(accessToken);
            Cookies.set("fbToken", accessToken, { expires: 7 });

            handelClientsPage(accessToken);
            setToken(accessToken);

            setLoggedIn(true);
            // navigate("/add-campaign-summery")
          } else {
            console.log("User cancelled login or did not fully authorize.");
            setLoggedIn(false);
          }
        },
        {
          config_id: '2496508650531906',
        },
        // {
        //   scope:
        //     "read_insights,pages_manage_cta,pages_manage_instant_articles,pages_show_list,ads_management,ads_read,business_management,leads_retrieval,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement",
        // }
      );
    }
  };

    // fetch fb pages
  const handelClientsPage = async (token) => {
    setOpenLoadPagesPopup(true)
    // console.log("/user-pages?access_token", token)
    Axios.get(`/user-pages?access_token=${token}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    })
      .then((response) => {
        setClientPages(response?.data?.message?.data);
        // console.log("datawork", response?.data?.message?.data)
        setOpenLoadPagesPopup(false)
        handleClickOpen()
       
      })
      .catch((error) => {
        setOpenLoadPagesPopup(false)
        console.log("Error setting up request:", error);
      });
  };
  const [selectedCity, setSelectedCity] = useState([]);
  const handelSelected = (item) => {
    let myObject = {
      id: item?.id,
      name: item?.name,
      // access_token: item?.access_token,
      about: item?.about,
      phone: item?.phone
    };

    handlePages(myObject)
    // setSelectedCity([...selectedCity, myObject]);

  };

  // add page
  const handlePageAccess = (item) => {

    setButtonStatus({ ...buttonStatus, [item?.id]: 'loading' });
    setIsPageLoad(true)
    const body = {
      pageId: item?.id,
      access_token: token,
    };

    Axios.post("/page-access-request", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    })
      .then((response) => {
        setIsPageLoad(false)
        const data = response?.data?.message;

        if (data?.access_status === "CONFIRMED") {
          // handelSelected(item);
         
          setSelectedItems([...selectedItems, item.id])
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
          handelSelected(item)
        } else if (data?.access_status === "PENDING") {
         
          setSelectedItems([...selectedItems, item.id])
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
          handelSelected(item)
        }
      })
      .catch((error) => {
        setIsPageLoad(false)
        if (
          error?.response?.data?.message?.error?.error_subcode === "1752041"
        ) {
         
          setButtonStatus({ ...buttonStatus, [item?.id]: 'error' });
        } else if (error?.response?.data?.message?.error?.error_subcode == "1690061") {
         
          setSelectedItems([...selectedItems, item.id])
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
          handelSelected(item)
        } else if (error?.response?.data?.message?.error?.error_subcode == "1690115") {

         
          setSelectedItems([...selectedItems, item.id])
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
          handelSelected(item)

        } else {
          // handelSelected(item);
         
          setButtonStatus({ ...buttonStatus, [item?.id]: 'error' });


        }
        console.log(
          "Error setting up request:",
          error?.response?.data?.message?.error
        );
      });


    // setAccessTokenPage(item?.access_token);
  };
  const handlePages = async (item) => {

    try {
      const res = await Axios.post("/add-pages",item, {
        headers:{
          Authorization:userToken
        }
       } );

       if(res.status===200){
        // handleUser()
       }
    } catch (error) {
      console.log("Error setting up request:");
      alert("Conntection failed");
    }

  };

  const onSubmit = () => {
      handleUser()
    // handlePages();
      setOpen(false)
     
  };


  return (
    <>
     
      <Toast ref={toast} />
      <div className="antialiased">
        <Toast ref={toast} />
        <div className="container-fluid p-0">
          <div className="container p-0">
            <div className="row">
              <div className="col-12" style={{ backgroundColor: bgcolor ? bgcolor : 'white' }} >
                <div className="mb-3" >
                  <div className="d-flex justify-content-start align-items-center mb-3" >

                  </div>

                  {clientPages?.length > 0 ? (
                    <>

                      <div>
                        
                       
                        <PopupForSelectFbPages setOpen={setOpen} buttonStatus={buttonStatus} clientPages={clientPages} selectedItems={selectedItems} handlePageAccess={handlePageAccess} onSubmit={onSubmit} handleClose={handleClose} open={open} />

                      </div>
                    </>
                  ) : (

                    <div
                      className=""
                      style={{
                        display: "flex",
                        width: '100%',
                        justifyContent: "center",
                        marginTop: "10%",
                      }}
                    >
                      <p
                        onClick={launchFBE}
                        style={{
                          background: "#1877f2",
                          color: "white",
                          padding: "10px 30px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          border: "none",
                          width: '100%',
                          textAlign: 'center',
                          display: "flex",
                          alignItems: "center",
                          justifyContent: 'center'
                        }}
                      >
                        <FacebookRoundedIcon sx={{ marginRight: 1 }} />
                        
                        {openLoadPagesPopup?(<>Fetching pages&nbsp;<span class="loaderfb"></span></>):"Link your facebook page"}
                      </p>
                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectToFbAndSelectPages;
