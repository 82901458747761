import { Link, NavLink, useNavigate } from "react-router-dom";
import "./navbar2.css";
import { useContext, useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import Cookies from "js-cookie";
import { AuthContext } from "../AuthProvider";
import navLinkOpen from '../asets/navLinkOpen.svg'
import navLinkClose from '../asets/navLinkClose.svg'
import Darklogo from '../asets/darkLogo.svg'
import logo from '../asets/logo.svg'
import TopBanner from "../components/topbanner/TopBanner";
import { ProfilePageContext } from "../context/ProfilePageContext";


export default function Navbar2({ menu }) {
  const [open, setOpen] = useState(false);
  const { getUserProfileData, overallPercentage, loadUser, accountPercentage, fbpagePercentage, businessPercentage, notificationPercentage} = useContext(ProfilePageContext);
  const [showbanner, setShowBanner] =  useState(false);
  const toast = useRef(null);
  const { setUserToken } = useContext(AuthContext);
  const navigate = useNavigate();

   const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleToggle = () => {
    setOpen(!open);
  };


  useEffect(()=>{
    if (!loadUser) { 
    if(overallPercentage===100){
     
      setShowBanner(true)
    }else{
      
      setShowBanner(false)
    }
  }
},[overallPercentage])

if (loadUser) {
  return null;
}

  if (open) {
    return (
      <div className="openedNavbar">
        <Toast ref={toast} />
        <div className="nav-container">
          <div className="logo">
            <Link to="/">
              <img src={Darklogo} alt="leapx" />
            </Link>
          </div>
          <div className="toggleBtn">
            <button onClick={handleToggle}>
              <img src={navLinkClose} alt="menu" />
            </button>
          </div>
        </div>
        <nav className="nav-links" style={{ overflowY: 'scroll' }}>
         
          <div>
            <NavLink to="/property-details">
              <p>Launch Ad</p>
            </NavLink>
          </div>
          <div>
            <NavLink to="/campaign-management">
              <p>Campaign Management</p>
            </NavLink>
          </div>
          <div>
            <NavLink to="/lead-management">
              <p>Lead Management</p>
            </NavLink>
          </div>
          <div>
            <NavLink to="/transactions">
              <p>Transactions</p>
            </NavLink>
          </div>
          {/* <div>
            <NavLink to="/Profile">
              <p>Profile</p>
            </NavLink>
          </div> */}

          <div className="dropdown ps-2 mb-0">
      <button className="dropbtn text-start pb-0 ps-2" onClick={toggleDropdown}>
        Profile
      </button>
      <div className={`dropdown-content ps-3 pt-0 ${isOpen ? 'show' : ''}`}>
      <NavLink style={{fontSize:'12px'}} to="/account-details">Account Details </NavLink>
      <NavLink style={{fontSize:'12px'}} to="/business-details">Business Details </NavLink>
      <NavLink style={{fontSize:'12px'}} to="/facebook-page">Facebook Page </NavLink>
      <NavLink style={{fontSize:'12px'}} to="/notification-settings">Notification Settings </NavLink>
        {/* <a href="#">Li</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a> */}
      </div>
    </div>




          <div>
            <NavLink to="/leapx-support">
              <p>Help & Support*</p>
            </NavLink>
          </div>
          <div>
            <a href="https://www.leapx.ai/terms-of-use" target="_blank">
              <p>Terms of Use</p>
            </a>
          </div>
          <div>
            <a
              href="https://www.leapx.ai/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              <p>Privacy Policy</p>
            </a>
          </div>
          <div>
            <a
              href="https://www.leapx.ai/refundpolicy"
              target="_blank"
              rel="noreferrer"
            >
              <p>Refund Policy</p>
            </a>
          </div>
          
          <div
            onClick={() => {
              localStorage.removeItem("tokenLogin");
              setUserToken("");
              Cookies.remove("userToken");
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "LogOut SuccessFully!",
                life: 3000,
              });
              setTimeout(() => {
                window.location.reload(true);
                navigate("/");
              }, 1000);

              
            }}
          >
            <p>Logout</p>
          </div>
        </nav>
      </div>
    );
  }
  // const showbanner = true
  return (
    <>
   {overallPercentage !== 100 && <TopBanner positionbanner ={1} accountPercentage={accountPercentage} fbpagePercentage={fbpagePercentage} businessPercentage={businessPercentage} notificationPercentage={notificationPercentage} />} 
    <div className="navbar-container">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="LeapX" />
        </Link>
      </div>
      {menu && (
        <div className="toggleBtn">
          <button onClick={handleToggle}>
            <img src={open ? navLinkClose : navLinkOpen} alt="toggle" />
          </button>
        </div>
      )}
    </div>
    </>
  );
}
