import React from 'react'
import ReviewDetails from '../../pages/review-details/ReviewDetails'


function WebReviewDetails() {

 
  return (
    <>
    <div className="container-fluid">
        <div className="row">
        {/* <div className="d-none d-md-block">
              <WebStepper steps={stepweb} active={1}/>
             </div> */}
        <div className="col-12">
              
             
            </div>
                <ReviewDetails/>
           
        </div>
    </div>
    </>
  )
}

export default WebReviewDetails