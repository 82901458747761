import React from 'react'
import '../login-with-google/loginwithgoogle.css'
import { NavLink } from 'react-router-dom'
import '../login-with-google/loginwithgoogle.css'

function LoginWithAnotherAccounnt() {
  return (
     <div className="container-fluid">
        <div className="container">

            <div className="row">
                <div className="col-12">
                    <div className="form-card">
                    <div className='d-flex justify-content-start align-items-center mb-3' >
                        <NavLink to="/"><i class="fa-solid fa-arrow-left" style={{color:'black', fontSize:'20px'}}></i></NavLink>
                        </div>
                    <div>
                        <h3 className="text-start px-1 google-heading ">Email not registerd</h3>
                        <p style={{color:'#cfc7c7'}} className="text-start px-1">
                        ite seems your are not register with us, Create new profile for <b className='text-primary-imp'> manik@leapx.ai  </b>      </p>
                    </div>
                    <div>
                        <p style={{color:'#cfc7c7'}}>
                            or Login with us
                        </p>
                    </div>
                    <div class="login-container">
                        <button class="google-login-button text-primary-imp">
                           
                            Login with another account
                        </button>
                    </div>

                    <div className="mt-4 mb-3">
                    <NavLink to="/signup/enter-otp"><button className="submit-button">Create Ne Account</button></NavLink> 
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </div>
  )
}

export default LoginWithAnotherAccounnt