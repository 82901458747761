import React from 'react'
import AdOverViewPage from '../../pages/ad-overview/adOverViewPage'


function WebAddCampOverview() {
 

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <AdOverViewPage />
        </div>

      </div>
    </div>
  )
}

export default WebAddCampOverview