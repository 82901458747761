import React from 'react'
import AdCampaignSummeryAndPay from '../../pages/review-details/AdCampaignSummeryAndPay'


function WebAddCampSummeryAndPay() {
 
  return (
    <div>
    
  <AdCampaignSummeryAndPay/> 
    </div>
  )
}

export default WebAddCampSummeryAndPay