import React from 'react';
import brandlogo1 from '../../asets/asetes/client logo/clientlogo2/1.png';
import brandlogo2 from '../../asets/asetes/client logo/clientlogo2/2.png';
import brandlogo3 from '../../asets/asetes/client logo/clientlogo2/3.png';
import brandlogo4 from '../../asets/asetes/client logo/clientlogo2/4.png';
import brandlogo5 from '../../asets/asetes/client logo/clientlogo2/5.png';
import brandlogo6 from '../../asets/asetes/client logo/clientlogo2/6.png';
import brandlogo7 from '../../asets/asetes/client logo/clientlogo2/7.png';
import brandlogo8 from '../../asets/asetes/client logo/clientlogo2/8.png';
import brandlogo9 from '../../asets/asetes/client logo/clientlogo2/9.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function PopularClients() {
  const brandlogo = [
    { id: 1, logo: brandlogo1 },
    { id: 2, logo: brandlogo2 },
    { id: 3, logo: brandlogo3 },
    { id: 4, logo: brandlogo4 },
    { id: 5, logo: brandlogo5 },
    { id: 6, logo: brandlogo6 },
    { id: 7, logo: brandlogo7 },
    { id: 8, logo: brandlogo8 },
    { id: 9, logo: brandlogo9 },
    { id: 10, logo: brandlogo3 },
    { id: 11, logo: brandlogo5 },
  ];

  return (
    <>
      <div className='p-0 my-3 my-md-0 position-relative' >
        <p className='popular-client-p position-absolut importantoutcome-p p-0 m-0 text-center'>
          20+ Businesses count on us for their growth
        </p>
        <section className='brand-logo-main-container shadow-s px-0 ' >
          <div className="brand-container pt-0 p-0">
            <Swiper
              slidesPerView={10} // Adjust as needed for number of logos per view
              centeredSlides={false}
              autoplay={{
                delay: 0, // Autoplay delay in milliseconds (0 for continuous scrolling)
                disableOnInteraction: false, // Keep autoplay on user interaction
              }}
              spaceBetween={15}
              speed={1800} // Speed of the transition
              loop={true} // Enable looping
              freeMode={{
                enabled: true,
                sticky: true,
                minimumVelocity: 0.3,
              }}
              grabCursor={true}
              modules={[Autoplay]}
              breakpoints={{
                0: {
                  slidesPerView: 4,
                  spaceBetween: 2,
                },
                640: {
                  slidesPerView: 4,
                  spaceBetween: 2,
                },
                768: {
                  slidesPerView: 8,
                  spaceBetween: 4,
                },
                1024: {
                  slidesPerView: 10,
                  spaceBetween: 15,
                },
              }}
              className="mySwiper"
              style={{ transform: 'rotateY(180deg)' }}
            >
              {brandlogo.map((val) => (
                <SwiperSlide key={val.id} style={{ transform: 'rotateY(180deg)' }}>
                  <div className="p-0 logo-container">
                    <img
                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                      src={val.logo}
                      alt="icon"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      </div>
    </>
  );
}

export default PopularClients;
