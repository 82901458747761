
import { createContext, useState, useEffect, useContext } from "react";
// import Cookies from "js-cookie";
import Axios from "../Axios";
import { AuthContext } from "../AuthProvider";

function checkConnectedSuccess(pages) {
    return pages.map(page => page.connected).includes('success');
}

// function isKeyAbsent(array) {
//     return array.every(obj => !obj.hasOwnProperty('connected'));
//   }

  function isConnectedPresent(arr) {
     return arr?.some(obj => !obj?.hasOwnProperty('connected'));;
  }


export const ProfilePageContext = createContext()

const ProfilePageProvider = ({ children }) => {
     const [systemUser, setSystemUser] = useState("")
    const {userToken} = useContext(AuthContext)
    const [accountPercentage, setAccountPercentage] = useState(0)
    const [userName, setUserName] = useState("")
    const [userData, setUserData] = useState("")
    const [fbpagePercentage, setFbpagePercentage] = useState(0)
    const [businessPercentage, setBusinessPercentage] = useState(0)
    const [overallPercentage, setOverallPercentage] = useState(0)
    const [loadUser, setLoadUser] = useState(false)
    const [configId, setConfigId] = useState("")
    const [configIdTest, setConfigIdTest] = useState("")
    const [notificationPercentage, setNotificationPercentage] = useState(0)
    const [profileCompleteStatus, setProfileCompleteStatus] = useState({
        accountdetails: 0,
        businessdetails: 0,
        fbpage: 0,
        notification: 0
    })

    const getUserProfileData = async (token) => {
        setLoadUser(true)
        //    console.log("render context user")
        try {
            const res = await Axios.get("/user", {

                headers: {
                    Authorization: token
                }
            });
            if (res.status === 200) {
                const config = res?.data?.configId
                console.log("livefb", config)
                   if(config){
                      setConfigId(config)
                   }else{
                     const configid = "2496508650531906"
                    setConfigId(configid)
                   }
                   
                   const configIdTesting = res?.data?.config_test
                   console.log("livefbtest", configIdTesting)
                   if(configIdTesting){
                    setConfigIdTest(configIdTesting)
                 }else{
                
                setConfigIdTest("")
                 }

                 const systemusertest = res?.data?.system_user;
                  if(systemusertest){
                    setSystemUser(systemusertest)
                  }else{
                    setSystemUser("")
                  }
               
               
                   const details = res?.data?.user;

                // console.log("user", details);
                if (details) {
                    setUserData(details);
                    setUserName(details?.name)

                   

                    // fb percentage

                    let fbpercentage = 0;

                    if (details?.page_id?.length > 0) {

                        const isconnectedpresent = isConnectedPresent(details?.page_id)

                        //    console.log("isconnectedpresent", isconnectedpresent)
                        const isconnected = checkConnectedSuccess(details?.page_id)

                        if (isconnected || isconnectedpresent) {
                            fbpercentage = 40;
                            setProfileCompleteStatus({...profileCompleteStatus, fbpage:1})
                        }

                    }

                    if (fbpercentage) {
                        setFbpagePercentage(fbpercentage)
                    } else {
                        setFbpagePercentage(0)
                    }



                    // account percentage

                    let accountpercetage = 0
                    // let completedFields = 0;
                    if (details?.name) accountpercetage += 5;
                    if (details?.email_verified_at) accountpercetage += 10;
                    if (details?.mobile) accountpercetage += 5;
                    // if (details?.password_updated_at) accountpercetage += 5;
                    if (accountpercetage) {
                        setAccountPercentage(accountpercetage)
                        if (accountPercentage === 20) {
                            setProfileCompleteStatus({...profileCompleteStatus, accountdetails:1})
                        }
                    } else {
                        setAccountPercentage(0)
                    }

                    // business percentage
                    let businesspercentage = 0;

                    if (details?.business_name) businesspercentage += 15;
                    // if(details?.gst) businesspercentage += 6;
                    if (details?.pan_no) businesspercentage += 15;
                    // if(details?.business_type) businesspercentage += 6;
                    // if(details?.logo) businesspercentage += 6;

                    if (businesspercentage) {
                        setBusinessPercentage(businesspercentage)
                        if (businessPercentage === 30) {
                            setProfileCompleteStatus({...profileCompleteStatus, businessdetails:1})
                        }
                    } else {
                        setBusinessPercentage(0)
                    }

                    // Notification percentage
                    let notificationpercentage = 0;
                    if (details?.notification_emails?.length > 0) notificationpercentage += 5;
                    if (details?.gsheet_emails?.length > 0) notificationpercentage += 5;

                    if (notificationpercentage) {
                        setNotificationPercentage(notificationpercentage)
                        if (notificationPercentage === 10) {
                            setProfileCompleteStatus({...profileCompleteStatus, notification:1})
                        }
                    } else {
                        setNotificationPercentage(0)
                    }

                    // total percentage
                    const overallpercentage = fbpercentage + accountpercetage + businesspercentage + notificationpercentage;

                    if (overallpercentage) {
                        setOverallPercentage(overallpercentage)
                    }

                }
            }
        } catch (error) {


            console.error("Error fetching user profile data", error);
        }finally{
            setLoadUser(false)
        }
    }



    useEffect(()=>{
         if(userToken){
            getUserProfileData(userToken)
         }
        
    },[userToken])


    return (

        <ProfilePageContext.Provider value={{ getUserProfileData, userData, accountPercentage, userName, fbpagePercentage, businessPercentage, notificationPercentage, overallPercentage, profileCompleteStatus , loadUser, setLoadUser, configId, configIdTest, systemUser}}>
            {children}
        </ProfilePageContext.Provider>
    )
}

export default ProfilePageProvider