import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../AuthProvider';
import Axios from '../../Axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import  { isValidPhoneNumber } from 'react-phone-number-input';
import { Eye, EyeOff, ChevronDown } from 'lucide-react';
import 'react-phone-number-input/style.css';
import ProfileCompleteCard from './components/ProfileCompleteCard';
import { Input } from '../../components/ui/input';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import { Card } from '../../components/ui/card';
import Navbar2 from '../../navbar/Navbar2';
import { Button } from '../../components/ui/button';
import ResetPasswordCard from './components/ResetPasswordCard';
import indicon from '../../asets/account-details-icons/india.svg';
import { ProfilePageContext } from '../../context/ProfilePageContext';
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
import DeleteAccount from './components/DeleteAccount';

// function checkConnectedSuccess(pages) {
//     return pages.map(page => page.connected).includes('success');
// }

function AccountDetails() {
    const { userToken } = useContext(AuthContext);
    const [emailVerifedLoad, setEmailVerifedLoad] = useState(false)
    const [errorButton, setErrorButton] = useState("")
    const {
        getUserProfileData,
        userData,
        accountPercentage,
        userName,
        fbpagePercentage,
        businessPercentage,
        notificationPercentage,
        overallPercentage
    } = useContext(ProfilePageContext);
    const [showPassword, setShowPassword] = useState(false);
    const [accountDetailsLoad, setAccountDetailsLoad] = useState(false);
    const [emailVerifyError, setEmailVerifyError] = useState("")
    const [userProfileData, setUserProfileData] = useState({});
    const [showResetPasswrodCard, setShowRsetPasswordCard] = useState(false);

    function formatPhoneNumber(phoneNumber) {
        if (phoneNumber.startsWith("+91")) {
            return phoneNumber.slice(3);
        }
        if (phoneNumber.length === 10) {
            return phoneNumber;
        }
        return phoneNumber;
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Full Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        phone: Yup.string().test('is-valid-phone', 'Invalid phone number', value => isValidPhoneNumber(value || '')).required('Phone Number is required'),
        password: Yup
            .string('Enter your password')
            .min(8, 'Password should be of minimum 8 characters length')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    });

    const formik = useFormik({
        initialValues: {
            name: userProfileData.name || '',
            email: userProfileData.email || '',
            phone: userProfileData.mobile || '',
            password: '',
        },
        validationSchema,
        onSubmit: (values) => {
            handleAccountDetails(values);
        },
    });

    useEffect(() => {
        if (userToken) {
            getUserProfileData(userToken);
        }
    }, [userToken]);

    useEffect(() => {
        if (userData) {
            setUserProfileData(userData);
        }
    }, [userData]);

    useEffect(() => {
        formik.setValues({
            name: userProfileData.name || '',
            email: userProfileData.email || '',
            phone: '+91' + userProfileData.mobile || '+91',
            password: '',
        });
    }, [userProfileData]);

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const handleAccountDetails = async (formData) => {
        if (formData?.password) {
            if (!userData?.email_verified_at) {
                setErrorButton("Please verify your email")
                return
            }
        }
        const body = { name: formData?.name, email: formData?.email, phone: formatPhoneNumber(formData?.phone), password: formData?.password };

        setAccountDetailsLoad(true);
        try {
            const res = await Axios.post("/complete-profile", body, {
                headers: {
                    Authorization: userToken
                }
            });
            if (res.status === 200 && res.data.valid) {
                console.log("form submitted");
                hotTost.success("Form submitted")
                getUserProfileData(userToken);
            }
        } catch (error) {
            console.error("Error saving account details", error);
        } finally {
            setAccountDetailsLoad(false);
        }
    };

    const onResetPassword = () => {
        setShowRsetPasswordCard(true);
    };


    // verifyemail
    const handleVerifyEmail = async () => {
        setEmailVerifedLoad(true)

        try {
            const res = await Axios.post("/create-token", { requestForEmailVerify: true }, {
                headers: {
                    Authorization: userToken
                },
            })

            if (res.status === 200) {
                if (res.data?.valid) {
                    // setEmailVerifyError(res.data?.message)
                    const messages = res?.data?.message || 'Email verification link send to your email '
                    hotTost.success(messages)
                    // getUserProfileData(userToken)

                } else {
                    hotTost.error(res?.data?.message)
                    setEmailVerifyError(res.data?.message)
                }
                console.log("Email Verified")

            }
        } catch (error) {
            hotTost.error("Enter server error")
            setEmailVerifyError("Enternal server error")
            // console.log("error", error)
        } finally {
            setEmailVerifedLoad(false)
        }
    }


    return (
        <>
            <Toaster position="top-right" />

            <div className="d-md-none">
                <Navbar2 menu={true} />
            </div>
            <div className="container mx-auto bg-[#FBFBFB]">
                <div className='py-3 d-none d-md-block'>
                    <h2 className="ad-main-h text-2xl font-semibold text-start">Account Details</h2>                  
                </div>
                
                <div className="row">

                    {!showResetPasswrodCard ? (
                        <>
                            <div className="col-12 mb-5 mb-md-0 col-md-8 order-2 order-md-1">
                                <div className=' py-0 d-block d-md-none'>
                                    <h2 className="ad-main-h text-start">Account Details</h2>
                                </div>
                                <Card className="p-6 shadow-none bg-white">
                                    <div className='max-w-[400px]'>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className="mb-4">
                                                <label className="block ad-label">Full Name</label>
                                                <Input
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    disabled={!!userProfileData?.name}
                                                    placeholder="Enter Your Name"
                                                    className={`w-full h-11 mt-1 shadow-none ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-300'} ${!!userProfileData?.name ? ' border-gray-300' : ''}`}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <p className="text-red-500 text-sm mt-1">{formik.errors.name}</p>
                                                )}
                                            </div>

                                            {/* <div className="mb-4">
                                                <label className="block ad-label">Email</label>
                                                <div className="relative">
                                                    <Input
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Enter Your Email"
                                                        disabled={!!userProfileData?.email}
                                                        className={`shadow-none h-11 w-full pr-20 mt-1 ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`} // Added padding-right to prevent overlap
                                                    />
                                                    {userData?.email_verified_at ? (
                                                        <button type='button' className="absolute right-0  top-[-4px] h-full bg-transparent px-2 rounded-r flex items-center justify-center mt-1">
                                                            <CircleCheck className="text-green-500" />
                                                        </button>
                                                    ) : (
                                                        <button type='button'
                                                            onClick={handleVerifyEmail}
                                                            className="absolute right-0 top-[-4px] h-full bg-[#62c] text-white px-2 text-[10px] rounded-r mt-1"
                                                        >
                                                            Verify
                                                        </button>
                                                    )}
                                                </div>
                                                {formik.touched.email && formik.errors.email && (
                                                    <p className="text-red-500 text-sm mt-1">{formik.errors.email}</p>
                                                )}
                                            </div> */}



                                            <div className="mb-4">
                                                <label className="block ad-label">Email</label>
                                                <div className="relative flex gap-2 items-center">
                                                <Input
                                                        value={formik.values.email}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        placeholder="Enter Your Email"
                                                        disabled={!!userProfileData?.email_verified_at}
                                                        className={`shadow-none h-11 w-full pr-20 mt-1 ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`} // Added padding-right to prevent overlap
                                                    />
                                                    <div>
                                                        {userData?.email_verified_at ? (""
                                                            // <button type='button' className=" h-11 bg-green-400 text-white px-2 font-semibold text-[12px] w-[60px] rounded mt-1">
                                                            //     Verified
                                                            // </button>
                                                        ) : (
                                                            <button type='button'
                                                                onClick={handleVerifyEmail}
                                                                disabled={emailVerifedLoad? true:false}
                                                                style={{opacity:emailVerifedLoad?'0.7':'1'}}
                                                                className=" bg-[#62c] h-11 text-white px-2 font-semibold text-[12px] w-[60px] rounded mt-1"
                                                            >
                                                                Verify
                                                            </button>
                                                        )}

                                                    </div>

                                                </div>
                                                {formik.touched.email && formik.errors.email && (
                                                    <p className="text-red-500 text-sm mt-1">{formik.errors.email}</p>
                                                )}
                                            </div>







                                            <div className="grid w-full items-center mb-4 gap-1.5">
                                                <label className='block ad-label'>Phone Number</label>
                                                <div className="relative justify-start flex items-center p-0">
                                                    <div className="flex items-center justify-center w-16 border-l border-t border-b border-r-0 h-11 rounded-l">
                                                        {/* <img height={15} width={18} className='' src={indicon} alt="" /> */}
                                                        <span className='font-[600]  text-[14px]' style={{ color: 'rgba(9, 9, 11, 0.3)' }}>+91</span>
                                                        <ChevronDown className='w-5 mt-1 h-5' style={{ color: 'rgba(9, 9, 11, 0.3)' }} />
                                                    </div>
                                                    <Input
                                                        name="phone"
                                                        value={formik.values.phone}
                                                        onChange={value => formik.setFieldValue('phone', value)}
                                                        onBlur={formik.handleBlur}
                                                        defaultCountry="IN"
                                                        placeholder="Enter phone number"
                                                        disabled={!!userProfileData?.mobile}
                                                        className={`shadow-none h-11 w-full flex-1 rounded-l-none ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
                                                    />

                                                    {formik.touched.phone && formik.errors.phone && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.phone}</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mb-4">
                                                <label className="block ad-label">{userProfileData?.password_updated_at ? 'Password' : 'Create Password'}</label>
                                                <div className="relative">
                                                    <Input
                                                        value={userProfileData?.password_updated_at ? '' : formik.values.password}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        type={showPassword ? 'text' : 'password'}
                                                        name="password"
                                                        id="password"
                                                        disabled={!!userProfileData?.password_updated_at}
                                                        placeholder={userProfileData?.password_updated_at ? 'xxxxxxxx' :'Enter Password'}
                                                        className={`w-full  h-11 mt-1 shadow-none ${formik.touched.password && formik.errors.password ? 'border-red-500' : ''}`}
                                                    />
                                                    {userProfileData?.password_updated_at ? '' : <button
                                                        type='button'
                                                        disabled={userProfileData?.password_updated_at ? true : false}
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        className="absolute right-0 top-0 mt-2 mr-2"
                                                    >
                                                        {showPassword ? <EyeOff /> : <Eye />}
                                                    </button>}
                                                </div>
                                                {formik.touched.password && formik.errors.password && (
                                                    <p className="text-red-500 text-sm mt-1">{formik.errors.password}</p>
                                                )}
                                                {userProfileData?.password_updated_at && <p className='text-sm text-end mt-1' style={{ color: '#62c' }}><span style={{ cursor: 'pointer' }} onClick={onResetPassword}>Reset Password</span></p>}
                                            </div>

                                            <div className="mb-4">
                                                <Button type="submit" className="w-[170px] bg-[#62c] shadow-none hover:bg-[#62c]" disabled={(accountDetailsLoad || (userProfileData?.password_updated_at && userProfileData?.mobile && userProfileData?.email_verified_at && userProfileData?.name ))?true:false}>
                                                    Save
                                                </Button>

                                                {errorButton.length > 0 && <p className='m-0 p-0 mt-2 text-start text-red-400 font-[500] text-[12px]'>{errorButton}</p>}
                                            </div>
                                        </form>

                                       
                                    </div>
                                    <div className='text-end'>
                    <DeleteAccount/>
                </div>
                                </Card>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-12 mb-5 mb-md-0 pt-2 pt-md-0 col-md-8 order-2 order-md-1">
                                <Card className="p-6 shadow-none bg-white">
                                    <div className='max-w-[400px]'>
                                        <ResetPasswordCard setShowRsetPasswordCard={setShowRsetPasswordCard} />
                                    </div>
                                </Card>
                            </div>
                        </>
                    )}

                    <div className="col-12 col-md-4 order-1 mb-md-0 order-md-2">
                        <ProfileCompleteCard
                            accountPercentage={accountPercentage}
                            userName={userName}
                            fbpagePercentage={fbpagePercentage}
                            businessPercentage={businessPercentage}
                            notificationPercentage={notificationPercentage}
                            overallPercentage={overallPercentage}
                        />
                    </div>
                </div>

            </div>
            <div className="d-block d-md-none"><BottomNavbar/></div>

        </>
    );
}

export default AccountDetails;
