
import { createContext, useState } from "react";
// import Cookies from "js-cookie";
import Axios from "../Axios";

function checkConnectedSuccess(pages) {
    return pages.map(page => page.connected).includes('success');
}


export const addBudgetContext = createContext()

const AddBudgetProvider = ({ children }) => {

    const [orderDetails, setOrderDetails] = useState(0)
    
// get orders details
const getOrderDetails = async (campdbid, userToken)=>{
 
    try {
       const res = await Axios.get('/get-user-transaction', {headers:{Authorization:userToken}, params:{campaignId:campdbid}});
         if(res.status===200 && res?.data?.valid){
           const orders = res?.data?.order;
          setOrderDetails(orders)
                  console.log("res", res?.data?.order)
         }
    } catch (error) {
      setOrderDetails("")
       console.log("error", error)
    }finally{
  
    }
  }
    



    return (

        <addBudgetContext.Provider value={{orderDetails, getOrderDetails, setOrderDetails }}>
            {children}
        </addBudgetContext.Provider>
    )
}

export default AddBudgetProvider