import { Skeleton, Stack } from '@mui/material'
import React from 'react'

function AdPreviewSkeleton() {
  return (
    <>
      <div className='mt-md-4 mt-1'  style={{width:'90%', height:'380px',}}>
      <Stack spacing={1}>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="text" sx={{ fontSize: '1rem', width:'100%' }} />
      <Skeleton variant="rectangular" sx={{width:'100%'}} height={300} />
     
    </Stack>
      </div>
    </>
  )
}

export default AdPreviewSkeleton