import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../AuthProvider";

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Cookies from "js-cookie";
import Axios from "../../../Axios";
// import { ProfilePageContext } from "../../../context/ProfilePageContext";


function TestingConnectFbPage({handelClientsPage, setToken, fetchFbPageLoad, configId}) {
   
    const {  setFbToken } = useContext(AuthContext);
   
    // const [fetchFbPageLoad, setFetchFbPageLoad] = useState(false)


    useEffect(() => {
        const loadFacebookSDK = () => {
            // Load the JavaScript SDK asynchronously
            const script = document.createElement("script");
            script.id = "facebook-jssdk";
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            script.async = true;

            const firstScript = document.getElementsByTagName("script")[0];
            firstScript.parentNode.insertBefore(script, firstScript);

            // Optional: Clean up the script on component unmount
            return () => {
                const fbSdkScript = document.getElementById("facebook-jssdk");
                if (fbSdkScript) {
                    fbSdkScript.parentNode.removeChild(fbSdkScript);
                }
            };
        };

        window.fbAsyncInit = () => {
            // FB JavaScript SDK configuration and setup
            window.FB.init({
                appId: "1052762895750334",
                // appId: "700560265020731",
                cookie: true,
                xfbml: true,
                version: "v20.0",
            });
        };

        loadFacebookSDK();
    }, []);

    // FB.login(function(response) [    console.log(response);],[    config_id: '<CONFIG_ID>',    response_type: 'code',    override_default_response_type: true]);

    const launchFBE = () => {
        if (window.FB) {

            window.FB.login(function(response){
                console.log("res login", response)
                  const code = response?.authResponse?.code
                     if(code){
                        getFbToken(code)
                     }
            },
            {
              config_id: configId,
              response_type: 'code',
              override_default_response_type: true
            }
        )




            // window.FB.login(
            //     function (response) {
            //         // console.log(response);
            //         if (response.authResponse) {
            //             const accessToken = response?.authResponse?.accessToken;
            //             // console.log("fbresponse", response)
            //             setFbToken(accessToken);
            //             Cookies.set("fbToken", accessToken, { expires: 7 });

            //             handelClientsPage(accessToken);
            //             setToken(accessToken);
                        

            //         } else {
            //             console.log("User cancelled login or did not fully authorize.");
                       
            //         }
            //     },
            //     {
                 
            //         config_id: configId,
                    
            //     },
               
            // );
        }
    };



    const getFbToken = async (code)=>{
           try {
             const res = await Axios.post("/get-facebook-token", {code} )
             console.log("toekn res", res)
             if(res.status===200){
                const data = res?.data;
                console.log("gettoken", data)
                
                }
           } catch (error) {
              console.log("error", error)
           }
    }

    // console.log("config", configId)

    return (
        <>

            <div className="d-flex flex-column justify-content-center align-items-center pt-2">
                <button
                    onClick={launchFBE}
                     className="add-facebook-pages-new"
                   
                >

                 { <FacebookRoundedIcon sx={{ marginRight: 2 }} />}  

                    {'System User'}
                </button>
            </div>

        </>
    );
}

export default TestingConnectFbPage;
