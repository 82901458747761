import { Card } from '@mui/material'
import React from 'react'
import './profilecomponent.css'
import checkedIcon from '../../../asets/account-details-icons/check-circle.svg'
import crossicon from '../../../asets/account-details-icons/x-square.svg'
import CircularProgress from '@mui/material/CircularProgress';
import {  useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom'


function ProfileCompleteCard({accountPercentage, userName, fbpagePercentage, businessPercentage, notificationPercentage, overallPercentage}) {

     const theme = useTheme();
     const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
 
     const size = isSmallScreen ? 64 : 100; // Adjust size based on screen size
     // const firstLetter = userName ?userName.charAt(0) : '';

     return (
          <div className="complete-profile-card-container mt-3 mt-md-0 pb-md-0" style={{ width: '100%' }}>
               <Card sx={{borderRadius:'12px', boxShadow: 'none',  minWidth:'240px', width: '100%', border: '1px solid rgba(203, 213, 225, 1)', backgroundColor: 'rgba(255, 255, 255, 1)' }} className='p-1 p-md-3'>
              
                    <div className="complete-profile-card gap-3  gap-md-1 flex sm:flex-row md:flex-col lg:flex-col xl:flex-col justify-center items-center">
                         
                         
                          <div>
                          <div className=" flex justify-center items-center">
                              <Box position="relative" display="inline-flex complte-profile-circular-box" >
                                   <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={size}
                                        sx={{
                                             color: 'rgba(204, 204, 204, 0.3)', // Background color of the circle (black)
                                             position: 'absolute',
                                        }}
                                   />
                                   <CircularProgress
                                        variant="determinate"
                                        value={overallPercentage}
                                        size={size}
                                        sx={{
                                             color: '#62c', // Foreground color of the progress part (blue)
                                        }}
                                   />
                                   <Box
                                        top={0}
                                        left={0}
                                        bottom={6}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                   >
                                        <div className='profile-circle-percentage-inner flex justify-center items-center'>
                                             <Typography variant="h6" sx={{ color: 'rgba(102, 34, 204, 1)', fontSize:'14px', fontWeight:'600' }} className='firstName-text' style={{}} component="div">
                                                  {overallPercentage}%
                                             </Typography>
                                        </div>

                                   </Box>
                              </Box>
                             
                         </div>
                          </div>
                         
                          <div className='w-[100%]'>
                          
                          <div className=" mb-1 mb-md-3 mt-2">
                          <div className='mb-1 mb-md-3 d-block d-md-none'>
                              <p className='m-0 p-0 complete-profile-text text-md-center '>Complete Profile</p>
                         </div>
                              <p className='p-0 m-0 text-start d-none d-md-block text-md-center ad-cp-name'>Hello {userName}</p>
                            {overallPercentage !== 100 && <p className='p-0 m-0 ad-freemembership-p text-start text-md-center'>🎉 Hurry! Free Membership For First 50 Users </p>}  
                         </div>
                         <div className='mb-1 mb-md-3 d-none d-md-block'>
                              <p className='m-0 p-0 complete-profile-text text-md-center '>Complete Profile</p>
                         </div>
                         <div style={{ width: '100%' }} className=' flex justify-content-md-center justify-content-start'>
                              <div className=' pe-1 ps-md-3 complete-ptrofile-percentage-card ' >
                                   <div className='d-flex pe-1 pe-md-0 gap-x-2 gap-md-0 align-items-start align-items-md-center flex-col flex-wrap sm:flex-col md:flex-row flex-md-column justify-between'>
                                   <div className=' mb-1 percentage-card-width mb-md-3 w-full flex gap-1 gap-md-3 items-center justify-start' style={{ opacity:accountPercentage===20?'1':'0.6',  }}>
                                   <Link  className='flex-1' to="/account-details">
                                        <div className='flex justify-start  items-center' style={{maxWidth:'100%'}}>
                                             <img className='complete-pro-icon' src={accountPercentage===20?checkedIcon:crossicon} alt="" />&nbsp;
                                             <p className='p-0 m-0 ad-cp-p'>Account Details</p>

                                        </div>
                                        </Link>
                                        <div className=' percentage-width'>
                                             <p className='p-0 m-0 ad-cp-p text-end'>+20%</p>
                                        </div>
                                  
                                   </div>

                                   <div className=' mb-1 percentage-card-width mb-md-3 flex gap-1 gap-md-3 items-center justify-start' style={{ opacity:businessPercentage===30?'1':'0.6' }}>
                                   <Link  className='flex-1' to="/business-details">
                                        <div className='flex justify-start items-center'>
                                             <img className='complete-pro-icon' src={businessPercentage===30?checkedIcon:crossicon} alt="" />&nbsp;
                                             <p className='p-0 m-0 ad-cp-p'>Business Details</p>

                                        </div>
                                        </Link>
                                        <div className='percentage-width'>
                                             <p className='p-0 m-0 ad-cp-p text-end'>+30%</p>
                                        </div>

                                   </div>
                                   </div>
                                   
                                   <div className='d-flex pe-1 pe-md-0 gap-x-2 justify-content-between  gap-md-0 flex-md-column flex-col sm:flex-col flex-wrap md:flex-row align-items-start align-items-md-center'>
                                   <div className=' mb-1 percentage-card-width mb-md-3 flex gap-1 gap-md-3 items-center justify-start' style={{ opacity:fbpagePercentage===40?'1':'0.6'  }}>
                                   <Link className='flex-1' to="/facebook-page">
                                        <div className='flex justify-start items-center '>
                                             <img className='complete-pro-icon' src={fbpagePercentage===40?checkedIcon:crossicon} alt="" />&nbsp;
                                             <p className='p-0 m-0 ad-cp-p'>Facebook Page</p>

                                        </div>
                                        </Link>
                                        <div className='percentage-width'>
                                             <p className='p-0 m-0 ad-cp-p text-end'>+40%</p>
                                        </div>

                                   </div>

                                   <div className=' mb-1 mb-md-3 percentage-card-width flex gap-1 gap-md-3 items-center justify-content-start' style={{ opacity:notificationPercentage===10?'1':'0.6' }}>
                                       <Link className='flex-1' to="/notification-settings">
                                       <div className='flex justify-start items-center '>
                                             <img className='complete-pro-icon' src={notificationPercentage===10?checkedIcon:crossicon} alt="" />&nbsp;
                                             <p className='p-0 m-0 ad-cp-p text-start'>Notification</p>

                                        </div>
                                       </Link>
                                        
                                        <div className='percentage-width'>
                                             <p className='p-0 m-0 ad-cp-p text-end'>+10%</p>
                                        </div>

                                   </div>
                                   </div>
                                  
                              </div>
                         </div>
                          </div>


                    </div>
               </Card>
          </div>
     )
}

export default ProfileCompleteCard