import React, { useState, useEffect , useContext} from 'react';
import { addDays } from "date-fns";
import { Calendar } from '../../../components/ui/calendar';
import { cn } from "../../../lib/utils";
import { format, isBefore, isSameDay } from "date-fns"
// import { zonedTimeToUtc } from 'date-fns-tz';
import './addbudgetforcampaign.css';
import editicon from '../../../asets/account-details-icons/Group 1000008470.svg';
import calendaricon from '../../../asets/account-details-icons/Group 1000008498.svg';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import { Card } from '../../../components/ui/card';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { AuthContext } from '../../../AuthProvider';
import Axios from '../../../Axios';
import PaymentForAddBudget from './AddBudgetPayment';
import { useSnackbar } from '../../../SnackBarProvider';
import PaymentLoader from '../../../paymentmgn/PaymentLoader';

function formatedCampDate(dateString) {
  const date = new Date(dateString);

  // Define options for formatting the date
  const options = { year: 'numeric', month: 'short', day: 'numeric' };

  // Convert the date to the desired format
  return date.toLocaleDateString('en-US', options);
}

function formatToIndianNumber(amount) {
  return new Intl.NumberFormat('en-IN').format(amount);
}


function AddBudgetForCampaign({ className, addBudgetInfo, campaignIdForAddBudget, campaingfbId, budgetPrice }) {
  const {userDetails, userToken, handleAllFBApiCall, laodAddBudget,   setLoadAddBudget, fetchCampaignInsites} = useContext(AuthContext)
  const payForAddBudgetWithRazorpay = PaymentForAddBudget()
  const [date, setDate] = useState()
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = React.useState(new Date())
  const [budget, setBudget] = useState('');
  const [isPayWithWallet, setIsPayWithWallet] = useState(false)
  const [budgetError, setBudgetError] = useState('');
  const [calenderFormatedDate, setCalenderFormatedDate] = useState("")
  const [usedBallance, setUsedBallance] = useState(0)
  const [payableAmounts, setPayableAmounts] = useState(0)
  const [open, setOpen] = useState(false);
 
  const showSnackbar = useSnackbar();

  const [ budgetCardInfo, setBudgetCardInfo] = useState({
      increase_camp_budget:0,
      sub_total:0,
      gst:0,
      total:0,
      amount_payable:0,
      used_wallet_balance:0,
      wallet:0,
      total_razorpay_payment:0
  })

  const [campDate, setCampDate] = useState({
    start_date: '',
    end_date: ''
  })

  const handleButtonClick = () => {
    setShowCalendar(!showCalendar);
  };

  // handle select calander
  const handleSelect = (selectedDate) => {
    if (selectedDate) {
      
      const convertedDate = new Date(selectedDate)?.toISOString();
      // console.log("dateselect", convertedDate)
      setCalenderFormatedDate(convertedDate)
      setDate(selectedDate)
      setOpen(false)
    } else {
      setDate(null);
      setCalenderFormatedDate('')
      setOpen(false)
    }
  };



  const isDisabled = (date) => {
    const today = new Date()
    return isBefore(date, today) || isSameDay(date, today)
  }

  const handleMonthChange = (newMonth) => {
    setCurrentMonth(newMonth)
  }



  React.useEffect(() => {
    const previousButton = document.querySelector(".react-calendar__navigation__prev-button")
    if (previousButton) {
      if (isBefore(currentMonth, new Date())) {
        previousButton.setAttribute("disabled", "true")
      } else {
        previousButton.removeAttribute("disabled")
      }
    }
  }, [currentMonth])

  useEffect(() => {
    if (addBudgetInfo) {
      setCampDate({ ...campDate, start_date: formatedCampDate(addBudgetInfo?.start_date), end_date: formatedCampDate(addBudgetInfo?.end_date) })
    }
  }, [addBudgetInfo])


  const handleAddBudgetChange = (e) => {
    const value = e.target.value;
    setBudget(value);

    // Validate the input
    if (!/^\d*$/.test(value)) {
      setBudgetError('Budget should be a number');
    } else if (value && Number(value) < 1000) {
      setBudgetError('Budget should be at least 1000');
    } else {
      setBudgetError('');
    }
  };


  useEffect(() => {
    if (budget > 999) {
      const walletBalance = Number(userDetails?.wallet) || 0;
      const budgetChange = Number(budget);
      const subtotal = budgetChange;
      const gst = budgetChange * 18 / 100;
      const totalWithGst = budgetChange + gst;
  
      setBudgetCardInfo(prevState => ({
        ...prevState,
        increase_camp_budget: budgetChange,
        sub_total: subtotal,
        total: totalWithGst,
        gst: gst,
        wallet: walletBalance
      }));
  
      if (walletBalance >= totalWithGst) {
        const usedBalance = totalWithGst;
        setUsedBallance(usedBalance);
        setPayableAmounts(0);
        setBudgetCardInfo(prevState => ({
          ...prevState,
          amount_payable: 0,
          used_wallet_balance: usedBalance,
          total_razorpay_payment: 0
        }));
        setIsPayWithWallet(true);
      } else {
        const payableBalance = totalWithGst - walletBalance;
        const usedBalance = walletBalance;
        setUsedBallance(usedBalance);
        setPayableAmounts(payableBalance);
        setBudgetCardInfo(prevState => ({
          ...prevState,
          amount_payable: payableBalance,
          used_wallet_balance: usedBalance,
          total_razorpay_payment: payableBalance
        }));
        setIsPayWithWallet(false);
      }
    }
  }, [budget, userDetails?.wallet]);




  const createOrder = async (campaignBody) => {
    // setLoadCreateOrder(true)
    setLoadAddBudget(true)
    
    //  console.log("adcampaignBody.used_wallet_spend", adcampaignBody.used_wallet_spend)
    const body = {
      total_amount:budget,
      amount: Math.floor(Number(campaignBody.payable_amount)) * 100, //in smallest currendcy unit. i.e in paisa
      currency: "INR",
      receipt: "Anurag#955",
      used_wallet_spend:campaignBody?.used_wallet_spend,
      notes: {
        description: "payment against ad campaign",
         campaign: campaignIdForAddBudget,
      }
    }

        if(campaignBody?.endDate){
          body['end_date'] = campaignBody?.endDate
        }

    try {
      const res = await Axios.post("/create-order", body, {
        headers: {
          Authorization: userToken
        }
      })
      if (res.status === 200 && res?.data?.valid) {
         
        const data = res?.data?.transaction;
        const isEndDateExist = res?.data?.end_date?res?.data?.end_date:''
          //  console.log("create order", data)
        // const orderid = res?.data?.orderId

            if(Number(data?.total_rzp_amount)){
                
            //  razorpay trasaction

               const prefilledDetails = { name: userDetails?.name, email: userDetails?.email, number: userDetails?.mobile }
               const  Body = {
                campaign_id:campaignIdForAddBudget,
                used_wallet_spend:data?.amount || 0,
                payable_amount:data?.total_rzp_amount || 0,  
               }
                if(isEndDateExist?.length>0){
                  setDate("")
                  setCalenderFormatedDate("")
                    Body['endDate'] = isEndDateExist;
                }
              //  Body['endDate'] = campaignBody?.EndDate

              //  console.log("prefilledDetails", prefilledDetails)
         
               payForAddBudgetWithRazorpay(data?.total_rzp_amount, Body, data?.order_id, prefilledDetails)


              //  update state
              setBudget("")
              setBudgetCardInfo(prevState => ({
                ...prevState,
                increase_camp_budget:0,
                sub_total:0,
                gst:0,
                total:0,
                amount_payable:0,
                used_wallet_balance:0,
                wallet:0,
                total_razorpay_payment:0
              }));
               
              }else{
               

                // wallet trasaction
              const  Body = {
                campaign_id:campaignIdForAddBudget,
                used_wallet_spend:data?.amount || 0,
                payable_amount:data?.total_rzp_amount || 0,  
               }

               if(isEndDateExist?.length>0){
                setDate("")
                setCalenderFormatedDate("")
                Body['endDate'] = isEndDateExist;
            }

               
              handleAddBudget(Body)


              // update state
              setBudget("")
              setBudgetCardInfo(prevState => ({
                ...prevState,
                increase_camp_budget:0,
                sub_total:0,
                gst:0,
                total:0,
                amount_payable:0,
                used_wallet_balance:0,
                wallet:0,
                total_razorpay_payment:0
              }));
            }


        
      }
    } catch (error) {
      console.log(error)
      setBudget("")
      setBudgetCardInfo(prevState => ({
        ...prevState,
        increase_camp_budget:0,
        sub_total:0,
        gst:0,
        total:0,
        amount_payable:0,
        used_wallet_balance:0,
        wallet:0,
        total_razorpay_payment:0
      }));
      
      
    }finally{
      setLoadAddBudget(false)
      // setLoadCreateOrder(false)
    }
  }





  const handleAddBudget = async (Body)=>{ 
    setLoadAddBudget(true)

   const body = {
      campaignId: Body.campaign_id,
      used_wallet_spend:Body.used_wallet_spend,
      payable_amount: Body.payable_amount,
      
    }
   
   try {
      const res = await Axios.post("/update-campaign-budget", body, {headers:{Authorization:userToken}});
        if(res.status===200 && res?.data?.valid){


          // hotTost.success("Campaign budget updated")
           showSnackbar("Success: Campaign budget updated", 'success');
            
             handleAllFBApiCall(userToken)
             fetchCampaignInsites(userToken)
             setBudget("")
             setBudgetCardInfo(prevState => ({
              ...prevState,
              increase_camp_budget:0,
              sub_total:0,
              gst:0,
              total:0,
              amount_payable:0,
              used_wallet_balance:0,
              wallet:0,
              total_razorpay_payment:0
            }));
            
            if(Body?.endDate){
              const status = 1
             updateEndDate(Body?.endDate, status)
            }else{
              window.location.reload()
            }
             
            // window.location.reload()
        }else{
          showSnackbar("Failed to update the campaign budget, try again later", 'error');
        }

       
   } catch (error) {
    // setEndDateLoad(false)
      console.log("error", error)
      showSnackbar("Failed to update the campaign budget", 'error');
      // hotTost.error("")
      handleAllFBApiCall(userToken)
      fetchCampaignInsites(userToken)

      setBudget("")
      setBudgetCardInfo(prevState => ({
        ...prevState,
        increase_camp_budget:0,
        sub_total:0,
        gst:0,
        total:0,
        amount_payable:0,
        used_wallet_balance:0,
        wallet:0,
        total_razorpay_payment:0
      }));
   }finally{
      setTimeout(()=>{
        setLoadAddBudget(false)
      }, 1000)
    

   }
}





  const updateEndDate = async (enddate, status)=>{ 
    setLoadAddBudget(true)
       const body = { 
        campaignId: campaignIdForAddBudget,  //campaign's data base id
        endDate: enddate
    }
       try {
          const res = await Axios.post("/update-end-date", body, {headers:{Authorization:userToken}});
            if(res.status===200 && res?.data?.valid){
                // hotTost.success("end date updated")
                  if(status){
                    window?.location?.reload()
                  }else{
                    showSnackbar("Success: End date updated", 'success');
                    setDate("")
                    setCalenderFormatedDate("")
                    handleAllFBApiCall(userToken)
                  }
               
            }else{
              showSnackbar("Unable to update end date, please try again later", 'error');
            }
       } catch (error) {
        // setEndDateLoad(false)
          console.log("error", error)
          showSnackbar("Unable to update end date", 'error');
          // hotTost.success("unable to update end date")
       }finally{
        setLoadAddBudget(false)
        // setEndDateLoad(false)
       }
  }


 
  


const confirmAndPay = async ()=>{

  if(calenderFormatedDate && campaignIdForAddBudget && Number(budget)<1000){
    updateEndDate(calenderFormatedDate)
  }

  if(Number(budget)>999){

       let  campaignBody = {
            campaign_id:campaignIdForAddBudget || "",
            used_wallet_spend:budgetCardInfo?.used_wallet_balance || 0,
            payable_amount:budgetCardInfo?.total_razorpay_payment || 0,  

           }

           campaignBody['endDate'] = calenderFormatedDate
      
            createOrder(campaignBody)  
       
  }
                       
}


  return (
    <>
     <Toaster position="top-right" />

    <div>
      <div className='max-w-[100%]'>
        <div className='mb-2 mb-md-3'>


          <div className="add-budget-container  px-md-2 flex flex-wrap justify-between items-center">
            <div className='flex justify-start items-center gap-2'>
              <div className="addbudget-left">
                <div className='w-[16px] h-[16px] flex justify-center items-center'>
                  <img className='max-w-[100%] h-auto' src={calendaricon} alt="" />
                </div>
              </div>
              <div className="addbudget-right">
                <p className='text-[14px] p-0 m-0 font-[500] leading-[18.23px] text-[#3B3B3B]'>Schedule & Budget</p>
                {/* <p className='text-[12px] p-0 m-0 font-[400] text-[#767C7C]'>Jan 20, 2023 - Jan 28, 2023</p> */}
              </div>
            </div>

            <div className="edit-container flex justify-center items-center">
              <div className='w-[18px] h-[18px] flex justify-center items-center'>
                <button onClick={handleButtonClick}>
                  <img src={editicon} className='w-[17px] h-[17px]' alt="" />
                </button>
              </div>
            </div>
          </div>

          {/* Add calendar */}
          <div className={cn("calendar-container mt-3 ", showCalendar && "open")}>
            <div className='flex items-start flex-col justify-between'>


              <div className=' py-2'>
                <div className=''>

                  <div className='flex items-center flex-wrap gap-4 mb-4 justify-between '>
                    <div className="add-start-date">
                      <p className='text-start p-0 m-0 mb-2 text-[#353A44] font-[500] text-[14px] leading-[14.33px]'>Start Date</p>
                      {/* calender */}
                      <Button variant={"outline"} className={cn("w-[150px] text-[#767C7C] justify-start cursor-not-allowed text-left font-normal px-2")} style={{ border: '1px solid #E4E4E7', cursor:'not-allowed' }} >
                        <CalendarIcon className="mr-2 h-4 w-4 text-[#71717A]" />
                        {campDate?.start_date ? <>{campDate?.start_date}</> : <span className='text-[14px] leading-[20px]'>Start Date</span>}
                      </Button>

                    </div>

                    <div className="add-end-date">
                      <p className='text-start p-0 m-0 mb-2 text-[#353A44] font-[500] text-[14px] leading-[14.33px]'>End Date</p>
                      {/* calender */}
                      <Popover className="" open={open} onOpenChange={setOpen}>
                        <PopoverTrigger className='z-[99999]' asChild>
                          <Button variant={"outline"} className={cn("min-w-[150px] justify-start text-[14px] text-[#767C7C] text-left font-normal px-2", !date && "text-muted-foreground")} style={{ border: '1px solid #E4E4E7' }} >
                            <CalendarIcon className="mr-2 h-4 w-4 #71717A" />
                            {date ? formatedCampDate(date) : <span className='text-[14px] leading-[20px] '>{campDate?.end_date}</span>}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                            mode="single"
                            className="z-[999999999]"
                            selected={date}
                            onSelect={handleSelect}
                            initialFocus
                            disabled={isDisabled}
                            onMonthChange={handleMonthChange}
                          />
                        </PopoverContent>
                      </Popover>

                    </div>
                  </div>

                  <div className="add-budget-container mb-3">
                    <div className=''>
                      <p className='text-start p-0 m-0 mb-2 text-[#353A44] font-[500] text-[14px] leading-[14.33px]'>Add Budget</p>
                      <div className=''>
                        <div className='max-w-[150px]   flex justify-start items-center p-0 m-0'>
                          <div className='rounded-l-sm shadow-sm bg-white h-9 flex justify-center items-center' style={{ border: '1px solid #E4E4E7' }}>
                            <p className='p-0 text-center m-0 text-[14px] font-[400] w-[20px] text-[#596171]'>₹</p>
                          </div>

                          <Input type="text" value={budget} onChange={handleAddBudgetChange} placeholder="Add Budget" className=" w-120px rounded-l-none bg-white border-l-0 text-[#596171]" style={{ border: '1px solid #E4E4E7' }} />
                          {/* <div className='ms-2'>
                            <Button disabled={(loadCreateOrder || budget<10000)?true:false} onClick={handleConfirmAndPayForAddBudget} className="shadow-sm h-9 w-12 bg-[#62c] text-[24px] hover:bg-[#62c]">+</Button>
                          </div> */}

                        </div> 
                        {budgetError && <p className='p-0 m-0 text-[10px] text-red-500 text-start'>{budgetError}</p>}
                      </div>
                    </div>
                  </div>
                  <div className='mt-3 d-none'>
                    <p className='p-0 m-0 text-[18px] font-[500] text-[#596171]'>Amount Payable <b className='text-[#353A44]'>INR 11,800</b></p>

                    <div className='mt-3'>
                      <Button className="bg-[#62c] shadow-sm text-white hover:bg-[#62c]">Confirm And Save </Button>
                    </div>
                  </div>


                </div>
              </div>

               
            {Number(budget)>999 &&  <div className=' min-w-[230px] mb-3 max-w-[256px]'>
                <div className='p-1'>
                  <Card className="p-2 shadow-sm">
                    <div>
                      {/* <div className='mb-3 bg-[#f4f4f580]'>
                        <p className='p-0 mb-2 m-0 text-[#353A44] font-[600] text-[11px]'>{orderDetails?.tranId}</p>
                        <p className='p-0 m-0  text-[#596171] font-[400] text-[11px]'>{orderDetails?.createdAt}</p>

                      </div> */}

                      <div className='mb-3'>
                        <p className='p-0 mb-2 m-0 text-[#353A44] font-[600] text-[10px]'>Add Budget Details</p>
                        <div className='flex items-center justify-between mb-2'>
                          <p className='p-0 m-0 text-[#596171] text-[10px] font-[500]'>Additional Budget</p>
                          <p className='p-0 m-0 text-[#353A44] font-[400] text-[10px]'>INR {formatToIndianNumber(budgetCardInfo?.increase_camp_budget || 0)}</p>
                        </div>
                          
                        <div className='flex items-center justify-between'>
                          <p className='p-0 m-0 text-[#596171] text-[10px] font-[500]'>Updated Campaign Budget</p>
                          <p className='p-0 m-0 text-[#353A44] font-[400] text-[10px]'>INR {formatToIndianNumber((Number(budgetPrice) + Number(budgetCardInfo?.increase_camp_budget)) || 0)}</p>
                        </div>

                      </div>
                      <div>
                        <hr className='text-[#596171] ' />
                      </div>

                      <div className="mb-2 flex justify-between items-center">
                        <p className='p-0 m-0  text-[#596171] font-[400] text-[10px]'>SubTotal</p>
                        <p className='p-0 m-0 text-[#353A44] font-[400] text-[10px]'>INR {formatToIndianNumber( budgetCardInfo?.sub_total || 0)}</p>
                      </div>

                      <div className="mb-2 flex justify-between items-center">
                        <p className='p-0 m-0  text-[#596171] font-[400] text-[10px]'>GST @18%</p>
                        <p className='p-0 m-0 text-[#353A44] font-[400] text-[10px]'>INR {formatToIndianNumber( budgetCardInfo?.increase_camp_budget * (18/100) ) || 0}</p>
                      </div>

                      <div className="mb-2 flex justify-between items-center">
                        <p className='p-0 m-0  text-[#353A44] font-[600] text-[11px]'>Total</p>
                        <p className='p-0 m-0 text-[#353A44] font-[400] text-[11px]'>INR {formatToIndianNumber( budgetCardInfo?.total) || 0}</p>
                      </div>

                      <div className="mb-2 flex justify-between items-center">
                        <p className='p-0 m-0 text-[#353A44] font-[500] text-[10px]'>Payable</p>
                      </div>

                      <div className=" mb-2 flex justify-between items-center">
                      <p className='p-0 m-0  text-[#596171] font-[400] text-[10px]'>Wallet Balance</p>
                      <p className='p-0 m-0 text-[#353A44] font-[400] text-[10px]'>INR {formatToIndianNumber(Number( budgetCardInfo?.wallet)) || 0}</p>
                    </div>

                      <div className=" mb-2 flex justify-between gap-1 items-center">
                        <p className='p-0 m-0  text-[#353A44] font-[500] text-[13px]'>Amout Payable</p>
                        <p className='p-0 m-0 text-[#353A44] font-[500] text-[13px]'> INR {formatToIndianNumber(Number( budgetCardInfo?.amount_payable)) || 0}</p>
                      </div>

                    </div>
                  </Card>
                </div>
              </div>}  
           

            </div>

            {/* confirm and pay */}

            <div className=''>
            {(Number(budget)>999) &&  <p className='p-0 m-0 mb-3 text-[14px] font-[500] text-[#596171]'>Amount Payable <b className='text-[#353A44]'> INR {formatToIndianNumber(Number(budgetCardInfo?.total_razorpay_payment))}</b></p> }

              <div className='mt-0 mb-3'>
                <Button onClick={confirmAndPay} disabled={(laodAddBudget)?true:false}   className="bg-[#62c] w-[150px] shadow-sm text-white hover:bg-[#62c]"> {budget>999?'Save And Pay':'Save'} </Button>
              </div>
            </div>

          </div>
        </div>
       
      </div>
    </div>

    </>
  );
}

export default AddBudgetForCampaign;
