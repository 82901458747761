import React, { useState, useEffect } from 'react';
import './auth.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import logo from '../asets/auth/Leapx-Meta 1.png'
import gift from '../asets/auth/gift.png'
import rocket from '../asets/auth/rocket.png'
import { jwtDecode } from "jwt-decode";
import {  NavLink, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import bgimg from '../asets/auth/girl 1.jpg'
import { GoogleLogin } from '@react-oauth/google';
import Axios from '../Axios';
import { CircularProgress } from "@mui/material";
// import SlickSlider from './SlickSlider';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';



const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

function UserSignup() {
  const [errorSignup, setErrorSignup] = useState("")
  const [userSsoLoad, setUserSsoLoad] = useState(false)
  const navigate = useNavigate()


  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const userdetails = { name: "", email: values?.email, googleId: "" }
      // const decoded = {name:"", email:values.email}
      CheckEmailAlreadExistInDb(values?.email, userdetails)
    },
  });

  
  const CheckEmailAlreadExistInDb = async (email, userDetails) => {
    setUserSsoLoad(true)
    try {
      const res = await Axios.get("/check-email", {
        params: {
          email: email
        }
      })
      if (res.status === 200) {
        // console.log("res", res.data)

        if (res.data.valid) {
          setErrorSignup("")
          navigate('/signup-mobile', { state: { userDetails } });
        } else {
          const errors = res?.data?.message
          if (errors) {
            setErrorSignup(errors)
          }

          // alert(res?.data?.message)
        }
      }
    } catch (error) {

      const serverError = "Server Error"
      setErrorSignup(serverError)

      //  console.log("error", error)
    } finally {
      setUserSsoLoad(false)
    }
  }


  const responseGoogle = (response) => {
    const jwtToken = response?.credential
    const decoded = jwtDecode(jwtToken);
    if (decoded?.email) {
      const userdetails = { name: decoded?.name, email: decoded?.email, googleId: decoded?.sub }
      CheckEmailAlreadExistInDb(decoded?.email, userdetails)
      // setUserssoDetails({...userssodetails, name:decoded?.name, email:decoded?.email})
    }
    // console.log("google", response);
  };

  useEffect(() => {
    
     setErrorSignup("")
    
  }, [formik.values.email])
  

  return (


    <>
      <div className="signup-container">
          
      <div className="signup-left-container position-relative ">
             <div className=' flex justify-center sm:items-start md:items-center position-relative'>
             <img  src={bgimg} className='auth-bgimage d-none d-md-block' alt="" />
             
             </div>
             {/* <div className="slick-slider-container flex justify-center items-center">
              <SlickSlider/>
             </div> */}
            
          </div>

          
         <div className=' signup-right-container'>
         <div className="usersignup-card">
          
        <div className="usersignupcard-inner">

        
          <div className='signup-card'>
            <div className='text-start form-card-login signup-form-container mt-md-3 py-3'>
              <img src={logo} className="img-top-logo " alt="" />
            </div>
            <div className='mt-5'>
            <div className='mt-md-3 mt-3 signup-form-container'>
              {/* <h3 className="text-start d-md-none px-md-1  google-heading-signup ">SignUp</h3> */}
              <p className=" d-md-block text-start mb-4 google-heading-signup" >Sign Up</p>
             
            </div>


            <div className="signup-form-container">
              {/* <div className="signup-logo mb-4 mb-md-4">

                <p className='get-started'><img src={rocket} style={{width:'20px', height:'auto'}} alt="" /> First 50 users get 1st year free <img src={gift} style={{width:'20px', height:'auto'}} alt="" /> (Worth INR 99,999/year)</p>
              </div> */}

              <div className="signup-from">
                <div className="signup-email-label">
                  <p className='' >Enter your email to begin</p>
                </div>

                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4 mb-md-3">

                    <TextField
                      id="outlined-basic"
                      fullWidth
                      size='small'
                      variant="outlined"

                      placeholder='Enter Your Email'
                      name="email"
                      color="secondary"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          formik.handleSubmit();
                        }
                      }}

                      InputProps={{
                        startAdornment: (
                          <InputAdornment sx={{color:'#62c', padding:'0px', fontWeight:'600'}} position="start">
                            <EmailIcon sx={{fontSize:'26px'}}/>
                          </InputAdornment>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '0.8rem', // Change this to your desired font size
                          },
                        },
                      }}
                      

                      
                    />
                  </div>
                  <div className="signup-google-sso-container mt-md-4 mt-4">
                    <button disabled={(userSsoLoad) ? true : false} type="submit" className="submit-button"  >{(userSsoLoad) ? <CircularProgress size={20} color="inherit" /> : 'Next'}  </button>
                    {/* <Button sx={{backgroundColor:'#62c'}} variant="contained" type="submit">
              {userSsoLoad ? <CircularProgress size={18} color="inherit" /> : 'Next'}
              </Button> */}
                    {errorSignup.length > 0 && <p className='text-danger'>{errorSignup}</p>}
                  </div>
                </form>
                   {/* Or */}
                <div className="d-flex  align-items-center my-2">
                      <p className="border" style={{height:'0.7px', width:'49%', overflow:'hidden'}}></p>
                      <p style={{fontSize:'14px'}}>OR</p>
                      <p className="border" style={{height:'0.7px',  width:'49%', overflow:'hidden'}}></p>
                    

                    </div>

                <div className=" mt-2 mb-3 " style={{ width: '100%' }}>
                  <div className="text-start d-flex justify-content-center align-items-center google-login-container">
                    <GoogleLogin
                     width='300px'
                      text='signup_with'
                     size='large'
                      onSuccess={responseGoogle}
                      onFailure={() => console.log('Login Failed')}
                    />
                  </div>
                  <div className='mt-5 mt-md-4 mb-4 mb-md-2'>
                  <p className=" text-center signup-already-account" >
                      Already have an account?{" "}
                      <NavLink className="text-primary-imp fw-bold" to="/login">
                        Login
                      </NavLink>
                    </p>
                  </div>
                  
                </div>

              </div>

            </div>

            <div className='my-3 terrm-of-use-container'>
              <p className='p-0 m-0 term-of-user' style={{ }}>By continuing you agree to our <a href="https://www.leapx.ai/privacy-policy" style={{ color: '#62c' }} target='_blank'>privacy policy</a> and <a style={{ color: '#62c' }} href="https://www.leapx.ai/terms-of-use" target='_blank'>terms of use</a></p>
            </div>
            </div>
           
          </div>
          </div>
        </div>
     
        </div>
         </div>
        

     


    
    </>




  );
}

export default UserSignup;
