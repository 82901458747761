import { Button, Card} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import './leadpage.css';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import Axios from '../../Axios';
import { AuthContext } from '../../AuthProvider';



const LeadsPageForm = ({singleleads, setisShowForm}) => {
    const [showCommet, setShowComment] = useState(false)
    // const [ cardShow,  setCardShow] = useState(false)
    const [commentMessage, setCommentMessage] = useState("")
    const {userToken} = useContext(AuthContext)
    const [allComment, setAllComment] = useState([])
    

    
   

    const excludedKeys = ["email", "full_name", "phone_number", "created_time", "id", 'comment'];
//console.log("leads", singleleads)

    // useEffect(()=>{
    //    setCardShow(false)
    // },[singleleads])
   // console.log("singleleads", singleleads)

  
  
  

 const addComment = ()=>{
    setShowComment(!showCommet)
 }

 const getSingLeadData = async (id)=>{
  try {
   const res = await Axios.get(`/get-lead-data?leadId=${id}`,  {
     headers:{
         Authorization:userToken
     },
     params:{

     }
  })
      if(res.status===200){
           const data = res.data.message;
              setAllComment(data?.comment)
            
      }
  } catch (error) {
   
  }
}


useEffect(()=>{
     if(singleleads?.id){
      getSingLeadData(singleleads?.id)
     }
},[singleleads?.id])


const handleAdComment = async (leadid)=>{
  const body ={
    leadId:leadid,
    
}
 if(commentMessage){
  body['comment'] = commentMessage
 }

  try {
     const res = await Axios.post("/update-lead", body, {
        headers:{
            Authorization:userToken
        }
     })
          if(res.status===200){
                      if(res?.data?.valid){
                        getSingLeadData(leadid) 
                        setCommentMessage("")         
                      }             
          }
  } catch (error) {
      console.log(error)

  }finally{
    
  }
 }

//format date 
function formatedDate(inputDate) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(inputDate);
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${months[monthIndex]} ${day}, ${year}`;
  return formattedDate;
}

const onShowForm = ()=>{
    setisShowForm(false)
}


 //console.log("allcomment", allComment)

    return (
        <>
           

            <div className="form-contain position-sticky" style={{ overflowY:'scroll', maxHeight:'90vh', top:'70px'}}>
            <Card sx={{ width:'100%', paddingBottom:'14px' }}>
                <div className='top-card mt-4 pb-4 px-3 ' style={{borderBottom:'2px solid #C2C1C6'}} >
                <div className=" d-flex justify-content-between align-items-center">
                       {singleleads?.full_name?.length>0?<p className='' style={{fontSize:'1.5rem', fontWeight:'700'}}>{singleleads?.full_name}</p> :null} 
                    
                      <p onClick={onShowForm} className='d-flex justify-content-center align-items-center' style={{width:'24px', height:'24px', border:'1px solid #C2C1C6', borderRadius:'6px', cursor:'pointer'}}><CloseIcon sx={{color:'#828B96', fontSize:'14px'}} /></p>
                     
                    </div>
                    <div className='d-flex justify-content-start gap-1 align-items-center flex-wrap'>
                      {singleleads?.phone_number?.length>0?(<>
                        <p className='p-0 m-0' style={{color:'#6E6E6E', fontSize:'.92rem', fontWeight:'500'}}>Contact Number :</p>
                        <p className='p-0 m-0' style={{fontSize:'1rem', color:'#6419C2', fontWeight:'500'}}>{singleleads?.phone_number}</p>
                      </>):null}  <div>
                       
                        
                        </div>

                        {/* <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{textTransform:'capitalize', backgroundColor:'white', color:'black', fontSize:'12px',  '&:hover':{backgroundColor:'white'} }}
                                    variant='contained'
                                    disableRipple
                                >
                                    Lead Quality{open?<KeyboardArrowUpIcon sx={{padding:'0px', margin:'0px'}}/>:<KeyboardArrowDownIcon sx={{padding:'0px', margin:'0px'}}/>}
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    sx={{marginTop:'2px'}}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={()=>onSelectLeadQuality(singleleads?.id, "hot")} sx={{fontSize:'14px'}}>
                                     
                                      
                                   <img src={leadicon1} className='img-fluid me-2' alt="" />
                                   Relevant lead
                                      </MenuItem>
                                    
                                    <MenuItem onClick={()=>onSelectLeadQuality(singleleads?.id, "irrelevent")}  sx={{fontSize:'14px'}}>
                                    <img src={ deleteleadicon1} className='img-fluid me-2' alt="" />
                                    Irrelevant lead </MenuItem>
                                </Menu>
                       </div> */}
                      
                    </div>
                    <div>
                  {singleleads?.email?.length>0? <div className='d-flex justify-content-start gap-1 align-items-center flex-wrap'>
                         <p className='p-0 m-0' style={{color:'#6E6E6E', fontSize:'.92rem', fontWeight:'500'}}>Email :</p>
                        <p className='p-0 m-0' style={{fontSize:'1rem', color:'#6419C2', fontWeight:'500', width:'200px'}}>{singleleads?.email}</p>
                         </div>:null} 
                  
                    </div>
                </div>
                   
                 
                  <div className='border mt-0' style={{}}>
                  <div className="comment-form-card py-3 px-3">
                           <p style={{fontSize:'20px', fontWeight:'700'}} className='text-start'>Form Answer</p>


                           {singleleads && Object.keys(singleleads)?.map(key => (
                           !excludedKeys.includes(key) && (

                                <>
                             {singleleads[key]?.length>0? <div className='' key={key}>
                          <p className='text-start pb-0 mb-0 ' style={{color:'rgba(110, 110, 110, 1)', fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp;<span className=''>{key && key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, ' ')}</span> </p>
                          <p className='text-start fw-bold '  style={{fontSize:'600'}}><b>Ans.</b>&nbsp;{singleleads[key]?.replace(/_/g, ' ')}</p>
                          </div> :null}  
                             
                                </>
        )
      ))}

                    </div>

                    <div className="comment-form px-2" style={{width:'100%'}}>
                        <div className='d-flex justify-content-between'>
                            <span style={{fontSize:'1.2rem', fontWeight:'700'}}>
                                Past Comment
                            </span>
                            <Button   sx={{backgroundColor:'#6622cc', width:'10rem', textTransform:'capitalize', '&:hover':{backgroundColor:'#6622cc',   transition: 'display 0.3s ease-in-out'}}} onClick={addComment}  variant='contained'> <AddIcon/>  Add Comment</Button>
                        </div>



                        <div   style={{}}>
                             <div className={`table-comment-container ${showCommet ? 'show-comment-card py-2 mb-3' : ''}`}>
                                <p>Add Comment</p>
                                 <textarea value={commentMessage}  className='comment-box' onChange={(e)=>setCommentMessage(e.target.value)} style={{width:'100%'}} placeholder='type comment...' name="" id="" cols="8" rows="4"></textarea>
                                   <div>
                                    <Button onClick={()=>handleAdComment(singleleads?.id)} sx={{backgroundColor:'#6622cc', textTransform:'capitalize',  '&:hover':{backgroundColor:'#6622cc'}}}  variant='contained'>Save</Button>
                                   </div>
                             </div>
                           <table className="table-comment" style={{width:'100%'}}>
                                <tr className='head' style={{background:'rgba(110, 110, 110, 1)'}}>
                                    <th>Date</th>
                                    <th>Comments</th>
                                </tr>
                                {allComment?.length>0?(
                                     allComment?.map((val, idx)=>(
                                         <>
                                       {val?.date && <tr key={idx+1}>
                                        <td style={{fontSize:'14px', fontWeight:'500'}}>{val?.date? formatedDate(val?.date):null}</td>
                                        <td style={{fontSize:'', fontWeight:'500', color:'rgba(110, 110, 110, 1)'}}>{val?.comment}</td>
                                      </tr>}  
                                       
                                         </>
                                   
                                     
                                     ))
                                    
                                    
                                ):null }
                                
                            </table>
                        </div>
                    </div>
                  </div>
                    
                </Card>
            </div>
        </>
    );
}

export default LeadsPageForm;
