import { Box, Button, IconButton, Typography } from "@mui/material";
import "./exploreLeads.css";
import { Link, useNavigate } from "react-router-dom";
import googlesheeticon from '../../asets/google sheets icon.png'
import googlesheetdisable from '../../asets/googlesheet disable.png'

export default function ExploreLeadCard({ data, handleDownload }) {
   //console.log("explre lead", data);
  const navigate = useNavigate();
  function handleNavigateHomePage() {
    navigate("/campaign-overview", {
      state: {
        campaign_id: data?._id,
        cmpid:data?.campaign_id
      },
    });
  }

  // formate data
  function formatDate(inputDate) {
    const date = new Date(inputDate);
  
    // Define months as an array
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    // Get day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    // Function to add ordinal suffix to the day
    // const getOrdinalSuffix = (number) => {
    //   const suffixes = ['th', 'st', 'nd', 'rd'];
    //   const v = number % 100;
    //   return (v >= 11 && v <= 13) ? 'th' : suffixes[(v - 1) % 10] || 'th';
    // };
  
    // Add ordinal suffix to the day
    const formattedDay = `${day}`;
  
    // Assemble the final formatted date
    const formattedDate = `${formattedDay} ${month} ${year}`;
  
    return formattedDate;
  }
  
  // const inputDate = "2024-01-03T08:34:13.400Z";
  // const formattedDate = formatDate(inputDate);
  
  //console.log(formattedDate);
  return (
    <section className="exploreLeadsCard"  style={{cursor:'pointer'}}>
      <div className="header" onClick={() => navigate(`/leads`, { state: data?.campaign_id, }) }>
        <div className="header-left flex-grow-1" style={{cursor:'pointer'}} >
        {/* <span
            className=" d-flex align-items-center justify-content-center me-1"
            style={{
              height: "30px",
              width: "30px",
              backgroundColor: "#62c",
              borderRadius: "50%",
            }}
          >
            <span style={{ color: "white" }}>{data?.name?.slice(0, 1)}</span>
          </span> */}
          <div className="header-left-right ">
            <h3
              style={{
               
                overflow: "hidden",
                textOverflow: "ellipsis",
                
              }}
             
            >
              {data?.name?.length>22?`${data?.name?.slice(0, 22)}..`:data?.name}
            </h3>
            <Typography component={"span"} fontWeight={600} fontSize={"12px"}>
              {formatDate(data?.date)}
              {/* #{data?.campaign_id} */}
            </Typography>
          </div>
        </div>
        <div className="header-right align-items-center " style={{minWidth:'120px'}}>
          <div className="row1">
             <span style={{color:data?.status==='ACTIVE'?'#0EAA00':data?.status==='PAUSED'?'#F07A4C':'#6622CC'}}>{data?.status}</span>
          </div>
          <div className="row2 mt-1" style={{width:'70px'}}>
            <img src="socialIcons/fbLogo.svg" alt="facebook" />
            <img style={{width:'15px', height:'15px'}} src="socialIcons/instagram.png" alt="facebook" />
            <img src="socialIcons/googleLogo.svg" alt="facebook" />
           
          </div>
        </div>
      </div>
      <div className="exploreLeadsCardDetails" onClick={() => navigate(`/leads`, { state: data?.campaign_id, }) }>
        <div className="detail-left" style={{cursor:'pointer'}} >
          <div className="detail-left-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="42"
              viewBox="0 0 40 42"
              fill="none"
            >
              <rect
                width="39.6547"
                height="41.543"
                rx="4.1543"
                fill="#E2D6F3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.0029 21.943C28.4258 22.9089 29.4228 24.2175 29.4228 25.9623V29.078H32.5385C33.1098 29.078 33.5771 28.6106 33.5771 28.0394V25.9623C33.5771 23.6982 29.8694 22.3584 27.0029 21.943Z"
                fill="#6622CC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7646 21.943C10.3418 22.9089 9.34477 24.2175 9.34477 25.9623V29.078H6.22904C5.65782 29.078 5.19046 28.6106 5.19046 28.0394V25.9623C5.19046 23.6982 8.89818 22.3584 11.7646 21.943Z"
                fill="#6622CC"
              />
              <path
                d="M19.0352 20.7705C21.3295 20.7705 23.1895 18.9106 23.1895 16.6162C23.1895 14.3219 21.3295 12.4619 19.0352 12.4619C16.7408 12.4619 14.8809 14.3219 14.8809 16.6162C14.8809 18.9106 16.7408 20.7705 19.0352 20.7705Z"
                fill="#6622CC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.2276 20.7705C26.5228 20.7705 28.3819 18.9115 28.3819 16.6162C28.3819 14.321 26.5228 12.4619 24.2276 12.4619C23.7394 12.4619 23.2825 12.5658 22.8463 12.7112C23.74 13.8164 24.2275 15.1948 24.2275 16.6162C24.2275 18.0376 23.74 19.416 22.8463 20.5213C23.2825 20.6667 23.7394 20.7705 24.2276 20.7705ZM17.9961 21.8091C15.2231 21.8091 9.6875 23.2008 9.6875 25.9634V28.0405C9.6875 28.6118 10.1549 29.0791 10.7261 29.0791H25.2661C25.8374 29.0791 26.3047 28.6118 26.3047 28.0405V25.9634C26.3047 23.2008 20.7691 21.8091 17.9961 21.8091Z"
                fill="#6622CC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.8418 20.7705C11.5465 20.7705 9.68747 18.9115 9.68747 16.6162C9.68747 14.321 11.5465 12.4619 13.8418 12.4619C14.3299 12.4619 14.7869 12.5658 15.2231 12.7112C14.3294 13.8164 13.8418 15.1948 13.8418 16.6162C13.8418 18.0376 14.3294 19.416 15.2231 20.5213C14.7869 20.6667 14.3299 20.7705 13.8418 20.7705ZM20.0732 21.8091C22.8462 21.8091 28.3818 23.2008 28.3818 25.9634V28.0405C28.3818 28.6118 27.9145 29.0791 27.3433 29.0791H12.8032C12.232 29.0791 11.7646 28.6118 11.7646 28.0405V25.9634C11.7646 23.2008 17.3002 21.8091 20.0732 21.8091Z"
                fill="#6622CC"
              />
            </svg>
          </div>

          <div
            className="detail-left-right"
           
          >
            <p>Leads</p>
            <p>
              {data?.leads_count
 ? data?.leads_count
 : 0}
            </p>
          </div>
        </div>{" "}
      
      </div>
      {data?.sheet?.url? (<>
        <a target='_blank' rel="noreferrer" href={data?.sheet?.url}>
                            <IconButton sx={{ color: '#6622CC', padding:'4px' }} aria-label="visibility">
                              <img src={googlesheeticon} width={22} height={22} alt="" />
                            </IconButton>
                          </a>
      </>):(

<IconButton disabled aria-label="visibility" sx={{padding:'4px'}}>
<img src={googlesheetdisable} width={22} height={22} alt="" />
</IconButton>
      )}
      {/* <Button
        onClick={() => handleDownload(data?.campaign_id)}
        disabled={data?.leads_count?false:true}
        sx={{
          backgroundColor: "#62c",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          padding: "8px",
          marginBottom: "10px",
         
        }}
        variant="contained"
      >
        <Box component={"span"} sx={{ marginRight: "7px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
          >
            <path
              d="M5.87097 8.34937C5.87097 8.22959 5.91855 8.11472 6.00324 8.03003C6.08794 7.94534 6.20281 7.89776 6.32258 7.89776H9.93548V1.12356C9.93548 0.884012 9.84032 0.654273 9.67094 0.484886C9.50155 0.315498 9.27181 0.220337 9.03226 0.220337H3.10258L0 3.3184V11.9623C0 12.2018 0.095161 12.4316 0.264549 12.601C0.433936 12.7703 0.663675 12.8655 0.903226 12.8655H9.03226C9.27181 12.8655 9.50155 12.7703 9.67094 12.601C9.84032 12.4316 9.93548 12.2018 9.93548 11.9623V8.80098H6.32258C6.20281 8.80098 6.08794 8.7534 6.00324 8.66871C5.91855 8.58401 5.87097 8.46914 5.87097 8.34937ZM3.6129 3.83324H0.903226V3.68872L3.4729 1.12356H3.6129V3.83324Z"
              fill="white"
            />
            <path
              d="M11.4349 5.7978C11.3485 5.72382 11.2374 5.68516 11.1237 5.68955C11.0101 5.69394 10.9022 5.74105 10.8218 5.82148C10.7414 5.90191 10.6943 6.00973 10.6899 6.12339C10.6855 6.23705 10.7241 6.34818 10.7981 6.43458L12.2659 7.8978H9.93555V8.80103H12.2794L10.7981 10.2823C10.7509 10.3228 10.7125 10.3726 10.6853 10.4287C10.6582 10.4847 10.643 10.5457 10.6406 10.6079C10.6382 10.6701 10.6487 10.7321 10.6714 10.7901C10.6941 10.848 10.7285 10.9007 10.7725 10.9447C10.8166 10.9887 10.8692 11.0231 10.9271 11.0458C10.9851 11.0685 11.0471 11.079 11.1093 11.0766C11.1715 11.0742 11.2325 11.059 11.2886 11.0319C11.3446 11.0048 11.3944 10.9664 11.4349 10.9191L14.0001 8.34942L11.4349 5.7978Z"
              fill="white"
            />
          </svg>
        </Box>
        Export leads
      </Button> */}
    </section>
  );
}
