import React, { useContext, useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { Trash2 } from 'lucide-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import Axios from '../../Axios';
import { AuthContext } from '../../AuthProvider';


function NotificationSettingsGoogleSheetEmails({getUserProfileData, userData}) {
    const { userToken } = useContext(AuthContext);
  const [googleSheetNotificationEmails, setGoogleSheetNotificationEmails] = useState([]);
  const [disableAddEmail, setDisableAddEmail] = useState(false);


  const formik = useFormik({
    initialValues: {
        gsheet_emails: '',
    },
    validationSchema: Yup.object({
      gsheet_emails: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email address'
        )
        .required(''),
    }),
  
    
    onSubmit: async (values, { resetForm }) => {
      setDisableAddEmail(true);
      const body = {
        gsheet_emails: [...googleSheetNotificationEmails, values.gsheet_emails],
      };
      try {
        const res = await Axios.post("/complete-profile", body, { headers: { Authorization: userToken } });
        if (res.status === 200 && res?.data?.valid) {
            setGoogleSheetNotificationEmails(body.gsheet_emails);
          
          resetForm();
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setDisableAddEmail(false);
        getUserProfileData(userToken);
      }
    },
  });

  useEffect(()=>{
    if(userData){
        setGoogleSheetNotificationEmails(userData?.gsheet_emails)
    }
  
},[userData])

  const onDeleteEmail = async (email) => {
    try {
      const remainingEmails = googleSheetNotificationEmails?.filter((val) => val !== email);
      const body = { gsheet_emails: remainingEmails };

      const res = await Axios.post("/complete-profile", body, { headers: { Authorization: userToken } });
      if (res.status === 200 && res?.data?.valid) {
        setGoogleSheetNotificationEmails(remainingEmails);
       
      }
    } catch (error) {
      console.log("error", error);
    }finally{
      getUserProfileData(userToken)
    }
  };

  return (
    <div>

<div>
              <p className='campaign-alrt-text text-start'>Email For Google Sheet</p>
            </div>
            <div className='max-w-[400px]'>
              <div className="my-4">
                <div className='flex justify-start items-center'>
                  <form className='w-full' onSubmit={formik.handleSubmit}>
                    <div className='w-full flex items-center justify-start'>
                      <Input
                        type="email"
                        name="gsheet_emails"
                        placeholder="Email For Google Sheet"
                        className="flex-1 w-full h-11 shadow-none rounded-r-none"
                        value={formik.values.gsheet_emails}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <Button
                        type="submit"
                        disabled={disableAddEmail || !formik.isValid}
                        className="notification-btn w-[33px] h-11 shadow-none rounded-l-none bg-[#62c] hover:bg-[#62c]"
                      >
                        +
                      </Button>
                    </div>
                  </form>
                </div>
                {formik.touched.gsheet_emails && formik.errors.gsheet_emails ? (
                  <div className="text-red-500">{formik.errors.gsheet_emails}</div>
                ) : null}

                {googleSheetNotificationEmails?.length > 0 && (
                  <Card className="shadow-none w-full mt-4 p-3">
                    <div className="notification-email-container">
                      {googleSheetNotificationEmails.map((email) => (
                        <div key={email} className='mb-3 flex justify-between items-center'>
                          <p className='p-0 m-0 notificationemail-p'>{email}</p>
                          <button onClick={() => onDeleteEmail(email)}>
                            <Trash2 className='text-red-500 w-[18px] h-[18px]' />
                          </button>
                        </div>
                      ))}
                    </div>
                  </Card>
                )}
              </div>
            </div>


    </div>
  )
}

export default NotificationSettingsGoogleSheetEmails