import React from 'react'
import './pagenotfound.css'
import { Button } from '@mui/material'

function PageNotFound() {
  return (
    <>
      <div className=" m-0 p-0 page-not-found-container ">
        <div className="m-0 p-0 page-not-found" style={{maxWidth:'500px'}}>
            <div className="m-0 p-0 page-not-found-card" >
                <p className='p-0 m-0 oops-text'>Oops !</p>
                <p className='p-0 m-0 text404 '>404 - PAGE NOT FOUND</p>
                <p className='p-0 m-0 message404'>The page your are looking for might have been removed</p>
                <p className='p-0 m-0 message404'>had its name changed or  its temporarily unavailable</p>
                 {/* <div className='page404button'>
                 <Button sx={{sm:{width:'100', md:{width:'120p'}}}} variant="contained">Back</Button>
                 </div> */}
            </div>
        </div>
      </div>
    
    </>
  )
}

export default PageNotFound