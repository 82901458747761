import { Button, Stack, Typography } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./ViewLeadsCard.css";
import { CircularProgressbar } from "react-circular-progressbar";
import MessageIcon from '@mui/icons-material/Message';
import {useNavigate } from "react-router-dom";

const ViewLeadsCard = ({ data }) => {
  //  console.log("data", data)
  const navigate = useNavigate();

  const budgetprice = Number(data?.lifetime_budget)

  const percentagespent = Math.round((data?.spend / budgetprice) * 100)

  function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }
  //  console.log("datada", percentagespent );


  // formate data
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    // Define months as an array
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();



    // Add ordinal suffix to the day
    const formattedDay = `${day}`;

    // Assemble the final formatted date
    const formattedDate = `${formattedDay} ${month} ${year}`;

    return formattedDate;
  }

  // const named = "Stya_Hive_Gurugram_LeapX_Campaigns"
  return (
    <section className="viewLeadCard" onClick={() => { navigate("/campaign-overview", { state: { campaign_id: data?._id, cmpid: data?.campaign_id }, }) }}>
      <div className="header" >
        <div className="header-left flex-grow-1" style={{ cursor: 'pointer' }} >

          <div className="header-left-right">
            {/* <Link to="/ad-overview" style={{color: "inherit"}}> */}
            <h3
              style={{

                textOverflow: "ellipsis",
                overflow: "hidden",

              }}

            >
              {/* Campaign Name */}
              {data?.name.length > 20 ? `${data?.name.slice(0, 20)}..` : data?.name}
            </h3>
            {/* </Link> */}
            <Typography component={"span"} fontWeight={600} fontSize={"12px"}>
              {/* #159 */} {data?.date ? formatDate(data?.date) : null}
            </Typography>
          </div>
        </div>
        <div className="header-right align-items-center" style={{ minWidth: '120px' }}>
          <div className="row1 ">
            <span style={{ color: data?.status === 'ACTIVE' ? '#0EAA00' : data?.status === 'PAUSED' ? '#F07A4C' : '#6622CC' }}>
              {/* Live */}
              {/* {data.status === "ACTIVE" ? "Live" : "PAUSED"} */}
              {data?.status}
            </span>
          </div>
          <div className="row2 mt-1" style={{ width: '70px' }}>
            <img src="socialIcons/fbLogo.svg" alt="facebook" />
            <img style={{ width: '15px', height: '15px' }} src="socialIcons/instagram.png" alt="facebook" />
            <img src="socialIcons/googleLogo.svg" alt="facebook" />

          </div>
        </div>
      </div>
      <Stack
        direction={"row"}
        spacing={2}
        marginTop={2}
        alignItems={"center"}
        justifyContent={"space-between"}

      >
        <Stack direction={"column"} spacing={2}>
          <div className="detail-left" style={{ cursor: 'pointer' }}>
            <div className="detail-left-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="42"
                viewBox="0 0 40 42"
                fill="none"
              >
                <rect
                  width="39.6547"
                  height="41.543"
                  rx="4.1543"
                  fill="#E2D6F3"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M27.0029 21.943C28.4258 22.9089 29.4228 24.2175 29.4228 25.9623V29.078H32.5385C33.1098 29.078 33.5771 28.6106 33.5771 28.0394V25.9623C33.5771 23.6982 29.8694 22.3584 27.0029 21.943Z"
                  fill="#6622CC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7646 21.943C10.3418 22.9089 9.34477 24.2175 9.34477 25.9623V29.078H6.22904C5.65782 29.078 5.19046 28.6106 5.19046 28.0394V25.9623C5.19046 23.6982 8.89818 22.3584 11.7646 21.943Z"
                  fill="#6622CC"
                />
                <path
                  d="M19.0352 20.7705C21.3295 20.7705 23.1895 18.9106 23.1895 16.6162C23.1895 14.3219 21.3295 12.4619 19.0352 12.4619C16.7408 12.4619 14.8809 14.3219 14.8809 16.6162C14.8809 18.9106 16.7408 20.7705 19.0352 20.7705Z"
                  fill="#6622CC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.2276 20.7705C26.5228 20.7705 28.3819 18.9115 28.3819 16.6162C28.3819 14.321 26.5228 12.4619 24.2276 12.4619C23.7394 12.4619 23.2825 12.5658 22.8463 12.7112C23.74 13.8164 24.2275 15.1948 24.2275 16.6162C24.2275 18.0376 23.74 19.416 22.8463 20.5213C23.2825 20.6667 23.7394 20.7705 24.2276 20.7705ZM17.9961 21.8091C15.2231 21.8091 9.6875 23.2008 9.6875 25.9634V28.0405C9.6875 28.6118 10.1549 29.0791 10.7261 29.0791H25.2661C25.8374 29.0791 26.3047 28.6118 26.3047 28.0405V25.9634C26.3047 23.2008 20.7691 21.8091 17.9961 21.8091Z"
                  fill="#6622CC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.8418 20.7705C11.5465 20.7705 9.68747 18.9115 9.68747 16.6162C9.68747 14.321 11.5465 12.4619 13.8418 12.4619C14.3299 12.4619 14.7869 12.5658 15.2231 12.7112C14.3294 13.8164 13.8418 15.1948 13.8418 16.6162C13.8418 18.0376 14.3294 19.416 15.2231 20.5213C14.7869 20.6667 14.3299 20.7705 13.8418 20.7705ZM20.0732 21.8091C22.8462 21.8091 28.3818 23.2008 28.3818 25.9634V28.0405C28.3818 28.6118 27.9145 29.0791 27.3433 29.0791H12.8032C12.232 29.0791 11.7646 28.6118 11.7646 28.0405V25.9634C11.7646 23.2008 17.3002 21.8091 20.0732 21.8091Z"
                  fill="#6622CC"
                />
              </svg>
            </div>

            <div className="detail-left-right"  >
              <p>Leads</p>
              <p>
                {data.leads_count}
                {/* <span>+0</span> */}
              </p>
            </div>
          </div>
          <div className="detail-left">
            <div className="detail-left-left">
              <p className="d-flex justify-content-center align-items-center" style={{ height: '38px', width: '38px', backgroundColor: '#E2D6F3', borderRadius: '4px', padding: '10px' }}>
                <MessageIcon sx={{ color: '#62c' }} />
              </p>

            </div>
            <div className="detail-left-right">
              <p>Messages</p>
              <p>
                0
                {/* <span>+0</span> */}
              </p>
            </div>
          </div>
        </Stack>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <div style={{ width: 80, height: 80, marginRight: '12px', marginBottom: '12px' }} className="">
            <CircularProgressbar
              // value={30}
              // text={`${30}% spend`}
              value={percentagespent ? percentagespent : 0}
              text={
                (percentagespent ? percentagespent : 0) + "%"
              }
              styles={{
                path: {
                  stroke: `#62c`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 2.5s ease 0s",
                  transform: "rotate(2.25turn)",

                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#8E8E8E6B",
                  strokeLinecap: "butt",
                  transform: "rotate(2.25turn)",
                  transformOrigin: "center center",
                },
                text: {
                  fill: "black",
                  fontSize: "15px",
                  fontWeight: '700'

                },
                background: {
                  fill: "#5e98c7",
                },
              }}
            />
          </div>
          <div className="finalResult">
            <div className="finalResult-one">
              <div className="finalResult-one-one">
                <div className="circle"></div>
                <span>Spend</span>
              </div>
              <p style={{ fontSize: '12px', fontWeight: '600' }}>
                <CurrencyRupeeIcon fontSize="11.5px" />
                {/* 15,000 */}
                {formatIndianNumber(Math.round(data?.spend))}
              </p>
            </div>

            <div className="finalResult-one">
              <div className="finalResult-one-one">
                <div className="circle"></div>
                <span> Deployed</span>
              </div>
              <p style={{ fontSize: '12px', fontWeight: '600' }}>
                <CurrencyRupeeIcon fontSize="11.5px" />
                {/* 50,000 */}
                {formatIndianNumber(Math.round(budgetprice))}
              </p>
            </div>
          </div>
        </Stack>
      </Stack>

    </section>
  );
};
export default ViewLeadsCard;
