import { useState, useContext, useEffect } from 'react';
import { Button } from '../../components/ui/button'
import { Card } from '../../components/ui/card'
import { AuthContext } from '../../AuthProvider';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, } from "../../components/ui/accordion"
import Axios from '../../Axios';
import successicon from '../../asets/Group.svg'
import crossicon from '../../asets/x.svg'
import warningicon from '../../asets/alert-triangle.svg'
import fbicon from '../../asets/Vector.svg'
import Navbar2 from '../../navbar/Navbar2';
import { Trash2 } from 'lucide-react';
import settingicon from '../../asets/settings.svg'
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
import { RefreshCw } from 'lucide-react';

// import ConnectToFacebook from './components/ConnectToFacebook';
// import Navbar2 from '../../navbar/Navbar2';
// import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
// import fbicon from '../../assets/account-details-icons/Vector.svg';

// import successicon from '../../assets/account-details-icons/Group.svg';
// import deleteicon from '../../assets/account-details-icons/trash-2.svg';
// import crossicon from '../../assets/account-details-icons/x.svg';
// import settingicon from '../../assets/account-details-icons/settings.svg';
import './accountdetails.css';
import ConnectToFacebook from './components/ConnectToFacebook';
import { ProfilePageContext } from '../../context/ProfilePageContext';
import ProfileCompleteCard from './components/ProfileCompleteCard';
import TestingConnectFbPage from './components/TestingConnectFbPage';


function FacebookPages() {
    const { userToken } = useContext(AuthContext);
    const {getUserProfileData, userData, accountPercentage, fbpagePercentage, businessPercentage, notificationPercentage, overallPercentage, configId, configIdTest , systemUser } = useContext(ProfilePageContext)
    
    const [fetchFbPageLoad, setFetchFbPageLoad] = useState(false);
    const [clientPages, setClientPages] = useState([]);
    const [isPageLoad, setIsPageLoad] = useState(false);
    const [token, setToken] = useState("");
    const [deleteLoad, setDeleteLoad] = useState({});
    const [addPagesLoad, setAddPagesLoad] = useState(false);
    const [buttonStatus, setButtonStatus] = useState({});
    const [userName, setUserName] = useState("");
    const [loadRefreshPage, setLoadRefreshPage] = useState(false)
 

    const handelClientsPage = async (token) => {
        setFetchFbPageLoad(true);
        try {
            const res = await Axios.get(`/user-pages?access_token=${token}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken,
                },
            });
            if (res.status === 200) {
                if (res?.data?.valid) {
                    getFacebookPagesFromDb();
                   
                } else {
                    setFetchFbPageLoad(false);
                }
            }
        } catch (error) {
            // setFetchFbPageLoad(false);
            // console.log("error")
        }finally{
            setFetchFbPageLoad(false);
        }
    };

    const getFacebookPagesFromDb = async () => {
        try {
            const res = await Axios.get("/user", {
                headers: {
                    Authorization: userToken,
                },
            });
            if (res.status === 200) {
                const data = res?.data?.user;
                if (data?.page_id?.length > 0) {
                    setUserName(res?.data?.user?.name);
                    setClientPages(data?.page_id);
                } else {
                    setClientPages([]);
                }
            }
        } catch (error) {
            // Handle error
        } finally {
            setFetchFbPageLoad(false);
        }
    };

    useEffect(() => {
        if (userToken) {
            getFacebookPagesFromDb();
        }
    }, [userToken]);


    const handlePageAccess = async (item) => {
        setAddPagesLoad(true);
        setButtonStatus({ ...buttonStatus, [item?.id]: 'loading' });
        setIsPageLoad(true);
        const body = { pageId: item?.id };
        try {
            const response = await Axios.post("/page-access-request", body, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: userToken,
                },
            });
            if (response.status === 200 && response.data.valid) {
                getFacebookPagesFromDb();
            }else{
                getFacebookPagesFromDb();
            }
        } catch (error) {
            console.log("error", error);
            getFacebookPagesFromDb();
        } finally {
            setAddPagesLoad(false);
            setButtonStatus({ ...buttonStatus, [item?.id]: 'notloading' });
            getUserProfileData(userToken)
        }
    };

    const handlePages = async (item) => {
        try {
            const res = await Axios.post("/add-pages", item, {
                headers: {
                    Authorization: userToken,
                },
            });
            if (res.status === 200) {
                getFacebookPagesFromDb();
            }
        } catch (error) {
            console.log("Error setting up request:");
        }
    };

    const handleDeletePage = async (pageid) => {
        setDeleteLoad({ ...deleteLoad, [pageid]: 'loading' });
        try {
            const res = await Axios.post("/delete-page", { pageId: pageid }, { headers: { Authorization: userToken } });
            if (res.status === 200 && res.data.valid) {
                getFacebookPagesFromDb();
            } else {
                console.log("error", res.data.message);
            }
        } catch (error) {
            console.log("error", error);
        } finally {
            setDeleteLoad({ ...deleteLoad, [pageid]: 'notloading' });
            getUserProfileData(userToken)
        }
    };

    const handleSubscribeApp = async (pageid) => {
        try {
            const res = await Axios.post("/subscribe-app", { pageId: pageid });
            if (res.status === 200 && res.data.valid) {
                getFacebookPagesFromDb();
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onReferchPage = async (id)=>{
        setLoadRefreshPage(true)
        try {
            const res = await Axios.post("/refresh-page", { page_id: id }, { headers: { Authorization: userToken } });
            if (res.status === 200 && res.data.valid) {
                getFacebookPagesFromDb();
               
            } else {
                console.log("error", res.data.message);
                getFacebookPagesFromDb();
            }
        } catch (error) {
            console.log("error", error);
            getFacebookPagesFromDb();
        }finally{
            setLoadRefreshPage(false)
            getUserProfileData(userToken)
        }
    }





    

    return (
        <>
        <div className="d-md-none">
     <Navbar2 menu={true} />
     </div>
            <div className=' max-w-[1350px] mx-auto bg-[#FBFBFB]'>
                <div class="flex items-start mb-5 mb-md-0 flex-col md:flex-row">
                <div className="mb-4 d-block d-md-none w-full mb-md-0 mt-md-3 px-1 py-0 ">
                        <ProfileCompleteCard
                            accountPercentage={accountPercentage}
                            userName={userName}
                            fbpagePercentage={fbpagePercentage}
                            businessPercentage={businessPercentage}
                            notificationPercentage={notificationPercentage}
                            overallPercentage={overallPercentage}
                        />
                    </div>
                    <div class=" w-full md:w-2/3 md:px-2">
                        <div className='py-2 px-2 d-flex justify-content-center justify-content-md-between items-center w-full]'>
                            <p className='m-0 p-0 d-none d-md-block text-[18px] md:text-[24px] text-black font-semibold leading-[22px] md:leading-[36px]'>Facebook Page</p>
                            <ConnectToFacebook  handelClientsPage={handelClientsPage} setToken={setToken} fetchFbPageLoad={fetchFbPageLoad} configId={configId} />
                        </div>

                      {configIdTest?.length>0 && <div className='py-2 px-2 flex justify-between items-center w-full]'>
                            <p className='m-0 p-0 text-[18px] md:text-[24px] text-black font-semibold leading-[22px] md:leading-[36px]'>FB Button For Testing</p>
                            <ConnectToFacebook handelClientsPage={handelClientsPage} setToken={setToken} fetchFbPageLoad={fetchFbPageLoad} configId={configIdTest} />
                        </div>}  

                        {systemUser?.length>0 && <div className='py-2 px-2 flex justify-between items-center w-full]'>
                            <p className='m-0 p-0 text-[18px] md:text-[24px] text-black font-semibold leading-[22px] md:leading-[36px]'>Syster User</p>
                            <TestingConnectFbPage handelClientsPage={handelClientsPage} setToken={setToken} fetchFbPageLoad={""} configId={systemUser} />
                        </div>}  
                     

                        <div className=' mt-3 px-1'>
                            <Accordion type="single" collapsible className="w-full ">

                                {clientPages.length > 0 && clientPages?.map((item, idx) => (

                                    <>
                                        {(item?.tasks?.includes("MANAGE") || !item?.hasOwnProperty('connected')) ? (<>

                                            <AccordionItem
                                                value={`item-${item?.id}`}
                                                className={`border-l-2 bg-white mb-2 mb-md-3 shadow-accordion rounded-sm py-2 px-2 shadow-sm item-center ${item?.connected === 'success'?((item?.leadgen_tos_accepted === false) || (!item?.lead_access)  || (item?.subscribed_apps?.data[0]?.id !== '1052762895750334') || (!item?.subscribed_apps?.data[0]?.subscribed_fields.includes("leadgen")))?'border-l-[#FF9EAF]':'':item?.connected === 'failed'?'border-l-[#FF9EAF]':''}  md:py-4 md:px-4`}
                                            >
                                                <AccordionTrigger className="no-underline hover:no-underline py-2 ">
                                                    <div className=' flex justify-between items-center w-[92%]'>
                                                        <div className='flex justify-start gap-2 flex-1 items-center'>
                                                           {(item?.connected === 'success' || !item?.hasOwnProperty('connected')) && <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={successicon} alt="" />} 
                                                            <p className='addpages-name p-0 m-0'>
                                                                {item?.name}
                                                            </p>
                                                            {buttonStatus[item?.id] === 'loading' && <div className=' flex items-center justify-center'><span class="loaderfbpage"></span></div> }
                                                        </div>

                                                      {item?.connected==='pending' && <Button  disabled={addPagesLoad ? true : false} onClick={(e) => {e.stopPropagation(); handlePageAccess(item); }} className="w-[77px] h-[30px] bg-[#62c] hover:bg-[#62c] text-white text-[12px]">{buttonStatus[item?.id] === 'loading'?'Connect...':'Connect'}</Button>}  
                                                      {(item?.connected === 'success' || item?.connected === 'failed') && <>
                                                        {((item?.leadgen_tos_accepted===false) || (!item?.lead_access) || (item?.subscribed_apps?.data[0]?.id !== '1052762895750334') || (!item?.subscribed_apps?.data[0]?.subscribed_fields.includes("leadgen")) )?<div className='flex gap-1 justify-end items-center'>
                                                            <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={warningicon} alt="" />
                                                            <p className='p-0 m-0 addpages-p'>Action required</p>
                                                        </div> :null}
                                                      
                                                      </>}
                                                      
                                                    </div>

                                                </AccordionTrigger>
                                                <AccordionContent>
                                                             <div>
  
                                                           <div className="mb-3"> {item?.connected === 'failed' ? <p className='addpages-p p-0 m-0'><img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={warningicon} alt="" />  {item?.user_name?item?.user_name:userName} may not be admin business account that owns this page</p> : null}</div>
                                                            {(item?.connected === 'success' || item?.connected === 'failed') && <div>
                                                                {item?.connected === 'success'?(<>
                                                                    {item?.leadgen_tos_accepted === false &&  <div className="mb-3 flex justify-start items-center gap-1">
                                                                   <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={crossicon} alt="" />
                                                                   {item?.leadgen_tos_accepted === false && <p className='addpages-p p-0 m-0'>  TOS Not Accepted, <a href="https://www.facebook.com/ads/leadgen/tos" target='_blank' style={{ color: 'black' }}><b>click here</b></a> to accept</p>}

                                                                   </div>}
                                                                </>):""}
                                                                  
                                                                
                                                                  {item?.connected === 'success'? (<>
                                                                    {((item?.subscribed_apps?.data[0]?.id !== '1052762895750334') || (!item?.subscribed_apps?.data[0]?.subscribed_fields.includes("leadgen"))) && <div className="mb-3 flex justify-start items-center gap-1">
                                                                   <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={crossicon} alt="" />
                                                                   {((item?.subscribed_apps?.data[0]?.id !== '1052762895750334') || (!item?.subscribed_apps?.data[0]?.subscribed_fields.includes("leadgen"))) && <p className='addpages-p p-0 m-0'>  App Subscription, <span onClick={() => handleSubscribeApp(item?.id)} style={{ color: 'black', cursor: 'pointer' }}><b>click here</b></span> to retry</p>}

                                                                   </div>}
                                                                  </>):""}
                                                                  
                                                                   {item?.connected === 'success'?(<>
                                                                   
                                                                    {!item?.lead_access &&  <div className="mb-3 flex justify-start items-center gap-1">
                                                                   <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={crossicon} alt="" />
                                                                   {!item?.lead_access && <p className='addpages-p p-0 m-0'>  Lead Access, contact LeapX support</p>}

                                                                   </div>} 
                                                                   
                                                                   </>):""}
                                                                  
                                                                
                                                            </div>}

                                                          <div className=' flex justify-between items-center'>
                                                          <div className=' flex justify-start gap-1 items-center'>
                                                         <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={fbicon} alt="" />
                                                         <p className='p-0 m-0 addpages-p' addpages-p>{(item?.tasks?.includes("MANAGE") || !item?.hasOwnProperty('connected')) ? `${item?.user_name?item?.user_name:userName} has full access to this page` : `${item?.user_name?item?.user_name:userName} has partial access to this page`}</p>
                                                        
                                                         </div>
                                                         <div>


                                                       {item?.connected === 'success'?((item?.subscribed_apps?.data[0]?.id !== '1052762895750334') || (!item?.subscribed_apps?.data[0]?.subscribed_fields?.includes("leadgen")) || (!item?.lead_access) || item?.leadgen_tos_accepted === false )?(<>
                                                        <button onClick={()=>onReferchPage(item?.id)} disabled={loadRefreshPage?true:false} className='me-3'><RefreshCw className={`text-black w-[18px] h-[18px] ${loadRefreshPage?'refreshpage':''}`} /></button>
                                                       
                                                       </>):'':''} 
                                                        
                                                         <button onClick={() => handleDeletePage(item?.id)} disabled={deleteLoad[item?.id] === 'loading' ? true : false}><Trash2  style={{ opacity: deleteLoad[item?.id] === 'loading' ? '0.7' : '1' }} className='text-red-500 w-[18px] h-[18px] m-0 ' /></button>
                                                        
                                                          </div>
                                                          </div>


                                                        </div>
                                                </AccordionContent>
                                            </AccordionItem>

                                        </>) : (<>

                                            {/* action required */}
                                            <AccordionItem
                                                value={`item-${item?.id}`}
                                                className={  `border-l-2 bg-white  mb-2 mb-md-3  rounded-sm py-2 px-2 shadow-accordion item-center  border-l-[#FF9EAF] md:py-4 md:px-4`}
                                            >
                                                <AccordionTrigger className="no-underline hover:no-underline py-2">
                                                    <div className=' flex justify-between items-center w-[92%]'>
                                                        <div className='flex justify-start gap-1 items-center '>
                                                            {/* <img src={successicon} alt="" /> */}
                                                            <p className='p-0 m-0 addpages-name'>
                                                                {item?.name}
                                                            </p>
                                                        </div>
                                                        <div className='flex gap-1 justify-start items-center'>
                                                            <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' src={warningicon} alt="" />
                                                            <p className='p-0 m-0 addpages-p'>Action required</p>
                                                        </div>

                                                        {/* <Button className="w-[80px] h-[30px] bg-[#62c] text-white text-[12px]">Connect</Button> */}
                                                    </div>

                                                </AccordionTrigger>
                                                <AccordionContent>
                                                    <div className='mb-3 mt-3 gap-1 flex items-center justify-start'>
                                                        <img src={warningicon} className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px] ' alt="" />
                                                        <p className='p-0 m-0 addpages-p'> {item?.user_name?item?.user_name:userName} doesn’t have admin access to link this page to LeapX</p>
                                                    </div>
                                                    <div className=" mb-3 flex items-center justify-start gap-1">
                                                        <img className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px] '  src={settingicon} alt="" />
                                                        <p className='p-0 m-0 addpages-p'> Add pages using the admin account <a href="+" target='_blank' style={{ color: '#62c' }}>(refer this link)</a></p>
                                                    </div>
                                                    {/* delete button container */}
                                                      <div className=' flex justify-between items-center'>
                                                      <div className='flex justify-start items-center gap-1'>
                                                            <img src={fbicon} alt="" className=' w-[14px] h-[14px] md:w-[19px] md:h-[19px]' />
                                                            <p className='p-0 m-0 addpages-p' >{item?.user_name?item?.user_name:userName} has partial access to this page</p>

                                                        </div>

                                                        <div>
                                                            
                                                          <button disabled={deleteLoad[item?.id] === 'loading' ? true : false} onClick={() => handleDeletePage(item?.id)}><Trash2  style={{ opacity: deleteLoad[item?.id] === 'loading' ? '0.7' : '1' }} className='text-red-500 w-[18px] h-[18px]' /></button>
                                                        
                                                        </div>
                                                      </div>
                                                   
                                                </AccordionContent>
                                            </AccordionItem>
                                        </>)}


                                    </>
                                ))}



                            </Accordion>
                        </div>
                    </div>
                    <div class="md:w-1/3">
                        {/* Column 2 (4/12 or 1/3) */}
                        <div className='fbconnect-information-container mt-2  mb-md-0 px-1 py-0'>
                        <div className="mb-4 d-none d-md-block mb-md-3 mt-md-3 px-1 py-0 ">
                        <ProfileCompleteCard
                            accountPercentage={accountPercentage}
                            userName={userName}
                            fbpagePercentage={fbpagePercentage}
                            businessPercentage={businessPercentage}
                            notificationPercentage={notificationPercentage}
                            overallPercentage={overallPercentage}
                        />
                    </div>
    <Card style={{ backgroundColor: 'white' }} className="px-4 mb-4 mb-md-0 shadow-sm border border-[#E4E4E7]">
        <div>
            <p className='p-0 m-0 addpages-main-h py-2'>Integration Steps</p>
            <div className='mt-1'>
                <ol className='list-decimal text-sm ps-3'>
                    <li>
                        <p className='p-0 m-0 addpages-p mb-3'>After clicking on <b>“Add Pages”</b>, log in to Facebook using the admin account for the page you want to connect.</p>
                    </li>
                    <li>
                        <p className='m-0 p-0 addpages-p mb-3'>Select the first option: <b>“Opt in to all current and future Businesses” under “choose the business you want LeapXads to access”</b> section</p>
                    </li>
                    <li>
                        <p className='m-0 p-0 addpages-p mb-3'>Choose the pages</p>
                    </li>
                    <li>
                        <p className='m-0 p-0 addpages-p mb-3'>Review Permission</p>
                    </li>
                    <li>
                        <p className='m-0 p-0 addpages-p mb-3'><b>Connect</b> the selected pages</p>
                    </li>
                    <li>
                        <p className='m-0 p-0 addpages-p mb-3'><b>To add a new page</b>, click on <b>“Add Pages”</b>, then <b>“Edit Previous Settings”</b></p>
                    </li>
                    <li>
                        <p className='m-0 p-0 addpages-p mb-3'><b>To add a page from a different account</b>, click on <b>“Add Pages”</b>, then <b>“Log in to another account”</b>.</p>
                    </li>
                </ol>
            </div>
        </div>
    </Card>

    
</div>

                    </div>
                </div>
            </div>

            <div className="d-block d-md-none"><BottomNavbar /></div>

        </>
    );
}

export default FacebookPages;
