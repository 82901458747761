import Navbar2 from "../../navbar/Navbar2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./leapxSupport.css";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";
import Axios from "../../Axios";
import { AuthContext } from "../../AuthProvider";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../components/ui/select";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import { useSnackbar } from "../../SnackBarProvider";

function loadingReducer(state, action) {
  if (action.type === "loading") {
    return true;
  } else {
    return false;
  }
}

export default function LeapxSupport() {
  const [selectedCampign, setSelectedCampign] = useState("");
  const [description, setDescriptoin] = useState("");
  const [loadingState, loadingDispatch] = useReducer(loadingReducer, false);
  const toast = useRef(null);
  const [allCamapaign, setAllCampaign] = useState([]);
  const { userToken } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [loadSupport, setLoadSupport] = useState(false);
  const location = useLocation();
  const [campaignName, setCampaignName] = useState("");
  const showSnackbar = useSnackbar();

  useEffect(() => {
    Axios.get("/user", {
      headers: {
        Authorization: userToken,
      },
    })
      .then((r) => {
        setUserData(r.data.user);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [userToken]);

  const onSubmitSupport = async (e) => {
    e.preventDefault();

    // Check if the required fields are filled
    if (!selectedCampign || !description) {
      showSnackbar('Please fill in all required fields', 'error');
      return;
    }

    setLoadSupport(true);

    const body = {
      user_name: userData?.name,
      business_name: userData?.business_name,
      phone: userData?.mobile,
      campaign_name: campaignName || '',
      problem_type: selectedCampign ? "campaign" : "other",
      campaign_id: selectedCampign || "",
      description: description || null,
    };

    try {
      const res = await Axios.post("/create-ticket", body, {
        headers: {
          Authorization: userToken,
        },
      });

      if (res.status === 200) {
        const data = res?.data;
        if (data.valid) {
          showSnackbar(`Success: 'Thank you for reaching out! Your support ticket has been successfully submitted.`, 'success');
          
          // Reset form fields
          setSelectedCampign("");
          setDescriptoin("");
          setCampaignName("");
          
        } else {
          showSnackbar(data.message || 'Server error', 'error');
        }
      }
    } catch (error) {
      console.log(error);
      showSnackbar('Oops! Something went wrong, Please try again later ', 'error');
    } finally {
      setLoadSupport(false);
    }
  };

  const getCampaign = async (e) => {
    e?.preventDefault();
    try {
      const res = await Axios.get("/get-all-campaigns", {
        headers: {
          Authorization: userToken,
        },
      });

      if (res.status === 200 && res?.data?.valid) {
        const data = res?.data?.message;
        setAllCampaign(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCampaign();
  }, []);

  useEffect(() => {
    const filterCampaign = allCamapaign.filter((val) => val?.id === selectedCampign);
    if (filterCampaign.length > 0) {
      const camp = filterCampaign[0]?.name;
      setCampaignName(camp);
    }
  }, [selectedCampign, allCamapaign]);

  return (
    <>
      <Toast ref={toast} />
      <div className="d-md-none">
        <Navbar2 menu={true} />
      </div>
      <div className="leapxSupportContainer">
        <div className="previous d-md-none">
          <button onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </button>
          <h1>Leapx Support</h1>
        </div>
        <form onSubmit={onSubmitSupport}>
          <div className="mb-3">
            <Label className="text-[16px] font-semibold">Campaign</Label>
            <Select value={selectedCampign || location?.campaignId} onValueChange={setSelectedCampign} className="w-full">
              <SelectTrigger className="w-full h-11">
                <SelectValue placeholder="Select campaign" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Select Campaign</SelectLabel>
                  {allCamapaign?.map((val) => (
                    <SelectItem key={val._id} value={val._id}>
                      {location?.campaign_name || val?.name}
                    </SelectItem>
                  ))}
                  <SelectItem value="other">Other</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>

          <div className="grid w-full gap-1.5 mb-3">
            <Label className="text-[16px] font-semibold">Description</Label>
            <Textarea
              className="min-h-[100px]"
              onChange={(e) => setDescriptoin(e.target.value)}
              value={description}
              placeholder="Type your message here."
              id="message"
            />
          </div>

          <div className="mt-5">
            <Button type="submit" disabled={loadingState} className="w-full bg-[#62c] hover:bg-[#62c]">
              {loadSupport ? "Submit..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
      <div className="d-md-none">
        <BottomNavbar />
      </div>
    </>
  );
}
