import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Cookies from "js-cookie";
import Axios from "../../Axios";
// import PopupForSelectFbPages from "./PopupForSelectFbPages";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { AuthContext } from "../../AuthProvider";
import PopupForSelectFbPagesInfo from "./PopupForSlectFbPagesInfo";
// import PopupForSelectFbPagesInfo from "./PopupForSelectFbPagesInfo";



function ConnectToFbAndSelectPageInfo({ handleUser, forsubmit, text, pagesListfromprofile, bgcolor }) {

  const [loggedIn, setLoggedIn] = useState(false);
  const [clientPages, setClientPages] = useState([]);
  const [token, setToken] = useState("");
  const [buttonStatus, setButtonStatus] = useState({});
  const toast = useRef(null);
  const [openLoadPopup, setOpenLoadPopup] = useState(false)
  const [isAnyPageSelected, setIsAnyPageSelected] = useState(true)
  const { userToken, setFbToken } = useContext(AuthContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isPageLoad, setIsPageLoad] = useState(false)
  const [buttonStates, setButtonStates] = useState([]);

  //  for popup
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const loadFacebookSDK = () => {

      // Load the JavaScript SDK asynchronously
      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;

      const firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      // Optional: Clean up the script on component unmount
      return () => {
        const fbSdkScript = document.getElementById("facebook-jssdk");
        if (fbSdkScript) {
          fbSdkScript.parentNode.removeChild(fbSdkScript);
        }
      };
    };

    window.fbAsyncInit = () => {
      // FB JavaScript SDK configuration and setup
      window.FB.init({
        appId: "1052762895750334",
        // appId: "700560265020731",
        cookie: true,
        xfbml: true,
        version: "v20.0",
      });
    };

    loadFacebookSDK();
  }, []);

  const launchFBE = () => {
    if (window.FB) {
      window.FB.login(
        function (response) {
          // console.log("fb res", response);
          if (response.authResponse) {

            const accessToken = response?.authResponse?.accessToken;
            setFbToken(accessToken);
            Cookies.set("fbToken", accessToken, { expires: 7 });

            handelClientsPage(accessToken);
            setToken(accessToken);
            // console.log("launtoken", accessToken)
            // Store access token for later
            setLoggedIn(true);
            // navigate("/add-campaign-summery")
          } else {
            console.log("User cancelled login or did not fully authorize.");
            setLoggedIn(false);
          }
        },

        {
          config_id: '2496508650531906',

        }




        // {
        //   config_id:'8098407086865359',
        // },
        // {
        //   config_id:'2496508650531906',
        // },
        // {
        //   scope:
        //     "read_insights,pages_manage_cta,pages_manage_instant_articles,pages_show_list,ads_management,ads_read,business_management,leads_retrieval,page_events,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement",
        // }
      );
    }
  };
  // get all pages using fbtokem
  const handelClientsPage = async (token) => {
    setOpenLoadPopup(true)
    // console.log("/user-pages?access_token", token)
    Axios.get(`/user-pages?access_token=${token}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    })
      .then((response) => {



        const filtereForProfilePage = response?.data?.message?.data?.filter(item => !pagesListfromprofile?.map(item1 => item1?.id).includes(item?.id));

        if (filtereForProfilePage) {

          setButtonStates(filtereForProfilePage?.map(() => ({
            isLoading: false,
            isSuccess: false,
            isError: false,
          })));
          setClientPages(filtereForProfilePage);
          handleClickOpen()

        }
        setOpenLoadPopup(false)
      })
      .catch((error) => {
        setOpenLoadPopup(false)

      });
  };


  // clicked pages
  const handelSelected = (item) => {
    let myObject = {
      id: item?.id,
      name: item?.name,
      about: item?.about,
      phone: item?.phone
    };

    handlePages(myObject)
  };
  const handlePageAccess = (item) => {

    setIsPageLoad(true)
    setButtonStatus({ ...buttonStatus, [item?.id]: 'loading' });
    const body = {
      pageId: item?.id,
      access_token: token,
    };

    Axios.post("/page-access-request", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: userToken,
      },
    })
      .then((response) => {
        setIsPageLoad(false)
        const data = response?.data?.message;

        if (data?.access_status === "CONFIRMED") {

          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
          setSelectedItems([...selectedItems, item.id])
          handelSelected(item)
          setIsAnyPageSelected(false)
        } else if (data?.access_status === "PENDING") {

          setSelectedItems([...selectedItems, item.id])
          handelSelected(item)
          setIsAnyPageSelected(false)
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
        }
      })
      .catch((error) => {

        setIsPageLoad(false)
        if (
          error?.response?.data?.message?.error?.error_subcode === "1752041"
        ) {

          setButtonStatus({ ...buttonStatus, [item?.id]: 'error' });
        } else if (error?.response?.data?.message?.error?.error_subcode == "1690061") {

          setSelectedItems([...selectedItems, item.id])
          handelSelected(item)
          setIsAnyPageSelected(false)
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
        } else if (error?.response?.data?.message?.error?.error_subcode == "1690115") {

          setSelectedItems([...selectedItems, item.id])
          handelSelected(item)
          setIsAnyPageSelected(false)
          setButtonStatus({ ...buttonStatus, [item?.id]: 'success' });
        } else {

          setButtonStatus({ ...buttonStatus, [item?.id]: 'error' });

        }
        console.log(
          "Error setting up request:"
        );
      });
  };
  const handlePages = async (item) => {

      try {
        const res = await Axios.post("/add-pages",item, {
          headers:{
            Authorization:userToken
          }
         } );

         if(res.status===200){
          handleUser()
         }
      } catch (error) {
        console.log("Error setting up request:");
        alert("Conntection failed");
      }

  };

  const onSubmit = () => {
    setOpen(false)
  };


  return (
    <>
      {/* <div className="d-md-none"> <NavbarLogo /></div> */}
      <Toast ref={toast} />
      <div className="antialiased">
        <Toast ref={toast} />
        <div className="container-fluid p-0">
          <div className="container p-0">
            <div className="row">
              <div className="col-12" style={{ backgroundColor: bgcolor ? bgcolor : 'white' }} >
                <div className="mb-3" >
                  <div className="d-flex justify-content-start align-items-center mb-3" >

                  </div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      width: '100%',
                      justifyContent: "center",
                      marginTop: "10%",
                    }}
                  >
                    <PopupForSelectFbPagesInfo buttonStatus={buttonStatus} clientPages={clientPages} selectedItems={selectedItems} handlePageAccess={handlePageAccess} onSubmit={onSubmit} handleClose={handleClose} open={open} />

                    <p
                      onClick={launchFBE}
                      style={{
                        background: "#1877f2",
                        color: "white",
                        padding: "10px 30px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        border: "none",
                        width: '100%',
                        textAlign: 'center',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'center'
                      }}
                    >
                      <FacebookRoundedIcon sx={{ marginRight: 1 }} />
                     {openLoadPopup?(<>Fetching pages&nbsp;<span class="loaderfb"></span></>):text ? text : 'Link your facebook page'} 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectToFbAndSelectPageInfo;
