import React, {useState, useContext, useEffect} from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { CircularProgressbar } from "react-circular-progressbar";
import rupes from "../../asets/rupess.png";
import { AuthContext } from '../../AuthProvider';
import './paymentHistoryPage.css'

function WalletCardForTransactionPage() {
    const {userDetails, allFbData } = useContext(AuthContext)
    const [walletCardData, setWalletCardData] = useState("")

    function formatIndianNumber(number) {
        const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
        return formattedNumber;
      }


      useEffect(() => {
        HandleHomePageData(allFbData) 
      }, [allFbData?.length])
    
const  HandleHomePageData = (allFbData)=>{
      
    let walletbalancecard = {
        wallet_balance: 0,
        total_spend: 0,
        deployed: 0,
        total_spend_till_date: 0,
      };
    


      allFbData.forEach((val)=>{
        let spent = 0;
         // walletcard


  //parseInt(item?.data?.actions ? item?.data?.actions?.find((action) => action?.action_type === 'lead')?.value : 0);
  val?.insights?.data?.forEach((val) => {
          
      spent = spent + (val?.spend?Number(val?.spend):0  );

    
    });




      if (val?.status === "ACTIVE" || val?.status === "PAUSED") {
        walletbalancecard.total_spend =
          walletbalancecard?.total_spend + Math.round((Number(spent)));
      }
      if (val?.status && spent) {
        //  console.log("valvv", val?.insights?.data[0]?.spend)

        walletbalancecard.total_spend_till_date =
          walletbalancecard?.total_spend_till_date +
          Math.round((Number(spent)))
      }
      if (val?.status === "ACTIVE" || val?.status === "PAUSED" || val?.status === "UNDER REVIEW") {
        walletbalancecard.deployed =
          walletbalancecard?.deployed + Math.round((Number(val?.lifetime_budget)));
      }
      if (val.status === "STOPPED") {
        walletbalancecard.wallet_balance =
          walletbalancecard.wallet_balance + Math.round((Number(val?.budget_remaining)));
      }

      })

      if (walletbalancecard) {
        //  console.log("walletbalancecard", walletbalancecard);
        setWalletCardData(walletbalancecard);
      }
}




  return (
     <>
      

<div className="row  p-0 m-0 justify-content-start">
  {/* <p className='m-0 p-0 mb-3 py-2' style={{ fontSize: '24px', fontWeight: '700' }}>Wallet</p> */}

  <div className="wallet3 w-full sm:w-[300px] flex justify-center items-center m-0 " style={{}}>
     <div className='d-flex justify-content-center gap-3  gam-md-3 gap-lg-5 gap-xl-3 gap-xxl-5 align-items-start position-relativ  '>
     <div className="balance" style={{ maxWidth: '266px' }}>
      <div className='d-flex align-items-center gap-1'>
        <img src={rupes} className='wallet-iconx' alt="" />
        <h6 className='p-0 m-0'>Wallet Balance</h6>
      </div>


      <p className="">
        <CurrencyRupeeIcon sx={{ fontSize: { xs: '22px', sm: '18px', md: '24px', lg: '22px', xl: '24px' }, paddingBottom: '0px', marginBottom: '5px' }} />
        {userDetails?.wallet
          ? formatIndianNumber(Math.round(Number(userDetails?.wallet)))
          : 0}
      </p >
      <div className="company-name" style={{ marginTop: '0px' }}>

        <h6 className='company-name-wallet'>{userDetails?.business_name}</h6>
        <p className='mt-1 spent-till-text'>
          Total spent till date <br />
          <CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '11px', xl: '11px' }, paddingBottom: '0px', marginBottom: '2px' }} />
          {walletCardData?.total_spend_till_date
            ? formatIndianNumber(walletCardData?.total_spend_till_date)
            : 0}{" "}
        </p>

      </div>
    </div>
    <div className=" position-relative flex-1" style={{  }}>
      <h6 className="position-absolut text-start active-camp-text" style={{ fontWeight: '500', color: 'white', width: '100%' }}>Active Campaigns</h6>
      <div className='slider'>
        <div style={{ minWidth: '60%', maxWidth: '90%' }} className=''  >
          {/* <h6 className="position-absolut text-center active-camp-text" style={{ fontWeight:'400', color:'white'}}>Active Campaigns</h6> */}

          <CircularProgressbar
            value={(walletCardData?.total_spend / walletCardData?.deployed) * 100}
            text={`${(walletCardData?.total_spend / walletCardData?.deployed) * 100
                ? Math.floor(
                  (walletCardData?.total_spend / walletCardData?.deployed) *
                  100
                )
                : 0
              }%`}
            styles={{
              path: {
                stroke: `#ffffff`,
                strokeLinecap: "butt",
                transition: "stroke-dashoffset 0.5s ease 0s",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
              },
              trail: {
                stroke: "#FFFFFF83",
                strokeLinecap: "butt",
                transform: "rotate(0.25turn)",
                transformOrigin: "center center",
              },
              text: {
                fill: "#ffffff",
                fontSize: "16px",
                fontWeight: 500,
              },
              background: {
                fill: "#3e98c7",
              },
            }}
          />
        </div>
        <div style={{ minWidth: '40%', maxWidth: '60%', marginLeft:'8px' }} className='d-flex justify-content-center' >
          <div className='ps-3'>
            <div className="spend">
              <h6 className="spent-heading">
                <div className="circle-type"></div>
                <span>Spent</span>
              </h6>

              <p style={{ fontSize: '9px', marginTop: '2px' }} className="p-0">
                <CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '11px', xl: '11px' }, paddingBottom: '0px', marginBottom: '2px' }} />
                {walletCardData?.total_spend ? formatIndianNumber(walletCardData?.total_spend) : 0}
              </p>
            </div>
            <div className="spend">
              <h6 className="spent-heading">
                <div className="circle-type" style={{ opacity: 0.8 }}></div>
                <span>Deployed</span>
              </h6>

              <p style={{ fontSize: '9px', marginTop: '2px' }} className="p-0"> <CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '12px', md: '12px', lg: '11px', xl: '11px' }, paddingBottom: '0px', marginTop: '-2px' }} /> {walletCardData?.deployed ? formatIndianNumber(walletCardData?.deployed) : 0}</p>
            </div>
          </div>

        </div>
      </div>

    </div>
     </div>
  
  </div>





</div>
     
     </>
  )
}

export default WalletCardForTransactionPage