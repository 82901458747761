import React, {useState, useContext} from 'react'
import { Button } from "../../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog"
import alerticon from '../../../asets/alerticonstus.svg'
import Axios from '../../../Axios';
import { AuthContext } from '../../../AuthProvider';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


function DeleteAccount() {
    const {userToken, setUserToken, setallFbData, setCampaignInsitsData} = useContext(AuthContext)
    const [open, setOpen] = useState(false);
    const [loadDeletedAc, setLoadDeletedAc] = useState(false);
    const [error, setError] = useState(false)
    const navigate = useNavigate()

    const handleDeleteAccount = async ()=>{
        setLoadDeletedAc(true)
           try {
             const res = await Axios.post("/delete-user", {token:userToken}, {headers:{Authorization:userToken}})
                  if(res.status===200){
                    Cookies.remove("userToken");
                    Cookies.remove("fbToken");
                    setUserToken("")
                    setallFbData("")
                    setCampaignInsitsData("")
                    window.location.reload()
                    navigate("/signup")
                         
                  }else{
                    setError(true)
                  }
           } catch (error) {
            setError(true)
           }finally{
            setLoadDeletedAc(false)
           
           }
    }

  return (
    <Dialog open={open}  onOpenChange={setOpen}>
    <DialogTrigger asChild className=''>
      <Button variant="outline" onClick={() =>{ setOpen(true);  setError(false); setLoadDeletedAc(false);}}  className="text-red-300 shadow-none text-[10px] h-7 p-1 hover:text-red-500">Delete Account</Button>
    </DialogTrigger>
    <DialogContent className="sm:max-w-[425px] ">
      <DialogHeader>
        <div className='text-center mb-2'>
            <img src={alerticon} alt="" />
        </div>
        <DialogDescription className="mt-2">
         {error?<p className=''>"We encountered an issue while attempting to delete your account. Please try again later</p>:<p className='text-center text-[16px]'>Are you sure you want to delete your LeapX account?</p>}   
        
        </DialogDescription>
      </DialogHeader>
 
      <DialogFooter>
         <div className='w-full flex items-center justify-center gap-6'>
         <Button onClick={handleDeleteAccount} disabled={loadDeletedAc?true:false} variant="outline" className="w-1/2">{loadDeletedAc?<><span class="loaderfbpage"></span></>:'Yes'}</Button>
         <Button className="w-1/2" onClick={() =>{ setOpen(false);  setError(false); setLoadDeletedAc(false)}}>Cancel</Button>
         </div>
     
      </DialogFooter>
    </DialogContent>
  </Dialog>
  )
}

export default DeleteAccount