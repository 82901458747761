import React from 'react';
// import desktopimg from '../../asets/asetes/leapx dashboard img.png';
import './landingpage-component.css';
import freedemoicon from '../../asets/asetes/demo icon.svg';
import { ArrowUpRight as MoveUpRight } from 'lucide-react';
// import partnerlogo from '../../asets/asetes/login-1-image (4).png'

function TryFreeAndDemo() {
  
  const openSignupInNewTab = () => {
    window.open("/signup", "_blank");
  };

  // const url = 'https://www.youtube.com/watch?v=nTKi1VKNtXQ'

  return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-12 py-3">
          <div className='tryforfreedemo-btn gap-3 d-flex flex-column flex-md-row justify-content-center align-items-center'>
            <button className='try-for-free-btn2 flex items-center justify-center' onClick={openSignupInNewTab}>
            <span>Try for free</span> &nbsp; <span><MoveUpRight className=' h-5'/></span>
            </button>
            <a className='getdemo-btn-outer' href="https://outlook.office365.com/book/LEAPXAIDEMO@leapx.ai" style={{color:'white'}} target='_blank' >
            <button className='get-demo-btn'>
               
               <img src={freedemoicon} width={20} height={20} alt="" />
              &nbsp; Book Demo             
            </button>
            </a>
          </div>
        </div>
      {/* <div className='col-12 pt-md-4 pt-3' style={{background:''}}>
                      <p className='importantoutcome-p my-3 my-md-0 pt-0 pb-0 text-center'>Supported by</p>
                      <div className='text-center ' style={{width:'100%'}}>
                        <img src={partnerlogo} className='partnerlogo'  alt="" />
                      </div>
                    </div> */}


      </div>
    </div>
  );
}

export default TryFreeAndDemo;
