import React from 'react'
import './landingpage-component.css';
import icon1 from '../../asets/asetes/iconim1.png'
import icon2 from '../../asets/asetes/iconim2.png'
import icon3 from '../../asets/asetes/iconim3.png'
import icon4 from '../../asets/asetes/iconim4.png'
// import simplebg from '../../asetes/simpleandinovativebg.png'
// import mobileview from '../../asetes/Group 1171277394.png'
import newimg from '../../asets/asetes/intuitive-features (1).png'

function SimpleIntuitiveFeatures() {
  return (
       <div className="container-fluid m-0 simpleIntuitiveFeatures-container ">
          <div className="sif-orangebg-left"></div>
          <div className="sif-orangebg-right"></div>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className='simpleIntuitiveFeatures-content pb-3'>
                        <p className='text-center sif-h '><span className='text-gradient'>Simple </span>and Intuitive features</p>
                        <p className='text-center p-0 m-0 sif-p'>Designed for those with no digital advertising experience, LeapX offers a straightforward </p>
                        <p className='text-center p-0 m-0 sif-p'>and intuitive interface. Enjoy simplicity without compromising on flexibility and choice.</p>
                    </div>
                </div>

            </div>
            <div className="row">
            <div className="col-12 col-md-6 d-block d-md-none">
                   <div className='d-flex position-relative justify-content-center align-items-center py-2'>
                   <img src={newimg}  style={{maxWidth:'100%', maxHeight:'664px', display:'block'}} className='simpleb' alt="" />
                      {/* <img src={simplebg}  style={{maxWidth:'100%', maxHeight:'664px', display:'block'}} className='simplebg' alt="" />
                    <img src={mobileview} className='sif-main-img' style={{maxWidth:'100%', height:'auto', display:'block'}} alt="" /> */}
                   </div>
                </div>
                <div className="col-12 col-md-6 d-none d-md-block">
                   <div className='d-flex position-relative justify-content-center align-items-center p-3'>
                   <img src={newimg}  style={{maxWidth:'100%', maxHeight:'664px', display:'block'}} className='simpleb' alt="" />
                      {/* <img src={simplebg}  style={{maxWidth:'100%', maxHeight:'664px', display:'block'}} className='simplebg' alt="" />
                    <img src={mobileview} className='sif-main-img' style={{maxWidth:'100%', height:'auto', display:'block'}} alt="" /> */}
                   </div>
                </div>
                <div className="col-12 col-md-6 ps-md-0 d-flex justify-content-center">
                      <div  className='ps-4 ps-md-1 py-4'>
                      <div className='sif-card-container1 gap-4 sif-card-container1 h-100 py-md-5 pt-md-5  d-flex flex-column justify-content-between'>
                        <div className='sif-card1 d-flex gap-2 px-2 justify-content-start align-items-start flex-column'>
                           {/* <img src={icon1} width={36} height={36} className='sif-icon3' alt="" /> */}
                              <div className='d-flex justify-content-start gap-1'>

                              <img src={icon1} width={36} height={36} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0'>Efficient & Quick</p>
                              </div>
                             
                              <p className='sif-card1-p p-0 m-0 '>Launch campaigns within 90 seconds</p>
                        </div>

                        <div className='sif-card1 d-flex gap-2 px-2 justify-content-start align-items-start flex-column'>
                            <div className='d-flex justify-content-start gap-1'>
                            <img src={icon2} width={36} height={36} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0  '>Monitor Progress</p>
                            </div>
                          
                              <p className='sif-card1-p p-0 m-0 '>Keep an eye on campaign progress and pace.</p>
                        </div>

                        {/* new */}
                        <div className='sif-card1 d-flex gap-2 px-2 justify-content-start align-items-start flex-column'>
                            <div className='d-flex justify-content-start gap-1'>
                            <img src={icon3} width={33} height={33} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0  '>Adaptable & Versatile</p>

                            </div>
                          
                              <p className='sif-card1-p p-0 m-0 '>Pause, resume, and tailor campaign durations to fit your needs</p>
                        </div>
                        <div className='sif-card2 d-flex gap-2 px-2 justify-content-center align-items-start flex-column'>
                           <div className='d-flex justify-content-start gap-1'>
                           <img src={icon4} width={33} height={33} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0 '>Manage Leads</p>

                           </div>
                        
                              <p className='sif-card1-p p-0 m-0'>Seamlessly connect and manage leads with ease</p>
                        </div>
                    
                        </div>
                      </div>
                       

                        {/* <div className='sif-card-container1 mt-md-0 gap-2 sif-card-container1 h-100 py-md-5  d-flex  flex-column justify-content-between'>
                        <div className='sif-card1 d-flex gap-2 px-2 justify-content-start align-items-start flex-column'>
                            <div className='d-flex justify-content-start gap-1'>
                            <img src={icon3} width={33} height={33} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0  '>Adaptable & Versatile</p>

                            </div>
                          
                              <p className='sif-card1-p p-0 m-0 '>Pause, resume, and tailor campaign durations to fit your needs</p>
                        </div>
                        <div className='sif-card2 d-flex gap-2 px-2 justify-content-center align-items-start flex-column'>
                           <div className='d-flex justify-content-start gap-1'>
                           <img src={icon4} width={33} height={33} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0 '>Manage Leads</p>

                           </div>
                        
                              <p className='sif-card1-p p-0 m-0'>Seamlessly connect and manage leads with ease</p>
                        </div>
                        </div> */}
                        
                </div>
                
                {/* <div className="col-12 col-md-3 ps-5 ps-md-0">
                <div className='sif-card-container1 mt-4 mt-md-0 gap-2 sif-card-container1 h-100 py-md-5  d-flex  flex-column justify-content-between'>
                        <div className='sif-card1 d-flex gap-2 px-2 justify-content-start align-items-start flex-column'>
                            <div className='d-flex justify-content-start gap-1'>
                            <img src={icon3} width={33} height={33} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0  '>Adaptable & Versatile</p>

                            </div>
                          
                              <p className='sif-card1-p p-0 m-0 '>Pause, resume, and tailor campaign durations to fit your needs</p>
                        </div>
                        <div className='sif-card2 d-flex gap-2 px-2 justify-content-center align-items-start flex-column'>
                           <div className='d-flex justify-content-start gap-1'>
                           <img src={icon4} width={33} height={33} className='sif-icon3' alt="" />
                              <p className='sif-card1-h p-0 m-0 '>Manage Leads</p>

                           </div>
                        
                              <p className='sif-card1-p p-0 m-0'>Seamlessly connect and manage leads with ease</p>
                        </div>
                        </div>
                </div> */}
            </div>
        </div>
       </div>
  )
}

export default SimpleIntuitiveFeatures