import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import './showpages.css'
import successicon from '../../asets/fbpagesicon/successicon.png'
import cancelicon from '../../asets/fbpagesicon/cancelicon.png'
import retryicon from '../../asets/fbpagesicon/retryicon.png'
import clickicon from '../../asets/fbpagesicon/clickicon.png'


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function PopupForSelectFbPagesInfo({clientPages, onSubmit, handlePageAccess, handleClose, open, buttonStatus}) {
  
   
  return (
    <React.Fragment>
      {/* {forsubmit?null:(<>
        <Button variant="outlined" onClick={handleClickOpen}>
        Click to select pages
      </Button>
      </>)} */}
      
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
       
        
      >
        <DialogTitle sx={{ m: 0, p: 1 }} className='d-flex justify-content-start align-itens-center' id="customized-dialog-title">
         <p className='p-0 m-0' style={{maxWidth:'280px', fontSize:'18px' , fontWeight:'500'}}> Click To Connect Facebook Pages</p>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers style={{maxHeight:'320px', maxWidth:'400px', overflowY:'scroll'}}>
            <div>
               
                <div className='facebook-pages-container mt-4'>
               <div>
                  <div className=' d-flex justify-content-between p-0 m-0'>
                    <p style={{ color:'#6E6E6E'}} className='p-0 m-0 page-clicked'>Total Pages <span style={{ color:'#EA9A00'}}>{clientPages?.length?clientPages?.length:0}</span></p>
                   
                  </div>
                 <div className='pages-container border' style={{borderRadius:'8px'}}>
                    {clientPages?.map((item, index)=>(
                      <>
                      <button disabled = {(buttonStatus[item.id] === 'success' || buttonStatus[item?.id] === 'loading')?true:false} onClick={() => {handlePageAccess(item);}} key={index + 1}  className={ buttonStatus[item.id] === 'success'?' mt-3 page-item2 ':' mt-3 page-item '} style={{borderRadius:'8px'}}>
                      <div className='d-flex justify-content-between align-items-center'>
                      <p className='p-0 m-0 page-name' >
                       {item?.name}
                      </p>
                      {buttonStatus[item?.id] === 'loading'?<>
                  <p className='p-0 m-0'>
                  <CircularProgress size={20} sx={{padding:'0px', margin:'0px', color:'rgba(0, 0, 0, 1)'}} />
                    </p>
                      </>:null}
                      {
                        buttonStatus[item.id] === 'success'?<> <p className='p-0 m-0 page-event'>
                        Connected <img src={successicon} alt="" />
                        </p></>:null
                      }
                      {
                        buttonStatus[item?.id] === 'error'?<>
                        <p className='p-0 m-0 page-event' >
                  <span style={{color:'rgba(234, 154, 0, 1)'}}>Retry <img src={retryicon} alt="" /></span>&nbsp; &nbsp;<span style={{color:'rgba(217, 0, 0, 1)'}}>Failed <img src={cancelicon} alt="" /></span>
                  </p>
                        </>:null
                      }
                      {
                        (buttonStatus[item.id] === 'success' || buttonStatus[item?.id] === 'error' || buttonStatus[item?.id] === 'loading' )?null:<>
                        <p className='p-0 m-0 page-event'>
                      Click to connect <img src={clickicon} alt="" />
                      </p>
                        </>
                      }
                        
                      </div>
                    
                    </button>
                      </>
                    ))}
                                 
                 </div>


               </div>
            </div> 

                      </div>
                  </DialogContent>
                  <DialogActions>
                    <Button  autoFocus onClick={() => { onSubmit()}}> Submit</Button>                   
                  </DialogActions>
                </BootstrapDialog>
              </React.Fragment>
  );
}
