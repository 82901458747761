
import {createContext,useEffect, useState} from "react";
import Cookies from "js-cookie";
import Axios from "./Axios";
import { io } from 'socket.io-client';

function checkConnectedSuccess(pages) {
    return pages.map(page => page.connected).includes('success');
}

function isKeyAbsent(array) {
    return array.every(obj => !obj.hasOwnProperty('connected'));
  }

export const AuthContext = createContext()

const AuthProvider = ({children}) => {
  const [loadConfirmAndPay, setLoadConfirmAndPay] = useState(false)
    const [userToken, setUserToken] = useState(null);
    const [fbToken, setFbToken] = useState(null);
    const [campaignCreationStatus, setCampaignCreationStatus] = useState({isCampLoading:false, isCampaignCreated:false})
    const [callApiCount, setCallApiCount] = useState(0)
    const [isCampLoading, setIsCampLoading] = useState(false)
    const [campaignId, setCampaignId] = useState(null);
    const [pageId, setPageId] = useState(null)
    const [pageAccessToken, setPageAccessToken] = useState(null)
    const [laodAddBudget, setLoadAddBudget] = useState(false)
    const [isFbDataLoader, setIsFbDataLoader] = useState(false)
    const [allFbData, setallFbData] = useState([]);
    const [userDetails, setUserDetails] = useState({})
    const [campaignInsitsLoad, setCampaignInsitsLoad] = useState(false)
    const [campaignInsitsData, setCampaignInsitsData] = useState([])
   
    const [addBudgetOrderId, setAddBudgetOrderId] = useState("")
    const [leadFromSocket, setLeadFromSocket] = useState({})
    const [userProfileData, setUserProfileData] = useState("")
    // const [DataForAddBudget, setUserDataForAddBudget] = useState({})
    // profile status
    const [accountPercentage, setAccountPercentage] = useState(0)
    const [fbpagePercentage, setFbpagePercentage] = useState(0)
    const [businessPercentage, setBusinessPercentage] = useState(0)
    const [overallPercentageGloabal, setOverallPercentageGloabal] = useState(0)
    const [notificationPercentage, setNotificationPercentage] = useState(0)
    const [profileCompleteStatus, setProfileCompleteStatus] = useState({
        accountdetails: 0,
        businessdetails: 0,
        fbpage: 0,
        notification: 0
    })

    const handleAllFBApiCall = async (userToken) =>{
      setIsFbDataLoader(true)
            try {
              const res = await Axios.get("/get-homepage-data", {
                headers:{
                  Authorization:userToken
                }
            })
           // console.log("homedatares", res)
               if(res.status===200){
                const data = res?.data?.message;
                setallFbData(data)
               
               }
              
            } catch (error) {
              //  console.log("errorhomedata",error)
            }finally{
              setIsFbDataLoader(false)
            }
      
    }


// get insites for campaign mgn page
// get ctr cpl etc
  
const fetchCampaignInsites =  async(userToken) =>{
  setCampaignInsitsData([])
  setCampaignInsitsLoad(true)
      try {
         const res = await Axios.get("/get-campaign-insights", {
          headers:{
            Authorization:userToken
          }
         })
           if(res.status===200){
              const data = res.data?.message
              
              let campigncard = []

              // console.log(data)
              if(data){
                data?.forEach((val) => { 
                  //  console.log("val", val)
                  const obj1 = {
                    campaign_id: val?.id,
                    name: val?.name?val?.name:'',
                    lifetime_budget:val?.lifetime_budget?(Number(val?.lifetime_budget)):0,
                    status: val?.status,
                    impressions: 0,
                    spend:  0,
                    leads_count: 0,
                    _id:val?.dbId,
                    discount:val?.discount,
                    date:val?.createdAt,
                     cpm:0,
                     cpc:0,                
                     ctr:0
                  };

                  val?.insights?.data?.forEach((val) => {
                
                    obj1.leads_count = (parseInt(val?.actions?.length ? val?.actions?.find((action)=>action?.action_type==='lead')?.value || 0 : 0));
                
                     obj1.impressions = val?.impressions?Number(val?.impressions): 0 ;
                     //spent
                     obj1.spend = parseFloat(val?.spend || 0);
                      //spent
                      // console.log("spend",(Number(val?.spend) || 0))
                    
      
                     //cpm
                      obj1.cpm =  (val?.cpm?Number(val?.cpm):0  )
                     //cpc
                     obj1.cpc =  (val?.cpc?Number(val?.cpc_link_click):0  )
                     //ctr
                     obj1.ctr =  (val?.ctr?Number(val?.ctr_link_click):0  )
                    
                 });

                 if (obj1?.name) {
                  
                  campigncard?.push(obj1);
                }
      

                }
              )}

               if( campigncard?.length>0){
                setCampaignInsitsData(campigncard)
               }else{
                setCampaignInsitsData([])
               }
              // console.log("insitesdata", data)
           }
      } catch (error) {
        setCampaignInsitsData([])
        console.log("error", error)
      }finally{
        setCampaignInsitsLoad(false)
      }
}


    useEffect(() => {
     const Token = Cookies.get('userToken');
     const FBToken = Cookies.get('fbToken');
     const campaign = Cookies.get('campaignID');
     const page = Cookies.get('pageId');
     const pageAccess = Cookies.get('pageAccessToken');
      if (Token) {
     //  setUserToken(Token)
        
     const checkAuth = async ()=>{
      try {
        const res = await Axios.get("/user", {
          headers:{
            Authorization:Token
          }    
        })
        if(res.status===200){
          setUserToken(Token)
          // console.log("userdetails", res?.data?.user)
           
            const details = res?.data?.user
                if(details){
                  setUserDetails(details)



                    // fb percentage

                    let fbpercentage = 0;

                    if (details?.page_id?.length > 0) {

                        const isconnectedpresent = isKeyAbsent(details?.page_id)

                        //   console.log("isconnectedpresent", isconnectedpresent)
                        const isconnected = checkConnectedSuccess(details?.page_id)

                        if (isconnected || isconnectedpresent) {
                            fbpercentage = 40;
                            setProfileCompleteStatus({...profileCompleteStatus, fbpage:1})
                        }

                    }

                    if (fbpercentage) {
                        setFbpagePercentage(fbpercentage)
                    } else {
                        setFbpagePercentage(0)
                    }



                    // account percentage

                    let accountpercetage = 0
                    // let completedFields = 0;
                    if (details?.name) accountpercetage += 5;
                    if (details?.email_verified_at) accountpercetage += 10;
                    if (details?.mobile) accountpercetage += 5;
                    // if (details?.password_updated_at) accountpercetage += 5;
                    if (accountpercetage) {
                        setAccountPercentage(accountpercetage)
                        if (accountPercentage === 20) {
                            setProfileCompleteStatus({...profileCompleteStatus, accountdetails:1})
                        }
                    } else {
                        setAccountPercentage(0)
                    }

                    // business percentage
                    let businesspercentage = 0;

                    if (details?.business_name) businesspercentage += 15;
                    // if(details?.gst) businesspercentage += 6;
                    if (details?.pan_no) businesspercentage += 15;
                    // if(details?.business_type) businesspercentage += 6;
                    // if(details?.logo) businesspercentage += 6;

                    if (businesspercentage) {
                        setBusinessPercentage(businesspercentage)
                        if (businessPercentage === 30) {
                            setProfileCompleteStatus({...profileCompleteStatus, businessdetails:1})
                        }
                    } else {
                        setBusinessPercentage(0)
                    }

                    // Notification percentage
                    let notificationpercentage = 0;
                    if (details?.notification_emails?.length > 0) notificationpercentage += 5;
                    if (details?.gsheet_emails?.length > 0) notificationpercentage += 5;

                    if (notificationpercentage) {
                        setNotificationPercentage(notificationpercentage)
                        if (notificationPercentage === 10) {
                            setProfileCompleteStatus({...profileCompleteStatus, notification:1})
                        }
                    } else {
                        setNotificationPercentage(0)
                    }

                    // total percentage
                    const overallpercentage = fbpercentage + accountpercetage + businesspercentage + notificationpercentage;

                    if (overallpercentage) {
                      // console.log("overallpercentage1", overallpercentage)
                      setOverallPercentageGloabal(overallpercentage)
                    }






                }
            
        }
      } catch (error) {
        Cookies.remove('userToken');
        window.location.reload()
        
      }
 }

 checkAuth()

      // handleAllFBApiCall(Token)
      }
      if(FBToken){
         setFbToken(FBToken)
      }
      if(campaign){
        setCampaignId(campaign)
      }
      if(page){
        setPageId(page)
      }
      if(pageAccess){
        setPageAccessToken(pageAccess)
      }

    }, []);




    
    
    
     
    useEffect(()=>{
        if(userToken){
          handleAllFBApiCall(userToken);
          fetchCampaignInsites(userToken)
        }
      // handleAllFBApiCall(userToken)

     //  console.log("runnns12")
    },[userToken])


   

  
  

  
    // socket
// useEffect(() => {

//   const socket = io('https://leapxads.com/socket.io',{ transports: ['websocket'], withCredentials: false , query:{token:userToken}});



    
//   function onConnect() {
//     console.log('Connected to server');
//   }


//   function onDisconnect() { 
//     console.log('Disconnected from server');
//   } 

//   function onFooEvent(lead) {
//     console.log('New lead received:', lead);
//     if(lead){
//       setLeadFromSocket(lead)
//     }
//   }
  
//   socket.on('connect', onConnect); 
//   socket.on('disconnect', onDisconnect);
//   socket.on('newLead', onFooEvent);

//   return () => {
//     socket.off('connect', onConnect); 
//     socket.off('disconnect', onDisconnect);
//     socket.off('newLead', onFooEvent);
//   };

// },[userToken]);







  return (
   
  <AuthContext.Provider value={{userToken, isFbDataLoader, setUserToken, fbToken, setFbToken, campaignId, setCampaignInsitsData, setCampaignId, pageId, setPageId, pageAccessToken, setPageAccessToken, allFbData, setallFbData, campaignCreationStatus, setCampaignCreationStatus, isCampLoading, setIsCampLoading, handleAllFBApiCall, callApiCount, setCallApiCount, userDetails, campaignInsitsLoad, campaignInsitsData, fetchCampaignInsites, loadConfirmAndPay, setLoadConfirmAndPay, leadFromSocket, userProfileData, addBudgetOrderId, setAddBudgetOrderId, laodAddBudget, setLoadAddBudget, overallPercentageGloabal,}}>
        {children}
        </AuthContext.Provider>
  )
}

export default AuthProvider