import React, { useContext, useEffect, useState } from "react";
import "./home.css";
import rupes from "../asets/rupess.png";
import user from "../asets/users.png";
import message from "../asets/message.png";
import review from '../asets/homeicon/review.png'
import flag from "../asets/flag.png";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
//import done from "../asets/done.png";
import draft from "../asets/draft.png";
import pause from "../asets/pause.png";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { parseISO, isBefore, isAfter, isDate } from 'date-fns';
// import companyLogo from "../asets/company.png";
import { Chart } from "primereact/chart";
//import axios from "axios";
import { Sidebar } from "primereact/sidebar";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar2 from "../navbar/Navbar2";
//import { Common } from "../common";
import LinkIcon from '@mui/icons-material/Link';
import EastIcon from "@mui/icons-material/East";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
// import HomepageCarouselCard from "../components/Cards/HomepageCarouselCard";
import BottomNavbar from "../components/bottomNavbar/BottomNavbar";
import Axios from "../Axios";
import { AuthContext } from "../AuthProvider";
import { Link, useLocation } from "react-router-dom";
import { Toaster, toast as hotTost } from 'react-hot-toast';
import { HomePageContext } from "../HomepageProvider";
import HomePageSlider from "../components/Cards/HomePageSlider";
// import GradientCircularProgress from "../adesktop-webpage/web-homepage/web-homepage-component/CircularProgresh";
import BlinkLoader from "../BlinkLoader";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import { cn } from "../lib/utils"
import { Popover, PopoverContent, PopoverTrigger } from "../components/ui/popover";
import { ProfilePageContext } from "../context/ProfilePageContext";

// const dataPoints = [
//   { date: "01 Nov", value: 6 },
//   { date: "02 Nov", value: 10 },
//   { date: "03 Nov", value: 7 },
//   { date: "04 Nov", value: 20 },
//   { date: "05 Nov", value: 25 },
//   { date: "06 Nov", value: 14 },
//   { date: "07 Nov", value: 21 },
//   { date: "08 Nov", value: 15 },
//   { date: "09 Nov", value: 5 },
//   { date: "10 Nov", value: 25 },
//   { date: "11 Nov", value: 14 },
//   { date: "12 Nov", value: 21 },
//   { date: "13 Nov", value: 15 },
//   { date: "14 Nov", value: 5 },
// ];

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};



export default function Home({className}) {
 const { 
    settCampaignMgnPage,

     setallHomePageData,
    setLeadMgnPage } = useContext(HomePageContext)
    const { getUserProfileData} = useContext(ProfilePageContext);
  const [visibleRight, setVisibleRight] = useState(false);
  const { userToken, allFbData, isCampLoading, callApiCount, isFbDataLoader, setCallApiCount, handleAllFBApiCall, fetchCampaignInsites } = useContext(AuthContext);

  const [chartOptionFilterValue, setChartOptionsFilterValue] =
    useState("last 7 days");

  const [completed, setComplete] = useState(0);
  const [paused, setPaused] = useState(0);
  const [active, setActive] = useState(0);
  const [drafts, setDrafts] = useState(0);
  const [dateStartEnd,setDateStartEnd] = useState({startDate:null, endDate:null})
  const [chartData, setChartData] = useState({ datasets: [] });
  const [chartOptions, setChartOptions] = useState({});
  const [userData, setUserData] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [circularSpent, setCircularSpent] = useState(0);
  const [mainCardData, setMainCardData] = useState([]);
  const [datePints, setDatePints] = useState([])
  const [openGraphFilter, setOpenGraphFilter] = useState(false);
  const [grapghData, setGraphData] = useState([]);
  const [homepage, setHomePage] = useState([]);
  const [dateRangeForFilter, setDateRangeForFilter] = useState({})
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [campaigncardData, setCampaignCardData] = useState([]);
  const [walletCardData, setWalletCardData] = useState("");
  const [totalLeadsCardData, setTotalLeadsCardData] = useState("");
  const [graphIpmression, setGraphIpmression] = useState("");
  const [ underRevieCount,  setUnderRevieCount] = useState(0)

  const formatName = (name) => {
   
    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');
   
    trimmedName = trimmedName.replace(/_/g, ' ').trim();
    
    return trimmedName.replace(/\s+/g, ' ');
  };

  const query = useQuery();
  const token = query?.get('token');


  useEffect(()=>{
    if(callApiCount===4){
     handleAllFBApiCall(userToken)
     fetchCampaignInsites(userToken)
    setCallApiCount(0)
    }
   
},[callApiCount])




  // verifyemail
  const VerifyEmail = async (verifytoken)=>{
    try {
      const res = await Axios.get("/verify-email", {headers:{Authorization:userToken}, params:{token:verifytoken}})

        if(res.status===200 && res?.data?.valid){
         hotTost.success("Email Verified")
           // console.log("Email Verified")
        }
    } catch (error) {
      console.log("error", error)
    }
}


useEffect(()=>{
 if(token){
   VerifyEmail(token)
 }

},[token])




const HandleHomePageData = async (allFbData) => {

  setallHomePageData(allFbData)
  setHomePage(allFbData);

  const graphdataArr = []


  let walletbalancecard = {
    wallet_balance: 0,
    total_spend: 0,
    deployed: 0,
    total_spend_till_date: 0,
  };

  let totalleadsCard = {
    total_leads: 0,
    live_campaing: 0,
    complete_campaing: 0,
    under_review_campaing: 0,
    draft_campaign: 0,
    paused_campaign: 0,
  };

  let graphimpression = {
    impression: 0,
    views: 0,
    spend: 0,
    linkclicks:0,
  };

  let graphData1 = [];

  let dateArr = [];

  let campigncard = [];


  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}



const datestart =  allFbData[0]?.createdAt;
const dateend = allFbData[allFbData.length-1]?.createdAt



if(datestart && dateend){
        // change date format
    const startingdate = formatDate(datestart)
    const endingdate = formatDate(dateend)

    setDateStartEnd((prev)=>({...prev, startDate:startingdate, endDate:endingdate}))
}



  //  console.log("allfbdata", allFbData)
  allFbData.forEach((val) => {
    // console.log("val", val);
    //leads count
    let leadscount = 0;
    let impressions = 0;
    let clicks = 0;
    let spent = 0;
    let linkClicks = 0;

    // console.log("clickssss",clicks)

    //parseInt(item?.data?.actions ? item?.data?.actions?.find((action) => action?.action_type === 'lead')?.value : 0);
    val?.insights?.data?.forEach((val) => {
        

      // console.log("leads", val);
      leadscount = leadscount + (parseInt(val?.actions?.length>0 ? val?.actions?.find((action)=>action?.action_type==='lead')?.value || 0 : 0));
      //total leads card -> total leads
      totalleadsCard.total_leads =
        totalleadsCard?.total_leads +
        (parseInt(val?.actions?.length ? val?.actions?.find((action)=>action?.action_type==='lead')?.value || 0 : 0));

        // // impressions
        // console.log("clicks", val?.clicks)
        // console.log("spent", val?.spend)
        // console.log("impressions", val?.impressions)

        graphimpression.impression = graphimpression.impression + (val?.impressions?Number(val?.impressions): 0 );


        impressions = impressions + (Number(val?.impressions) || 0 )

        //clicks

       
      graphimpression.views = graphimpression.views + (val?.clicks?Number(val?.clicks):0 );


        clicks = clicks + (val?.clicks || 0 );

        //spent
        graphimpression.spend = graphimpression.spend + (val?.spend?Number(val?.spend):0  );
        spent = spent + (val?.spend?Number(val?.spend):0  )
    
      // linkClicks
      linkClicks = linkClicks + (parseInt(val?.actions?.length>0 ? val?.actions?.find((action)=>action?.action_type==='link_click')?.value || 0 : 0));
      graphimpression.linkclicks = graphimpression.linkclicks +  (parseInt(val?.actions?.length>0 ? val?.actions?.find((action)=>action?.action_type==='link_click')?.value || 0 : 0));
 
    
      });

    const obj1 = {
      campaign_id: val?.id,
      name: val?.name ? val?.name : '',
      lifetime_budget: (val?.lifetime_budget),
      status: val?.status,
      impressions: impressions,
      spend: Number(spent),
      leads_count: leadscount,
      _id: val?.dbId,
      date: val?.createdAt
    };
    if (obj1?.name) {
      campigncard?.push(obj1);
    }
 // console.log("status", val?.status)
    // walletcard

    if (val?.status === "ACTIVE" || val?.status === "PAUSED") {
      walletbalancecard.total_spend =
        walletbalancecard?.total_spend + Math.round((Number(spent)));
    }
    if (val?.status && spent) {
      //  console.log("valvv", val?.insights?.data[0]?.spend)

      walletbalancecard.total_spend_till_date =
        walletbalancecard?.total_spend_till_date +
        Math.round((Number(spent)))
    }
    if (val?.status === "ACTIVE" || val?.status === "PAUSED" || val?.status === "UNDER REVIEW") {
      walletbalancecard.deployed =
        walletbalancecard?.deployed + Math.round((Number(val?.lifetime_budget)));
    }
    if (val.status === "STOPPED") {
      walletbalancecard.wallet_balance =
        walletbalancecard.wallet_balance + Math.round((Number(val?.budget_remaining)));
    }

    if (val?.status === "ACTIVE" || val?.status === "UNDER REVIEW" || val?.status === "PAUSED") {
      totalleadsCard.live_campaing = totalleadsCard.live_campaing + 1;
    } if (val?.status === "DRAFT") {
      totalleadsCard.draft_campaign = totalleadsCard?.draft_campaign + 1;
    } if (val?.status === "PAUSED") {
      totalleadsCard.paused_campaign =
        totalleadsCard?.paused_campaign + 1;
    } if (val?.status === "COMPLETED" || val?.status === "STOPPED") {
      totalleadsCard.complete_campaing =
        totalleadsCard?.complete_campaing + 1;
    } if (val?.status === "UNDER REVIEW") {
      totalleadsCard.under_review_campaing =
        totalleadsCard?.under_review_campaing + 1;
    }

   
   
    //grapgh data
        //  console.log("val", val)
        
        if (val?.insights?.data?.length) {
            
          const datapoints = val?.insights?.data?.map(item => {
            const leadAction = item.actions?.find(action => action.action_type === "lead");
            const leadValue = leadAction ? parseInt(leadAction.value, 10) : 0;
            dateArr?.push(item.date_start)
            const date = new Date(item.date_start).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short'
            }).replace(' ', ' ');
            return { date, value: leadValue };
          });


  
          if (datapoints) {
            
            graphdataArr?.push(datapoints)
          }
  
        }  
   
    //grapgh data
    val?.ads?.data?.forEach((val) => {
      val?.leads?.data.forEach((lead) => {
        //  console.log("leadgra", lead);
        if (lead?.created_time !== null || lead?.created_time !== "") {
          //  console.log("time", lead?.created_time);
          graphData1.push(lead?.created_time);
        }
      });
    });

  });
  if (campigncard.length) {
    setCampaignCardData(campigncard);
    setLeadMgnPage(campigncard)
    settCampaignMgnPage(campigncard)
    // console.log("cama", campigncard);
  }

  if (walletbalancecard) {
    //  console.log("walletbalancecard", walletbalancecard);
    setWalletCardData(walletbalancecard);
  }
  if (totalleadsCard) {
    // console.log("walletbalancecard", totalleadsCard);
    setTotalLeadsCardData(totalleadsCard);
  }
  if(graphimpression){
    setGraphIpmression(graphimpression)
  }
  ///graphdata
  if (graphData1.length > 0) {



    //  new calculation for graph date 29-3-2-24
    // Function to get the date in the format "dd MMM"
    function getFormattedDate(dateString) {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'short' };
      return date.toLocaleDateString('en-US', options);
    }

    // Function to count occurrences of dates within the last 14 days
    function countOccurrences(dates) {
      const dateCounts = {};
      const currentDate = new Date();

      for (const date of dates) {
        const currentDateObj = new Date(date);
        const diffTime = Math.abs(currentDate - currentDateObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays <= 360) {
          const formattedDate = getFormattedDate(date);
          dateCounts[formattedDate] = (dateCounts[formattedDate] || 0) + 1;
        }
      }

      return dateCounts;
    }

    // Function to generate data points array
    function generateDataPoints(dateCounts) {
      const dataPoints = [];
      for (const date in dateCounts) {
        dataPoints.push({ date, value: dateCounts[date] });
      }
      return dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date));
    }

    // Count occurrences for dates within the current year
    const dateCounts = countOccurrences(graphData1);

    // Generate data points array
    const datapoints = generateDataPoints(dateCounts);

    // Output data points

    if (datapoints?.length) {
      setDatePints(datapoints)
    }
    //end new calculation for graph

    //  console.log("grapghdata", graphData1);
    const formattedDates = graphData1?.map((lead) => {
      const date = new Date(lead);
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      });
      return formattedDate;
    });
    //console.log("fromatterdate", formattedDates);
    if (formattedDates) {
      const resultArray = [];

      const dateCountMap = new Map();

      formattedDates?.forEach((date) => {
        if (dateCountMap.has(date)) {
          dateCountMap.set(date, dateCountMap.get(date) + 1);
        } else {
          dateCountMap.set(date, 1);
        }
      });

      dateCountMap.forEach((value, key) => {
        const formattedDate = key; // You can customize this if needed
        resultArray.unshift({ date: formattedDate, value });
      });

      if (resultArray.length) {
        // console.log("formats", resultArray);
        setGraphData(resultArray);
      }
   
    }
  }


  //graph
  if(graphdataArr?.length>0){
    //  console.log("graphdataarr",graphdataArr.flat())

    const data = graphdataArr?.flat()
    const aggregatedData = Object.values(data.reduce((acc, curr) => {
      if (acc[curr.date]) {
        acc[curr.date].value += curr.value;
      } else {
        acc[curr.date] = { date: curr.date, value: curr.value };
      }
      return acc;
    }, {}));
     if(aggregatedData){



      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      // function parseDate(dateStr) {
      //     const [day, month] = dateStr.split(' ');
      //     return new Date(`2024-${monthNames.indexOf(month) + 1}-${day}`);
      // }

      function parseDate(dateStr) {
        const [day, month] = dateStr.split(" ");
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = monthNames.indexOf(month);
        return new Date(2024, monthIndex, parseInt(day));
    }


    function fillMissingDates(data) {
      // Parse the date strings into Date objects for easier comparison
      data = data.map(entry => ({
          ...entry,
          date: new Date(entry.date + " 2024") // Assuming the year 2024; adjust as needed
      }));
    
      // Sort the data by date
      data.sort((a, b) => a.date - b.date);
    
      const filledData = [];
      let currentDate = new Date(data[0].date);
      const endDate = new Date(data[data.length - 1].date);
    
      while (currentDate <= endDate) {
          const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
          const existingEntry = data.find(entry => entry.date.getTime() === currentDate.getTime());
      
          if (existingEntry) {
              filledData.push({
                  date: formattedDate,
                  value: existingEntry.value
              });
          } else {
              filledData.push({
                  date: formattedDate,
                  value: null
              });
          }
      
          // Increment the date by one day
          currentDate.setDate(currentDate.getDate() + 1);
      }
    
      return filledData;
  }
      
      function addMissingDates(data) {
          // Create a map of existing dates and their values
          const dateMap = new Map(data.map(item => [item.date, item.value]));
          
          // Determine the range of dates to fill
          const startDate = parseDate(data[0].date);
          const endDate = parseDate(data[data.length - 1].date);
          const result = [];
      
          // Iterate through each day in the range
          for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
              const day = String(d.getDate()).padStart(2, '0');
              const month = monthNames[d.getMonth()];
              const formattedDate = `${day} ${month}`;
      
              // Check if the date exists in the original data
              if (dateMap.has(formattedDate)) {
                  const originalValue = dateMap.get(formattedDate);
                  result.push({ date: formattedDate, value: originalValue === 0 ? 0 : originalValue });
              } else {
                  result.push({ date: formattedDate, value: null }); // Insert null for missing dates
              }
          }
      
          return result;
      }
      




      // const filledData = addMissingDates(aggregatedData);
   
      const filledData = fillMissingDates(aggregatedData);
      // console.log("result",result);
          if (filledData) {
            setDatePints(filledData)
            //  console.log("filldata", filledData)
          }

 // console.log("dateArr", dateArr)
        if(dateArr?.length>0){
        const dateorder = dateArr?.sort((a, b) => new Date(a) - new Date(b));
        const enddate = dateorder[dateorder?.length-1]
           if(enddate?.length)
        setDateStartEnd((prev)=>({...prev, endDate:enddate}))
        }



      //   console.log("datsdaa", aggregatedData)
      //  setDatePints(aggregatedData)

       const totalValue = aggregatedData.reduce((sum, item) => sum + item.value, 0);
      //  console.log("totalvalue", totalValue)
     }

  }


};

  useEffect(() => {
    HandleHomePageData(allFbData);
  }, [allFbData?.length]);


   // profile context
   useEffect(()=>{
    if(userToken){
     getUserProfileData(userToken)
    }
 },[userToken])

// //get-campaign-property
//   const getCampaignProperty = async ()=>{
//          try {
//             const res = await Axios.get("/get-properties", {
//                 headers:{
//                    Authorization:userToken
//                 }
               
//             });
//              if(res.status===200){
//                  const data = res?.data?.message
//                  // console.log("drafttttt", data)
//                   setDraftNumber(data.length)
//              }
//          } catch (error) {
//             console.log(error.message)
//          }
//   }
 
//   useEffect(()=>{
//     getCampaignProperty()
//   },[])



  const createGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(0, "rgba(255, 0, 0, 1)"); // Start color
    gradient.addColorStop(1, "rgba(0, 0, 255, 1)"); // End color
    return gradient;
  };
  // effect for chart
  // effect for chart
  useEffect(() => {
    let chartLabels;
    let chartValues4leads;
    let chartValues4message;
  
    if (chartOptionFilterValue === "last 7 days") {
      chartValues4leads = datePints.slice(-7).map((dp) => dp.value);
  
      chartLabels = datePints.slice(-7).map((dp) => dp.date);
    } else if (chartOptionFilterValue === "last 14 days") {
      chartValues4leads = datePints.slice(-14).map((dp) => dp.value);
  
      chartLabels = datePints.slice(-14).map((dp) => dp.date);
    } else if (chartOptionFilterValue === "last 28 days") {
      chartValues4leads = datePints.slice(-28).map((dp) => dp.value);

      chartLabels = datePints.slice(-28).map((dp) => dp.date);
    } else if (chartOptionFilterValue === "custom day") {
      // console.log("setDateRangeForFilter", dateRangeForFilter);
      const fromDateStr = dateRangeForFilter?.from ? dateRangeForFilter.from : "2024-03-02T18:30:00.000Z";
      const toDateStr = dateRangeForFilter?.to ? dateRangeForFilter.to : "2024-03-21T18:30:00.000Z";
      const fromDate = new Date(fromDateStr);
      const toDate = new Date(toDateStr);
  
      const monthMap = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
      };
  
      const currentYear = fromDate.getFullYear();
  
      const filteredData = datePints?.filter((item) => {
        const [day, month] = item.date.split(" ");
        const itemDate = new Date(currentYear, monthMap[month], parseInt(day));
        return itemDate >= fromDate && itemDate <= toDate;
      });
  
      if (filteredData) {
        chartValues4leads = filteredData.map((dp) => dp.value);
        chartLabels = filteredData.map((dp) => dp.date);
      }
    } else {
      chartValues4leads = datePints.map((dp) => dp.value);
 
      chartLabels = datePints.map((dp) => dp.date);
    }
  
    const maxLabelsToShow = 8;
    const stepSize = chartLabels.length > maxLabelsToShow ? Math.ceil(chartLabels.length / maxLabelsToShow) : 1;
  
    const data = {
      labels: chartLabels,
      datasets: [
        {
          label: "Leads",
          fill: true,
          backgroundColor: "transparent",
          yAxisID: "y",
          tension: 0.4,
          data: chartValues4leads,
          borderColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
  
            if (!chartArea) {
              return null;
            }
            return createGradient(ctx, chartArea);
          },
          pointRadius: 1,
          pointHoverRadius: 7,
          pointBackgroundColor: "white",
          pointBorderColor: "rgb(255, 0, 255)",
          pointHoverBackgroundColor: "rgb(255, 0, 255)",
          pointHoverBorderColor: "white",
        },
      ],
    };
  
    const verticalLinePlugin = {
      id: "verticalLinePlugin",
      afterDraw: (chart, args, options) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const ctx = chart.ctx;
          const x = chart.tooltip._active[0].element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;
  
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 10;
          ctx.strokeStyle = "rgba(0, 0, 0, 0.9)";
          ctx.setLineDash([5, 5]);
          ctx.stroke();
  
          ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
          ctx.fillRect(0, topY, x, bottomY - topY);
  
          ctx.restore();
        }
      },
    };
  
    setChartData(data);
  
    setChartOptions({
      maintainAspectRatio: false,
      aspectRatio: 1.3,
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          autoSkip: false,
          maxTicksLimit: maxLabelsToShow,
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 1, // Ensure at least 8 ticks
            callback: function (val, index) {
              return this.getLabelForValue(val);
            },
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Leads",
          },
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 10,
            max: 40,
          },
        },
      },
      plugins: {
        tooltip: {
          mode: "index",
          intersect: false,
        },
        legend: {
          display: false,
        },
        verticalLinePlugin,
      },
      elements: {
        line: {
          tension: 0.3,
        },
      },
    });
  }, [chartOptionFilterValue, datePints?.length, dateRangeForFilter]);
 

  useEffect(() => {
    Axios.get("/user", {
      headers: {
        Authorization: userToken,
      },
    })
      .then((r) => {
        setUserData(r.data.user);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  
  useEffect(() => {
    const mainCardPercentage =
      Number(mainCardData?.spend / mainCardData?.total) * 100;
    setCircularSpent(mainCardPercentage);
  }, [mainCardData]);

  //calulate active paus and stopped

  useEffect(() => {
    let underReviewsCount = 0;
    let activeCount = 0;
    let draftCount = 0;
    let pausedCount = 0;
    let stoppedCount = 0;
    let completeCount = 0;

    // Iterate through the array and count statuses
    campaignData?.forEach((campaign) => {
     // console.log("sefda", campaign);
      if (campaign?.status === "UNDER REVIEW") {
        underReviewsCount++;
      //  console.log("sfuasQQWWW", underReviewsCount);
      } else if (campaign?.status === "ACTIVE") {
        activeCount++;
      } else if (campaign?.status === "DRAFT") {
        draftCount++;
      //  console.log("draftco", draftCount);
      } else if (campaign?.status === "PAUSED") {
        pausedCount++;
      } else if (campaign?.status === "STOPPED") {
        stoppedCount++;
      } else if (campaign?.status === "COMPLETE") {
        completeCount++;
      }
      // Add more else if statements if needed for other statuses
    });

    if (stoppedCount || completeCount) {
      setComplete(stoppedCount + completeCount);
    }

    if (pausedCount) {
      setPaused(pausedCount);
    }
    if (activeCount) {
      setActive(activeCount);
    }
    if (draftCount) {
      setDrafts(draftCount);
    }
    if(underReviewsCount){
        setUnderRevieCount(underReviewsCount)
    }
  }, [campaignData]);

  //  console.log("fss", campaignData);

//  console.log("a", drafts);

//indian formate number
function formatIndianNumber(number) {
  const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
  return formattedNumber;
}



const handleSelect = (range) => {
  setDateRange(range);
};

const handleUpdate = () => {
  setDateRangeForFilter(dateRange)
  // console.log('Selected Date Range:', dateRange);
  setChartOptionsFilterValue("custom day");
  setOpenGraphFilter(false);
  
  
};

const datestart = dateStartEnd?.startDate?dateStartEnd?.startDate:'2024-02-14';
const dateend = dateStartEnd?.endDate?dateStartEnd?.endDate:'2024-03-22'
  //  console.log("datestart", datestart)
  //  console.log("dateend", dateend)
const dateStart = parseISO(datestart);
const dateEnd = parseISO(dateend);

const isDateDisabled = (date) => {
  return isBefore(date, dateStart) || isAfter(date, dateEnd);
};

// console.log("campaigncardData", campaigncardData)


  return (
    <div className="Mobile-View">
      <Navbar2 menu={true} />
      <div className="background-top">
        <div className="welcome">
          <h6>Welcome!</h6>
          <p>{userData?.name}</p>
        </div>
      </div>
      <div className="wallet">
        <div className="balance me-1">
         <div className="d-flex justify-content-start align-items-center">
         <img src={rupes} width={20} height={20} alt="" />
          <h6 className="ms-1" style={{fontSize:'12px'}}>Wallet Balance</h6>
         </div>
          {/* <p>₹ {mainCardData.spend}</p> */}
          <p className="" style={{fontSize:'20px'}}>
      <CurrencyRupeeIcon sx={{fontSize:'24px', paddingBottom:'0px', marginBottom:'5px'}}/>
           {userData?.wallet
              ? Math.round(Number(userData?.wallet))
              : 0}
          </p >
          <div className="company-name" style={{marginTop:'4px'}}>
            {/* <h6>Ziyu Adtech Pvt Ltd</h6> */}
            <h6 style={{fontSize:'12px', fontWeight:'400'}}>{userData?.business_name}</h6>
            <p className="p-0" style={{fontSize:'11px'}}>
              Total spent till date:  <br/>
            
              
              <CurrencyRupeeIcon sx={{fontSize:'9px', padding:'0px', marginBottom:'2px'}}/>
              {walletCardData?.total_spend_till_date
                ? formatIndianNumber(walletCardData?.total_spend_till_date)
                : 0}{" "}
             
              </p>
           
            {/* <p>Total spent till date ₹ {userData?.total} </p> */}
          </div>
        </div>
        <div className="slider position-relative ">
        <h6 className="position-absolute text-center" style={{top:'-25px ', right:'30px', fontSize:'12px', fontWeight:'400',  color:'white'}}>Active Campaigns</h6> 
          <div style={{ width: 80, height: 80 }}>
         
            <CircularProgressbar
              value={
                (walletCardData?.total_spend / walletCardData?.deployed) * 100
              }
              text={`${
                (walletCardData?.total_spend / walletCardData?.deployed) * 100
                  ? Math.floor(
                      (walletCardData?.total_spend / walletCardData?.deployed) *
                        100
                    )
                  : 0
              }%`}
              styles={{
                path: {
                  stroke: `#ffffff`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                trail: {
                  stroke: "#FFFFFF83",
                  strokeLinecap: "butt",
                  transform: "rotate(0.25turn)",
                  transformOrigin: "center center",
                },
                text: {
                  fill: "#ffffff",
                  fontSize: "24px",
                  fontWeight: 500,
                },
                background: {
                  fill: "#3e98c7",
                },
              }}
            />
          </div>
          <div>
            <div className="spend">
              <h6 className="spent-heading">
                <div className="circle-type"></div>
                <span>Spent</span>
              </h6>
              {/* <p>₹ {sum - spend}</p> */}
              <p style={{fontSize:'9px', marginTop:'2px'}} className="p-0" >
              <CurrencyRupeeIcon sx={{fontSize:'9px',marginTop:''}}/>
                {walletCardData?.total_spend ? formatIndianNumber(walletCardData?.total_spend) : 0}
              </p>
            </div>
            <div className="spend">
              <h6 className="spent-heading">
                <div className="circle-type" style={{ opacity: 0.8 }}></div>
                <span>Deployed</span>
              </h6>
              {/* <p>₹ {sum}</p> */}
              <p className="p-0" style={{fontSize:'9px', marginTop:'2px'}}><CurrencyRupeeIcon sx={{fontSize:'9px'}}/> {walletCardData?.deployed ? formatIndianNumber(walletCardData?.deployed) : 0}</p>
            </div>
          </div>
        </div>
      </div>
      <section>

       {isCampLoading && <div className="d-flex justify-content-center align-items-center mt-3 m-0" >
         
                 
         <div className=" py-2 px-2  border shadow-sm" style={{width:'88%', borderRadius:'16px'}}>
         <p className="text-center ">Campaign creation under progress</p>
          <div className="text-center p-0 m-0">
          <span class="loader-spinner"></span>
          {/* <GradientCircularProgress sizevalue={20}/> */}
          </div>
        
         </div>    
</div>} 
        

        <div className="overview">
          <h1>Overview</h1>
          <div className="overview-sec">
            <div className="total-box">
              <img src={user} width={38} height={40} alt="" />
              <Link to="/lead-management">
                <div style={{ marginLeft: 10 }}>
                  <h6>Total leads</h6>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      marginTop: "-4px",
                    }}
                  >
                    {/* {leadCount} */}
                    {/* {totalLeadCount ? totalLeadCount : 0} */}
                    {isFbDataLoader?<BlinkLoader/>:totalLeadsCardData?.total_leads
                      ? totalLeadsCardData?.total_leads
                      : 0}
                    {/* <sup>+0</sup> */}
                  </p>
                </div>
              </Link>
            </div>
            <div className="total-box">
              <img src={message} width={38} height={40} alt="" />
              <div style={{ marginLeft: 10 }}>
                <h6 style={{fontSize:'12px', fontWeight:'500'}}>Total messages</h6>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                    marginTop: "-4px",
                  }}
                >
                  0<sup>+0</sup>
                </p>
              </div>
            </div>
          </div>
          <div className="overview-sec">
            <Link to="/campaign-management">
              <div className="total-box">
                <img src={flag} width={38} height={40} alt="" />
                <div style={{ marginLeft: 10 }}>
                  <h6 style={{fontSize:'12px', fontWeight:'500'}}>Live campaigns</h6>
                  <p style={{ display: "flex", alignItems: "center" }}>
                    {isFbDataLoader?<BlinkLoader/>:totalLeadsCardData?.live_campaing
                      ? totalLeadsCardData?.live_campaing
                      : 0}
                    {/* 3456f */}
                  </p>
                </div>
              </div>
            </Link>
            <div
              style={{
                width: "48%",
                color: "#434343",
                paddingInlineStart: "5px",
              }}
            >
              <div className="box-icon-text">
                <div className="icon_box">
                  <img src={review} width={8} height={8} alt="" />
                </div>
                <p>
                  Under Review{" "}
                   {totalLeadsCardData?.under_review_campaing
                      ? totalLeadsCardData?.under_review_campaing
                      : 0}
                 
                </p>
              </div>
              <div className="box-icon-text">
                <div className="icon_box">
                  <img src={pause} width={8} height={8} alt="" />
                </div>
                <p>
                  Paused{" "}
                  {totalLeadsCardData?.paused_campaign
                    ? totalLeadsCardData?.paused_campaign
                    : 0}
                </p>
              </div>
              <div className="box-icon-text">
                <div className="icon_box">
                  <img src={draft} width={8} height={8} alt="" />
                </div>
                <p>Completed {" "}
                {totalLeadsCardData?.complete_campaing
                    ? totalLeadsCardData?.complete_campaing
                    : 0}
               </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="chart-kapil-container">
          <div className="title">
            <h4>Reports</h4>
            <div className="btns">
              <div
                className="select-btn"
                onClick={() => setOpenGraphFilter(!openGraphFilter)}
              >
                <span>{chartOptionFilterValue}</span>
                <svg
                  width="4"
                  height="3"
                  viewBox="0 0 4 3"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.82322 2.77521C1.92085 2.87285 2.07915 2.87285 2.17678 2.77521L3.76777 1.18422C3.8654 1.08659 3.8654 0.928302 3.76777 0.830671C3.67014 0.733039 3.51184 0.733039 3.41421 0.830671L2 2.24488L0.585786 0.830671C0.488155 0.733039 0.329864 0.733039 0.232233 0.830671C0.134602 0.928302 0.134602 1.08659 0.232233 1.18422L1.82322 2.77521ZM1.75 2.39844V2.49844H2.25V2.39844H1.75ZM1.75 2.49844V2.59844H2.25V2.49844H1.75Z"
                    fill="black"
                  />
                </svg>
              </div>
              {openGraphFilter && (
                <>
                  <button
                                  className="select-btn-options"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 7 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 7 days
                                </button>
                                <button
                                  className="select-btn-options"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 14 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 14 days
                                </button>
                                <button
                                  className="select-btn-options border"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 28 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 28 days
                                </button>

                                <button
                                  className="select-btn-options border"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                   
                                  }}
                                >
                                  <div className={cn("grid gap-2", className)}>
                                    <Popover style={{zIndex:'10000'}} >
                                      <PopoverTrigger  asChild>
                                        <button
                                          id="date"

                                          variant={"outline"}
                                          style={{ FontSize: '9px', Padding: '0px' }}

                                          className={cn(
                                            "w-[100%]  overflow-hidden p-0 border-none",
                                            !dateRange && "text-muted-foreground"
                                          )}
                                        >
                                          custom date
                                        </button>
                                      </PopoverTrigger>
                                      <PopoverContent  className="w-auto p-0 z-[10000]" align="start">
                                        <Calendar
                                          initialFocus
                                          classNames=""
                                          style={{zIndex:'10000'}}
                                          className="clalender-bg"
                                          mode="range"
                                          defaultMonth={dateRange?.from}
                                          selected={dateRange}
                                          onSelect={handleSelect}
                                          disabled={isDateDisabled}
                                          numberOfMonths={2}
                                          
                                        />
                                        <div className=" text-end pb-2 px-2">
                                        <Button  onClick={handleUpdate} className="bg-[#62c] text-white">Update</Button>

                                        </div>
                                      </PopoverContent>
                                    </Popover>
                                  </div>
                                </button>
                </>
              )}
            </div>
            {/* ) */}
          </div>
          <div className="chart">
            <Chart
              style={{ width: "100%" }}
              type="line"
              data={chartData}
              options={chartOptions}
            />
          </div>
          <div className="impression-view-spend-count">
            <div className="main-container">
              <div className="image">
                <RemoveRedEyeOutlined sx={{ color: "#F07A4C", fontSize: "" }} />
              </div>
              <div className="details">
                <p>Impressions</p>
                {/* <p>1.15M</p> */}
                <p>
                  {isFbDataLoader?<BlinkLoader/>:graphIpmression?.impression
                    ? graphIpmression?.impression
                    : 0}
                </p>
              </div>
            </div>
            <div className="main-container">
              <div className="image">
               
                <AdsClickIcon sx={{ color: "#F07A4C", fontSize: "" }}/>
              </div>
              <div className="details">
                <p>Clicks</p>
                <p>{isFbDataLoader?<BlinkLoader/>:graphIpmression?.views ? graphIpmression?.views : 0}</p>
              </div>
            </div>
            <div className="main-container">
              <div className="image">
              
                <LinkIcon sx={{ color: "#F07A4C", fontSize: "" }}/>
              </div>
              <div className="details">
                <p>Link Clicks</p>
                <p>{isFbDataLoader?<BlinkLoader/>:graphIpmression?.linkclicks ? graphIpmression?.linkclicks : 0}</p>
              </div>
            </div>
            <div className="main-container">
              <div className="image">
              <CurrencyRupeeIcon sx={{color: "#F07A4C", fontSize: "" }}/>
              
              </div>
              <div className="details">
                <p>Spent</p>
                {/* <p>1.15M</p> */}
                <p>{isFbDataLoader?<BlinkLoader/>:graphIpmression?.spend ? Math.round(graphIpmression?.spend) : 0}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="carousel"
        style={{ margin: 20, width: "90%", marginBottom: "100px" }}
      >
        {/* <section style={{ margin: 20, width: "90%" }}> */}
     {(campaigncardData?.some(campaign => campaign?.status === "ACTIVE")) && <div className="button-campaign">
          <p className="heading">Campaign</p>
          <Link to="/campaign-management">
            <button className="button-day" type="button">
              View All
              <EastIcon sx={{ width: "16px" }} />
            </button>
          </Link>
        </div>}   
     
        {/* {[1, 2, 3, 4, 5].map((item) => { */}
        {/* return ( */}
        <div
          id="carouselExampleInterval"
          class="carousel slide"
          data-bs-ride="carousel"
        >
            <HomePageSlider data={campaigncardData} />
          {/* <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
                  
                  </div>

            {campaigncardData?.length &&
              campaigncardData?.map((carouselCardData) => {
                return (
                  <div class="carousel-item active" data-bs-interval="10000">
                    <HomepageCarouselCard data={carouselCardData} />
                  </div>
                );
              })}
          </div> */}
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        {/* );
        })} */}
      </section>
      <BottomNavbar isPressed={true} whichOnePressed={1} />
      <Sidebar
        visible={visibleRight}
        position="right"
        onHide={() => setVisibleRight(false)}
      >
        <h2>Right Sidebar</h2>
        <p>
         
        </p>
      </Sidebar>
    </div>
  );
}
