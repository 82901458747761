import { Box, Button, Menu, MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import React, { useContext, useEffect, useState } from 'react'
import SortIcon from '@mui/icons-material/Sort';
import bookmarkOf from '../../asets/webimages/bookmarkoff.png'
import LeadsPageForm from '../../pages/lead/LeadsPageForm';
import { AuthContext } from '../../AuthProvider';
import BlinkLoader from '../../BlinkLoader';
import deleteleadicon1 from '../../asets/webimages/deleteleadmark3.png'
import IconButton from '@mui/material/IconButton';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

function convertDateFormat(inputDateString) {
    // Parse the input date string
    var dateObject = new Date(inputDateString);
  
    // Extract the components of the date
    var day = dateObject.getDate();
    var month = dateObject.getMonth() + 1; // Months are zero-based, so we add 1
    var year = dateObject.getFullYear() % 100; // Get the last two digits of the year
  
    // Format the date as "M/D/YY"
    var formattedDate = day + '/' + month + '/' + year;
  
    return formattedDate;
  }

  const formatName = (name) => {
   
    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');
   
    trimmedName = trimmedName.replace(/_/g, ' ').trim();
    
    return trimmedName.replace(/\s+/g, ' ');
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

//   formtime
function convertTimeFormat(inputDateString) {


  const dateOptions = {
    timeZone: 'Asia/Kolkata',
    hour12: true, // Format time as 12-hour clock (optional)
  };
  
  const dateString = new Date(inputDateString).toLocaleString('en-US', dateOptions);

  const dateObj = new Date(dateString);

  // Extracting time
  const time = dateObj.toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
          




    // // Parse the input date string
    // var dateObject = new Date(inputDateString);
  
    // // Extract the hours and minutes
    // var hours = dateObject.getHours();
    // var minutes = dateObject.getMinutes();
  
    // // Format the time as "hh:mm am/pm"
    // var formattedTime = formatTwoDigits(hours) + ':' + formatTwoDigits(minutes) + ' ' + getAMPM(hours);
  
    return time;
  }
  


  // function formatTwoDigits(number) {
  //   return number < 10 ? '0' + number : number;
  // }
  
  // function getAMPM(hours) {
  //   return hours >= 12 ? 'pm' : 'am';
  // }


 

  const itemsPerPage = 15;

function WebTableForLeads({leadsdata, formatDate, createdDate, CampName, forFormLeads, loadLeadData, handleLeadQuality, loadUpdateLead}) {
 
    const [allLeads, setAllLeads] = useState([])
    const [anchorEl, setAnchorEl] =useState(null);
    const {userToken} = useContext(AuthContext)
    const [searchTerm, setSearchTerm] = useState('');
    const [isRowSelect, setIsRowSlelect] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const open = Boolean(anchorEl);
   const [signleleads, setSignleleads] = useState("")
   const [isShowForm, setisShowForm] = useState(false)
    // console.log("leadsdata", leadsdata)


useEffect(()=>{
   setAllLeads(leadsdata)
},[leadsdata?.length])

  // console.log("formlead", forFormLeads)
    const handleClose = () => {
        setAnchorEl(null);
      };

      

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };



      const SearchfilterData = () => {
        return leadsdata.filter(item =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      };

    const onFilterByDate = ()=>{
         
        const filterdate = [...allLeads].sort((a, b)=> new Date(b?.time) - new Date(a?.time))
          // console.log("date", filterdate)
        if(filterdate){
                    setAllLeads(filterdate)
                    handleClose()
     }
    }
     
   
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = (searchTerm?SearchfilterData():leadsdata)?.slice(startIndex, endIndex);
    // pagination
    const handlePagination = (event, page)=>{
      setCurrentPage(page);
      scrollToTop()
    }
    
    const SendleadsToForm = (id)=>{
      setIsRowSlelect(id)
      // scrollToTop()
     const filterleads = forFormLeads?.filter((val)=>val.id===id)
          if(filterleads?.length){
            setisShowForm(true)
            setSignleleads(filterleads[0])
          } 
         
    }

    const onSelectLeadQuality = (id, type)=>{
      handleLeadQuality(id, type)
    
  
   }

  
  return (
    <>
{/* search and filter */}
 <div className="row  p-0 m-0" >
        <div className="col-12">
        <Box   className='mb-0'
            component={"div"}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <div className="searchBox">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M8.48307 9.85618C8.43311 9.80622 8.34711 9.79937 8.28574 9.84431C8.28574 9.84431 8.29167 9.84089 8.24011 9.87716C7.3892 10.4755 6.35211 10.8269 5.2329 10.8269C2.34296 10.8269 0 8.48423 0 5.59423C0 2.70422 2.3429 0.361328 5.2329 0.361328C8.1229 0.361328 10.4656 2.70422 10.4656 5.59423C10.4656 6.70794 10.1177 7.74072 9.52431 8.58912C9.48552 8.64478 9.48187 8.64866 9.48187 8.64866C9.43898 8.70683 9.44651 8.79603 9.49488 8.8444L9.80787 9.15739C9.85783 9.20736 9.95798 9.23359 10.0232 9.21899C10.0232 9.21899 10.0166 9.21831 10.0764 9.20895C10.5242 9.13983 10.9978 9.27785 11.3428 9.62278L12.978 11.258C13.5529 11.8327 13.5529 12.7646 12.978 13.3393C12.4033 13.9142 11.4714 13.9142 10.8968 13.3393L9.26151 11.704C8.91817 11.3609 8.77992 10.89 8.84676 10.4443C8.85612 10.3823 8.8568 10.3893 8.8568 10.3893C8.87323 10.3177 8.84448 10.2176 8.79612 10.1692L8.48307 9.85618ZM5.23291 8.86469C7.03904 8.86469 8.5034 7.40033 8.5034 5.5942C8.5034 3.78784 7.03904 2.32372 5.23291 2.32372C3.42655 2.32372 1.96242 3.78784 1.96242 5.5942C1.96242 7.40033 3.42655 8.86469 5.23291 8.86469Z"
                  fill="#949494"
                />
              </svg>
              <input type="text" placeholder="Search by name" value={searchTerm}  onChange={(e)=> setSearchTerm(e.target.value)} />
             
            </div>

{/* sorting by leads, date, spent */}
<div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{margin:'0px', padding:'0px'}}
        className="p-0 m-0"
      >
         <SortIcon sx={{color:'#6622cc', margin:'0', padding:'0'}}/>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onFilterByDate}>Leads by date</MenuItem>
    
      </Menu>
    </div>
  

          </Box>
        </div>
        <div className="row p-0 m-0" style={{flexWrap:'nowrap'}}>
        <div className="col-8" >
   

   {loadLeadData?(<>
     <div className='userDetails-skelton my-3'>
     <Box sx={{ display: 'flex', alignItems: 'center' }}>
   <Skeleton animation="wave" sx={{border:''}} variant="circular" width={40} height={40} />
     <Box sx={{width:'100%', marginLeft:'4px'}}>
     <Skeleton variant="text" width="90%" sx={{ fontSize: '1rem', marginBottom:'4px' }} />
   <Skeleton variant="text" width="90%" sx={{ fontSize: '1rem' }} />
     </Box>      
   </Box>
     </div>
    
   </>):(<>
     <div className="userDetails " style={{ marginBlock: "20px" }}>
       <div className="left">
         <div className="image">
         <span
       className=" d-flex align-items-center justify-content-center me-1"
       style={{
         height: "30px",
         width: "30px",
         backgroundColor: "#62c",
         borderRadius: "50%",
       }}
     >
       <span style={{ color: "white" }}>{CampName?.slice(0, 1)}</span>
     </span>
         </div>
         <div className="user-details" >
           <h2>{CampName}</h2>
          {createdDate?.length && <p>{formatDate(createdDate)}</p>} 
           {/* <p>#{location?.state}</p> */}
         </div>
       </div>
       {/* <button >View Details</button> */}
     </div>
   </>)}  
  

       <div className="leadtabl">
               <table className='' style={{width:'100%', }} >
                    <tr className="table-bg p-2">
                       <th> Name  </th>
                       <th> Contact </th>
                       <th> Date  </th>
                       <th> Time </th>
                       <th className='text-center'> Lead Quality  </th>
                      
                    </tr>
                    {loadLeadData? <>     
                      
                      <tr  style={{cursor:'pointer'}}  >
                      <td  className=' table-color text-start'>{<BlinkLoader/>}</td>
                      <td  className='table-color text-start' style={{cursor:'pointer'}}   >{<BlinkLoader/>}</td>
                      <td   style={{cursor:'pointer'}} className='table-color-bold position-relative text-start'><span>{<BlinkLoader/>}</span> </td>
                      <td  className="table-color text-start">{<BlinkLoader/>}</td>
                      <td  className="table-color text-center"> 
                      <IconButton aria-label="delete"   >
                                <ThumbUpAltIcon/> 
                                
                                </IconButton>
                      

                    </td>
                     
                      </tr>
                 </>:<>
                { currentItems?.map((val, idx)=>(
                          
                          <>     
                          
                               <tr  style={{cursor:'pointer', backgroundColor:isRowSelect===val?.id?'#b7b5bb':null}} key={idx} className={idx%2!==0?'table-bg':'' } >
                               <td style={{color:isRowSelect===val?.id?'white':null}} onClick={()=>SendleadsToForm(val?.id)} className='px-1 table-color'>{loadLeadData?<BlinkLoader/>:val?.name}</td>
                               <td  onClick={()=>SendleadsToForm(val?.id)} className='table-color' style={{cursor:'pointer', color:isRowSelect===val?.id?'white':null}}   >{loadLeadData?<BlinkLoader/>:val?.phone}</td>
                               <td onClick={()=>SendleadsToForm(val?.id)}  style={{cursor:'pointer', color:isRowSelect===val?.id?'white':null}} className='table-color-bold position-relative'><span>{loadLeadData?<BlinkLoader/>:convertDateFormat(val?.time)}</span> </td>
                               <td style={{color:isRowSelect===val?.id?'white':null}} onClick={()=>SendleadsToForm(val?.id)} className="table-color">{loadLeadData?<BlinkLoader/>:convertTimeFormat(val?.time)}</td>
                              <td style={{color:isRowSelect===val?.id?'white':null}}  className="table-color d-flex justify-content-center align-items-center"> 
                                <Stack direction="row" spacing={1}>
                                  {(val?.type==='irrelevent' || val?.type==='irrelevent' || val?.type==='irrelevant')?(<>
                                    <IconButton aria-label="delete" onClick={()=>handleLeadQuality(val?.id, 'fb_lead')}>
                                <ThumbDownAltIcon/> 
                                
                                </IconButton>
                                  </>):(<>
                                    <IconButton aria-label="delete" onClick={()=>onSelectLeadQuality(val?.id, "irrelevant")}>
                              <ThumbDownOffAltIcon/>
                                
                                </IconButton>
                                  </>)}

                               {
                                (val?.type==='hot' || val?.type==='HOT')?(<>
                                <IconButton aria-label="delete" sx={{color:'#6622cc'}}  onClick={()=>handleLeadQuality(val?.id, 'fb_lead')}>
                                <ThumbUpAltIcon/> 
                                
                                </IconButton>
                                </>):(<>
                                  <IconButton aria-label="delete" onClick={()=>onSelectLeadQuality(val?.id, "hot")}>
                                  <ThumbUpOffAltIcon />
                                
                                </IconButton>
                                </>)
                               }
                                </Stack>
                               
                               {/* <button className="filter-sort">
                              {(val?.type==='hot' || val?.type==='HOT')? <img src="star.svg" alt='bookmark' />:(val?.type==='irrelevent' || val?.type==='irrelevent')? <img width={11} height={11} src={deleteleadicon1} alt='bookmark' />:null } 
                             </button> */}
                               

                             </td>
                              
                               </tr>
                          </>
                        ))}
                 </>        
                  
                       }
               </table>
               <div className='my-2'>
               <Pagination count={Math.ceil((searchTerm?SearchfilterData():leadsdata)?.length / itemsPerPage)} page={currentPage} color="secondary"   onChange={handlePagination} />
               </div>
           </div>
</div>
<div  class="col-4 pt-4" style={{minWidth: '350px', }}>
   { isShowForm?<LeadsPageForm  handleLeadQuality={handleLeadQuality} singleleads={signleleads} setisShowForm={setisShowForm}/>:null}  
</div>
        </div>
    
 </div>

    </>
  )
}

export default WebTableForLeads