import React from 'react'
import infoImg from '../../asets/webimages/authimg.png'
import FirstPage from '../../pages/firstPage/FirstPage'
function WebAccountInfo() {
  return (
      <div className="container-fluid">
        <div className="row">
            <div className="col-6">
           
                <div className='' style={{width:'100%'}}>
                    <div>
                        <FirstPage/>
                    </div>
                </div>
           
            </div>
            <div className="col-6">
               
            </div>
        </div>
      </div>
  )
}

export default WebAccountInfo