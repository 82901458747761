import React, { useState, useEffect, useContext } from 'react';
import './auth.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import loginimg from '../asets/login-1-image (1).png';
import { Button, TextField, Checkbox } from '@mui/material';
import OtpInput from 'react-otp-input';
import logo from '../asets/auth/Leapx-Meta 1.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import Axios from '../Axios';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AuthContext } from '../AuthProvider';
import InputAdornment from '@mui/material/InputAdornment';
import Cookies from 'js-cookie';
import bgimg from '../asets/auth/girl 1.jpg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SlickSlider from './SlickSlider';
import congIcon from '../asets/auth/party-popper.png'

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone number is required')
    .matches(/^\d{10}$/, 'Invalid phone number'),

});

function UserSignupMobileDetails() {
    const { setUserToken, setFbToken } = useContext(AuthContext);
    const [isOtpSend, setIsOtpSend] = useState(false)
    const [verifOtpLoad, setVerifyOtpLoad] = useState(false)
    const [otp, setOtp] = useState('');
    const [timerStarted, setTimerStarted] = useState(false);
    const [showVerifyCheck, setShowVerifyCheck] = useState(false)
    const [resendOtpLoad, setResendOtpLoad] = useState(false)
    const [numberTen, setNumberTen] = useState(0)
    const [verifyError, setVerifyError] = useState(null)
    const [showResendBtn, setShowResendBtn] = useState(false)
    const [isOtpVerify, setIsOtpVerify] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isMobileVerified, setIsEmailVerified] = useState(false)
    const location = useLocation();
    const [mobileVerifyToken, setMobileVerifyToken] = useState("")
    const [seconds, setSeconds] = useState(60);
    const [isSendBtn, setIsSendBtn] = useState(true)
    const [apiError, setApiError] = useState(null)
    const [SignupError2, setSignupError2] = useState("")
    const userDetails = location.state?.userDetails;
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',

        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //    if(apiError.length>0){
            //      return
            //    }
            //   console.log("SignupError2", SignupError2)
            //    if(SignupError2?.length>0){
            //        return
            //    }
             
            if (!mobileVerifyToken) {
                hotTost.error("Please verify phone number")
                setSignupError2("Please Verify Phone Number")
                //  console.log("Please Verify Mobile")
                return
            }
            setIsLoading(true)

            const body = {
                name: values?.name,
                email: userDetails?.email,
                mobile: values?.phone,
                verifyToken: mobileVerifyToken
            };
            if (userDetails?.googleId) {
                body.googleId = userDetails?.googleId
            }

            Axios
                .post("/signup", body, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {

                    setIsLoading(false)
                    const data = response?.data?.valid;
                    const isEmail = response?.data?.email
                    if (isEmail === false) {
                        hotTost.error(response?.data?.message)

                        setTimeout(() => {
                            navigate("/login");
                        }, 1000);
                    } else if (data === false) {
                        hotTost.error(response?.data?.message)
                        setSignupError2(response?.data?.message)
                    } else {
                        hotTost.success(response?.data?.message)


                        const registerToken = response?.data?.token;
                        if (registerToken) {
                            setUserToken(registerToken)
                            Cookies.set('userToken', registerToken, { expires: 14 });
                            navigate("/")

                        }


                    }

                })
                .catch((error) => {
                    setIsLoading(false)
                    setSignupError2("Enternal server error")
                    console.log("Error setting up request:", error?.AxiosError);
                    // Display error in toast
                    const errorMessage =
                        error.response?.data?.message || 'Enternal server error';
                    hotTost.error(errorMessage);
                });
        },
    });

   
    //timer
    useEffect(() => {
        let intervalId;

        if (timerStarted && seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else {
            setTimerStarted(false);
            setShowResendBtn(true);
        }

        // Clear the interval when the component unmounts or when the conditions change
        return () => clearInterval(intervalId);
    }, [timerStarted, seconds]);


    useEffect(() => {
        if (userDetails?.name) {
            formik.setFieldValue('name', userDetails.name);
        }
    }, [userDetails]);





    const onSendOTP = async (mobile) => {
        setResendOtpLoad(true)
        try {
            const res = await Axios.post("/resend-otp", { mobile })

            // console.log("data", res)
            if (res.status === 200) {

                const data = res?.data?.message
                if (res?.data?.valid) {
                    setIsOtpSend(true)
                    setIsSendBtn(false)

                    setTimerStarted(true)
                    setShowResendBtn(false)
                    hotTost.success(res?.data?.message)

                } else {
                    hotTost.error(res?.data?.message)
                    setIsSendBtn(true)
                    setTimerStarted(false)
                    setShowResendBtn(true)
                    setApiError(res?.data?.message)
                }
            }
        } catch (error) {
            setIsOtpSend(false)

            setTimerStarted(false)
            setShowResendBtn(true)
            console.log(error.message)
        } finally {
            setResendOtpLoad(false)
        }



    }


    useEffect(() => {
      setSignupError2("")
      setApiError("")
        if (formik.values.phone?.length == 10) {
            onSendOTP(formik.values.phone)
        }
    }, [formik.values.phone])


    
const onSignup = ()=>{

}






    const onVerifyOtp = async (otp) => {
        setVerifyOtpLoad(true)
        try {
            const res = await Axios.post("/verify-otp", { mobile: formik.values.phone, code: otp })
            if (res.status === 200) {
                const data = res?.data?.message
                if (res.data.valid) {
                    hotTost.success(res?.data?.message)
                    const verifytoken = res?.data?.token
                    setMobileVerifyToken(verifytoken)
                    setIsOtpSend(false)
                    setIsOtpVerify(false)
                    setTimerStarted(false)
                    setSignupError2("")
                    setApiError("")
                    setShowVerifyCheck(true)
                    setIsEmailVerified(true)
                } else {
                    hotTost.error(res?.data?.message)
                    setVerifyError(res?.data?.message)
                    setIsOtpSend(true)
                    setIsOtpVerify(true)
                    setShowVerifyCheck(false)
                    setIsEmailVerified(false)
                }
            }
        } catch (error) {
            setIsOtpSend(false)
            setIsOtpVerify(true)
            setShowVerifyCheck(false)
            console.log(error.message)
            setIsEmailVerified(false)
        } finally {
            setVerifyOtpLoad(false)
        }


    }


    const onResendOtp = async (mobile) => {
        try {
            const res = await Axios.post("/resend-otp", { mobile })

            // console.log("data", res)
            if (res.status === 200) {
                const data = res?.data?.message
                if (res?.data?.valid) {
                    setIsOtpSend(true)
                    setIsSendBtn(false)
                    setSignupError2("")
                    setApiError("")
                    setSeconds(60)
                    setTimerStarted(true)
                    setShowResendBtn(false)
                    hotTost.success(res?.data?.message)

                } else {
                    hotTost.error(res?.data?.message)
                    setSignupError2(res?.data?.message)
                    setIsSendBtn(false)
                    setTimerStarted(false)
                    setShowResendBtn(true)


                }
            }
        } catch (error) {
            setIsSendBtn(false)
            setIsOtpSend(true)
            setTimerStarted(false)
            setShowResendBtn(true)
            setSignupError2("Enternal server error")
            console.log(error.message)
        }

    }


    const formatTime = () => {
        const remainingSeconds = seconds % 360;
        return remainingSeconds;
    };



    const handleExternalSubmit = (e) => {
        e.preventDefault()
        if (!isMobileVerified) {
            return hotTost.error("Please verify your mobile")
        }
    };

    //console.log("otp", otp?.length)

    useEffect(() => {
        setSignupError2("")
        setApiError("")
        if (otp?.length === 6) {
           
            // setApiError('')
            onVerifyOtp(otp)
        }
    }, [otp])


    const editNumber = (e) => {
        if (e.target.value.length === 10) {
            setIsSendBtn(true)
        }
        setOtp('')
        setIsOtpSend(false)
        setSeconds(60)

        setTimerStarted(false)

    }
    return (
        <>
            <Toaster position="top-right" />

            <div className="signup-container">

            <div className="signup-left-container position-relative ">
             <div className=' flex justify-center sm:items-start md:items-center position-relative'>
             <img  src={bgimg} className='auth-bgimage d-none d-md-block' alt="" />
             
             </div>
             {/* <div className="slick-slider-container flex justify-center items-center">
              <SlickSlider/>
             </div> */}
            
          </div>




                 
          <div className='signup-right-container'>
                <div className="usersignup-card">


                    <div className="signup-form-container">
                         <div className='signup-form-container'>
                         <div className='text-start form-card-login mt-md-3 py-3'>
                            <img src={logo} className="img-top-logo  ps-md-0" alt="" />
                        </div>
                        <div className='left-card-height mt-5'>
                        <div className=''>
                        <div className='mt-3'>
                            {/* <h3 className="text-start d-md-none px-md-1  google-heading ">SignUp</h3> */}
                            <p className=" text-start mb-4 google-heading-signup">Sign Up</p>
                           
                        </div>


                        <div className="signup-logo mt-3">
                            <div className='google-heading-con pb-0 mb-0 flex justify-start items-start'><p className='p-0 m-0 cong'>Congratulations !</p><img src={congIcon} className='cong-icon' alt="" /></div>
                            <p className='signup-congration-p '> One step left to grow your business with LeapX</p>
                        </div>


                        <div className='d-flex justify-content-center align-items-center mt-4' style={{}}>
                            <div className="signup-from " style={{ width: '100%' }}>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className=" ">
                                        <div className='mb-3' style={{}}>
                                            {/* <div style={{ minWidth: '26%' }}>
      <label style={{ fontWeight: 'bold' }}> Name : </label>
    </div> */}             <label className='mb-1 signup-mini-label' style={{ fontWeight: '500' }}>Name</label>
                                            <TextField
                                                id="name"
                                                fullWidth
                                                size='small'
                                                variant="outlined"
                                                placeholder='Enter Your Name'
                                                name="name"
                                                color="secondary"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.name && Boolean(formik.errors.name)}
                                                helperText={formik.touched.name && formik.errors.name}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle sx={{ color: '#62c', fontSize: '26px' }} />
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        '& .MuiInputBase-input::placeholder': {
                                                          fontSize: '0.8rem', // Change this to your desired font size
                                                        },
                                                      },
                                                }}
                                            />
                                        </div>

                                        <div className="mb-3 position-relative" style={{}}>
                                            <label className='mb-1 signup-mini-label' style={{ fontWeight: '500' }} >Phone Number</label>
                                            <TextField
                                                id="phone"
                                                name="phone"
                                                size='small'
                                                type="text"
                                                value={formik.values.phone}
                                                onChange={(e) => { formik.handleChange(e); setNumberTen(e.target.value); setApiError(null); editNumber(e); setVerifyError("") }}
                                                // onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={apiError ? apiError : formik.touched.phone && Boolean(formik.errors.phone)}
                                                helperText={formik.touched.phone && formik.errors.phone}
                                                disabled={(numberTen?.length === 10 && showVerifyCheck) ? true : false}
                                                sx={{ width: "100%" }}
                                                placeholder='Enter Phone Number'
                                                variant="outlined"
                                                color="secondary"
                                                required

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment sx={{ color: '#62c', padding: '0px', fontWeight: '600' }} position="start">
                                                            <span>+91 <KeyboardArrowDownIcon /></span>
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        '& .MuiInputBase-input::placeholder': {
                                                          fontSize: '0.8rem', // Change this to your desired font size
                                                        },
                                                      },
                                                }}
                                            />
                                            {/* {(numberTen?.length===10 && isSendBtn)? <button disabled={resendOtpLoad} onClick={()=>onSendOTP(formik.values.phone)} className="position-absolute send-verify-otp send-otp-btn">Send OTP</button>:(numberTen?.length===10 && showVerifyCheck)?<span className="position-absolute otp-verify-btn"><CheckCircleIcon sx={{color:'#6622cc'}}/></span>:null} */}
                                            {(numberTen?.length === 10 && isSendBtn) ? '' : (numberTen?.length === 10 && showVerifyCheck) ? <span className="position-absolute otp-verify-btn"><CheckCircleIcon sx={{ color: '#6622cc' }} /></span> : null}
                                            {/* <div className="text-start mt-1">
                                {(numberTen?.length === 10 && isSendBtn) ? <button disabled={resendOtpLoad} onClick={() => onSendOTP(formik.values.phone)} className="position-absolut send-verify-otp send-otp-btn">Send OTP</button> : ""}

                            </div> */}


                                            {formik.touched.phone ? <p className="text-end text-danger position-absolute" style={{ top: '70px', right: '0px', fontSize: '0.73rem', textTransform: 'initial' }}>{numberTen?.length === 10 && apiError ? apiError : null}</p> : null}
                                        </div>
                                        <div className='' style={{}}>
                                            {(isOtpSend && numberTen.length === 10 && isOtpVerify) && <div className="mb-3 p-0 ">
                                                <p className="text-start pb-0 mb-0" style={{ width: '100%', color: '#B2B6BE', fontSize: '12px' }}> Enter OTP</p>
                                                <div className="m-0 p-0 otp-filds d-flex justify-content-between align-items-center py-2">
                                                    <OtpInput
                                                        value={otp}

                                                        containerStyle={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            padding: '0',
                                                            margin: '0',
                                                            width: '100%'

                                                        }}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputStyle={{
                                                            width: '40px',


                                                            borderTop: 'none',
                                                            borderRight: 'none',
                                                            borderLeft: 'none',

                                                            borderBottom: '2px solid gray',

                                                        }}


                                                        renderInput={(props) => <input     {...props} />}
                                                    />


                                                </div>
                                                <div className="text-end position-relative p-0 m-0">
                                                    {formatTime() ? <span className="" style={{ fontSize: '0.895rem', color: '#B2B6BE' }}>Resend OTP In&nbsp; <span style={{ color: '#F07A4C' }}>{formatTime()}&nbsp;sec</span> </span> : null}
                                                    {(formatTime() === 0) ? <span onClick={() => onResendOtp(formik.values.phone)} className="p-0 m-0" style={{ width: '93px', fontWeight: '700', cursor: 'pointer', fontSize: '0.895rem', color: '#6622CC' }}>Resend OTP</span> : null}
                                                    <span className="text-start p-0 m-0 text-danger position-absolute" style={{ top: '-5px', left: '0px', fontSize: '0.73rem', textTransform: 'initial' }}>{verifyError ? verifyError : null}</span>
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="my-4 mt-5 text-end" style={{}}>
                                            <button disabled={isLoading ? true : false} type="submit" className="submit-button"  >{(isLoading) ? <CircularProgress size={20} color="inherit" /> : 'Next'}  </button>
                                            {SignupError2?.length > 0 && <p className='text-danger p-0 m-0 text-start'>{SignupError2}</p>}
                                            {/* <Button sx={{ backgroundColor: '#62c' }} variant="contained" type="submit">
                                    Next
                                </Button> */}
                                        </div>

                                      {(apiError === "User with same mobile already exists.") && <div>
                                        <p className=" text-center signup-already-account" >
                                        A user with this mobile number already exists. You can Log In using this number.?{" "}
                      <NavLink className="text-primary-imp fw-bold" to="/login">
                        Login
                      </NavLink>
                    </p>
                                      </div> }  
                                      

                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                        </div>
                         </div>
                       
                        
                        

                    </div>





                </div>
                </div>
            </div>

        </>
    );
}

export default UserSignupMobileDetails;
