import {  useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from '../../Axios'
import { AuthContext } from '../../AuthProvider'




const Payment = () => {
   
    const {userToken, campaignCreationStatus, setCampaignCreationStatus, setIsCampLoading, setCallApiCount, loadConfirmAndPay, setLoadConfirmAndPay} = useContext(AuthContext)
    // const showSnackbar = useSnackbar();
    const [loading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    

    const formatPaymentTime = (date) => {
      // Define month names array
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
  
      // Get day, month, year, hour, and minute
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();
  
      // Format the date string
      return `${day} ${month} ${year}, ${hour}:${minute < 10 ? '0' : ''}${minute}`;
    };
  
    const verifyOrder = async ( paymentid, orderidnew, signature, adcampaignBody, prefilledDetails, amount)=>{
      setLoadConfirmAndPay(true)
        const body = {
          order_id: orderidnew, 
          payment_id: paymentid,
          razorpay_signature:signature
      }
          try {
             const res = await Axios.post("/verify-order", body, {
                 headers:{
                    Authorization:userToken,
                  
                 }

             })

             console.log("verify order", res)
                if(res.status===200){
                  const paymentTimeFromApi = new Date();
                  const payment_details = {
                    userName:prefilledDetails?.name,
                    time:formatPaymentTime(paymentTimeFromApi),
                    method:'Online',
                    payment_id:paymentid,
                    amount:amount
                   }
                  handleCampaign(adcampaignBody, paymentid)

                  setTimeout(()=>{
                    navigate("/payment-successful", {state:{paymentdata:payment_details}});
                  })
                    // console.log("order verified")
                }
          } catch (error) {
              console.log(error)
          }finally{
            setLoadConfirmAndPay(false)
          }
    }

       //api for capaign creation
      //api 1
      const handleCampaign = (adcampaignBody, paymentid ) => {
        setLoadConfirmAndPay(false)
        setCampaignCreationStatus({...campaignCreationStatus, isCampLoading:true, isCampaignFailed:false})
        setIsCampLoading(true)
        const body = {
          page_id:adcampaignBody.page_id,
          // access_token: fbToken,
          campaign_id: adcampaignBody.campaign_id,
          used_wallet_spend:adcampaignBody.used_wallet_spend,
          payable_amount: adcampaignBody.payable_amount,
          payment_id:paymentid
        }
         
       // console.log(body);
        Axios.post("/add-campaign", body, {
          headers: {
            Authorization: userToken,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            
            setIsCampLoading(false)
            setCallApiCount(4)
            
          })
          .catch((error) => {
            setIsLoading(false);
            setCampaignCreationStatus({...campaignCreationStatus, isCampLoading:false, isCampaignCreated:false})
            console.log("Error setting up request:", error);
            setIsCampLoading(false)
            setCallApiCount(4)
            // showSnackbar(`Error: Campaign Creation Failed`, 'error');

            
          });
      };


          //razorpay script
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
                //     window.alert('You are offline, failed to load the resources.')
            }
            document.body.appendChild(script)
        })
    }
    
     const payWithRazorpay = async ( amount, adcampaignBody, isWallateAndBudgetEqual, orderid, prefilledDetails ) => {

                   // console.log("orderid", orderid)
                // if(amount ){
                //   createOrder(amount*100, adcampaignBody.campaign_id)
                // }
              
                  if(isWallateAndBudgetEqual){
                    const paymentTimeFromApi = new Date();
                    const payment_details = {
                      userName:prefilledDetails?.name,
                      time:formatPaymentTime(paymentTimeFromApi),
                      method:'Wallet',
                      payment_id:"Wallet Transaction",
                      amount:adcampaignBody?.used_wallet_spend,
                     }
                    handleCampaign(adcampaignBody)
                    setTimeout(()=>{
                      navigate("/payment-successful", {state:{paymentdata:payment_details}});
                    })
                   }else{
                   // console.log("payload", adcampaignBody)
                    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
                    if (!res) {
                        alert('You are offline, failed to load the resources.')
                        return
                    }
                
                   var options = {
                    "description": 'Order fees',
                     order_id:orderid,
                     'captured':true,
                    // image: 'https://i.imgur.com/3g7nmJC.png',
                    // image:'https://i.imgur.com/3g7nmJC.png',
                    currency: 'INR',
                    
                        key: 'rzp_test_tQA0WDlYMnLscN', //anurag
                      //  key:'rzp_live_kFf2x1pRYyzrD4',
                  
                    amount: amount* 100,//amount in paise
                    
                    name: 'LeapX',
                   // description:'Thanks for purchasing',
                    notes: {
                      description: "Leapx",
                      campaign: adcampaignBody.campaign_id,
                      
                  
              },
                    prefill: {
                        email:prefilledDetails?.email,
                        contact:prefilledDetails?.number,
                        name:prefilledDetails?.name,
                    },
                    // theme: { color: '#e2bc3f' },
                    handler: function (response) {
                     // console.log("razotpay res", response)
                        
                        if(response?.razorpay_payment_id && response?.razorpay_order_id && response?.razorpay_signature){
                          verifyOrder(response?.razorpay_payment_id, response?.razorpay_order_id, response?.razorpay_signature, adcampaignBody, prefilledDetails, amount)
                          //  console.log("raz apy red after pay", response)
                        }
                       
                      
                    },
                    // prefill:{
                    //      name:'leapX'

                    // }
                };
              
                    const paymentObject = new window.Razorpay(options)
                    //  console.log("paymentObject", paymentObject)
                    // paymentObject.on('payment.failed', (response) => {
                    //     update_order(order?.id,response.error.metadata.payment_id,2,type)
                    // })
                    paymentObject.open()
                   }
                



         
          
    }
     
  return payWithRazorpay
    
  
}

export default Payment