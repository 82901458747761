import React from 'react'

function BlinkLoader() {
  return (
    <div className="blinkloading-container">
      <div className="blinkloading-dot dot1"></div>
      <div className="blinkloading-dot dot2" ></div>
      <div className="blinkloading-dot dot3"></div>
    </div>
  )
}

export default BlinkLoader