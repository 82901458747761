import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import StopIcon from "@mui/icons-material/Stop";
import { PlayArrow } from "@mui/icons-material";
import PauseIcon from "@mui/icons-material/Pause";
import alerticon from '../../asets/alerticonstus.svg'

export default function AlertDialog({ handleCampaignStatus, buttontype}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    handleCampaignStatus()
    handleClose();
  };

  return (
    <React.Fragment>

                {buttontype==='stop' && <button
                    className="pause-btn pause-text btn-ok primarybg-col"
                    onClick={() => {
                      handleClickOpen();
                      
                    }}
                  >
                    <StopIcon sx={{ color: "#fff", fontSize: "16px" }} />
                    &nbsp;Stop
                  </button>}

                    {buttontype==='resume' && <button
                    className="pause-btn btn-ok"
                    style={{ backgroundColor: "white", color: "#62c" }}
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <PlayArrow sx={{ color: "#62c", fontSize: "16px" }} />
                    &nbsp;Resume
                  </button>}

                  {buttontype==='pause' && <button
                  className="pause-btn pause-text btn-ok primarybg-col"
                  onClick={() => {
                    handleClickOpen()
                  }}
                >
                  <PauseIcon sx={{ color: "white", fontSize: "16px" }} />
                  &nbsp;Pause
                </button>}
                  





      {/* <Button variant="outlined" onClick={handleClickOpen}>
        {Submit}
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
             <div className="text-center mb-3">
                <img src={alerticon} alt="" />
              <p className="text-center mt-2 mt-md-3" style={{width:'95%', fontSize:'17.3px', fontWeight:'600', color:'rgba(0, 0, 0, 1)'}}>
                {buttontype==='stop' && 'Are you sure you want to stop campaign'}
                {buttontype==='pause' && 'Are you sure you want to pause campaign'}
                {buttontype==='resume' && 'Are you sure you want to resume campaign'}
                 
                 
                 </p>
             </div>
             <div className="d-flex justify-content-center gap-3">
             <Button  fullWidth sx={{ textTransform:'capitalize', color:'#62c', border:'1px solid #62c', '&:hover': { borderColor: '#62c' }, '&:active': { borderColor: '#62c' },}} variant="outlined" onClick={handleYes}>
            Yes
          </Button>
          <Button fullWidth sx={{backgroundColor:'#62c', textTransform:'capitalize', '&:hover': { backgroundColor: '#62c' }, '&:active': { backgroundColor: '#62c' },}} variant="contained" onClick={handleClose} autoFocus>
            Cancel
          </Button>
             </div>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions className="border" sx={{display:'flex', justifyContent:'space-around'}}>
          
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
