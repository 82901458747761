import { useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../AuthProvider';
import { useLocation } from 'react-router-dom';
import { ProfilePageContext } from '../context/ProfilePageContext';

export const IntercomManager = ({ children }) => {
  const { update, boot, shutdown } = useIntercom();
  const { userToken } = useContext(AuthContext);
  const location = useLocation();
  const {userData, loadUser } = useContext(ProfilePageContext);

  //  console.log("userdetails", userData)

  useEffect(() => {
    if (loadUser) return;

    if (
      location.pathname === '/leapx-for-realestate' || 
      location.pathname === '/login' || 
      location.pathname === '/signup' || 
      location.pathname === '/signup-mobile' || 
      location.pathname === '/forgot-password'
    ) {
      shutdown();
    } else {
      boot();
    }
  }, [location, loadUser, boot, shutdown]);

  useEffect(() => {
    if (!loadUser && userData && userToken) {
      update({
        name: userData.name,
        email: userData.email,
        userId: userData._id,
      });
    }
  }, [loadUser, userData, userToken, update]);

  return children;
};
