import React, { useState, useEffect, useContext , useRef} from 'react';
import './auth.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, useMediaQuery } from '@mui/material';
import logo from '../asets/auth/Leapx-Meta 1.png'
import { jwtDecode } from "jwt-decode";
import {  NavLink, useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import bgimg from '../asets/auth/girl 1.jpg'
import Axios from '../Axios';
import { CircularProgress } from "@mui/material";
import {  useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {  useGoogleLogin } from '@react-oauth/google';
import googleicon from '../asets/google icon.png'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Toast } from "primereact/toast";
import Cookies from "js-cookie";
import { AuthContext } from "../AuthProvider";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import mobileicon from '../asets/auth/mobile icon.svg'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
// import LoginWithMobileOrEmail from "./login-with-mobile-or-email/LoginWithMobileOrEmail";
import { useIntercom } from "react-use-intercom";
import LoginWithMobileOrEmail from '../pages/authpage/login-home-page/login-with-mobile-or-email/LoginWithMobileOrEmail';


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };


const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});


function  LoginUser() {


    const toast = useRef(null);
    const { setUserToken, setFbToken } = useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const [modal, setModal] = useState(false);
    const [displayForgotPassword, setDisplayForgotPassword] = useState(false);
    const navigate = useNavigate();
    const [isloginLoad, setIsloginLoad] = useState(false)
    const [chooseLogin, setChooseLogin] = useState('phone')
  
    // intercom
    const { update } = useIntercom();
  
  
    // for email verification
    const query = useQuery();
    const token = query.get('token');
  
  
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: validationSchema,
  
      onSubmit: async (values) => {
        setIsloginLoad(true)
        const body = {
          password: values.password,
          username: values?.email
        }
        Axios.post("/login", body, {
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            setModal(false);
            setIsloginLoad(false)
            if (
              response?.data?.message === "Invalid credentials." ||
              response?.data?.message === "User not found."
            ) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: response?.data?.message,
                life: 3000,
              });
              setDisplayForgotPassword(true);
            } else {
              setValue({ ...value, password: "" });
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: response?.data?.message,
                life: 3000,
              });
              if (response?.data?.token) {
                setUserToken(response?.data?.token);
                setFbToken(response?.data?.fbToken);
                const Token = response?.data?.token;
                const fbToken = response?.data?.fbToken;
                Cookies.set("userToken", Token, { expires: 14 });
                Cookies.set("fbToken", fbToken, { expires: 14 });
               
                 if(token){
                    navigate(`/?token=${token}`);
                 }else{
                  navigate("/");
                 }
               
              }
              // handleUser(response?.data?.token);
            }
          })
          .catch((error) => {
            setIsloginLoad(false)
            setModal(false);
            setDisplayForgotPassword(true);
            console.log("Error setting up request:", error);
          });
      },
    });
  
    const handleTogglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    const initialState = {
      email: "",
      password: "",
    };
    const [value, setValue] = useState(initialState);
  
  
    const handleUser = async (token) => {
      // debugger;
      Axios.get("/user", {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setModal(false);
          const data = response?.data?.user;
  
  
          if (data) {
  
            // ;
            localStorage.setItem("tokenLogin", JSON.stringify(token));
            navigate("/");
            // window.location.reload(true);
          } else {
            const registerToken = token;
            navigate("/signup-2", {
              replace: true,
              state: {
                registerToken,
              },
            });
          }
          setValue(initialState);
        })
        .catch((error) => {
          setModal(false);
          console.log("Error setting up request:", error);
        });
    };
  
  
    const googleLogin = useGoogleLogin({
      onSuccess: (response) => {
        // console.log("tokenResponse", response)
  
        fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
          headers: {
            'Authorization': `Bearer ${response?.access_token}`
          }
        })
          .then(response => response.json())
          .then(data => {
            // console.log("googledata", data);
            if (data?.email) {
              hadnleGooleLoginApi(data?.email, response?.access_token)
            }
            // Access user details from the 'data' object
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
          });
      },
      onError: () => console.log('Login Failed')
    });
  
    const hadnleGooleLoginApi = async (email, token) => {
      const body = {
        email: email,
        googleToken: token
  
      }
  
      Axios.post("/google-login", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setModal(false);
          if (
            response?.data?.message === "Invalid credentials." ||
            response?.data?.message === "User not found."
          ) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response?.data?.message,
              life: 3000,
            });
            setDisplayForgotPassword(true);
          } else {
            setValue({ ...value, password: "" });
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: response?.data?.message,
              life: 3000,
            });
            if (response?.data?.token) {
              setUserToken(response?.data?.token);
              setFbToken(response?.data?.fbToken);
              const Token = response?.data?.token;
              const fbToken = response?.data?.fbToken;
              Cookies.set("userToken", Token, { expires: 7 });
              Cookies.set("fbToken", fbToken, { expires: 14 });
              navigate("/");
            }
            // handleUser(response?.data?.token);
          }
        })
        .catch((error) => {
          setModal(false);
          setDisplayForgotPassword(true);
          console.log("Error setting up request:", error);
        });
  
    }
  
  
    const handleLogin = () => {
      formik.handleSubmit();
    }
  
  
    const responseGoogle = (response) => {
      const jwtToken = response?.credential
      const decoded = jwtDecode(jwtToken);
      console.log("res", response)
      console.log("decode", decoded)
      if (decoded?.email) {
        const userdetails = { name: decoded?.name, email: decoded?.email, googleId: decoded?.sub }
        hadnleGooleLoginApi(userdetails?.email, response?.credential)
        // setUserssoDetails({...userssodetails, name:decoded?.name, email:decoded?.email})
      }
      // console.log("google", response);
    };
  
  



  return (


    <>
    <Toast ref={toast} />
      <div className="signup-container">
          
      <div className="signup-left-container position-relative ">
             <div className=' flex justify-center sm:items-start md:items-center position-relative'>
             <img  src={bgimg} className='auth-bgimage d-none d-md-block' alt="" />
             
             </div>
           
            
          </div>

          
         <div className=' signup-right-container'>
         <div className="usersignup-card">
          
        <div className="usersignupcard-inner">

        
          <div className='signup-card'>
            <div className='text-start form-card-login signup-form-container mt-md-3 py-3'>
              <img src={logo} className="img-top-logo " alt="" />
            </div>
            <div className='mt-5'>
            <div className='mt-md-3 mt-3 signup-form-container'>
              {/* <h3 className="text-start d-md-none px-md-1  google-heading-signup ">SignUp</h3> */}
              <p className=" d-md-block text-start mb-4 google-heading-signup" >Login</p>
             
            </div>


            <div className="signup-form-container">
              {/* <div className="signup-logo mb-4 mb-md-4">

                <p className='get-started'> Enter your login details</p>
              </div> */}

              <div className="signup-from">
              
                 {/* login user */}

                 <div className="logintab mb-3 d-flex justify-content-start">
                  <div className="tab-container d-flex justify-content-between align-items-center gap-1 py-2 px-2">
                  <button onClick={()=>setChooseLogin('phone')} className={chooseLogin==='phone'?'shadow-btn login-switch-tab-button':'login-switch-tab-button hover-btn'}>
                       <PhoneIphoneIcon sx={{color:chooseLogin==='phone'?'':'rgba(132, 132, 132, 1)'}} /> Mobile
                       </button>
                       
                       <button onClick={()=>setChooseLogin('email')} style={{color:chooseLogin==='email'?'':'rgba(132, 132, 132, 1)'}} className={chooseLogin==='email'?'shadow-btn login-switch-tab-button':'login-switch-tab-button hover-btn'}>
                         <MailOutlineIcon sx={{color:chooseLogin==='email'?'':'rgba(132, 132, 132, 1)'}}/> Email
                       </button>

                      
                  </div>
                </div>

                 {chooseLogin==='email' &&  <div className="email-login-container">
                   <div className="mb-3">
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    variant="standard"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    color="secondary"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  
                </div>
                <div className="mb-4">
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    color="secondary"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                </div>

                <div
                  className="mb-3"
                  style={{
                    textAlign: "right",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  <span
                    style={{ cursor: "pointer", color: "#62c" }}
                    onClick={() => {
                      navigate("/forgot-password", { state: value.email });
                    }}
                  >
                    Forgot Password?
                  </span>
                </div>


                <div className=" mb-md-0 " >
                 
                  <button
                    className="submit-button " style={{borderRadius:'999px'}}
                    onClick={handleLogin}
                    disabled={isloginLoad ? true : false}
                  
                  >
                    {isloginLoad ? <CircularProgress size={20} color="inherit" /> : 'Login'}
                  </button>
                  {/* </NavLink> */}
                        <div className="d-flex  align-items-center">
                          <p className="border" style={{height:'0.7px', width:'49%', overflow:'hidden'}}></p>
                          <p style={{fontSize:'14px'}}>OR</p>
                          <p className="border" style={{height:'0.7px',  width:'49%', overflow:'hidden'}}></p>
                        

                        </div>
                  <span className="submit-button-google">
                 
                    <button className="submit-button " style={{ backgroundColor: 'white', color: 'black', borderRadius:'999px' }} onClick={() => googleLogin()}>Sign in with Google &nbsp; <img src={googleicon} width={20} height={20} alt="" /></button>
                  </span>

                  <div className="mb-0 mt-md-2">

                    <p className="text-center">
                      Don't have an account?{" "}
                      <NavLink to="/signup" className="text-primary-imp">
                        Sign Up
                      </NavLink>
                    </p>
                  </div>
                </div>
                   </div>}

                  {chooseLogin==='phone' &&<div className="phone-login-container">
                    <LoginWithMobileOrEmail />
                   </div>}
                 {/* end login user */}

              </div>

            </div>

           
            </div>
           
          </div>
          </div>
        </div>
     
        </div>
         </div>
        

     


    
    </>




  );
}

export default  LoginUser;
