import React from "react";
import leapxlogo from "../../../asets/leapx logo.jpg";
import { NavLink } from "react-router-dom";
function LoginHomePage() {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className=""
              style={{ maxHeight: "169px", overflow: "hidden" }}
            >
              <img
                src={leapxlogo}
                style={{ maxHeight: "169px" }}
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="home-content">
              <p className="text-center" style={{ fontSize: "14px" }}>
                Welcome to Leapx, where running ads is simple and efficient. Our
                plateform is designed for speed and reliability, ensure your ads
                reach your audience swiftly.
              </p>
              <p className="text-center" style={{ fontSize: "14px" }}>
                With LeapX managing ads is a breeze . our user-fiendly interface
                makes it easy for you to create, monitor and optimize ad
                campaigns in just a few clicks
              </p>
            </div>

            <div className="mt-5 mb-3">
              <NavLink to="/signup/enter-otp">
                <button className="submit-button">Login</button>
              </NavLink>
            </div>
            <div>
              <p className="text-center">
                Don't have an account?{" "}
                <span className="text-primary-imp">Register</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginHomePage;
