import React, { useState } from "react";
import AliceCarousel from 'react-alice-carousel';


import HomepageCarouselCard from "./HomepageCarouselCard";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function HomePageSlider({data}) {
  const [activeData, setActiveData] = useState([])

   useEffect(()=>{

        const activeCampaign = data?.filter((val)=>val?.status==='ACTIVE')
          if(activeCampaign){
               setActiveData(activeCampaign)
          }
         
  },[data])

  const responsive = {
    0: { 
        items: 1
    },
    568: { 
        items: 2
    },
    1324: {
        items: 3, 
      
    },
};
  // const itemsLength = data?.from({ length: 3 });

  const items = activeData?.slice(0, 3).map((item, index) => {
     
      return (<div key={index+1}  className="item">
        
          <HomepageCarouselCard data={item}  />
        
        </div>);
  });
  return (
    <>
  
   { activeData?.length>0  &&  <div className="row mt-0 pt-0 pe-3" >
   <div className="col-12 " style={{maxWidth:'930px'}}>
    <div>
    <div className='d-md-flex justify-content-between align-items-center mb-3 d-none'>
                  <p className="heading"  style={{fontSize:'20px'}} >Campaigns</p>
                  <Link className="pe-3 button-day " style={{fontSize:'12px', color:'black'}} to="/campaign-management">View All <ArrowForwardIcon sx={{fontSize:'12px'}}/></Link>
                 </div>
      </div> 
   <AliceCarousel
   responsive={responsive}
  mouseTracking
  items={items}   
  disableDotsControls
/> 
   </div>  
</div>}  
   
      
</>
     
     
  );
}

export default HomePageSlider;