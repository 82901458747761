import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMap, Popup } from 'react-leaflet';
import L, { Map } from 'leaflet'
import Tooltip from '@mui/material/Tooltip';
import 'leaflet/dist/leaflet.css';
// import RoomIcon from '@mui/icons-material/Room';
// import { createControlComponent } from '@react-leaflet/core'
// import { Control } from 'leaflet'
import ShareLocationIcon from '@mui/icons-material/ShareLocation';

// const ZoomControl = createControlComponent(
//   (props) => new Control.Zoom(props),
// )


// Component for the Center Map button
const CenterMapButton = ({ locations }) => {
  const map = useMap();

  const handleClick = () => {
    if (locations?.length === 0 || locations?.length===1) return;

    // Create a bounding box from the locations
    const bounds = L.latLngBounds(locations?.map(loc => [loc?.lat, loc?.lon]));

    // Adjust the map view to fit the bounding box
    map?.fitBounds(bounds);
  };

  return (
    <>
    <Tooltip title="  Centre Map" placement="top" arrow>

   <button className="hidemap position-absolute" onClick={handleClick}  style={{top:'44px', right:'8px', zIndex:'999', color:'rgb(28, 30, 33)', backgroundColor: 'rgba(0, 0, 0, 0.1)', borderColor: 'rgba(0, 0, 0, 0.05)'}}> <ShareLocationIcon sx={{opacity:'0.5'}}/></button>

   </Tooltip>
    </>
  );
};






const TargetingLocaionWithRadius = ({ showSearchForMap, locationCoordinates, locationRadiusValue, singLocationCoordinated }) => {
   
  const [showMultipleLocation, setShowMultipleLocation] = useState([])
//  const [centerPosition, setCenterPosition] = useState({lat:28.4646148, lon:77.0299194, zoomLevel:7})
const [centerPosition, setCenterPosition] = useState([28.4646148, 77.0299194])
//const [mapBounds, setMapBounds] = useState(null);
  //const [map, setMap] = useState(null);
  // const map = useMap()
//   console.log('map center:', map.getCenter())
//   return null
// }

//const map = useMap();

const mapRef = useRef();

// function handleSetView(location){
//    const {current={}} = mapRef;
//    const {leafletElement:map} = current;
//    map.setView([location.lat, location.lon], 14)
// }

function handleFlyTo(location){
  const {current={}} = mapRef;
  // console.log("current",location)
  //const { leafletElement:map } = current;
       if(location?.type==='city'){
        current?.flyTo([location?.lat, location?.lon] ,8);
       }else if(location?.type==='place'){
        current?.flyTo([location?.lat, location?.lon] ,12);
       }else{
        current?.flyTo([location?.lat, location?.lon] ,10);
       }
  
   // map.flyTo([location.lat, location.lon], 12, {duration:2})

      }

      console.log("singLocationCoordinateddslkfhewjiofnewo", singLocationCoordinated)

useEffect(()=>{
     if(singLocationCoordinated?.lat){
      handleFlyTo(singLocationCoordinated)
     }
},[singLocationCoordinated])


useEffect(()=>{
    if(locationCoordinates?.length){
       
      handleFlyTo(locationCoordinates[locationCoordinates?.length-1])
    }
},[locationCoordinates?.length])

// const centerMap = () => {
    
//   if (mapRef?.current) {
//     console.log("center map")
//     const bounds = mapRef?.current?.leafletElement?.getBounds();
//     mapRef?.current?.leafletElement?.fitBounds(bounds);
//   }
// };



// useEffect(() => {
//   if (locationCoordinates.length > 0) {
//     const bounds = L.latLngBounds(locationCoordinates.map(loc => [loc.lat, loc.lon]));
//     setMapBounds(bounds);
//   }
// }, [locationCoordinates]);

// useEffect(() => {
//   if (mapBounds && mapRef.current) {
//     mapRef.current.fitBounds(mapBounds);
//   }
// }, [mapBounds]);

// const calculateCenter = () => {
//   const latitudes = locationCoordinates.map(loc => loc.lat);
//   const longitudes = locationCoordinates.map(loc => loc.lon);
//   const avgLatitude = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
//   const avgLongitude = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;
//   return [avgLatitude, avgLongitude];
// };

// const handleCenterButtonClick = () => {
//   const newCenter = calculateCenter();
//   const {current={}} = mapRef;
 
//  //const { leafletElement:map } = current;
//  current?.flyTo(newCenter ,4);
// };


// useEffect(() => {
//   if (singLocationCoordinated && mapRef.current) {
//      if(map){
//       map.flyTo([singLocationCoordinated.lat, singLocationCoordinated.lon], 13); // Zoom level can be adjusted as needed
//       // Center the location within the container (optional)
//       map.setView([singLocationCoordinated.lat, singLocationCoordinated.lon], map.getZoom(), { animate: true });
//      }
   
//   }
// }, [singLocationCoordinated]);

// useEffect(()=>{
//      if(singLocationCoordinated){
//       setCenterPosition({...singLocationCoordinated, zoomLevel:12})
//      }
// },[singLocationCoordinated])


   useEffect(()=>{
        if(locationCoordinates){
          setShowMultipleLocation(locationCoordinates)
        }
   },[locationCoordinates])

  const markericon = new L.Icon({
    iconUrl:require('../../../asets/marker2.png'),
    iconSize:[35, 40],
    iconAnchor:[19, 34]
  })

  const markericonexclude = new L.Icon({
    iconUrl:require('../../../asets/marker delete.png'),
    iconSize:[35, 40],
    iconAnchor:[19, 34]
  })

//   const centerMap = (lat, lon) => {
//     setCenterPosition({ lat, lon, zoomLevel: 12 });
// };


const token = "pk.eyJ1IjoiZGlwYWsta3VtYXIxMjMiLCJhIjoiY2x3Z2VvMXgzMDA1MzJqcXJlZ2VuNTBxOSJ9.aclqm1n6jZC8MMZ0_sU3jg"
 const mapStyle = 'mapbox/streets-v12'; // or any other Mapbox style
// console.log("showMultipleLocation", showMultipleLocation)
//const mapStyle = 'planemad/ckf4xcet7231819mm2e8njlca'; // or any other Mapbox style

  return (
    <>
    {/* <Tooltip title="  Centre Map" placement="top" arrow>

   <button className="hidemap position-absolute" onClick={handleCenterButtonClick}  style={{top:'44px', right:'8px', zIndex:'999', color:'rgb(28, 30, 33)', backgroundColor: 'rgba(0, 0, 0, 0.1)', borderColor: 'rgba(0, 0, 0, 0.05)'}}> <ShareLocationIcon sx={{opacity:'0.5'}}/></button>

   </Tooltip> */}

      <MapContainer ref={mapRef} center={centerPosition} zoom={7} scrollWheelZoom={true} style={{ height: '230px', width: '100%' }}>
         
      

     

        <TileLayer
          url={`https://api.mapbox.com/styles/v1/${mapStyle}/tiles/{z}/{x}/{y}?access_token=${token}`}
          // attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>'
        id="mapbox/streets-v12"
          tileSize={512}
          zoomOffset={-1}
        />
{/* <FeatureGroup>

<Polygon
          positions={pokBoundary}
          pathOptions={{ color: 'red', weight: 2, opacity: 0.01, fillColor: 'red', fillOpacity: 0.1 }}
        />
</FeatureGroup> */}

<CenterMapButton locations={locationCoordinates}/>
        {/* Render markers based on markerData */}
        {showMultipleLocation?.map((marker, index) => (
          <>
         {(marker?.type==='city' || marker?.type==='place' || marker?.type==='address')?(<>
          <Circle
          pathOptions={{ weight: 1 }}
          fillOpacity={0.2}
          color={marker?.isInclude === "include"?"#6622cc":'red'}
     center={[marker?.lat?Number(marker?.lat):null, marker?.lon?Number(marker?.lon):null]}
    //  eventHandlers={{click:()=>handleFlyTo(marker)}}
      // pathOptions={{ fillColor: marker?.isInclude === "include"?"#6622cc":'red' }}
      radius={marker?.radius?marker?.radius*1000:0}
      >
     <Marker key={index+1} position={[marker?.lat>0?Number(marker?.lat):null, marker?.lon?Number(marker?.lon):null]} style={{ zIndex: 99999 }} icon={marker?.isInclude === "include" ? markericon:markericonexclude} eventHandlers={{click:()=>handleFlyTo(marker)}} >  {/* Set higher z-index */}
    
      </Marker>
    </Circle>
         {/* <CircleMarker  key={index+1} center={[marker?.lat>0?Number(marker?.lat):null, marker?.lon?Number(marker?.lon):null]} radius={marker?.radius} color={marker?.isInclude === "include"?"#6622cc":'red'} fillOpacity={0.2} >
       
       </CircleMarker> */}
       
       </>):''}  
           <Marker key={index+1} position={[marker?.lat?Number(marker?.lat):null, marker?.lon?Number(marker?.lon):null]} style={{ zIndex: 99999 }} icon={marker?.isInclude === "include" ? markericon:markericonexclude} eventHandlers={{click:()=>handleFlyTo(marker)}} >  {/* Set higher z-index */}
           {/* <Popup  className='' >
        {marker?.name?marker?.name:''} {marker?.region?marker?.region:''} {marker?.country_code?marker?.country_code:''}
      </Popup> */}
      </Marker>
          </>
        
         
        ))}
       
      </MapContainer>
    </>
  );
};

export default TargetingLocaionWithRadius;