import React from 'react'
import leadicon from '../../../asets/webimages/home/leadsicon.png'
import messageicon from '../../../asets/webimages/home/messageicons.png'
import activecamicon from '../../../asets/webimages/home/activecamicon.png'
import { useNavigate } from 'react-router-dom'
import BlinkLoader from '../../../BlinkLoader'
import '../webhome.css'
function WebHomeTotalOverview({data, isFbDataLoader}) {
  const navigate = useNavigate()
   
  return (
    <>
      <div className="col-4 col-lg-4 mb-4 mb-lg-0">
          <div>
              <div className='d-flex justify-content-center align-items-center home-overview1-card'>
                    <div onClick={()=>navigate("/lead-management")} className='d-flex justify-content-center align-items-center overview-card  shadowsm ' style={{ backgroundColor:'#EFF6F8', cursor:'pointer'}}>
                    <div className='p-1'>
                      <img src={leadicon} alt="" />

                  </div>
                  <div className='overview-content'>
                      <p className='p-0 m-0 overview-heading' >Total leads</p>
                      <p className='p-0 m-0 overview-number' >{isFbDataLoader?<BlinkLoader/>:data?.total_leads?data?.total_leads:0}</p>
                  </div>
                    </div>
                
              </div>
          </div>
            </div>

            {/* <div className="col-3">
          <div>
              <div className='d-flex justify-content-center align-items-center home-overview2-card'>
                    <div className='d-flex justify-content-center align-items-center overview-card shadowsm' style={{ backgroundColor:'#EFF8EF'}}>
                    <div className='p-1'>
                      <img src={messageicon} alt="" />

                  </div>
                  <div className='overview-content'>
                      <p className='p-0 m-0 overview-heading' >Total Messages</p>
                      <p className='p-0 m-0 overview-number' >0</p>
                  </div>
                    </div>
                
              </div>
          </div>
            </div> */}

            <div className="col-4 col-lg-4 mb-4 mb-lg-0">
          <div>
              <div className='d-flex justify-content-center align-items-center home-overview3-card '>
                    <div  onClick={()=>navigate("/campaign-management")} className='d-flex justify-content-center align-items-center overview-card shadowsm' style={{cursor:'pointer', backgroundColor:'#FFF0F3'}}>
                    <div className='p-1'>
                      <img src={activecamicon} alt="" />

                  </div>
                  <div className='overview-content'>
                      <p className='p-0 m-0 overview-heading'>Live campaign</p>
                      <p className='p-0 m-0 overview-number' >{isFbDataLoader?<BlinkLoader/>:data?.active_campaign?data?.active_campaign:0}</p>
                  </div>
                    </div>
                
              </div>
          </div>
            </div>
    </>
    
  )
}

export default WebHomeTotalOverview