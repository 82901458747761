import React, { useContext, useEffect, useRef, useState } from "react";
import "../property-details/properttydetails.css";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MuiChipsInput } from "mui-chips-input";
// import DatePicker from "react-datepicker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useLocation } from "react-router-dom";
import Navbar2 from "../../navbar/Navbar2";
import property from "../../asets/property.png";
import location from "../../asets/location.png";
import sizeimg from "../../asets/size.png";
// import rupes from "../../asets/rupes.png";
import rupes from "../../asets/homeicon/rupee-indian.png";
// import calander from "../../asets/calander.png";
import area from "../../asets/area.png";
import donetext from "../../asets/donetext.png";
import { AuthContext } from "../../AuthProvider";
import Axios from "../../Axios";
import Cookies from "js-cookie";
import CancelIcon from '@mui/icons-material/Cancel';
//import Loader from "../../Loader";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button } from "@mui/material";
import WebStepper from "../../adesktop-webpage/web-component/webstepper/WebStepper";
import LoadingForReviewDetails from "../../components/LoadingScreen/LoadingForReviewDetails";
//import { useFormik } from "formik";
import * as Yup from 'yup';
import BottomNavbar from "../../components/bottomNavbar/BottomNavbar";


const reviewSchema = Yup.object().shape({
    Name: Yup.string()
        .required('Name is required'),
    Address: Yup.string()
        .required('Location is required'),
    Property: Yup.string().oneOf(['Residential', 'Commercial'], 'Property type must be either residential or commercial')
        .required('Property type is required'),

    Size: Yup.string()
        .required('Size is required'),
    Price: Yup.string()
        .required('Price is required'),
    // Status: Yup.string()
    //   .oneOf(['Ready to move', 'Under Construction'], 'Status must be either ready to move or underconstruction')
    //   .required('Status is required'),
    creativesFlag: Yup.string()
        .oneOf(['true', 'false'], 'You must specify if you want to use your own creative or not')
        .required('Creative choice is required')
});



function ReviewDetails() {

    const [errors, setErrors] = useState({});

    const initialState = {
        Name: "",
        Address: "",
        Property: "",
        Size: "",
        Price: "",
        Status: "",
        // Designed: "",
        Budget: 19900,
        Day: 30,
        Flat: "",
        creativesFlag: null,
        payment_plan: "",
        rera_number: "",
        expected_roi: "",
        other_offers: "",
        video_url: ""
    };

    const navigate = useNavigate();
    const { userToken, setCampaignId, setPageId } = useContext(AuthContext);
    const { state } = useLocation();
    //console.log("add",state?.address)
    const urlData = state?.weburl;
    const is_sell = state?.is_sell;
    const propertyidnew = state?.property_id;
    const [flatError, setFlatError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [campaignBudgetData, setcampaignBudgetData] = useState(19900);
    const [getPropertyId, setGetPropertyId] = useState('')
    const [selectedImages, setSelectedImages] = useState([])
    const [selectedImages2, setSelectedImages2] = useState([])
    const [images, setImages] = useState([]);
    const [images2, setImages2] = useState([]);
    const [userDetail, setUserDetail] = useState({});
    const [selectFacebookPage, setSelectFacebookPage] = useState(null);
    const [draftgetProperty, setDraftgetProperty] = useState("")
    const [draftcmapId, setDraftcampId] = useState("")
    const [propImages, setPropImages] = useState([])
    const [propImages2, setPropImages2] = useState([])
    const [Highlights, setHighlights] = useState([]);
    const [Dates, setDates] = useState('');
    const [modal, setModal] = useState(false);
    const fileInputRef = useRef(null);
    const videoFileInputRef = useRef(null)
    const fileInputRef2 = useRef(null);
    const [imageSizeError, setImageSizeError] = useState(false)
    const [imageSizeError2, setImageSizeError2] = useState(false)
    const [value, setValue] = useState(initialState);
    const [designCreativeStatus, setDesignCreativeStatus] = useState(false)
    const [incompleteForm, setIncompleteForm] = useState(false)
    // const [creativeVideo, setCreativeVideo] = useState([])



    // new 12-8-24
    const [previews, setPreviews] = useState([]);
    const [imagess, setImagess] = useState([]);
    const [videos, setVideos] = useState([]);
    const [errorss, setError] = useState(null);
    const [imagesUrl, setImagesUrl] = useState([])
    const [videosUrl, setVideosUrl] = useState([])


    const handleFilesChange = (e) => {
        const files = Array.from(e.target.files);
        const newPreviews = [];
        const newImages = [];
        const newVideos = [];
        let hasError = false;


        // Check the total number of files (existing + new)
        let totalFiles = previews.length + files.length;
             if(videosUrl?.length>0){
                totalFiles = totalFiles + videosUrl?.length
             }
             if(imagesUrl?.length>0){
                totalFiles = totalFiles + imagesUrl?.length
             }
             
        if (totalFiles > 5) {
            setError('You can only select up to 5 files.');
            return;
        }


        const videoMaxSize = 200 * 1024 * 1024; // 200 MB

        files.forEach((file) => {
            if (file?.type?.startsWith('image/') && file?.size > 16 * 1024 * 1024) { // 16MB in bytes
                setError(`One or more selected image files are too large. Please select files smaller than 15 MB.`);
                hasError = true;
                return;
            }else if(file?.type?.startsWith('video/') && file?.size > videoMaxSize){
                setError(`One or more selected video files are too large. Please select files smaller than 200 MB.`);
                hasError = true;
                return;
            }

            const previewURL = URL.createObjectURL(file);
            newPreviews?.push({ file, url: previewURL });

            if (file?.type?.startsWith('image/')) {
                newImages.push(file);
            } else if (file?.type?.startsWith('video/')) {
                newVideos.push(file);
            }
        });

        if (!hasError) {
            setPreviews((prev) => [...prev, ...newPreviews]);
            setImagess((prev) => [...prev, ...newImages]);
            setVideos((prev) => [...prev, ...newVideos]);
            setError(null); // Clear the error if all files are valid
        }

        // setPreviews((prev) => [...prev, ...newPreviews]);
        // setImagess((prev) => [...prev, ...newImages]);
        // setVideos((prev) => [...prev, ...newVideos]);
        // setError(null); // Clear the error if all files are valid
    };


    const handleDeletePreview = (index) => {
        const file = previews[index].file;
        const isImage = file?.type?.startsWith('image/');

        setPreviews((prev) => prev.filter((_, i) => i !== index));
        if (isImage) {
            setImagess((prev) => prev.filter((_, i) => i !== index));
        } else {
            setVideos((prev) => prev.filter((_, i) => i !== index));
        }

        fileInputRef.current.value = '';
    };

    const handleDeleteUrlPreview = (index) => {
          setVideosUrl((prev)=> prev?.filter((_, i)=> i !== index))
       
        fileInputRef.current.value = '';
    };


    const handleDeleteImagesUrl = (idx) => {
        setImagesUrl((prev) => prev?.filter((_, i) => i !== idx))
    }

    const handleDeleteVideosUrl = (idx) => {
        setVideosUrl((prev) => prev?.filter((_, i) => i !== idx))
    }



    // console.log("preview", previews)
    // console.log("imagess", imagess)
    // console.log("videos", videos)
    // console.log("videosurl", videosUrl)
    // console.log("imgurl", imagesUrl)


    // video
    const [videoFile, setVideoFile] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);


    // check user internate connectivity
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        // Update state when online status changes
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup listeners on component unmount
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);





    useEffect(() => {
        setDraftcampId(state?.campaignDfId)
        //  console.log("dfid",state.campaignDfId )
    }, [state?.campaignDfId])
    // console.log("issellre", state?.is_sell)

    // console.log("valueeee", value)
    const [propertyType, setPropertyType] = useState([]);


    useEffect(() => {
        // console.log("address", state?.address)

        //  setName(state?.address)



        setValue((prev) => ({
            ...prev,
            Name: state?.address?.Name || "",
            Address: state?.address?.Address ? state?.address?.Address : '',
            Property: state?.address?.Type ? state?.address?.Type : '',
            Size: state?.address?.Sizes || "",
            Price: state?.address?.['Price Range'] || "",
            // Status: state?.address?.Status || "",
            Budget: 19900,
            Day: 30,
            Flat: state?.address?.Subtype || "",
            creativesFlag: state?.address?.creativesFlag || "",
            payment_plan: state?.address?.["Payment Plan"] ?? "",
            rera_number: state?.address?.["Rera Number"] ?? "",
            expected_roi: state?.address?.ROI ? state?.address?.ROI : '',
            other_offers: state?.address?.Offers ? state?.address?.Offers : '',

        }));
        //  console.log("state?.address?.Subtype", state?.address?.Subtype)
        //console.log("update", value)
        if (state?.address?.Amenities) {
            const Amenitiesdata = state?.address?.Amenities?.split(", ");
            if (Amenitiesdata?.length) {
                setHighlights(Amenitiesdata)
            }

            // console.log("Amenitiesdata", Amenitiesdata)
        }


        if (state?.address?.['Possession Date']?.length > 0) {
            const parsedDate = new Date(state?.address?.['Possession Date']);

            setDates(parsedDate)
        }
    }, [state?.address]);
    //get property for draft campaign
    const handleGetPropertyForDreaft = async (propertyId) => {
        try {
            const res = await Axios.get("/get-property", {
                headers: {
                    Authorization: userToken
                },
                params: {
                    property_id: propertyId
                }
            });
            if (res.status === 200) {
                const data = res?.data?.message
                if (data) {
                    setDraftgetProperty(data)
                    setGetPropertyId(data?._id)
                    // console.log("modifyobj",data)

                    setValue((prev) => ({
                        ...prev,
                        Name: data?.name || "",
                        Address: data?.location || "",
                        Property: data?.type || "",
                        Size: data?.size || "",
                        Price: data?.starting_price || "",
                        // Status: data?.status || "",
                        Budget: 19900,
                        Day: data.days,
                        Flat: data?.is_flat && ['Flat', 'Plot', 'Land', 'Office', 'Shop/Showroom']?.includes(data?.is_flat) ? data?.is_flat : '',
                        creativesFlag: data?.creativesFlag,
                        payment_plan: data?.payment_plan ? data?.payment_plan : "",
                        rera_number: data?.rera_number ? data?.payment_plan : "",
                        expected_roi: data?.expected_roi ? data?.expected_roi : "",
                        other_offers: data?.other_offers ? data?.other_offers : "",
                        video_url: data?.videos

                    }));

                    if (data?.creativesFlag == 'true' || data?.creativesFlag === true) {
                        setPropImages(data?.images)
                        setUploadedVideoUrl(data?.videoUrl)
                        const videourl = data?.videos || [];
                        const imgurl = data?.images || []
                        setVideosUrl(videourl)
                        setImagesUrl(imgurl)

                    } else if (data?.creativesFlag == 'false' || data?.creativesFlag === false) {
                        setPropImages2(data?.images)
                        setUploadedVideoUrl("")
                    }


                    if (data?.key_highlights?.length > 0) {
                        const keyhigh = data?.key_highlights?.split(", ");
                        if (keyhigh?.length) {
                            setHighlights(keyhigh)
                        }
                    }
                }
                // console.log("propertydata", data)
            }
        } catch (error) {
            //  alert("get properrty failed")
            console.log(error.message)
        }
    }

    useEffect(() => {
        handleGetPropertyForDreaft(state?.propertyId)
    }, [state?.propertyId])

    //console.log("namemm", name)
    //console.log("value", value)

    useEffect(() => {
        if (value.Property === "Residential") {
            setPropertyType(["Flat", "Plot"]);
        } else if (value.Property === "Commercial") {
            setPropertyType(["Shop/Showroom", "Land", "Office"]);
        } else {
            setPropertyType([]);
        }


        // setValue(prev => ({
        //     ...prev,
        //     Flat: '',
        // }));
    }, [value.Property]);

    useEffect(() => {
        handleUser();
    }, []);

    const handleUser = async () => {
        //   let stringWithoutDoubleQuotes = tokenLogin.replace(/"/g, "");
        Axios.get("/user", {
            headers: {
                Authorization: userToken,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const data = response?.data?.user;
                // console.log("==============================>", data);
                setUserDetail(data);
            })
            .catch((error) => {
                console.log("Error setting up request:", error);
            });
    };
    const handleInputChange = (e) => {
        const { name } = e.target;
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
        // console.log("name", name)
        if (errors[name]) {
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors[name];
                return newErrors;
            });
        }
    };


    const handleChange = (newChips) => {
        setHighlights(newChips);
    };

    const steps = ["Provide details", "Review details", "Confirm & Pay"];

    const handleProperty = (event) => {
        const { name } = event?.target;

        setValue({
            ...value,
            Property: event.target.value,
        });
        //  console.log("name", name)

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors, Property: '' };

            return newErrors;
        });

    };
    const handleFlat = (event) => {
        setValue({
            ...value,
            Flat: event,
        });

    };
    const handleStatus = (event) => {
        setValue({
            ...value,
            Status: event.target.value,
        });
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors, Status: '' };

            return newErrors;
        });
    };

    const handleCreativeFlag = (e) => {
        setDesignCreativeStatus(true)
        setValue({
            ...value,
            creativesFlag: e.target.value
        })




        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors, creativesFlag: '' };

            return newErrors;
        });



        if (e.target.value) {
            setDesignCreativeStatus(true)
        } else {
            setDesignCreativeStatus(false)
        }


    }
   

    const handlePropertySubmit = () => {
        console.log("navigator?.onLine", navigator?.onLine)
       
        if (!isOnline) {

            setModal(false); // Show modal with connectivity error
            // setModalContent("No internet connection. Please check your connectivity and try again.");
            return; // Exit the function if there's no internet connection
        }


        //console.log("erwohfejihwef")
        if (!value?.Flat) {
            setFlatError(true)
            return
        }

        setFlatError(false)



        if (!(imagess?.length || images2?.length || videos?.length)) {
            setErrors("Choose at least one file")
            return;
        }


        const key = Highlights.join(", ");
        const dateObject = new Date(Dates);
        const year = dateObject.getFullYear();
        const body = {
            name: value?.Name,
            location: value?.Address,
            type: value?.Property,
            size: value?.Size,
            starting_price: value?.Price,
            key_highlights: key?.length ? key : '85% Green and Open areas, Largest Club-house (1.25 lacs sqft), Double Height Entrance Lobby, 9+ft wide party decks',
            // status: value?.Status,
            // possession_date: year,
            campaign_budget: Number(campaignBudgetData),
            is_flat: value?.Flat,
            lifetime_budget: Number(campaignBudgetData),
            url: urlData,
            is_sell: is_sell,
            creativesFlag: value?.creativesFlag,
            payment_plan: value?.payment_plan,
            rera_number: value?.rera_number,
            expected_roi: value?.expected_roi,
            other_offers: value?.other_offers,
            property_id: propertyidnew ? propertyidnew : ''

        };

        const formData = new FormData();
        formData.append("name", body.name)
        formData.append("location", body.location)
        formData.append("type", body.type)
        formData.append("size", body.size)
        formData.append("starting_price", body.starting_price)
        formData.append("key_highlights", body.key_highlights)
        // formData.append("status", body.status)
        // formData.append("possession_date", body.possession_date)
        formData.append("campaign_budget", body.campaign_budget)
        formData.append("is_flat", body.is_flat)
        formData.append("lifetime_budget", body.lifetime_budget)
        formData.append("payment_plan", body?.payment_plan)
        formData.append("rera_number", body?.rera_number)
        formData.append("expected_roi", body?.expected_roi)
        formData.append("other_offers", body?.other_offers)
        formData.append("url", body.url)
        formData.append("is_sell", body.is_sell)
        formData.append("creativesFlag", body.creativesFlag)
        
          if(body.property_id){
            formData.append("property_id", body.property_id)
          }
        //formData.append('images', selectedImages);
        // if (images?.length) {
        //     for (let i = 0; i < selectedImages?.length; i++) {
        //         // formData.append("images", selectedImages[0])
        //         formData.append("images", selectedImages[i]);
        //     }
        // } else if (images2?.length) {
        //     for (let i = 0; i < selectedImages2?.length; i++) {
        //         // formData.append("images", selectedImages[0])
        //         formData.append("images", selectedImages2[i]);
        //     }
        // }

        if (value?.creativesFlag == 'true' || value?.creativesFlag === true) {
            if (imagess?.length > 0) {
                imagess.forEach((image, index) => {
                    formData.append(`images`, image);
                });
            }


            if (videos?.length) {
                videos.forEach((video, index) => {
                    formData.append(`video`, video);
                });

            }

        } else if (value?.creativesFlag == 'false' || value?.creativesFlag === false) {
            if (images2?.length) {
                for (let i = 0; i < selectedImages2?.length; i++) {
                    // formData.append("images", selectedImages[0])
                    formData.append("images", selectedImages2[i]);
                }
            }
        }





        // if (videoFile) {
        //     formData.append('video', videoFile);
        // }




        setModal(true);

        Axios.post("/create-property", formData, {
            headers: {
                Authorization: userToken,
                "Content-Type": "multipart/form-data",
            },
        })

            .then((response) => {
                if (response.status === 200) {
                    if (response?.data?.valid) {
                        const data = response?.data?.campaign;
                        const data2 = response?.data?.message;

                        setCampaignId(data?._id);
                        localStorage.setItem('propertyid', data?.property_id);
                        Cookies.set("campaignID", data?._id, { expires: 7 });


                        setModal(false);
                        navigate("/campaign-summary", {
                            state: {
                                campaign: data,
                                message: data2,
                                status: 0,
                                propertyId: data?.property_id,
                                campaignDfId: data?._id
                            }
                        })
                    }
                    setModal(false);

                }

                //  handleCampaign(data?._id, data2?.end_date, data?.page_id);
            })
            .catch((error) => {
                setModal(false);
                console.log(error);
            });
    };

    useEffect(() => {
        setPageId(selectFacebookPage?.id);
        Cookies.set("pageId", selectFacebookPage?.id, { expires: 7 });
    }, [selectFacebookPage]);




    // console.log("summery", modifiedObject?.Summary);




    const handleImageUpload = async (event) => {

        const files = event.target.files;
        //console.log("file", event)         
        if (!files.length) {
            return; // No files selected, do nothing
        }
        const fileSizeInKB = files[0].size / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        if (fileSizeInMB > 15) {
            setImageSizeError(true);
            return
        }
        setImageSizeError(false);

        setSelectedImages(files)
        const selectedImagesArray = [];

        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                selectedImagesArray.push({ file: files[i], preview: e.target.result });
                if (selectedImagesArray.length === files.length) {
                    setImages(selectedImagesArray);
                }

            };
            reader.readAsDataURL(files[i]);
            //  reader.readAsDataURL(files[i]);
        }
    };


    const handleImageUpload2 = async (event) => {

        const files = event.target.files;
        //console.log("file", event)         
        if (!files.length) {
            return; // No files selected, do nothing
        }

        const fileSizeInKB = files[0].size / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        // console.log("imgMB", fileSizeInMB)
        if (fileSizeInMB > 15) {
            setImageSizeError2(true);
            return
        }
        setImageSizeError2(false);

        setSelectedImages2(files)
        const selectedImagesArray = [];

        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                selectedImagesArray.push({ file: files[i], preview: e.target.result });
                if (selectedImagesArray.length === files.length) {
                    setImages2(selectedImagesArray);
                }

            };
            reader.readAsDataURL(files[i]);
            //  reader.readAsDataURL(files[i]);
        }
    };


    const handleDeleteImage = (index) => {
        setPropImages([])
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
        //  console.log("updatedImages", updatedImages)

        const updatedImagesSelected = [...selectedImages];
        updatedImagesSelected.splice(index, 1);
        //  console.log("selectedImages", selectedImages)
        setSelectedImages(updatedImagesSelected);


        fileInputRef.current.value = '';


        // setImages(images.filter((_, i) => i !== index));
        // console.log("updatedImagesSelected", updatedImagesSelected)
    };


    const handleDeleteImage2 = (index) => {
        setPropImages2([])
        const updatedImages = [...images2];
        updatedImages.splice(index, 1);
        setImages2(updatedImages);
        //  console.log("updatedImages", updatedImages)

        const updatedImagesSelected = [...selectedImages2];
        updatedImagesSelected.splice(index, 1);
        //  console.log("selectedImages", selectedImages)
        setSelectedImages2(updatedImagesSelected);


        fileInputRef2.current.value = '';


        // setImages(images.filter((_, i) => i !== index));
        // console.log("updatedImagesSelected", updatedImagesSelected)
    };


    useEffect(() => {

        if (value?.creativesFlag == 'true' || value?.creativesFlag === true) {
            setImages2([])
            setSelectedImages2([])
            setPropImages2([])
        } else if (value?.creativesFlag == 'false' || value?.creativesFlag === false) {
            setImages([])
            setSelectedImages([])
            // for video
            setVideoFile(null);
            setVideoPreview(null);
            setUploadedVideoUrl(null);
            setError("")
            setImagesUrl([])
            setVideosUrl([])
            setPreviews([])
            setImagess([])
            setVideos([])
        }


    }, [value?.creativesFlag])






    //edit property for draft
    const handleEditPrpertyForDraft = async () => {


           if(!(imagess?.length>0 || imagesUrl?.length>0 || videos?.length>0 || videosUrl?.length>0 ||  images2?.length>0 || propImages2?.length>0 )){
              setErrors("Choose at least one file")
              return
           }




        const key = Highlights.join(", ");
        const dateObject = new Date(Dates);
        const year = dateObject.getFullYear();
        const body = {
            name: value?.Name,
            location: value?.Address,
            type: value?.Property,
            size: value?.Size,
            starting_price: value?.Price,
            key_highlights: key,
            // status: value?.Status,
            // possession_date: year,
            campaign_budget: Number(campaignBudgetData),
            is_flat: value?.Flat,
            lifetime_budget: Number(campaignBudgetData),
            property_id: state?.propertyId,
            creativesFlag: value?.creativesFlag,
            payment_plan: value?.payment_plan,
            rera_number: value?.rera_number,
            expected_roi: value?.expected_roi,
            other_offers: value?.other_offers
        };

        const formData = new FormData();
        formData.append("name", body.name)
        formData.append("location", body.location)
        formData.append("type", body.type)
        formData.append("size", body.size)
        formData.append("starting_price", body.starting_price)
        formData.append("key_highlights", body.key_highlights)
        formData.append("status", body.status)
        formData.append("possession_date", body.possession_date)
        formData.append("campaign_budget", body.campaign_budget)
        formData.append("is_flat", body.is_flat)
        formData.append("lifetime_budget", body.lifetime_budget)
        formData.append("property_id", body?.property_id)
        formData.append("creativesFlag", body?.creativesFlag)
        formData.append("payment_plan", body?.payment_plan)
        formData.append("rera_number", body?.rera_number)
        formData.append("expected_roi", body?.expected_roi)
        formData.append("other_offers", body?.other_offers)
        formData.append("campaign_id", draftcmapId)


        if (value?.creativesFlag == 'true' || value?.creativesFlag === true) {

            if (imagess?.length > 0) {

                imagess.forEach((image, index) => {
                    formData.append(`image`, image);
                });

            }

            if (imagesUrl?.length > 0) {
                formData.append(`images`, JSON.stringify(imagesUrl) );
                // imagesUrl?.forEach((item, index) => {
                //     formData.append(`images`, JSON.stringify(item) );
                // });
            }else{
                  const arr = []
                formData.append(`images`, JSON.stringify(arr) );
            }

            if (videos?.length>0) {
                videos.forEach((video, index) => {
                    formData.append(`video`, video);
                });

            }

            if (videosUrl?.length > 0) {
                formData.append(`videos`, JSON.stringify(videosUrl) );
                // videosUrl?.forEach((item, index) => {
                //     formData.append('videos', JSON.stringify(item))
                // })
            }else{
                const arr = []
                formData.append('videos', JSON.stringify(arr))
            }

        } else if (value?.creativesFlag == 'false' || value?.creativesFlag === false) {
            if (images2.length > 0) {
                for (let i = 0; i < selectedImages2?.length; i++) {
                    formData.append("image", selectedImages2[i])
                }
            }
            if(propImages2?.length>0){
                 
                formData.append(`images`, JSON.stringify(propImages2) );
            }else{
                const arr = []
                formData.append(`images`, JSON.stringify(arr) );
            }
        }



        // if (imagess?.length) {
        //     for (let i = 0; i < imagess.length; i++) {
        //         formData.append("images", selectedImages[i])
        //     }
        // } else if (images2.length) {
        //     for (let i = 0; i < selectedImages2?.length; i++) {
        //         formData.append("images", selectedImages2[i])
        //     }
        // }



        // if (videoFile) {
        //     formData.append('video', videoFile);
        // } else if (value?.video_url) {
        //     if (!uploadedVideoUrl) {
        //         console.log("called")
        //         formData.append('videoUrl', "");
        //         formData.append('videoId', "");
        //     }
        // }


        setIsLoading(true);
        try {
            const res = await Axios.post("/edit-property", formData, {
                headers: {
                    Authorization: userToken,
                },

            });
            if (res.status === 200) {
                const data2 = res?.data?.message;
                const data = res?.data?.campaign

                localStorage.setItem('propertyid', data?.property_id);

                navigate("/campaign-summary", {
                    state: {
                        campaign: data,
                        message: data2,
                        status: state?.status,
                        propertyId: state.propertyId,
                        campaignDfId: state?.campaignDfId
                    }
                })

            }
        } catch (error) {

            //alert("failed")
            console.log(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    // console.log("uploadedVideoUrl", uploadedVideoUrl)
    // console.log("valueurl", value?.video_url)

    async function handleNextClick(statustype) {

        if (!isOnline) {
            setIncompleteForm(false)
            // setModal(false); // Show modal with connectivity error
            // setModalContent("No internet connection. Please check your connectivity and try again.");
            return; // Exit the function if there's no internet connection
        }

        try {
            await reviewSchema.validate(value, { abortEarly: false });
            setErrors({});
            setIncompleteForm(false);
            if (statustype === 1) {
                console.log("click1")

                handlePropertySubmit();
            } else {

                console.log("click2")

                handleEditPrpertyForDraft()

            }
            // console.log('Form data is valid:', value);
            // Submit the form data to the server or perform another action
        } catch (err) {
            const validationErrors = {};
            if (err.inner) {
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
            }

            setErrors(validationErrors);
            setIncompleteForm(true)
        }
    }

    const ImageThumbnail = ({ src, onDelete }) => {
        // console.log("src", src)
        return (
            <>

                <div className="image-thumbnail-container">
                    <img className="image-thumbnail " src={src} alt="thumbnail" />
                    <span onClick={() => onDelete(onDelete)} className="delete-preview">
                        <CancelIcon sx={{ fontSize: '18px', }} />
                    </span>

                </div>
            </>
        )
    };




    const UrlImageThumbnail = ({ src, onDelete }) => {
        return (<>
            <div className="image-thumbnail-container">
                <img className="image-thumbnail " src={src} alt="thumbnail" />
                <span onClick={() => onDelete(onDelete)} className="delete-preview">
                    <CancelIcon sx={{ fontSize: '18px', }} />
                </span>

            </div>

        </>)




    }





    const theme = createTheme({
        palette: {
            primary: {
                main: '#6622cc', // Active step background color
            },
            secondary: {
                main: '#000', // Active step label color
            },
        },
    });

    const stepweb = [
        {
            label: 'Provide details',
            description: `Please copy paste the website link of the project for which you need the leads`,
        },
        {
            label: 'Review details',
            description: "We have pulled and pre-filled the details of the property for your convenience. Kindly review/ edit/ add details in the form below."
        },
        {
            label: 'Confirm & Pay',
            description: null,
        },
    ];



    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('video/')) {
            setVideoFile(file);
            setVideoPreview(URL.createObjectURL(file));
            setUploadedVideoUrl(null); // Clear existing video URL
        } else {
            setVideoFile(null);
            setVideoPreview(null);
            alert('Please select a valid video file.');
        }
    };


    const handleDeleteVideo = () => {
        setVideoFile(null);
        setVideoPreview(null);
        setUploadedVideoUrl(null);
        videoFileInputRef.current.value = '';
    };


    const VideoThumbnail = ({ src, onDelete }) => {
        // console.log("srcvideo", src)
        return (
            <>

                <div className="image-thumbnail-container">

                    <video
                        src={src}
                        style={{ maxWidth: '130px', height: '70px', }}
                        muted
                        playsInline
                        disablePictureInPicture

                        controlsList="nodownload nofullscreen noremoteplayback"
                        onPlay={(e) => e.preventDefault()}
                    />



                    <span onClick={() => onDelete(onDelete)} className="delete-preview">
                        <CancelIcon sx={{ fontSize: '18px', }} />
                    </span>

                </div>
            </>
        )
    };

    const VideoUrlThumbnail = ({ src, onDelete }) => {
        // console.log("srcvideo", src)
        return (
            <>

                <div className="image-thumbnail-container">

                    <video
                        src={src}
                        style={{ maxWidth: '130px', height: '70px', }}
                        muted
                        playsInline
                        disablePictureInPicture

                        controlsList="nodownload nofullscreen noremoteplayback"
                        onPlay={(e) => e.preventDefault()}
                    />



                    <span onClick={() => onDelete(onDelete)} className="delete-preview">
                        <CancelIcon sx={{ fontSize: '18px', }} />
                    </span>

                </div>
            </>
        )
    };


    return (
        <>

            {modal || isLoading ? (<LoadingForReviewDetails />) : (
                <>
                    <div className="p-0 m-0 d-md-none">
                        <Navbar2 menu={true} />
                    </div>
                    <div className="container-fluid" id="reviewDetails">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-none d-md-block">
                                        <WebStepper steps={stepweb} active={1} />
                                    </div>

                                    {/* test */}

                                    {/* testend */}
                                    <div
                                        className="mt-4"
                                        style={{ paddingTop: 20, paddingBottom: 10 }}
                                    >
                                        <Box sx={{ width: "100%", }} className="mt-1 d-md-none">

                                            <ThemeProvider theme={theme}>
                                                <Stepper activeStep={1} alternativeLabel>
                                                    {steps.map((label) => (
                                                        <Step key={label} sx={{ '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: 'white' }, '& .MuiStepLabel-root .Mui-active': { color: 'rgba(102, 34, 204, 1)' }, '& .MuiStepLabel-root .Mui-completed': { color: 'rgba(102, 34, 204, 1)' } }}>
                                                            <StepLabel sx={{
                                                                '& .Mui-active': {
                                                                    color: 'secondary.main',
                                                                },
                                                                '& .Mui-completed': {
                                                                    color: 'secondary.main', // Active label color
                                                                },

                                                            }} style={{ fontSize: 10 }}>
                                                                {label}
                                                            </StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper>
                                            </ThemeProvider>
                                        </Box>
                                    </div>
                                </div>
                                {/* main form container */}
                                <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">



                                    <div className="form-card  mt-md-0 rounded">
                                        <div className="form">





                                            <div className="mb-3 ">
                                                <p className="heading-Property">Review Details</p>
                                                <p
                                                    style={{ textAlign: "justify", color: '#B0B0B0', fontWeight: '400' }}
                                                    className="heading-Property1"
                                                >
                                                    We have pulled & pre-filled the details of the property for your convenience. Kindly review/edit/add details as required
                                                </p>
                                            </div>

                                            <div className="d-flex flex-md-row flex-column  justify-content-between align-items-start ">


                                                <div style={{ width: '100%' }} className="pe-md-5 pe-0 mb-3">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Property Name*</b>
                                                    </label>
                                                    <div
                                                        className={
                                                            "input-container"
                                                            //  +(value.Name === "" && "validationFailed")
                                                        }
                                                    >
                                                        <img src={property} width={15} height={20} alt="" />
                                                        <input
                                                            value={value?.Name}
                                                            onChange={handleInputChange}
                                                            type="text"
                                                            className={"textInput-q"}
                                                            placeholder="Enter Property Name"
                                                            name="Name"
                                                            id="Name"
                                                            required
                                                        />
                                                        {value?.Name && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                    {errors?.Name && <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Property name is required</p>}

                                                </div>
                                                <div style={{ width: '100%' }} className=" pe-0 mb-3">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Location*</b>
                                                    </label>
                                                    <div
                                                        className="input-container "
                                                    // style={!value?.Address ? { border: "1px solid red" } : {}}
                                                    >
                                                        <img src={location} width={15} height={20} alt="" />
                                                        <input
                                                            value={value?.Address}
                                                            onChange={handleInputChange}
                                                            name="Address"
                                                            id="Address"
                                                            className={"textInput-q"}
                                                            type="text"
                                                            required
                                                            placeholder="Enter Property Location"
                                                        />
                                                        {value?.Address && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        {errors?.Address && <p className="  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Location is required</p>}
                                                        <p className="creative-location text-end">
                                                            "Location you want to show in the creative”

                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="mb-3 text-start d-flex justify-content-between align-items-ceter">
                                                <div>
                                                    <FormControl >
                                                        <FormLabel
                                                            id="demo-row-radio-buttons-group-label"
                                                            sx={{ color: "black" }}
                                                        >
                                                            <b style={{ fontSize: '16px', fontWeight: '500', color: 'black' }}>Property type*</b>
                                                        </FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"

                                                            value={value?.Property}
                                                            onChange={handleProperty}


                                                        >
                                                            <div
                                                                className={
                                                                    value?.Property === "Residential"
                                                                        ? "checkbox"
                                                                        : "checkbox1"
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    value="Residential"
                                                                    name="Residential"
                                                                    control={
                                                                        <Radio style={{ color: "#6622CC" }} />
                                                                    }
                                                                    label="Residential"
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    value?.Property === "Commercial"
                                                                        ? "checkbox"
                                                                        : "checkbox1"
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    value="Commercial"
                                                                    name="Commercial"
                                                                    control={
                                                                        <Radio style={{ color: "#6622CC" }} />
                                                                    }
                                                                    label="Commercial"
                                                                />
                                                            </div>
                                                        </RadioGroup>

                                                    </FormControl>
                                                    {errors?.Property && <p className="  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Property type is required</p>}

                                                    <div style={{ width: "100%", textAlign: "left" }}>
                                                        {propertyType?.map((item, index) => {
                                                            return (
                                                                <Button
                                                                    onClick={() => {
                                                                        handleFlat(item);
                                                                    }}
                                                                    sx={{ borderRadius: '14px', textTransform: 'capitalize', padding: '4px', marginLeft: '5px' }}
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor:
                                                                            item === value?.Flat ? "#6622CC" : "#ddd",
                                                                        color:
                                                                            item === value?.Flat
                                                                                ? "#ffffff"
                                                                                : "#000000",
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {item}
                                                                </Button>
                                                            );
                                                        })}
                                                        {<div>{(value?.Flat) ? '' : value?.Property ? <p className="  p-0 m-0 text-danger mb-1" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>choose type</p> : ''}</div>}

                                                    </div>
                                                </div>


                                            </div>

                                            <div className="d-flex flex-md-row  flex-column justify-content-between align-items-start">
                                                <div style={{ width: '100%' }} className="pe-md-5 pe-0 mb-3">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Size*</b>
                                                    </label>
                                                    <div
                                                        className="input-container "
                                                    // style={!value?.Size ? { border: "1px solid red" } : {}}
                                                    >
                                                        {value?.Property === "Residential" ? (
                                                            <img src={sizeimg} width={18} height={14} alt="" />
                                                        ) : (
                                                            <img src={area} width={18} height={18} alt="" />
                                                        )}
                                                        <input
                                                            type="text"
                                                            name="Size"
                                                            id="Size"
                                                            className="textInput-qp"
                                                            placeholder="Enter Available Sizes"
                                                            value={value?.Size}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        {value?.Size && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}

                                                    </div>
                                                    {errors?.Size && <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Size is required</p>}

                                                </div>

                                                <div style={{ width: '100%' }} className=" pe-0 mb-3">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Price Range*</b>
                                                    </label>
                                                    <div
                                                        className="input-container"
                                                    // style={!value?.Price ? { border: "1px solid red" } : {}}
                                                    >
                                                        <img src={rupes} width={12} height={14} alt="" />

                                                        <input
                                                            type="text"
                                                            name="Price"
                                                            id="Price"
                                                            placeholder="Enter Price Range"
                                                            className="textInput-q"
                                                            value={value?.Price}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        {value?.Price && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                    {errors?.Price && <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Price range is required</p>}

                                                </div>
                                            </div>

                                            <div>

                                                <label
                                                    htmlFor="weburl"
                                                    className="d-block text-start mb-2"
                                                >
                                                    <b style={{ fontWeight: '500', color: 'black' }}>Features/Amenities</b>
                                                </label>
                                                <div className="input-container p-0 m-0" style={{ border: 'none', }}>
                                                    <MuiChipsInput
                                                        value={Highlights}
                                                        onChange={handleChange}
                                                        sx={{
                                                            width: "100%",
                                                            borderRadius: '5px',
                                                            textOverflow: 'ellipsis',
                                                            overflowY: "auto",
                                                            padding: '0px',
                                                            '& .MuiChip-label': { color: 'white' },
                                                            '& .MuiInputBase-root': {
                                                                display: 'block',
                                                                textAlign: 'start',
                                                            },
                                                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#ccc', // Change border color when focused
                                                            },
                                                        }}
                                                        placeholder="Add more"
                                                    />
                                                </div>
                                                <p className="mb-3 creative-location">
                                                    Enter up to 5-7 key highlights of the property. This will be used in the creatives
                                                </p>
                                            </div>



                                            {/* <p className="text-start reviewpage-planoffer-p" style={{ color: 'rgb(176, 176, 176)' }}>The following fields would be used for the creatives and copies</p> */}

                                            <div className="d-flex gap-md-5 flex-md-row flex-column">
                                                <div style={{ width: '100%' }} className=" pe-0">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Payment Plan</b>
                                                    </label>
                                                    <div
                                                        className={
                                                            "input-container mb-3 "

                                                        }
                                                    >

                                                        <input
                                                            value={value?.payment_plan}
                                                            onChange={handleInputChange}
                                                            type="text"
                                                            className={"textInput-q"}
                                                            placeholder="Payment Plan"
                                                            name="payment_plan"
                                                            id="paymentplan"

                                                        />
                                                        {value?.payment_plan && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                </div>
                                                <div style={{ width: '100%' }} className=" pe-0">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Expected ROI</b>
                                                    </label>
                                                    <div
                                                        className={
                                                            "input-container mb-3 "

                                                        }
                                                    >

                                                        <input
                                                            value={value?.expected_roi}
                                                            onChange={handleInputChange}
                                                            type="text"
                                                            className={"textInput-q"}
                                                            placeholder="Expected ROI"
                                                            name="expected_roi"
                                                            id="expectedroi"

                                                        />
                                                        {value?.expected_roi && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="d-flex gap-md-5 flex-md-row flex-column">
                                                <div style={{ width: '100%' }} className=" pe-0">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Rera Number</b>
                                                    </label>
                                                    <div
                                                        className={
                                                            "input-container mb-3 "

                                                        }
                                                    >

                                                        <input
                                                            value={value?.rera_number}
                                                            onChange={handleInputChange}
                                                            type="text"
                                                            className={"textInput-q"}
                                                            placeholder="Rera Number"
                                                            name="rera_number"
                                                            id="reranumber"

                                                        />
                                                        {value?.rera_number && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                </div>

                                                <div style={{ width: '100%' }} className=" pe-0">
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >
                                                        <b style={{ fontWeight: '500', color: 'black' }}>Any Other Offers</b>
                                                    </label>
                                                    <div
                                                        className={
                                                            "input-container mb-3 "

                                                        }
                                                    >

                                                        <input
                                                            value={value?.other_offers}
                                                            onChange={handleInputChange}
                                                            type="text"
                                                            className={"textInput-q"}
                                                            placeholder="Other Offers"
                                                            name="other_offers"
                                                            id="otheroffers"

                                                        />
                                                        {value?.other_offers && (
                                                            <img src={donetext} width={14} height={14} alt="" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>




                                            {/* <div className="d-flex flex-md-row flex-column gap-md-4 justify-content-between align-items-start">
                                                <div style={{ width: '100%' }} className="mb-3 text-start pe-md-5 pe-0">
                                                    <FormControl>
                                                        <FormLabel id="demo-row-radio-buttons-group-label">
                                                            <b style={{ fontWeight: '500', color: 'black' }}>Status*</b>
                                                        </FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="Status"
                                                            value={value?.Status}
                                                            onChange={handleStatus}
                                                        >
                                                            <div
                                                                className={
                                                                    value?.Status === "Ready to move"
                                                                        ? "checkbox"
                                                                        : "checkbox1"
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    value="Ready to move"
                                                                    control={
                                                                        <Radio style={{ color: " #6622CC" }} />
                                                                    }
                                                                    label="Ready to move"
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    value?.Status === "Under Construction"
                                                                        ? "checkbox"
                                                                        : "checkbox1"
                                                                }
                                                            >
                                                                <FormControlLabel
                                                                    value="Under Construction"
                                                                    control={
                                                                        <Radio style={{ color: " #6622CC" }} />
                                                                    }
                                                                    label="Under Construction"
                                                                />
                                                            </div>
                                                        </RadioGroup>
                                                    {errors?.Status && <p className="text-start  p-0 m-0 text-danger" style={{fontSize:'12px', top:'10px', zIndex:'9'}}>Property Status is required</p>}


                                                    </FormControl>
                                                </div>
                                                <div style={{ width: '100%' }} className=" pe-0">
                                                    {value?.Status === "Under Construction" && (
                                                        <>
                                                            <label
                                                                htmlFor="weburl"
                                                                className="d-block text-start mb-2"
                                                            >
                                                                <b style={{ fontWeight: '500', color: 'black' }}>Possession Date</b>
                                                            </label>
                                                            <div
                                                                className="mb-3 input-container"
                                                            // style={
                                                            //   Dates?.length < 1 ? { border: "1px solid red" } : {}
                                                            // }
                                                            >
                                                                <img
                                                                    src={calander}
                                                                    width={20}
                                                                    height={20}
                                                                    alt=""
                                                                />
                                                                <DatePicker
                                                                    selected={Dates ? Dates : null}

                                                                    onChange={(date) => {
                                                                        setDates(date);
                                                                    }}
                                                                    // style={!Dates ? { border: "1px solid red" } : {}}
                                                                    // style={{ width: "90%" }}
                                                                    className="textInput-qp"
                                                                    showMonthYearPicker
                                                                    dateFormat="MMMM yyyy"
                                                                    placeholderText="Select Possession Month & Year"
                                                                />
                                                                {Dates && (
                                                                    <img
                                                                        src={donetext}
                                                                        width={14}
                                                                        height={14}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div> */}

                                            {/* own creative & design my creative */}
                                            <div className="d-flex flex-md-row flex-column justify-content-between align-items-start py-3">


                                                <FormControl>
                                                    <FormLabel id="demo-row-radio-buttons-group-label " sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                        <b className="" style={{ fontWeight: '500', color: 'black', textAlign: 'start' }}>Do you want to use your own creative?* { }</b>
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="Do you want to use your own creative"
                                                        value={value?.creativesFlag}
                                                        onChange={handleCreativeFlag}
                                                    >
                                                        <FormControlLabel value={true} name="true" sx={{ fontSize: { xs: '12px', sm: '12px', md: '16px', lg: '16px' }, margin: '0px' }} control={<Radio style={{ color: " #6622CC" }} />} label="Yes, I want to use my own creative" />
                                                        <FormControlLabel value={false} name="false" sx={{ fontSize: { xs: '12px', sm: '12px', md: '16px', lg: '16px', margin: '0px', textAlign: 'start' } }} control={<Radio style={{ color: " #6622CC" }} />} label="No, please design a creative for me" />


                                                    </RadioGroup>
                                                    {errors?.creativesFlag && <p className="text-start  p-0 m-0 mb-2 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Creative choice is required</p>}

                                                </FormControl>


                                            </div>
                                            {/* design my creative end */}
                                            <>



                                                {((value?.creativesFlag === true) || (value?.creativesFlag == 'true')) && (<>
                                                    <div>
                                                        <label
                                                            htmlFor="weburl"
                                                            className="d-block text-start mb-2"
                                                        >


                                                            <b style={{ fontWeight: '500', color: 'black' }}> Upload your creative*   </b>
                                                        </label>
                                                        <div>
                                                            <div className="image-upload-container ">

                                                                {imagesUrl?.length > 0 && (<>{imagesUrl?.map((url, index) => (
                                                                    <>
                                                                        {/* {console?.log(url)} */}
                                                                        {/* <img src={url} alt="" /> */}
                                                                        <UrlImageThumbnail
                                                                            key={index}
                                                                            src={url}
                                                                            onDelete={() => handleDeleteImagesUrl(index)}
                                                                        />

                                                                    </>



                                                                ))}</>)




                                                                }

                                                                {videosUrl?.length > 0 && videosUrl?.map((url, index) => (
                                                                    <>
                                                                        <VideoUrlThumbnail
                                                                           key={index}
                                                                            src={url?.videoUrl}
                                                                            onDelete={() => handleDeleteUrlPreview(index)}
                                                                        />
                                                                    </>
                                                                ))}

                                                                {previews?.map((preview, index) => (
                                                                    <>



                                                                        {preview?.file?.type.startsWith('image/') ? (
                                                                            <ImageThumbnail
                                                                                key={index}
                                                                                src={preview.url}
                                                                                onDelete={() => handleDeletePreview(index)}
                                                                            />
                                                                            // <img src={preview.url} alt={`Preview ${index}`} />
                                                                        ) : (
                                                                            <VideoThumbnail

                                                                                src={preview.url}
                                                                                onDelete={() => handleDeletePreview(index)}
                                                                            />
                                                                        )}


                                                                    </>
                                                                ))}







                                                                {/* {(images?.length ? images : propImages).map((src, index) => (
                                                                <>
                                                                    <ImageThumbnail
                                                                        key={index}
                                                                        src={src.preview ? src.preview : `${src}`}
                                                                        onDelete={() => handleDeleteImage(index)}
                                                                    />


                                                                </>

                                                            ))}

                                                            {videoPreview && (<>
                                                                <VideoThumbnail

                                                                    src={videoPreview}
                                                                    onDelete={handleDeleteVideo}
                                                                />

                                                            </>)} */}

                                                                <label className=" upload-box " style={{ cursor: 'pointer' }}>
                                                                    <div className="plus-icon">+</div>
                                                                    <input type="file" ref={fileInputRef} accept=".jpg, .jpeg, .png, .mp4" multiple className="" onChange={handleFilesChange} hidden />
                                                                </label>
                                                            </div>
                                                            
                                                        </div>

                                                        <p className='text-[10px] p-0 m-0 mt-1 text-start text-gray-400'>Image Format: JPG, JPEG, PNG | Video Format : MP4 | Image Size: Max 15MB | Video Size : Max 200MB | You Can Choose Up To 5 Files.  </p>
                                                        {errorss?.length > 0 && <p className="mt-1 m-0 p-0 text-start text-danger text-[12px]">{errorss}</p>}
                                                        <div className="d-flex justify-content-start align-items-center ">
                                                            {imageSizeError ? '' : <p className="creative-location">
                                                                {(value?.creativesFlag == 'true' || value?.creativesFlag == 'false') && <div>{(imagess?.length || videos?.length) ? '' : <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Select Image/Video</p>}</div>}


                                                            </p>}

                                                            <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>{imageSizeError ? 'File size must be below 15 MB.' : null}</p>

                                                        </div>
                                                    </div>

                                                    {/* .upload video */}


                                                    {/* <div>
                                                        <label
                                                            htmlFor="weburl"
                                                            className="d-block text-start mb-2"
                                                        >


                                                            <b style={{ fontWeight: '500', color: 'black' }}> Upload your creative(Video)   </b>
                                                        </label>

                                                        <div className="image-upload-container ">

                                                            {videoPreview && (<>
                                                                <VideoThumbnail

                                                                    src={videoPreview}
                                                                    onDelete={handleDeleteVideo}
                                                                />

                                                            </>)}

                                                            {uploadedVideoUrl && (

                                                                <VideoThumbnail

                                                                    src={uploadedVideoUrl}
                                                                    onDelete={handleDeleteVideo}
                                                                />
                                                            )
                                                            }
                                                            <label className=" upload-box " style={{ cursor: 'pointer' }}>
                                                                <div className="plus-icon">+</div>
                                                                <input type="file" ref={videoFileInputRef} accept="video/*" className="" onChange={handleVideoChange} hidden />
                                                            </label>
                                                        </div>

                                                       
                                                    </div> */}





                                                    {/* <p className="mb-3 creative-location">
                                                    {(value?.creativesFlag=='true' || value?.creativesFlag=='false') && <div>{images?.length?'':<p className="text-start  p-0 m-0 text-danger" style={{fontSize:'12px', top:'10px', zIndex:'9'}}>select image</p>}</div> }

                                                    </p> */}

                                                </>)}


                                                {((value?.creativesFlag === false) || (value?.creativesFlag == 'false')) && (<>
                                                    <label
                                                        htmlFor="weburl"
                                                        className="d-block text-start mb-2"
                                                    >


                                                        <b style={{ fontWeight: '500', color: 'black' }}> Upload project images/Ad*  </b>
                                                    </label>

                                                    <div className="image-upload-container ">
                                                        {(images2?.length ? images2 : propImages2).map((src, index) => (
                                                            <>
                                                                <ImageThumbnail
                                                                    key={index}
                                                                    src={src.preview ? src?.preview : `${src}`}
                                                                    onDelete={() => handleDeleteImage2(index)}
                                                                />


                                                            </>

                                                        ))}

                                                        <label className=" upload-box " style={{ cursor: 'pointer' }}>
                                                            <div className="plus-icon">+</div>
                                                            <input type="file" ref={fileInputRef2} accept="image/jpg, image/png, image/jpeg" className="" onChange={handleImageUpload2} hidden />
                                                        </label>
                                                    </div>

                                                    <p className='text-[10px] p-0 m-0 mt-1 text-start text-gray-400'>Image Format: JPG, JPEG, PNG | Size: Max 15MB</p>

                                                    <div className="d-flex justify-content-start align-items-center ">
                                                        {imageSizeError2 ? '' : <p className="creative-location">
                                                            {(value?.creativesFlag == 'true' || value?.creativesFlag == 'false') && <div>{images2?.length ? '' : <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>Select image</p>}</div>}


                                                        </p>}

                                                        <p className="text-start  p-0 m-0 mb-1 text-danger" style={{ fontSize: '12px', top: '10px', zIndex: '9' }}>{imageSizeError2 ? 'File size must be below 15 MB.' : null}</p>

                                                    </div>






                                                </>)}

                                            </>


                                            <p className="mb-3 creative-location" style={{ display: 'none' }}>
                                                *Amount you want to spend for this campaign
                                            </p>

                                            <div className="mt-3 mt-md-3 review-submit-btn-container d-flex justify-content-between">
                                                <div className='d-md-inline d-none' style={{ width: '48%' }}>
                                                    <Button variant='outlined' onClick={() => navigate("/property-details")} sx={{ border: '1px solid #6622CC', color: '#6622CC', padding: '10px 20px', width: '100%', }} startIcon={<ArrowBackIcon />}>Back</Button>
                                                </div>


                                                <NavLink to="/property-details" className="d-inline d-md-none" style={{ width: "50%" }}>
                                                    <button
                                                        className="submit-button-outline me-2 d-flex justify-content-center align-items-center"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <svg
                                                            minWidth="18"
                                                            className=""
                                                            height="11"
                                                            viewBox="0 0 21 11"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M6.207 0.292786C6.01947 0.105315 5.76516 0 5.5 0C5.23484 0 4.98053 0.105315 4.793 0.292786L0.292999 4.79279C0.105528 4.98031 0.000213623 5.23462 0.000213623 5.49979C0.000213623 5.76495 0.105528 6.01926 0.292999 6.20679L4.793 10.7068C4.9816 10.8889 5.2342 10.9897 5.4964 10.9875C5.7586 10.9852 6.00941 10.88 6.19482 10.6946C6.38023 10.5092 6.4854 10.2584 6.48767 9.99619C6.48995 9.73399 6.38916 9.48139 6.207 9.29279L3.5 6.49979H20C20.2652 6.49979 20.5196 6.39443 20.7071 6.20689C20.8946 6.01936 21 5.765 21 5.49979C21 5.23457 20.8946 4.98022 20.7071 4.79268C20.5196 4.60514 20.2652 4.49979 20 4.49979H3.5L6.207 1.70679C6.39447 1.51926 6.49979 1.26495 6.49979 0.999786C6.49979 0.734622 6.39447 0.480314 6.207 0.292786Z"
                                                                fill="#6622CC"
                                                            />
                                                        </svg>
                                                        &nbsp;
                                                        BACK
                                                    </button>
                                                </NavLink>

                                                <div style={{ width: "50%" }}>
                                                    <button
                                                        onClick={() => handleNextClick(state?.status)}
                                                        className="submit-button ms-2"
                                                        type="button"

                                                    >
                                                        NEXT &nbsp;
                                                        <svg
                                                            width="21"
                                                            height="11"
                                                            className="svg-inline"
                                                            viewBox="0 0 21 11"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M14.793 0.292786C14.9805 0.105315 15.2348 0 15.5 0C15.7652 0 16.0195 0.105315 16.207 0.292786L20.707 4.79279C20.8945 4.98031 20.9998 5.23462 20.9998 5.49979C20.9998 5.76495 20.8945 6.01926 20.707 6.20679L16.207 10.7068C16.0184 10.8889 15.7658 10.9897 15.5036 10.9875C15.2414 10.9852 14.9906 10.88 14.8052 10.6946C14.6198 10.5092 14.5146 10.2584 14.5123 9.99619C14.51 9.73399 14.6108 9.48139 14.793 9.29279L17.5 6.49979H1C0.734784 6.49979 0.48043 6.39443 0.292893 6.20689C0.105357 6.01936 0 5.765 0 5.49979C0 5.23457 0.105357 4.98022 0.292893 4.79268C0.48043 4.60514 0.734784 4.49979 1 4.49979H17.5L14.793 1.70679C14.6055 1.51926 14.5002 1.26495 14.5002 0.999786C14.5002 0.734622 14.6055 0.480314 14.793 0.292786Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    </button>
                                                    {incompleteForm && <p className="text-danger text-start ps-2 mb-3 mt-0 p-0 " style={{ fontSize: '14px' }}>Kindly fill all required fields.</p>}
                                                    {!isOnline && <p className="text-danger text-start ps-2 mb-3 mt-0 p-0 " style={{ fontSize: '14px' }}>You are offline.</p>}
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {modal ? "" : <div className="d-block d-md-none"><BottomNavbar isPressed={true} whichOnePressed={2} /></div>}
                </>

            )}
        </>

    );
}

export default ReviewDetails;
