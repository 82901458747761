import React from 'react';
import BrandLoader from '../asets/loaderv2.gif'

function PaymentLoader() {
  return (
    <>
      <div className='flex justify-center flex-col items-center' style={{width:'100%', height:'100vh'}}>
                <img src={BrandLoader} className='loading-payment' style={{ width: "200px", height: "200px" }} alt="" />
                <p className='text-center'>Loading Payment...</p>
      </div>
    
    </>
  )
}

export default PaymentLoader