import React, { useState } from 'react';
import './landingpage-component.css';
import previewimg from '../../asets/asetes/video img.png';
import './previewvideo3.css';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import partnerlogo from '../../asets/asetes/login-1-image (4).png'
import PopularClients from './PopularClients';

function PreviewVideo() {
  const [isPlaying, setIsPlaying] = useState(false);

  const url = 'https://www.youtube.com/watch?v=Z6eRVRwREl8';

  const handleClick = () => {
    setIsPlaying(true);
  };

  const handleClose = () => {
    setIsPlaying(false);
  };

  return (
    <>

      {!isPlaying ? (<>

        <div className="container-fluid preview-video-container m-0" style={{ border: 'none' }}>
          <div className="container">

            <div className="row">
              <div className='pb-3 m-0' style={{ width: '100%' }}>
                <p className='prev-h p-0 m-0 text-center'>
                  <span className='text-gradient'>Watch</span> the AI Magic
                </p>
                <p className='prev-p p-0 m-0 text-center'> Get. Set. Leap</p>
              </div>
              <div className="row">

                <div className="col-12 d-flex justify-content-center pb-0">
                  <div className="preview-video-inner-container position-absol pb-0">
                    <div className="preview-img-containr pb-0">
                      <img
                        src={previewimg}
                        alt='img'
                        onClick={handleClick}
                        className='clickable-image'
                        style={{ height: 'auto', maxWidth: '100%' }}
                      />
                    </div>
                  </div>
                </div>

              </div>

            </div>


            <div className="row">
              <div className='col-12 pt-md-4 pt-3' style={{ background: '' }}>
                <p className='importantoutcome-p my-3 my-md-0 pt-0 pb-0 text-center'>Supported by</p>
                <div className='text-center ' style={{ width: '100%' }}>
                  <img src={partnerlogo} className='partnerlogo' alt="" />
                </div>
              </div>
            </div>


          </div>
          <div className="row">
          <PopularClients/>
          </div>
        </div>
        

      </>) : (<>
        <div className="container-fluid  m-0" style={{ border: 'none' }}>
         
            <div className="row">
              <div className="row ">
                <div className="col-12 video-player-container">
                  <button className="close-button" onClick={handleClose}>
                    <CloseIcon />
                  </button>
                  <div className="video-player-wrapper" style={{maxHeight:'80vh', width:'100%'}}>
                    <ReactPlayer
                      className="react-player"
                      
                      url={url}
                      playing={true} // Automatically play video
                      width="100%"
                      height="100%"

                    // controls={true}
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 pt-md-4 pt-3' style={{ background: '' }}>
                <p className='importantoutcome-p my-3 my-md-0 pt-0 pb-0 text-center'>Supported by</p>
                <div className='text-center ' style={{ width: '100%' }}>
                  <img src={partnerlogo} className='partnerlogo' alt="" />
                </div>
              </div>
            </div>

            <div className="row">
          <PopularClients/>
          </div>

        </div>

      </>)}



    </>
  );
}

export default PreviewVideo;
