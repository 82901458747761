import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';

function FrequentalyAskedQuestions() {
  const [expanded, setExpanded] = React.useState(null);
  const [showAll, setShowAll] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
  };

  const faqsArray = [
    { que: "What is LeapX's role in enhancing real estate Ad campaigns?", ans: "LeapX streamlines the advertising process for real estate professionals, including Realtors, Channel Partners, Dealers, and Brokers. Our AI-driven platform offers a straightforward and efficient way to produce high-quality, genuine result from prominent platforms like Facebook and Google (coming soon). Designed with user-friendliness in mind, LeapX ensures that even those with no background in marketing can effectively utilize the service to expand their business outreach." },
    { que: 'How can I begin creating real estate advertisements with LeapX?', ans: "Getting started with LeapX is easy. First, become a member by opting for our inaugural offer, which allows the first 50 users to subscribe at a special annual rate of just Rs 0 for the entire year. After this promotional period, the subscription fee will be Rs 9,999 monthly. Upon completing your subscription, you'll receive a confirmation email granting you access to the platform. With that, you're all set to leverage LeapX to enhance and grow your real estate business." },
    { que: 'Is LeapX suitable for those inexperienced with digital advertising?', ans: "Absolutely. LeapX is tailored for ease of use, ensuring that even individuals with no background in digital advertising can harness the platform to foster business growth. Our AI takes care of the complex tasks, requiring only your business details to begin. Simply provide your project's website URL and images, and LeapX will handle the rest, crafting and launching effective advertising campaigns on your behalf" },
    { que: 'What online advertising platforms are compatible with LeapX?', ans: "At present, as we are in the beta phase, LeapX supports advertising exclusively on Facebook. However, we are actively working to incorporate Google's diverse advertising channels, which will enable you to extend your campaigns to Google Search, Display, and Video soon" },
    { que: 'What methods does LeapX employ to target my ads accurately?', ans: "LeapX utilizes a sophisticated combination of industry-leading creative strategies, media buying practices, data analytics, and optimization techniques to ensure that your ads connect with the most relevant audience right from the start. Our proprietary algorithm takes into account various factors such as the property's location, price, and features to identify the ideal consumer profile. This targeted approach ensures that your property resonates with potential buyers, leading to optimal campaign results" },
    { que: 'Does LeapX offer a beginner-friendly guide to using the platform?', ans: "Absolutely. While LeapX is intuitively designed to ensure ease of use for first-time users, we also provide a comprehensive step-by-step guide that includes both written documentation and video tutorials. Every subscriber receives access to these resources, along with ongoing support to help you make the most of our platform." },
    { que: 'What support is available if I encounter difficulties with LeapX?', ans: "We are committed to providing seamless assistance. If any issue arises while using LeapX, you can contact us via email, message, or phone call. Our dedicated support team is responsive and eager to help, ensuring a turnaround time of 2 to 4 business hours for addressing your queries." },
    { que: 'What level of autonomy do I have in managing my ads on LeapX?', ans: "LeapX is designed to demystify digital advertising, providing you with full control over your campaigns. You have the flexibility to set your budget, choose the Facebook page for your ads, and pause or stop your campaigns as needed. For added convenience, there's also the option to export lead information directly to your email for easy access and follow-up" },
    { que: 'Does LeapX offer pricing plans that cater to various sizes of real estate businesses?', ans: "LeapX maintains a uniform pricing model to accommodate businesses of all scales, with an monthly standard rate of Rs 9,999. We are currently offering a special promotional rate of Rs 0 per year for the first 50 subscribers as part of our early access beta program." },
    { que: 'What outcomes can I anticipate by utilizing LeapX for my real estate campaigns?', ans: "LeapX has established benchmarks that showcase a 53% improvement in cost efficiency a 21% increase in quality over our competitors. Our platform is engineered with an 'objective-first' approach, ensuring that every facet from decision-making to data modelling is fine-tuned for performance optimization. We advise users to actively engage with our CRM system, particularly to highlight and track 'hot' leads. Such interactions enhance our algorithm's ability to refine audience targeting, progressively improving the quality of results based on your feedback." },
    {que:"What is the minimum spend required to launch a campaign on LeapX?", ans:"LeapX offers two types of campaign creation. Sell & Lease (coming soon) For Selling, the minimum amount for initiation of ad campaigns is Rs 10,000. For Leasing (coming soon), the  minimum amount stands at Rs 5,000. Each campaign must span at least 15 days. The duration & budget are strategically chosen to provide sufficient data for the campaign's performance optimisation. Initially, the cost per lead may be above the estimated average, but as the campaign accrues more data over time, these costs typically become more efficient and stable."},
  ];

  const questionsToShow = showAll ? faqsArray : faqsArray.slice(0, 5);

  return (
    <div className="container-fluid faqs-container">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className='faqs-left'>
              <div className='faqs-left-h'>
                <p className='text-center text-md-start'><span className='text-gradient'>Frequently</span> Asked Questions</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className='faqs-inner-right '>
              {questionsToShow.map((val, idx) => (
                <Accordion
                  key={idx}
                  expanded={expanded === idx}
                  onChange={handleChange(idx)}
                  sx={{ backgroundColor: 'rgba(255, 255, 255, 0.04)', className: 'mt-2' }}
                >
                  <AccordionSummary
                    expandIcon={expanded === idx ? <RemoveIcon sx={{ color: 'rgba(255, 255, 255, 0.55)' }} /> : <AddIcon sx={{ color: 'rgba(255, 255, 255, 0.55)' }} />}
                    aria-controls={`panel${idx + 1}-content`}
                    id={`panel${idx + 1}-header`}
                    sx={{ border: '1px solid rgba(255, 255, 255, 0.06)' }}
                  >
                    <Typography sx={{ color: 'white', fontSize: '16px', fontWeight: '400', fontFamily: "'Inter', 'sans-serif'" }}>{val.que}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: '#CCCCCC', fontSize: '15px' }}>
                      {val.ans}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
              <div className="text-end mt-3">
                <Button variant="contained" size='small' sx={{background:'rgba(255, 255, 255, 0.04)', color:'rgba(255, 255, 255, 0.55)', fontSize:'12px', textTransform:'capitalize',  '&:hover': {
      background: 'rgba(255, 255, 255, 0.04)',
      color: 'rgba(255, 255, 255, 0.55)',
    },
    '&:active': {
      background: 'rgba(255, 255, 255, 0.04)',
      color: 'rgba(255, 255, 255, 0.55)',
    },}}
                
                onClick={handleToggleShowAll}>
                  {showAll ? 'Show Less' : 'Show More'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FrequentalyAskedQuestions;
