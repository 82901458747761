import { Accordion, AccordionDetails, AccordionSummary, Box, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import Navbar2 from "../../navbar/Navbar2";
import "./adOverViewPage.css";
import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkIcon from '@mui/icons-material/Link';
import Groups2Icon from '@mui/icons-material/Groups2';
// import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import Tooltip from '@mui/material/Tooltip';
// import LocationOnIcon from "@mui/icons-material/LocationOn";
import { parseISO, isBefore, isAfter, isDate } from 'date-fns';
import StopIcon from "@mui/icons-material/Stop";
import { useLocation, useNavigate } from "react-router-dom";
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { cn } from "../../lib/utils"
import ToolTip from "../../components/ToolTip/ToolTip";
import { AuthContext } from "../../AuthProvider";
import Axios from "../../Axios";
// import { Provider, defaultTheme } from '@adobe/react-spectrum';
//import WebHomeIpressionViewSpentCard from "../../adesktop-webpage/web-homepage/web-homepage-component/WebHomeIpressionViewSpentCard";
import ForWebImpression from "./ForWebImpression";
// import OverviewCard from "./component/OverviewCard";
import AlertDialog from "./AlertDialog";
// import { DateRangePicker } from "@adobe/react-spectrum";
// import OverviewGraph from "../../adesktop-webpage/web-adcampoverview-page/overview-graph/OverviewGraph";
// import { parseDate } from '@internationalized/date';
import { AdPreviewSliderCard } from "./component/AdPreviewSliderCard";
import OverviewCampaigninfoCard from "./component/OverviewCampaigninfoCard";
import { Calendar } from "../../components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover"
import { Button } from "../../components/ui/button";
import AddBudgetForCampaign from "./addbudget/AddBudgetForCampaign";


// const dataPoints = [
//   { date: "01 Nov", value: 6 },
//   { date: "02 Nov", value: 10 },
//   { date: "03 Nov", value: 7 },
//   { date: "04 Nov", value: 20 },
//   { date: "05 Nov", value: 25 },
//   { date: "06 Nov", value: 14 },
//   { date: "07 Nov", value: 21 },
//   { date: "08 Nov", value: 15 },
//   { date: "09 Nov", value: 5 },
//   { date: "10 Nov", value: 25 },
//   { date: "11 Nov", value: 14 },
//   { date: "12 Nov", value: 21 },
//   { date: "13 Nov", value: 15 },
//   { date: "14 Nov", value: 5 },
// ];


function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

const dataPoints4Message = [

];





const AdOverViewPage = ({ className }) => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const { userToken, fbToken, allFbData } = useContext(AuthContext);
  // const [websiteLink, setWebsiteLink] = useState("");
  const [chartOptionFilterValue, setChartOptionsFilterValue] = useState("last 7 days");
  const [isStopped, SetIsStopped] = useState(true)
  const [keyHeighlightChips, setKeyHeighlightChips] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loadImpression, setLoadImpression] = useState(false)
  const [resultSpend, setResultSpend] = useState(0)
  const [filteredCampaign, setFilteredCampaign] = useState([])
  const [graphData, setGraphData] = useState([])
  const [getCreativeUrl, setGetCreativeUrl] = useState([])
  const [getInstagramPreview, setInstagramPreview] = useState([])
  const [budgetPrice, setBudgetPrice] = useState(0)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [datePints, setDatePints] = useState([])
  const [openGraphFilter, setOpenGraphFilter] = useState(false);
  // console.info(campaignData.id, "id");
  const [dateStartEnd, setDateStartEnd] = useState({ startDate: null, endDate: null })
  const [projectData, setProjectData] = useState({});
  const [totalleadsData, setTotalleadsData] = useState(0)
  const [campaingfbId, setCampaingfbId] = useState("")
  const [dateRangeForFilter, setDateRangeForFilter] = useState({})
  const [campaignIdForAddBudget, setCampaignIdForAddBudget] = useState("")
  const [addBudgetInfo, setAdBudgetInfo] = useState({})
  const [campaignInfo, setCampaignInfo] = useState({
    campaignName: "",
    campaignDbId: "",
    campaignId: "",
    campaignStatus: "",

  })

  // const [date, setDate] = useState({
  //   from: new Date(2022, 0, 20),
  //   to: addDays(new Date(2022, 0, 20), 20),
  // })
  const [dateRange, setDateRange] = useState({ from: null, to: null });


  const [campaignDataForGraph, setCampaignDataForGraph] = useState([])

  const [campaign, setCampaign] = useState({});

  const [impressionClicksSpent, setImpressionClicksSpent] = useState({})

  // effect for projectData

  const formatName = (name) => {

    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');

    trimmedName = trimmedName.replace(/_/g, ' ').trim();

    return trimmedName.replace(/\s+/g, ' ');
  };
  //key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, ' ')

  useEffect(() => {

    const filterCampaign = allFbData?.filter((val) => val?.id === location?.state?.cmpid)

    setLoadImpression(true)

    if (filterCampaign?.length > 0) {
      //  setCampaignIdForAddBudget()
      setCampaignInfo({ ...campaignInfo, campaignName: filterCampaign[0]?.name, campaignStatus: filterCampaign[0]?.status})
      // console.log("filtercamp", filterCampaign[0])
       setCampaignIdForAddBudget(filterCampaign[0]?.dbId)
       setCampaingfbId(filterCampaign[0]?.id)
      const AddBudgetInfo = {start_date:filterCampaign[0]?.start_time || '', end_date:filterCampaign[0].stop_time || ''}
          if(AddBudgetInfo){
             setAdBudgetInfo(AddBudgetInfo)
          }else{

          }
      setCampaignDataForGraph(filterCampaign[0])
      // console.log("filtercampaign", filterCampaign[0]?.insights?.data?.slice(0, 5))
      const data = filterCampaign[0]?.insights?.data || 0
      const startingdate = data[0]?.date_start || null
      const enddate = data[data?.length - 1]?.date_start
      setDateStartEnd((prev) => ({ ...prev, startDate: startingdate, endDate: enddate }))

      if (data) {
        const datapoints = data?.map(item => {
          const leadAction = item.actions?.find(action => action.action_type === "lead");
          const leadValue = leadAction ? parseInt(leadAction.value, 10) : 0;
          const date = new Date(item.date_start).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short'
          }).replace(' ', ' ');
          return { date, value: leadValue };
        });

        if (datapoints) {
          // console.log("datawithmessing", datapoints)
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

          function parseDate(dateStr) {
            const [day, month] = dateStr.split(' ');
            return new Date(`2024-${monthNames.indexOf(month) + 1}-${day}`);
          }

          function addMissingDates(data) {
            // Create a map of existing dates and their values
            const dateMap = new Map(data.map(item => [item.date, item.value]));

            // Determine the range of dates to fill
            const startDate = parseDate(data[0].date);
            const endDate = parseDate(data[data.length - 1].date);
            const result = [];

            // Iterate through each day in the range
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
              const day = String(d.getDate()).padStart(2, '0');
              const month = monthNames[d.getMonth()];
              const formattedDate = `${day} ${month}`;

              // Check if the date exists in the original data
              if (dateMap.has(formattedDate)) {
                const originalValue = dateMap.get(formattedDate);
                result.push({ date: formattedDate, value: originalValue === 0 ? 0 : originalValue });
              } else {
                result.push({ date: formattedDate, value: null }); // Insert null for missing dates
              }
            }

            return result;
          }

          const filledData = addMissingDates(datapoints);
          if (filledData) {
            setDatePints(filledData)
            // console.log("filldata", filledData)
          }






          //  console.log("datapoints", datapoints.slice(0, 30))
        }

      }
    } else {
      setDatePints([])
    }

    let graphimpression = {
      impression: 0,
      views: 0,
      spend: 0,
      linkClicks: 0,

    };



    let totalleads = 0


    filterCampaign[0]?.insights?.data?.forEach((val) => {

      // console.log("spend", val?.spend)

      totalleads = totalleads + (parseInt(val?.actions?.length ? val?.actions?.find((action) => action?.action_type === 'lead')?.value || 0 : 0));

      graphimpression.impression = graphimpression.impression + (val?.impressions ? Number(val?.impressions) : 0);

      //clicks

      graphimpression.views = graphimpression.views + (val?.clicks ? Number(val?.clicks) : 0);
      //spent
      graphimpression.spend += val?.spend ? Number(val.spend) : 0;

      // link clicks
      graphimpression.linkClicks = graphimpression.linkClicks + (parseInt(val?.actions?.length > 0 ? val?.actions?.find((action) => action?.action_type === 'link_click')?.value || 0 : 0));


    });


    if (totalleads) {
      setTotalleadsData(totalleads)
    }

    if (graphimpression) {
      setImpressionClicksSpent(graphimpression)
      setResultSpend(graphimpression?.spend)
      //  console.log("graph imp", graphimpression)
    }


    let graphData1 = [];

    const result = filterCampaign[0]?.insights?.data
    if (result) {
      setFilteredCampaign(result)
      setLoadImpression(false)
      const spend = Number(result[0]?.spend)

      const resultspend = spend
      // setResultSpend(resultspend)
      // console.log("filter",filterCampaign )




    }
    const budget = Number(filterCampaign[0]?.lifetime_budget)
    //  console.log("budget", budget)
    if (budget) {

      setBudgetPrice(budget)
    }
    // console.log("idcam", location?.state?.cmpid)

    //for graph card
    if (filterCampaign?.length) {
        // console.log("camadata", filterCampaign)
      filterCampaign[0]?.ads?.data?.forEach((val) => {
        val?.leads?.data.forEach((lead) => {
          //  console.log("leadgra", lead);
          if (lead?.created_time !== null || lead?.created_time !== "") {
            //  console.log("time", lead?.created_time);
            graphData1.push(lead?.created_time);
          }
        });
      });
    }
    if (graphData1?.length > 0) {

      function getFormattedDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        return date.toLocaleDateString('en-US', options);
      }

      // Function to count occurrences of dates within the last 14 days
      function countOccurrences(dates) {
        const dateCounts = {};
        const currentDate = new Date();

        for (const date of dates) {
          const currentDateObj = new Date(date);
          const diffTime = Math.abs(currentDate - currentDateObj);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays <= 360) {
            const formattedDate = getFormattedDate(date);
            dateCounts[formattedDate] = (dateCounts[formattedDate] || 0) + 1;
          }
        }

        return dateCounts;
      }

      // Function to generate data points array
      function generateDataPoints(dateCounts) {
        const dataPoints = [];
        for (const date in dateCounts) {
          dataPoints.push({ date, value: dateCounts[date] });
        }
        return dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date));
      }

      // Count occurrences for dates within the current year
      const dateCounts = countOccurrences(graphData1);

      // Generate data points array
      const datapoints = generateDataPoints(dateCounts);

      // Output data points

      if (datapoints?.length) {
        setDatePints(datapoints)
      }
      //end new calculation for graph



      const formattedDates = graphData1?.map((lead) => {
        const date = new Date(lead);
        const formattedDate = date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        });
        return formattedDate;
      });
      // console.log("fromatterdate", formattedDates);
      if (formattedDates) {
        const resultArray = [];

        const dateCountMap = new Map();

        formattedDates?.forEach((date) => {
          if (dateCountMap.has(date)) {
            dateCountMap.set(date, dateCountMap.get(date) + 1);
          } else {
            dateCountMap.set(date, 1);
          }
        });

        dateCountMap.forEach((value, key) => {
          const formattedDate = key; // You can customize this if needed
          resultArray.unshift({ date: formattedDate, value });
        });

        if (resultArray.length) {
          // console.log("formats", resultArray);
          setGraphData(resultArray);
        }

      }
    }

  }, [allFbData, location?.state?.campaign_id])




  useEffect(() => {

    const gettFormPropertyData = () => {
      Axios.get("/get-campaign-property", {
        headers: { Authorization: userToken },
        params: {
          // campaign_id: campaignId,
          campaign_id: location?.state?.campaign_id,
        },
      })
        .then((response) => {
          if (!response.data.valid) {
            alert("Failed to fetch project data");
            return;
          } else {
            setCampaign(response?.data?.campaign);
            setProjectData(response?.data?.message);
            setKeyHeighlightChips(
              response.data.message?.key_highlights?.split(",").map((key) => {
                return key.trim();
              })
            );
            console.log(response.data);

          }
        })
        .catch((err) => {
          console.error(`request failed: ${err.message}`);
        });
    }
    gettFormPropertyData()
  }, [location?.state?.campaign_id]);
  //console.log(campaign, "camaping - message, campaign");
  // effect for campaign data




  const createGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(0, "rgba(255, 0, 0, 1)"); // Start color
    gradient.addColorStop(1, "rgba(0, 0, 255, 1)"); // End color
    return gradient;
  };




  // effect for chart
  useEffect(() => {
    let chartLabels;
    let chartValues4leads;
    let chartValues4message;

    if (chartOptionFilterValue === "last 7 days") {
      chartValues4leads = datePints.slice(-7).map((dp) => dp.value);
      chartValues4message = dataPoints4Message.slice(-7).map((dp) => dp.value);
      chartLabels = datePints.slice(-7).map((dp) => dp.date);
    } else if (chartOptionFilterValue === "last 14 days") {
      chartValues4leads = datePints.slice(-14).map((dp) => dp.value);
      chartValues4message = dataPoints4Message.slice(-14).map((dp) => dp.value);
      chartLabels = datePints.slice(-14).map((dp) => dp.date);
    } else if (chartOptionFilterValue === "last 28 days") {
      chartValues4leads = datePints.slice(-28).map((dp) => dp.value);
      chartValues4message = dataPoints4Message.slice(-28).map((dp) => dp.value);
      chartLabels = datePints.slice(-28).map((dp) => dp.date);
    } else if (chartOptionFilterValue === "custom day") {
      // console.log("setDateRangeForFilter", dateRangeForFilter);
      const fromDateStr = dateRangeForFilter?.from ? dateRangeForFilter.from : "2024-03-02T18:30:00.000Z";
      const toDateStr = dateRangeForFilter?.to ? dateRangeForFilter.to : "2024-03-21T18:30:00.000Z";
      const fromDate = new Date(fromDateStr);
      const toDate = new Date(toDateStr);

      const monthMap = {
        Jan: 0,
        Feb: 1,
        Mar: 2,
        Apr: 3,
        May: 4,
        Jun: 5,
        Jul: 6,
        Aug: 7,
        Sep: 8,
        Oct: 9,
        Nov: 10,
        Dec: 11,
      };

      const currentYear = fromDate.getFullYear();

      const filteredData = datePints?.filter((item) => {
        const [day, month] = item.date.split(" ");
        const itemDate = new Date(currentYear, monthMap[month], parseInt(day));
        return itemDate >= fromDate && itemDate <= toDate;
      });

      if (filteredData) {
        chartValues4leads = filteredData.map((dp) => dp.value);
        chartLabels = filteredData.map((dp) => dp.date);
      }
    } else {
      chartValues4leads = datePints.map((dp) => dp.value);
      chartValues4message = dataPoints4Message.map((dp) => dp.value);
      chartLabels = datePints.map((dp) => dp.date);
    }

    const maxLabelsToShow = 8;
    const stepSize = chartLabels.length > maxLabelsToShow ? Math.ceil(chartLabels.length / maxLabelsToShow) : 1;

    const data = {
      labels: chartLabels,
      datasets: [
        {
          label: "Leads",
          fill: true,
          backgroundColor: "transparent",
          yAxisID: "y",
          tension: 0.4,
          data: chartValues4leads,
          borderColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return null;
            }
            return createGradient(ctx, chartArea);
          },
          pointRadius: 1,
          pointHoverRadius: 7,
          pointBackgroundColor: "white",
          pointBorderColor: "rgb(255, 0, 255)",
          pointHoverBackgroundColor: "rgb(255, 0, 255)",
          pointHoverBorderColor: "white",
        },
      ],
    };

    const verticalLinePlugin = {
      id: "verticalLinePlugin",
      afterDraw: (chart, args, options) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const ctx = chart.ctx;
          const x = chart.tooltip._active[0].element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 10;
          ctx.strokeStyle = "rgba(0, 0, 0, 0.9)";
          ctx.setLineDash([5, 5]);
          ctx.stroke();

          ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
          ctx.fillRect(0, topY, x, bottomY - topY);

          ctx.restore();
        }
      },
    };

    setChartData(data);

    setChartOptions({
      maintainAspectRatio: false,
      aspectRatio: 1.3,
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          autoSkip: false,
          maxTicksLimit: maxLabelsToShow,
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 1, // Ensure at least 8 ticks
            callback: function (val, index) {
              return this.getLabelForValue(val);
            },
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Leads",
          },
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 10,
            max: 40,
          },
        },
      },
      plugins: {
        tooltip: {
          mode: "index",
          intersect: false,
        },
        legend: {
          display: false,
        },
        verticalLinePlugin,
      },
      elements: {
        line: {
          tension: 0.3,
        },
      },
    });
  }, [chartOptionFilterValue, datePints?.length, dateRangeForFilter]);


  // campaign specific data api
  // const campaign_id = 120201393700220573;


  //resume campaign
  const handleCampaignResume = async () => {
    const body = {
      access_token: fbToken,
      campaign_id: campaign?.campaign_id,
      status: "STOPPED",
    };
    try {
      const response = await Axios.post("/resume-campaign", body, {
        headers: {
          Authorization: userToken,
        },
      });
      if (response.status === 200) {
        const data = response.data;
        // console.log("resume", data);
        alert(data?.message);
        window.location.reload()
      }
    } catch (error) {
      alert("resume campaign failed");
      console.log(error.message);
    } finally {
    }
  };
  //stop camaign
  const handleCampaignStop = async () => {
    const body = {
      access_token: fbToken,
      campaign_id: `${campaign?.campaign_id}`,
      status: "STOPPED",
    };
    try {
      const response = await Axios.post("/update-campaign", body, {
        headers: {
          Authorization: userToken,
        },
      });
      if (response.status === 200) {
        const data = response.data;
        alert(data?.message);
        window.location.reload()
        //  console.log("resume", data);
        // navigate("/")
      }
    } catch (error) {
      alert("stop campaign failed");
      console.log(error.message);
    } finally {
    }
  };
  // campaign- pause
  const handleCampaignPause = async () => {
    const body = {
      access_token: fbToken,
      campaign_id: campaign?.campaign_id,
      status: "PAUSED",
    };
    try {
      const response = await Axios.post("/update-campaign", body, {
        headers: {
          Authorization: userToken,
        },
      });
      if (response.status === 200) {
        const data = response.data;
        alert(data?.message);
        window.location.reload()
        // console.log("resume", data);
      }
    } catch (error) {
      alert("pause campaign failed");
      console.log(error.message);
    } finally {
    }
  };
  const isDraft = campaign?.status === "DRAFT" ? "none" : "";

  //console.log("graphdata", filteredCampaign)

  //console.log("camp", campaign)
  //convert in indian rupee
  function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }


  useEffect(() => {
    if (location?.state?.cmpid) {
      getCampaignCreative(location?.state?.cmpid)
      getInstagramCreative(location?.state?.cmpid)
    }

  }, [location?.state?.cmpid])

  const getCampaignCreative = async (id) => {
    setPreviewLoading(true)
    try {
      const res = await Axios.post("/get-campaign-creatives", { campaign_id: id }, { headers: { Authorization: userToken } })

      if (res.status === 200) {
        const creativeData = res?.data?.creatives
        const creativeinObj = creativeData?.map(item => {
          const iframeObjects = item?.data?.map(innerItem => ({
            body: innerItem.body
          }));
          return iframeObjects;
        }).flat();

        if (creativeinObj?.length > 0) {
          setGetCreativeUrl(creativeinObj)
        }
      }
    } catch (error) {
      console.log(error?.message)
    } finally {
      setPreviewLoading(false)
    }
  }

  const getInstagramCreative = async (id) => {
    setPreviewLoading(true)
    try {
      const res = await Axios.post("/get-instagram-ad", { campaign_id: id }, { headers: { Authorization: userToken } })

      if (res.status === 200) {
        // console.log("res.data", res.data)

        const creativeData = res.data.creatives
        const creativeinObj = creativeData?.map(item => {
          const iframeObjects = item?.data?.map(innerItem => ({
            body: innerItem.body
          }));
          return iframeObjects;
        }).flat();

        if (creativeinObj?.length > 0) {
          setInstagramPreview(creativeinObj)
        }
      }
    } catch (error) {
      console.log(error?.message)
    } finally {
      setPreviewLoading(false)
    }
  }


  const handleSelect = (range) => {
    setDateRange(range);
  };

  const handleUpdate = () => {
    setDateRangeForFilter(dateRange)
    // console.log('Selected Date Range:', dateRange);
    setChartOptionsFilterValue("custom day");
    setOpenGraphFilter(false);


  };

  const datestart = dateStartEnd?.startDate ? dateStartEnd?.startDate : '2024-02-14';
  const dateend = dateStartEnd?.endDate ? dateStartEnd?.endDate : '2024-03-22'
  //  console.log("datestart", datestart)
  //  console.log("dateend", dateend)
  const dateStart = parseISO(datestart);
  const dateEnd = parseISO(dateend);

  const isDateDisabled = (date) => {
    return isBefore(date, dateStart) || isAfter(date, dateEnd);
  };

  //    console.log("isdateDisable", isDateDisabled)
  // console.log("campaignInfo", campaignInfo)
  return (
    <>
      <div className="row p-0">
        <div className="p-0 m-0 col-12 col-md-7 col-lg-7 col-xl-8 ">
          <div className="row p-0 m-0">
            <div className="p-0 m-0 col-12 col-lg-12  col-lg-12 col-xl-8 ">
              <div className="add-overviewPage ps-2 p-md-0 pt-md-2 m-0">
                <div className="d-md-none"> <Navbar2 menu={true} /></div>
                <Box position={"relative "}>
                  <Box
                    height={"172px"}
                    sx={{
                      borderRadius: "0px 0px 16px 16px",
                      background: "linear-gradient(106deg, #62C 7.35%, #C2A7EB 193.15%)",
                      padding: "20px",
                    }}
                    className="d-md-none"
                  >
                    <Typography component={"h1"} variant="h6" sx={{ color: "white" }} className="">
                      Ad campaign overview
                    </Typography>
                  </Box>
                  <Box
                    paddingInline={2}
                    position={"static"}
                    marginTop={{ xs: '-100px', sm: '8px' }}
                    marginBottom={{ xs: '8px', sm: '8px' }}
                    width="100%"
                  >
                    <Box

                      display={isDraft}
                      sx={{
                        borderRadius: "20px",
                        background: "#F7F4F3",
                        boxShadow: "0px 4px 23.2px 0px rgba(0, 0, 0, 0.20)",
                        padding: "15px 10px",
                      }}
                      className=""
                    >
                      <div className="header">
                        <div className="header-left">
                          <span
                            className=" d-flex align-items-center justify-content-center me-1"
                            style={{
                              height: "30px",
                              width: "30px",
                              backgroundColor: "#62c",
                              borderRadius: "50%",
                            }}
                          >
                            <span style={{ color: "white" }}>
                              {campaignInfo?.campaignName ? campaignInfo?.campaignName?.slice(0, 1) : ''}
                            </span>
                          </span>
                          <div className="header-left-right">
                            <h3 className=""
                              style={{ width: '', fontWeight: '600', fontSize: '14px' }}>{campaignInfo?.campaignName ? campaignInfo?.campaignName?.length > 28 ? campaignInfo?.campaignName.slice(0, 28) + '...' : campaignInfo?.campaignName : ''}</h3>
                            <Typography
                              component={"span"}
                              fontWeight={600}
                              fontSize={"12px"}
                            >
                              {/* #59 */}
                              {/* {"#" + campaign?.campaign_id} */}
                            </Typography>
                          </div>
                        </div>
                        <div className="header-right d-flex justify-content-center flex-column align-items-center">

                          <div className="row2 py-1" style={{ width: '70px' }}>
                            <img src="socialIcons/fbLogo.svg" alt="facebook" />
                            <img style={{ width: '15px', height: '15px' }} src="socialIcons/instagram.png" alt="facebook" />
                            <img src="socialIcons/googleLogo.svg" alt="facebook" />

                          </div>
                        </div>
                      </div>
                      <Stack
                        justifyContent={"space-between"}
                        direction={"row"}
                        marginBlock="16px"
                        fontSize={"12px"}
                        alignItems={"baseline"}
                        className="status-live-or-not"
                        position={"relative"}
                      >
                        <Stack
                          direction={"row"}
                          spacing={1}
                          sx={{
                            color: "#2C2C2C",
                          }}
                          alignItems={"center"}
                        >
                          <span>Status</span>
                          <span style={{ color: campaignInfo?.campaignStatus === 'ACTIVE' ? '#0EAA00' : campaignInfo?.campaignStatus === 'PAUSED' ? '#F07A4C' : '#6622CC', opacity: 1, fontWeight: 700 }}>
                            {/* Live */}
                            {campaignInfo?.campaignStatus ? campaignInfo?.campaignStatus : ''}
                          </span>
                          <span
                            style={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              display: "inline-block",
                              backgroundColor: campaign?.status === 'ACTIVE' ? '#0EAA00' : campaign?.status === 'PAUSED' ? '#F07A4C' : '#6622CC',
                              opacity: 1,
                            }}
                          ></span>
                        </Stack>

                        <div className="ad-overview-chart">

                          <div className="btns" style={{ zIndex: '8px' }}>
                            <div
                              className="select-btn"
                              onClick={() => setOpenGraphFilter(!openGraphFilter)}
                            >
                              <span style={{ fontSize: '10px', cursor: 'pointer' }}>{chartOptionFilterValue}</span>
                              <svg
                                width="4"
                                height="3"
                                viewBox="0 0 4 3"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.82322 2.77521C1.92085 2.87285 2.07915 2.87285 2.17678 2.77521L3.76777 1.18422C3.8654 1.08659 3.8654 0.928302 3.76777 0.830671C3.67014 0.733039 3.51184 0.733039 3.41421 0.830671L2 2.24488L0.585786 0.830671C0.488155 0.733039 0.329864 0.733039 0.232233 0.830671C0.134602 0.928302 0.134602 1.08659 0.232233 1.18422L1.82322 2.77521ZM1.75 2.39844V2.49844H2.25V2.39844H1.75ZM1.75 2.49844V2.59844H2.25V2.49844H1.75Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            {openGraphFilter && (
                              <>
                                {/* <button
                                  className="select-btn-options"
                                  style={{ color: "black", fontWeight: "500" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("Till Date");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  Till Date
                                </button> */}
                                <button
                                  className="select-btn-options"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 7 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 7 days
                                </button>
                                <button
                                  className="select-btn-options"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 14 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 14 days
                                </button>
                                <button
                                  className="select-btn-options border"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {
                                    setChartOptionsFilterValue("last 28 days");
                                    setOpenGraphFilter(false);
                                  }}
                                >
                                  last 28 days
                                </button>

                                <button
                                  className="select-btn-options border"
                                  style={{ color: "#9F9F9F", fontWeight: "400" }}
                                  onClick={() => {

                                  }}
                                >
                                  <div className={cn("grid gap-2", className)}>
                                    <Popover>
                                      <PopoverTrigger asChild>
                                        <button
                                          id="date"

                                          variant={"outline"}
                                          style={{ FontSize: '9px', Padding: '0px' }}

                                          className={cn(
                                            "w-[100%]  overflow-hidden p-0 border-none",
                                            !dateRange && "text-muted-foreground"
                                          )}
                                        >
                                          custom date
                                        </button>
                                      </PopoverTrigger>
                                      <PopoverContent className="w-auto p-0" align="start">
                                        <Calendar
                                          initialFocus
                                          className="clalender-bg"
                                          mode="range"
                                          defaultMonth={dateRange?.from}
                                          selected={dateRange}
                                          onSelect={handleSelect}
                                          disabled={isDateDisabled}
                                          numberOfMonths={2}
                                        />
                                        <div className=" text-end pb-2 px-2">
                                          <Button onClick={handleUpdate} className="bg-[#62c] text-white">Update</Button>

                                        </div>
                                      </PopoverContent>
                                    </Popover>
                                  </div>
                                </button>





                              </>
                            )}
                          </div>
                        </div>
                      </Stack>
                      {/* <OverviewGraph dataForGraph={dataForGraph} graphload={graphload} datas={campaignDataForGraph} campStatus={campStatus} campStartEndDate={campStartEndDate} setGraphLoad={setGraphLoad} /> */}
                      <Chart
                        type="line"
                        options={chartOptions}
                        data={chartData}
                        style={{ minWidth: "100%" }}
                      />

                      <Stack
                        direction={"row"}
                        justifyContent={"space-start"}
                        marginBlock={"1rem"}
                        flexWrap={'wrap'}

                        gap={1}
                        className="campaign-impression-details d-md-none"

                      >

                        <Stack

                          direction="row"
                          alignItems={"center"}

                          spacing={1}
                        >
                          <button className="campign-info-btn">
                            <RemoveRedEyeOutlinedIcon
                              sx={{ color: "#6622CC", fontSize: "1rem" }}
                            />
                          </button>
                          <Stack>
                            <span className="campign-info-heading">Impressions</span>
                            <span>

                              {nFormatter(impressionClicksSpent?.impression, 2)}
                            </span>
                          </Stack>
                        </Stack>

                        <Stack direction="row" alignItems={"center"} spacing={1}>
                          <button className="campign-info-btn">

                            < AdsClickIcon sx={{ color: "#6622CC", fontSize: "1rem" }} />

                          </button>
                          <Stack>
                            <span className="campign-info-heading">Clicks(All)</span>
                            <span>{nFormatter(impressionClicksSpent?.views, 2)}</span>
                          </Stack>
                        </Stack>

                        <Stack direction="row" alignItems={"center"} spacing={1}>
                          <button className="campign-info-btn">

                            < LinkIcon sx={{ color: "#6622CC", fontSize: "1rem" }} />

                          </button>
                          <Stack>
                            <span className="campign-info-heading">Link Clicks</span>
                            <span>{nFormatter(impressionClicksSpent?.linkClicks, 2)}</span>
                          </Stack>
                        </Stack>

                        <Stack direction="row" alignItems={"center"} spacing={1}>
                          <button className="campign-info-btn">
                            <CurrencyRupeeIcon
                              sx={{ color: "#6622CC", fontSize: "1rem" }}
                            />
                          </button>
                          <Stack>
                            <span className="campign-info-heading">Spent</span>

                            <span>{impressionClicksSpent?.spend ? formatIndianNumber(Math.round(Number(impressionClicksSpent?.spend))) : 0}</span>
                            {/* <span>{resultSpend ? formatIndianNumber(Math.round(Number(resultSpend))) : 0}</span> */}
                          </Stack>
                        </Stack>
                    
                        <Stack direction="row" sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/leads`, { state: location?.state?.cmpid }) }} alignItems={"center"} spacing={1}>
                          <button className="campign-info-btn">
                            <Groups2Icon
                              sx={{ color: "#6622CC", fontSize: "1rem" }}
                            />
                          </button>
                          <Stack>
                            <span className="campign-info-heading">Total Leads
                             
                            </span>
                            <span>{totalleadsData ? formatIndianNumber(Math.round(Number(totalleadsData))) : 0}</span>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography sx={{ fontSize: "12px", opacity: "50%" }}>
                          Budget
                        </Typography>



                        {/* busdget slider and impression from mobile */}
                        <div className="p-0 mb-12 mt-0">


                          {filteredCampaign?.length ? (
                            <ToolTip
                              current={nFormatter(resultSpend, 1)}
                              percentage={(resultSpend / budgetPrice) * 100}
                            />
                          ) : (
                            <ToolTip current={nFormatter(0, 1)} percentage={0} />
                          )}
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ fontSize: "10px", fontWeight: '400', color: "#000000de" }}>
                              INR &nbsp;0
                            </p>
                            <p style={{ fontSize: "10px", fontWeight: '400', color: "#000000de" }}>
                              INR &nbsp;{Math.round(budgetPrice)}
                            </p>
                          </div>
                        </div>
                      </Stack>



                      {(campaign?.status === 'ACTIVE' || campaign?.status === 'PAUSED' || campaign?.status === 'UNDER REVIEW') && (<>
                        <Stack direction={"row"} justifyContent={"start"} className="" spacing={1}>
                          {campaign?.status === 'PAUSED' && (
                            <>
                              {isStopped ? (<>
                                <AlertDialog handleCampaignStatus={handleCampaignStop} buttontype="stop" />

                                <AlertDialog handleCampaignStatus={handleCampaignResume} buttontype="resume" />

                              </>) : (<>
                                <button
                                  className="pause-btn pause-text btn-ok primarybg-col"
                                  onClick={() => {
                                    alert("Your campaign is already stopped")
                                  }}
                                >
                                  <StopIcon sx={{ color: "#fff", fontSize: "16px" }} />
                                  &nbsp;Stop
                                </button>
                              </>)}

                            </>
                          )}

                          {campaign?.status === 'ACTIVE' && (
                            <AlertDialog handleCampaignStatus={handleCampaignPause} buttontype="pause" />

                          )}
                          {campaign?.status === 'UNDER REVIEW' && (<>
                            <>
                              {isStopped ? (<>
                                <AlertDialog handleCampaignStatus={handleCampaignStop} buttontype="stop" />



                              </>) : (<>
                                <button
                                  className="pause-btn pause-text btn-ok primarybg-col"
                                  onClick={() => {
                                    alert("Your campaign is already stopped")
                                  }}
                                >
                                  <StopIcon sx={{ color: "#fff", fontSize: "16px" }} />
                                  &nbsp;Stop
                                </button>
                              </>)}

                            </>
                          </>)}
                        </Stack>
                      </>)}

                 {(campaign?.status === 'ACTIVE' || campaign?.status === 'PAUSED' || campaign?.status === 'UNDER REVIEW') &&                  <div className=" mt-4">
                        <AddBudgetForCampaign addBudgetInfo={addBudgetInfo} campaignIdForAddBudget={campaignIdForAddBudget} campaingfbId={campaingfbId} budgetPrice={budgetPrice} />
                      </div>}     

                    </Box>

                  </Box>
                 

                </Box>
              </div>
            </div>
            <div className="p-0 ps-md-3 ps-lg-0 m-0 ps-lg-3 ps-xl-0 col-12 col-md-12 col-lg-12 col-xl-4 d-none d-md-block">

              <ForWebImpression graphIpmression={impressionClicksSpent} resultSpend={resultSpend} totalleadsData={totalleadsData} campid={location?.state?.cmpid} />
              {/* {filteredCampaign.length > 0 ? (
                filteredCampaign?.map((val) => (
                  <ForWebImpression graphIpmression={val} resultSpend={resultSpend} totalleadsData={totalleadsData} campid={location?.state?.cmpid} />
                ))) : <ForWebImpression graphIpmression={{}} resultSpend={resultSpend ? resultSpend : 0} />} */}
            </div>
          </div>
        </div>
        <div className="p-0 m-0 col-12 col-md-5 col-lg-5 col-xl-4">

          <div className=" " style={{ maxWidth: '600px', overflow: '' }}>
            {/* <h5 className="text-center text-md-start mt-4 mt-md-0 adpreview-heading">Ad Preview</h5> */}
            {/* <OverviewCard previewLoading={previewLoading} getInstagramPreview={getInstagramPreview} value={getCreativeUrl} campstatus={campaign?.status} /> */}
            <AdPreviewSliderCard previewLoading={previewLoading} getInstagramPreview={getInstagramPreview} value={getCreativeUrl} campstatus={campaign?.status} />

            <OverviewCampaigninfoCard projectData={projectData} />


          </div>
        </div>



        <div className="col-12 p-0 m-0 my-4 my-md-1 ">
          <Box sx={{ margin: '0px', padding: '0px' }} className="d-flex justify-content-md-start justify-content-center">
            <div className="supportBtns p- m-0 text-center " >
              <Stack
                spacing={3}
                direction={"row"}
                justifyContent={"space-between"}
              >
                <button
                  className="backBtn d-inline d-md-none "
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <KeyboardBackspaceIcon fontSize="12px" />
                  Back
                </button>

              </Stack>
              {/* <button
                className="supportBtn mt-2 ms-md-3 "
                onClick={() => {
                  navigate("/leapx-support", {
                    state: {
                      previousPage: "adOverViewPage",
                      campaignId: campaign?._id,
                      campaign_name: campaign?.name ? campaign?.name : ""
                    },
                  });
                }}
              >
                <img src="/contactIcon.svg" alt="" />
                Contact Leapx Support
              </button> */}


              {/* graph */}

            </div>


          </Box>


          {/* <div className="mt-3 p-0 " style={{width:'42%'}}>
       <OverviewGraph datas={campaignDataForGraph}/>

       </div> */}

          <div className="" style={{ marginBottom: '10vh' }}>

          </div>


        </div>

      </div>

    </>
  );
};

export default AdOverViewPage;
