import React, { useContext, useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import { Trash2 } from 'lucide-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProfileCompleteCard from './components/ProfileCompleteCard';
import { Card } from '../../components/ui/card';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
import Navbar2 from '../../navbar/Navbar2';
import Axios from '../../Axios';
import { AuthContext } from '../../AuthProvider';
import { ProfilePageContext } from '../../context/ProfilePageContext';
import NotificationSettingsGoogleSheetEmails from './NotificationSettingsGoogleSheetEmails';

function NotificationSettings() {
  const { userToken } = useContext(AuthContext);
  const [notificationEmails, setNotificationEmails] = useState([]);
  const [disableAddEmail, setDisableAddEmail] = useState(false);
  const { getUserProfileData, userData, accountPercentage, userName, fbpagePercentage, businessPercentage, notificationPercentage, overallPercentage } = useContext(ProfilePageContext);

  useEffect(() => {
    if (userToken) {
      getUserProfileData(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    if (userData) {
      setNotificationEmails(userData?.notification_emails || []);
    }
  }, [userData]);

  const formik = useFormik({
    initialValues: {
      notification_email: '',
    },
    validationSchema: Yup.object({
      notification_email: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Invalid email address'
        )
        .required(''),
    }),
    onSubmit: async (values, { resetForm }) => {
      setDisableAddEmail(true);
      const body = {
        notification_emails: [...notificationEmails, values.notification_email],
      };
      try {
        const res = await Axios.post("/complete-profile", body, { headers: { Authorization: userToken } });
        if (res.status === 200 && res?.data?.valid) {
          setNotificationEmails(body.notification_emails);
          getUserProfileData(userToken);
          resetForm();
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setDisableAddEmail(false);
      }
    },
  });

  const onDeleteEmail = async (email) => {
    try {
      const remainingEmails = notificationEmails?.filter((val) => val !== email);
      const body = { notification_emails: remainingEmails };

      const res = await Axios.post("/complete-profile", body, { headers: { Authorization: userToken } });
      if (res.status === 200 && res?.data?.valid) {
        setNotificationEmails(remainingEmails);
        getUserProfileData(userToken);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="d-md-none">
        <Navbar2 menu={true} />
      </div>
      <div className="container mx-auto bg-[#FBFBFB]">
        <div className='py-3 d-none d-md-block'>
          <h2 className="ad-main-h text-start">Notification Details</h2>
        </div>
        <div className="row">
          <div className="col-12 mt-3 mt-md-0 col-md-8 mb-4 mb-md-0 order-2 order-md-1">
            <div className='py-0 d-block d-md-none'>
              <h2 className="ad-main-h text-start">Notification Details</h2>
            </div>
            <Card className="p-6 shadow-none bg-white">
              <div>
                <p className='campaign-alrt-text text-start'>Campaign Lead Alerts</p>
              </div>
              <div className='max-w-[400px]'>
                <div className="my-4">
                  <div className='flex justify-start items-center'>
                    <form className='w-full' onSubmit={formik.handleSubmit}>
                      <div className='w-full flex items-center justify-start'>
                        <Input
                          type="email"
                          name="notification_email"
                          placeholder="Email"
                          className="flex-1 w-full h-11 shadow-none rounded-r-none"
                          value={formik.values.notification_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <Button
                          type="submit"
                          disabled={disableAddEmail || !formik.isValid}
                          className="notification-btn w-[33px] h-11 shadow-none rounded-l-none bg-[#62c] hover:bg-[#62c]"
                        >
                          +
                        </Button>
                      </div>
                    </form>
                  </div>
                  {formik.touched.notification_email && formik.errors.notification_email ? (
                    <div className="text-red-500">{formik.errors.notification_email}</div>
                  ) : null}

                  {notificationEmails?.length > 0 && (
                    <Card className="shadow-none w-full mt-4 p-3">
                      <div className="notification-email-container">
                        {notificationEmails.map((email) => (
                          <div key={email} className='mb-3 flex justify-between items-center'>
                            <p className='p-0 m-0 notificationemail-p'>{email}</p>
                            <button onClick={() => onDeleteEmail(email)}>
                              <Trash2 className='text-red-500 w-[18px] h-[18px]' />
                            </button>
                          </div>
                        ))}
                      </div>
                    </Card>
                  )}
                </div>
              </div>
              {/* google sheet */}
              <NotificationSettingsGoogleSheetEmails getUserProfileData={getUserProfileData} userData={userData} />
            </Card>
          </div>
          <div className="col-12 col-md-4 order-1 order-md-2 flex justify-center items-start">
            <ProfileCompleteCard accountPercentage={accountPercentage} userName={userName} fbpagePercentage={fbpagePercentage} businessPercentage={businessPercentage} notificationPercentage={notificationPercentage} overallPercentage={overallPercentage} />
          </div>
        </div>
      </div>
      <div className="d-block d-md-none"><BottomNavbar /></div>
    </>
  );
}

export default NotificationSettings;
