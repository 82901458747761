import React from 'react'
import './landingpage-component.css';
import herobgimg from '../../asets/asetes/heroscreen bg img2.svg'

function ImportantAndOutcome() {
  return (

   
     
    <div className='m-0 container-fluid  importantoutcome-container impactnadoutcome-bg' style={{overflow:'hidden'}}>
        
            <img src={herobgimg}  style={{objectFit:'cover', minHeight:'100vh', opacity:'0.2', zIndex:'0'}} className='position-absolute hero-dotted-bgimg' alt="" />
          
        <div className="iao-bluebg"></div>
        <div className="iao-orangebg"></div>
           <div className="container">
              <div className="row">

              
            <div className="col-12 col-md-6 d-flex justify-content-center align-items-start">
                <div className=' importantoutcome-left p-0 d-flex justify-content-start flex-column  align-items-start' style={{zIndex:'2'}}>
                       <p className='importantoutcome-h text-center mt-0 pt-0 text-md-start w-100 '>Experience Unmatched <span className='text-gradient' >Efficiency</span></p>
                       <p className='importantoutcome-p py-3 pe-md-5 text-md-start text-center w-100'>LeapX AI harnesses advanced algorithms and data science to fine-tune ad campaigns. By designing targeted creatives and optimizing in-flight adjustments, our platform delivers superior result efficiency. Experience streamlined and effective paid advertising with LeapX AI.</p>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="importantoutcome-right py-2">
                       <div className="io-card1  d-flex gap-3 justify-content-center align-items-center">
                            <div className='io-innercard1 shadow  d-flex justify-content-center flex-column align-items-center'>
                            <span></span> <span></span> <span></span> <span></span>                  
                                 <p className='io-innercard1-h text-center'>53%</p>
                                 <p className='io-innercard1-p text-center' style={{marginBottom:'0'}}>Lower CPR</p>
                            </div>
                            <div className='io-innercard2 shadow d-flex justify-content-center flex-column align-items-center '>
                            <span></span> <span></span> <span></span> <span></span>  
                                  <p className='text-center io-innercard1-h'>21%</p>
                                  <p className='text-center io-innercard1-p' style={{marginBottom:'0'}}> Better Quality</p>
                            </div>
                       </div>
                       <div className="io-card2 mt-3 d-flex shadow justify-content-center align-items-center">
                           <div className='io-innercard3 d-flex justify-content-center flex-column align-items-center'>
                           <span></span> <span></span> <span></span> <span></span>  
                           <p className='text-center io-innercard1-h'>94%</p>
                        <p className='text-center io-innercard1-p' style={{marginBottom:'0'}}>  Less Time Consuming</p>
                           </div>
                       </div>
                </div>
            </div>
            </div>
           </div>
    </div>
)
}

export default ImportantAndOutcome