import React from 'react'
import leapxsupportimg from '../../asets/webimages/leapxsupport.png'
import LeapxSupport from '../../pages/leapx-support/leapxSupport'
function WebLeapxSupport() {
  return (
     <>
         <div className="container-fluid">
            <div className="row">
                <div className="col-6 p-0" >
                    <div className=' overflow-hidden p-0' style={{width:'100%', height:'90.7vh'}}>
                        <img src={leapxsupportimg} style={{width:'100%', height:'auto'}}  alt="" />
                    </div>
                </div>
                <div className="col-6">
                    <div className='p-4'>
                        <div>
                            <LeapxSupport/>
                        </div>
                    </div>
                </div>
            </div>
         </div>
     </>
  )
}

export default WebLeapxSupport