import React, { useState, useEffect } from 'react';
import './auth.css';
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import logo from '../asets/auth/Leapx-Meta 1.png';
import bgimg from '../asets/auth/girl 1.jpg';
import Axios from '../Axios';
import OtpInput from 'react-otp-input';
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from "../SnackBarProvider";
import { Formik, Form } from 'formik';
import EnterNewPasswordPage from './EnterNewPasswordPage';

function ForgotPasswordPage() {
  const [loadButton, setLoadButton] = useState(false);
  const [isForgotPasswordSend, setIsForgotPasswordSend] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [verifyToken, setVerifyToken] = useState("")
  const showSnackbar = useSnackbar();
  const { state } = useLocation();

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const handleForgotPassword = async (values) => {
    setLoadButton(true);
    try {
      const res = await Axios.post("/forget-password", { email: values.email });
      if (res.status === 200) {
        const value = res.data;
        if (value.valid) {
          showSnackbar(`Success: ${value?.message}`, 'success');
          setEmail(values.email);
          setIsForgotPasswordSend(true);
        } else {
          showSnackbar(`Error: ${value?.message}`, 'error');
          setIsForgotPasswordSend(false);
        }
      }
    } catch (error) {
      showSnackbar(`Error: ${error?.response?.data?.errors[0]?.msg || 'Server error'}`, 'error');
      setIsForgotPasswordSend(false);
    } finally {
      setLoadButton(false);
    }
  };

  const onVerifyOtp = async (otp) => {
    try {
      const res = await Axios.post("/verify-email-otp", { email: email, code: otp });
      if (res.status === 200) {
        if (res.data.valid) {
          setIsOtpValid(true);
          setVerifyToken(res?.data?.token?res?.data?.token:"")
        } else {
          showSnackbar('Error: Incorrect OTP', 'error');
          setIsOtpValid(false);
          setVerifyToken("")
        }
      }
    } catch (error) {
      showSnackbar('Error: Server error', 'error');
      setVerifyToken("")
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      onVerifyOtp(otp);
    }
  }, [otp]);

  const handleEmailChange = () => {
    setIsForgotPasswordSend(false);
    setOtp('');
    setIsOtpValid(false);
    setVerifyToken("")
  };


  const OnCheckVerifyOtp = ()=>{
    showSnackbar('Please Enter OTP', 'error');
  }

  return (
    <>
      <div className="signup-container">
        <div className="signup-left-container position-relative">
          <div className='flex justify-center sm:items-start md:items-center position-relative'>
            <img src={bgimg} className='auth-bgimage d-none d-md-block' alt="" />
          </div>
        </div>

        <div className='signup-right-container'>
          <div className="usersignup-card">
            <div className="usersignupcard-inner">
              <div className='signup-card'>
                <div className='text-start form-card-login signup-form-container mt-md-3 py-3'>
                  <img src={logo} className="img-top-logo" alt="" />
                </div>
                <div className='mt-5'>
                  <div className="signup-form-container">
                    <div className="signup-from">
                      <div className="">
                        <div className="d-flex justify-content-start align-items-center mb-3">
                          {!isForgotPasswordSend ? 
                            <Link to="/login">
                              <ArrowBackIcon sx={{ color: "black" }} />
                            </Link> 
                            : 
                            <button style={{ border: 'none', padding: '0px' }} onClick={handleEmailChange}>
                              <ArrowBackIcon sx={{ color: "black" }} />
                            </button>
                          }
                        </div>
                        {!isOtpValid ? 
                          <div className="row">
                            <div className="col-12">
                              <div className="form-card-login">
                                <div className='mb-3'>
                                  <h3 className="text-start google-heading text-black">Forgot Password</h3>
                                </div>
                                <Formik
                                  initialValues={{ email: state || '' }}
                                  validationSchema={emailValidationSchema}
                                  onSubmit={handleForgotPassword}
                                >
                                  {({ values, handleChange, handleBlur, touched, errors }) => (
                                    <Form>
                                      <div className="mb-3">
                                        <TextField
                                          id="email"
                                          name="email"
                                          value={values.email}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleEmailChange();
                                          }}
                                          onBlur={handleBlur}
                                          type="email"
                                          sx={{
                                            width: "100%",
                                            '& .MuiInput-underline:after': {
                                              borderBottomColor: '#62c',
                                            },
                                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                              borderBottomColor: '#62c',
                                            },
                                          }}
                                          label="Email Id"
                                          placeholder="Enter Your Email"
                                          variant="standard"
                                          required
                                          autoComplete="off"
                                          error={touched.email && Boolean(errors.email)}
                                          helperText={touched.email && errors.email}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize:'14px',
                                              color: '#62c',
                                              '&.Mui-focused': { color: '#62c' },
                                            },
                                          }}
                                        />
                                      </div>

                                      {/* OTP Section */}
                                      {isForgotPasswordSend && (
                                        <div className="mb-3">
                                          <div className="m-0 p-0 otp-filds d-flex justify-content-between align-items-center py-2">
                                            <OtpInput
                                              value={otp}
                                              containerStyle={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '0',
                                                margin: '0',
                                                width: '100%',
                                              }}
                                              onChange={setOtp}
                                              numInputs={6}
                                              inputStyle={{
                                                width: '38px',
                                                borderTop: 'none',
                                                borderRight: 'none',
                                                borderLeft: 'none',
                                                borderBottom: '2px solid gray',
                                              }}
                                              renderInput={(props) => <input {...props} />}
                                            />
                                          </div>
                                          <div className='flex items-center justify-between'>
                                            <p className='p-0 m-0 text-[10px] text-start'>OTP expires in 5 minutes</p>
                                            <button
                                              type="button"
                                              className='p-0 m-0 text-[#62c] text-[10px] font-semibold'
                                              onClick={() => handleForgotPassword({ email })}
                                            >
                                              Resend OTP
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                      <div className="mb-3" style={{ marginTop: "20%" }}>
                                       
                                       
                                       {!isForgotPasswordSend?<button
                                          className="submit-button"
                                          type="submit"
                                          disabled={loadButton}
                                        >
                                          {loadButton ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
                                        </button>:<button
                                          className="submit-button"
                                          onClick={OnCheckVerifyOtp}
                                          type="button"
                                          disabled={loadButton}
                                        >
                                          {loadButton ? <CircularProgress size={20} color="inherit" /> : 'Verify'}
                                        </button>}
                                        
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                          : 
                          <div className="">
                            <EnterNewPasswordPage emailData={email} token={verifyToken} />
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordPage;
