import React, { useContext, useEffect, useState, useRef } from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Checkbox, Fab, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton } from '@mui/material';
import { Step, StepLabel, Stepper } from "@mui/material";
import ConnectToFbAndSelectPages from './ConnectToFbAndSelectPages';
import facebook from "../../asets/facebook.png";
import Person2Icon from "@mui/icons-material/Person2";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Dropdown } from 'primereact/dropdown';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Axios from '../../Axios';
import { RefreshCw } from 'lucide-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { AuthContext } from '../../AuthProvider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './addcampaignsummery.css'
import Popover from '@mui/material/Popover';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import EditIcon from '@mui/icons-material/Edit';
import { Slider as MuiSlider } from '@mui/material';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Payment from '../../paymentmgn/payment-gateway/PaymentNow';
import { useLocation, useNavigate } from 'react-router-dom';
import WebStepper from '../../adesktop-webpage/web-component/webstepper/WebStepper';
import { Button as ButtonShadcn } from '../../components/ui/button';
import Navbar2 from '../../navbar/Navbar2';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WrongLocationSharpIcon from '@mui/icons-material/WrongLocationSharp';
import TargetingLocaionWithRadius from '../../adesktop-webpage/web-campaign-creation/targetinglocation/TargetingLocationWithRadiud';
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
import PaymentLoader from '../../paymentmgn/PaymentLoader';
// import ManageAdPreview from '../../adesktop-webpage/web-campaign-creation/ManageAdPreview';

const steps = ["Provide details", "Review details", "Confirm & Pay"];


function AdCampaignSummeryAndPay() {
  const [userDetail, setUserDetail] = useState({});
  const { state } = useLocation();
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false);
  const [modifiedObject, setModifiedObject] = useState("")
  const { userToken, loadConfirmAndPay, setLoadConfirmAndPay } = useContext(AuthContext)
  const payWithRazorpay = Payment()
  const [showSearchForMap, setShowSearchForMap] = useState([])
  const [isUserDetailsLoad, setIsUserDetailLoad] = useState(false)
  const [amenitiesArray, SetAmenitiesArray] = useState([])
  const navigate = useNavigate()
  // const [addesOrRemovedItem, setAddesOrRemovedItem ] = useState("")
  const [laodGeoLocation, setLoadGeoLocation] = useState(false)
  const [isGeoLocationFetch, setIsGeoLocationFetch] = useState([])
  const [sliderValue, setSliderValue] = useState(50000); // Initial value
  const [lifeTimeBudget, setLifeTimeBudget] = useState(50000);
  const [isWallateAndBudgetEqual, setIsWallateAndBudgetEqual,] = useState(false)
  const [content, setContent] = useState("");
  const [searchValue, setSearchValue] = useState("")
  const [showMapContainer, setShowMapContainer] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [locationRadiusValue, setLocationRadiusValue] = useState({});
  // const [checkedLocation, setCheckedLocation] = useState([])
  const [targettingLocation, setTargettingLocation] = useState([])
  const [usedBalance, setUsedBalance] = useState(0)
  const [showList, setShowList] = useState(false);
  const [amountPayble, setAmountPayble] = useState(0)
  const [showMaps, setShowMaps] = useState(false)
  const [ errorSelectPages, setErrorSelectPages] = useState("")
  const mapRef = useRef(null);
  const [loadRefreshPage, setLoadRefreshPage] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [selectIncludeExclude, setSelectIncludeExclude] = useState('include');
  const [selectedLocations, setSelectedLocations] = useState([])
  const [singLocationCoordinated, setSingLocationCoordinated] = useState([])
  const [remainingWalletBalance, setRemainingWalletBalance] = useState(0)
  const [privacypolicyCheck, setPrivacypolicyChecked] = useState(false)
  const [expandedAccordion, setExpandedAccordion] = useState(false)
  const [GSTAmount, setGSTAmount] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [connectedPages, setConnectedPages] = useState([])

  const [selectedVal, setSelectedVal] = React.useState(null);


  const initialState = {
    Name: "",
    Address: "",
    Property: "",
    Size: "",
    Price: "",
    Status: "",
    // Designed: "",
    Budget: 19900,
    Day: 30,
    Flat: "",
  };

  const [selectFacebookPage, setSelectFacebookPage] = useState(null);
  const [value, setValue] = useState(initialState);
  const [createProMessage, setCreateProMessage] = useState("")
  const [createProCampaign, setCreateProCampaign] = useState("")

// check user internate connectivity
const [isOnline, setIsOnline] = useState(navigator.onLine);

useEffect(() => {
    // Update state when online status changes
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup listeners on component unmount
    return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
    };
}, []);




  function removeSpacesFromKeys(obj) {
    let newObj = {};
    Object?.keys(obj)?.forEach((key) => {
      let newKey = key.replace(/\s+/g, ""); // Remove spaces using a regular expression
      newObj[newKey] = obj[key];
    });
    return newObj;
  }

  useEffect(() => {
    setCreateProCampaign(state?.campaign)
    setCreateProMessage(state?.message)
    setContent(state?.message?.summary)
    //  console.log("campaign", state?.campaign)
    //  console.log("message", state?.message)

  }, [state?.message || state?.campaign])


  useEffect(() => {
    if (state?.address) {
      let modifiedObject2 = removeSpacesFromKeys(state?.address)
      let amenitiesArray2 = modifiedObject?.Amenities
        ? modifiedObject?.Amenities.split(", ")
        : [];
      if (modifiedObject2) {
        // console.log("modify", modifiedObject2)
        setModifiedObject(modifiedObject2)
      }
      if (amenitiesArray2) {
        SetAmenitiesArray(amenitiesArray2)
      }
    }

  }, [])

  const handleUser = async () => {
    setIsUserDetailLoad(true)
    //   let stringWithoutDoubleQuotes = tokenLogin.replace(/"/g, "");
    Axios.get("/user", {
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const data = response?.data?.user;
        const pagesdata = response?.data?.user?.page_id;
          if(pagesdata?.length>0){
            const filteredConnectedPages = pagesdata?.filter(item => item?.tasks?.includes("MANAGE") || !item?.hasOwnProperty('connected'));
               if(filteredConnectedPages?.length>0){
                const filteredSuccessPages = filteredConnectedPages?.filter(item => (item?.connected === 'success' || !item?.hasOwnProperty('connected')));
                      // console.log("filteredSuccessPages", filteredSuccessPages)
                    if(filteredSuccessPages?.length>0){
                      console.log()
                      setConnectedPages(filteredSuccessPages)
                    }else{
                      setConnectedPages([])
                    }
               }else{
                setConnectedPages([])
               }
           
          }else{
            setConnectedPages([])
          }
         

        setUserDetail(data);

        setIsUserDetailLoad(false)


      })
      .catch((error) => {
        console.log("Error setting up request:", error);
        setIsUserDetailLoad(false)
      });
  };

  useEffect(() => {
    handleUser()
  }, [])

  //edit property
  const handleEditPrperty = async () => {
    setIsConfirm(true)
   
    const body = {
      property_id: state?.propertyId ? state?.propertyId : createProCampaign?.property_id,
      leads: Math.round(Number(lifeTimeBudget) / Number(createProMessage?.cost_per_lead)),
      page_id: selectFacebookPage.id,
      days: Number(duration),
      summary: content,
      lifetime_budget: Number(lifeTimeBudget),
      campaign_id: state?.campaignDfId ? state?.campaignDfId : createProCampaign?._id
    };

    const formData = new FormData()
    formData.append("property_id", body.property_id)
    formData.append("leads", body.leads)
    formData.append("page_id", body.page_id)
    formData.append("days", body.days)
    formData.append("summary", body.summary)
    formData.append("lifetime_budget", body.lifetime_budget)
    formData.append("campaign_id", body.campaign_id)
    formData.append("amount", amountPayble)
    formData.append("geolocation", JSON.stringify(selectedLocations))
    setIsLoading(true);
    try {
      const res = await Axios.post("/edit-property", formData, {
        headers: {
          Authorization: userToken,
        },
      });
      if (res.status === 200) {
        const data = res?.data;
        // console.log("editproperty", data);
        //Payment gateway
        localStorage.removeItem('propertyid')
        const adcampaignBody = {
          page_id: selectFacebookPage.id,
          // access_token: fbToken,

          campaign_id: body.campaign_id,
          used_wallet_spend: usedBalance,
          payable_amount: amountPayble
          // payable_amount:2

        };


        if (adcampaignBody.campaign_id) {
          if (isWallateAndBudgetEqual) {


            createOrder(amountPayble, adcampaignBody, isWallateAndBudgetEqual, )
           
          } else {
            createOrder(amountPayble, adcampaignBody, isWallateAndBudgetEqual)
          }

          // payWithRazorpay(amountPayble, adcampaignBody, isWallateAndBudgetEqual )


        }


        //   handleCampaign();
      }
    } catch (error) {
      setIsLoading(false);
      
      console.log(error.message);
      setIsConfirm(false)
    }
  };



  // create order
  const createOrder = async (amountPayble, adcampaignBody, isWallateAndBudgetEqual) => {
    setIsConfirm(true)
    
    //  console.log("adcampaignBody.used_wallet_spend", adcampaignBody.used_wallet_spend)
    const body = {
        
      amount: Math.floor(amountPayble) * 100, //in smallest currendcy unit. i.e in paisa
      currency: "INR",
      receipt: "Anurag#955",
      used_wallet_spend:adcampaignBody.used_wallet_spend,
      notes: {
        description: "payment against ad campaign",
        campaign: adcampaignBody.campaign_id,
      }
    }
    try {
      const res = await Axios.post("/create-order", body, {
        headers: {
          Authorization: userToken
        }
      })
      if (res.status === 200) {
        
        const data = res?.data?.message
          //  console.log("create order", data)
        const orderid = data?.id

        if (orderid) {
          //     console.log("datdsda", orderid)
          const prefilledDetails = { name: userDetail?.name, email: userDetail?.email, number: userDetail?.mobile }
          //console.log("prefilledDetails", prefilledDetails)
          if (data) {
            payWithRazorpay(Math.floor(amountPayble), adcampaignBody, isWallateAndBudgetEqual, orderid, prefilledDetails)
            setTimeout(() => {
              setIsConfirm(false)
             
            }, 2000)
          }
        } else {
          const prefilledDetails = { name: userDetail?.name, email: userDetail?.email, number: userDetail?.mobile }
          payWithRazorpay(Math.floor(amountPayble), adcampaignBody, isWallateAndBudgetEqual, 1, prefilledDetails)
          setTimeout(() => {
            setIsConfirm(false)
           
          }, 2000)
        }

      }
    } catch (error) {
      console.log(error)
      setIsConfirm(false)
      
    }
  }


  // confirm and launch
  const OnConfirmAndLaunch = () => {

  //   if (!isOnline) {
          
      
  //     return; // Exit the function if there's no internet connection
  // }




    if (!selectFacebookPage) {
      // alert("Please Select Facebook Page");
      setErrorSelectPages("Please Select Facebook Page")
      return;
    }
    handleEditPrperty()
  }

  const itemTemplate = (option) => {
    return (
      <div className="p-flex p-align-center">
        <img src={facebook} alt={option.name} className="city-image" />
        <span style={{ marginLeft: 10 }}>{option.name}</span>
      </div>
    );
  };

  const handleDays = (event) => {
    const day = event.target.value ? event.target.value : 30;
    setDuration(day);
    setValue({
      ...value,
      Day: event.target.value ? event.target.value : 30,
    });
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


  useEffect(() => {

    if ((Number(lifeTimeBudget) >= 10000 && Number(lifeTimeBudget) <= 1000000)) {
      // wallet balance
      const plusgstlifetimebudget = lifeTimeBudget ? (Number(lifeTimeBudget) + (Number(lifeTimeBudget) * 18 / 100)) : 50000
      const gstamount = lifeTimeBudget ? (Number(lifeTimeBudget) * 18 / 100) : 50000 * (18 / 100)
      if (gstamount) {
        setGSTAmount(gstamount)
      }

      const wallatetResult = Number(userDetail?.wallet)
      if (wallatetResult > plusgstlifetimebudget) {

        const balance = wallatetResult - plusgstlifetimebudget
        const usedbalance = plusgstlifetimebudget
        setUsedBalance(usedbalance)
        // console.log("usedbalance",usedbalance )
        setIsWallateAndBudgetEqual(true)
        setRemainingWalletBalance(balance)
        setAmountPayble(0)
      } else if (wallatetResult === plusgstlifetimebudget) {
        setAmountPayble(0)
        setUsedBalance(wallatetResult)
        setIsWallateAndBudgetEqual(true)
        setRemainingWalletBalance(0)
      } else {
        const balance = plusgstlifetimebudget - wallatetResult
        setAmountPayble(balance)
        setUsedBalance(wallatetResult)
        setRemainingWalletBalance(0)
        setIsWallateAndBudgetEqual(false)
      }
    }



  }, [userDetail?.wallet, lifeTimeBudget])


  const theme = createTheme({
    palette: {
      primary: {
        main: '#6622cc', // Active step background color
      },
      secondary: {
        main: '#000', // Active step label color
      },
    },
  });

  const stepweb = [
    {
      label: 'Provide details',
      description: `Please upload official website link of the project, brochures and other pdfs related to the property you want to sell`,
    },
    {
      label: 'Review details',
      description: "We have pulled and pre-filled the details of the property for your convenience kindly review/ edit/ add details in the form below."
    },
    {
      label: 'Confirm & Pay',
      description: ``,
    },
  ];


  useEffect(() => {
    // console.log("state.compaign", state?.campaign)
    if (state?.campaign?.cities) {
      // console.log("cities", state?.campaign?.cities)

      handleSearchCurrentLocation(state?.campaign?.cities)
    }

  }, [state?.campaign])

    
  // console.log("selectedLocations", selectedLocations)


//  dynamic targeting
  const handleSearchCurrentLocation = async (searchvalue) => {
    setLoadGeoLocation(true)
    const body = {
      "location": searchvalue ? searchvalue : '',
      // "types": [ "city","region","neighborhood", "subcity", "subneighborhood"],
      "country_code": "IN"
    }

    try {
      const res = await Axios.post("/search-location", body, {
        headers: {
          Authorization: userToken
        }
      })
      //  console.log("location", res)
      if (res.status === 200) {
        const data = res?.data?.message?.data;
        const modifiedOptions = data?.map((location) => ({
          ...location,
          isInclude: selectIncludeExclude, // Set the type based on user selection
        }));

         if(modifiedOptions?.length>0){
          const citiesInIndia = modifiedOptions?.filter(item => item.country_code === "IN" && item.type === "city");
          //  console.log("citiesInIndia", citiesInIndia)
            
          handleAddedItem(citiesInIndia[0])


         }else{
        
          const defaultLocation = [
            {
              key: "1026297",
              name: "Gurugram",
              type: "city",
              country_code: "IN",
              country_name: "India",
              region: "Haryana",
              region_id: 1730,
              supports_region: true,
              supports_city: true
          }
          ]

          handleAddedItem(defaultLocation[0])
         }
        //  console.log(" modifiedOptions ",  modifiedOptions )
       
        // console.log("location", data)
      }
    } catch (error) {
      console.log(error?.message)
    } finally {
      setLoadGeoLocation(false)
    }

  }

// dynamic targeting

  const handleSearchTargettingLocation = async (searchvalue) => {
    setLoadGeoLocation(true)
    const body = {
      "location": searchvalue ? searchvalue : '',
      // "types": [ "city","region","neighborhood", "subcity", "subneighborhood"],
      "country_code": "IN"
    }
    try {
      const res = await Axios.post("/search-location", body, {
        headers: {
          Authorization: userToken
        }
      })
      //  console.log("location", res)
      if (res.status === 200) {
        const data = res?.data?.message?.data;
        const modifiedOptions = data?.map((location) => ({
          ...location,
          isInclude: selectIncludeExclude, // Set the type based on user selection
        }));

        const filterType = modifiedOptions?.filter((val) => (val.type === 'place' || val.type === 'city' || val.type === 'neighborhood' || val?.type === 'region'))
        // console.log("filtertype", filterType)
        // Extract keys from array1
        const keysInArray1 = selectedLocations.map(item => item.key);

        // Filter array2 to remove items that have keys present in array1
        const filteredLocation = filterType?.filter(item2 => !keysInArray1?.includes(item2.key));

        // console.log(newArray1);
        setTargettingLocation(filteredLocation)
        // console.log("location", data)
      }
    } catch (error) {
      console.log(error?.message)
    } finally {
      setLoadGeoLocation(false)
    }
  }

// dynamic targeting
  const searchLocation = (e) => {
    //handleSearchTargettingLocation(
    setSearchString(e.target.value)
    const searchValue2 = e.target.value?.trim();
    if (searchValue2) {
      setSearchValue(searchValue2)
      handleSearchTargettingLocation(searchValue2);
    }
    if (!searchValue2) {
      setTargettingLocation([])
    }
  }

// dynamic targeting
  const calculateDistance = (location1, location2) => {
    const { lat: lat1, lon: lon1 } = location1;
    const { lat: lat2, lon: lon2 } = location2;

    const R = 6371; // Earth's radius in kilometers
    const φ1 = lat1 * Math.PI / 180; // Convert degrees to radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers
    return distance;
  };


// dynamic targeting
  const getlocationForMap = async (searchkey, val, idx) => {

    setLoadGeoLocation(true)
    setIsGeoLocationFetch((prev) => [...prev, val.key]);

    try {
      const res = await Axios.get(`/get-lat-lon?type=${val?.type}&key=${val?.key}`, { headers: { Authorization: userToken } });
      if (res.status === 200) {
        const data = res.data?.message;
        //  console.log("data", res?.data?.message)
        if (data && data?.latitude && data?.longitude) {

          const newLocation = { lat: Number(data?.latitude), lon: Number(data?.longitude), ...val };
          //  console.log("newloca",newLocation  )
          if (newLocation?.radius && newLocation?.isInclude === 'include') {

            const overlappingLocations = selectedLocations?.filter(existingLocation =>

              (existingLocation.radius && existingLocation.isInclude === 'include' && existingLocation?.region === newLocation?.region) &&
              calculateDistance(newLocation, existingLocation) < Math.abs(newLocation.radius * 1000 - existingLocation.radius * 1000)
            );


            if (overlappingLocations?.length > 0) {
              // Remove overlapping locations from the map and selectedLocations state
              const updatedSelectedLocations = selectedLocations.filter(existingLocation =>
                !overlappingLocations?.includes(existingLocation)
              );
              //  setSelectedLocations([...updatedSelectedLocations, newLocation]);
              setSelectedLocations([...updatedSelectedLocations, newLocation]);
            } else {
              setSelectedLocations([...selectedLocations, newLocation]);
            }
          } else {
            // console.log("no radius")
            setSelectedLocations((prevState) => [...prevState, newLocation]);
          }

          setTargettingLocation([])
          setShowList(false); // Hide the list after selecting a locat
          setSearchString("")
        }
      }
    } catch (error) {
      console.error('Error fetching location:', error);
      // Handle error here, for example, display an error message to the user
    } finally {
      setLoadGeoLocation(false)
      //setIsGeoLocationFetch(null)
      setIsGeoLocationFetch((prev) => prev.filter((item) => item !== val.key));
    }


  }



// dynamic targetting
  const handleAddedItem = (val, idx) => {

      
    if (val?.type === 'city') {

      const keystr = `${val?.name},${val?.region ? val?.region : ''},${val?.country_name ? val?.country_name : ''} `
      if (keystr) {
        const newvalrad = { ...val, radius: 40 }
        getlocationForMap(keystr, newvalrad, idx)
      }

    } else if (val?.type === 'place') {
      const keystr = `${val?.name},${val?.region ? val?.region : ''},${val?.country_name ? val?.country_name : ''} `
      if (keystr) {
        const newvalrad = { ...val, radius: 2 }
        getlocationForMap(keystr, newvalrad, idx)
      }
    } else if (val?.type === 'address') {
      const keystr = `${val?.name},${val?.region ? val?.region : ''},${val?.country_name ? val?.country_name : ''} `
      if (keystr) {
        const newvalrad = { ...val, radius: 16 }
        getlocationForMap(keystr, newvalrad, idx)
      }
    } else {
      const keystr = `${val?.name},${val?.region ? val?.region : ''},${val?.country_name ? val?.country_name : ''} `
      if (keystr) {
        getlocationForMap(keystr, val, idx)
      }
    }

  }


// dyncamic targeting
  const handleLocationRadiusSlider = (locationId, newValue, idx) => {

    const updatedLocations = [...selectedLocations];
    updatedLocations[idx].radius = newValue;
    setSelectedLocations(updatedLocations);


  };

// dynamic targeting
  const handleRemoveSelectedLocation = (location) => {
    setSelectedLocations(
      selectedLocations.filter((item) => item.key !== location?.key)
    );
    setLocationRadiusValue(prevRadius => {
      const updatedRadius = { ...prevRadius };
      delete updatedRadius[location?.key];
      return updatedRadius;
    });

  }


// dynamic targeting
  const HandleCenterLocation = (val) => {

    setSingLocationCoordinated(val)



  }

  // dynamic targeting
  const handleIncludeExclude = (e) => {
    setSelectIncludeExclude(e.target.value)
    setTargettingLocation([])
  }

// dynamic tageting
  const handleClick = (event, val) => {
    setAnchorEl(event.currentTarget);
    setSelectedVal(val);
  };


  const handleClose = () => {
    setAnchorEl(null);
    setSelectedVal(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleIncreaseBudget = (e) => {
    e.preventDefault();

    if (lifeTimeBudget >= 10000 && lifeTimeBudget <= 1000000) {
      setLifeTimeBudget(prevBudget => Math.min(prevBudget + 2000, 1000000));
    }

  }

  const handleDecreaseBudget = (e) => {
    e.preventDefault();
    if (lifeTimeBudget >= 10000 && lifeTimeBudget <= 1000000) {
      setLifeTimeBudget(prevBudget => Math.max(prevBudget - 2000, 10000));
    }

  }

  const handleBudgetChange = (e) => {
    const inputVal = e.target.value;

    const isValidInput = inputVal === '' || /^[0-9]+$/.test(inputVal);
    if (!isValidInput) return;

    setLifeTimeBudget(inputVal);


  
  };


  if(loadConfirmAndPay){
    return <PaymentLoader/>
}


const onReferchPage = async (id)=>{
  setLoadRefreshPage(true)
  try {
      const res = await Axios.post("/refresh-page", { page_id: id }, { headers: { Authorization: userToken } });
      if (res.status === 200 && res.data.valid) {
        handleUser();
         
      } else {
          console.log("error", res.data.message);
          handleUser();
      }
  } catch (error) {
      console.log("error", error);
      handleUser();
  }finally{
      setLoadRefreshPage(false)
      handleUser()
  }
}


// subscribe app
const handleSubscribeApp = async (pageid) => {
  try {
      const res = await Axios.post("/subscribe-app", { pageId: pageid });
      if (res.status === 200 && res.data.valid) {
        handleUser();
      }
  } catch (error) {
      console.log("error", error);
  }
};
  
  // console.log("selectFacebookPage", selectFacebookPage)

  // console.log("selectFacebookPage", selectFacebookPage?.length)

  return (
    <>
      <>
        <div className=" d-md-none">
          <Navbar2 menu={true} />
        </div>
        <div className="contaoner-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 order-md-1">
                <div className="d-none d-md-block">
                  <WebStepper steps={stepweb} active={2} />
                </div>
                <div
                  className="mt-4 mb-2 mb-md-0"
                  style={{ paddingTop: 20, paddingBottom: 10 }}
                >
                  <Box sx={{ width: "100%", }} className="mt-3 d-md-none ">

                    <ThemeProvider theme={theme}>
                      <Stepper activeStep={2} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label} sx={{ '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': { fill: 'white' }, '& .MuiStepLabel-root .Mui-active': { color: 'rgba(102, 34, 204, 1)' }, '& .MuiStepLabel-root .Mui-completed': { color: 'rgba(102, 34, 204, 1)' } }}>
                            <StepLabel sx={{
                              '& .Mui-active': {
                                color: 'secondary.main',
                              },
                              '& .Mui-completed': {
                                color: 'secondary.main', // Active label color
                              },

                            }} style={{ fontSize: 10 }}>
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </ThemeProvider>
                  </Box>
                </div>
              </div>

              <div className=" d-md-none my-3 pe-4">
                {setContent?.length > 0 ? <Accordion sx={{ boxShadow: 'none', width: '100%' }} className='border' >
                  <AccordionSummary
                    onClick={() => setExpandedAccordion(!expandedAccordion)}
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: '27px', fontWeight: '400' }} />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
                      <Typography>Summary</Typography>
                      <Typography sx={{ textAlign: 'end', fontSize: '10px', marginRight: '2px' }}>{expandedAccordion ? 'click to collapse' : 'click to expand'}</Typography>
                    </Box>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ paddingLeft: '0.6rem' }}>
                      <p
                        dangerouslySetInnerHTML={{ __html: content }}
                        style={{ textAlign: "justify", color: '#585858', fontSize: '14px' }}

                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion> : null}

              </div>


              <div className="col-12 col-md-6 order-3 order-md-2">
                <div className="d-md-block d-none px-2 mb-3">
                  {setContent?.length > 0 ? <Accordion sx={{ boxShadow: 'none' }} className='border' >
                    <AccordionSummary
                      onClick={() => setExpandedAccordion(!expandedAccordion)}
                      expandIcon={<ExpandMoreIcon sx={{ fontSize: '27px', fontWeight: '400' }} />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
                        <Typography>Summary</Typography>
                        <Typography sx={{ textAlign: 'end', fontSize: '10px', marginRight: '2px' }}>{expandedAccordion ? 'click to collapse' : 'click to expand'}</Typography>
                      </Box>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ paddingLeft: '0.6rem' }}>
                        <p
                          dangerouslySetInnerHTML={{ __html: content }}
                          style={{ textAlign: "justify", color: '#585858', fontSize: '14px' }}

                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion> : null}

                </div>

                {/* location and map */}
                <div className="col-12 pt-0  px-2">

                  <div className='current location rounded px-2 py-0 py-md-1 ' style={{ display: showMapContainer ? 'none' : 'block' }}>


                    <div className="col-12">
                      <label
                        htmlFor=""
                        className="d-block text-start mb-md-2 mt-2"
                      >
                        <b> Locations</b>
                      </label>
                      <div>
                        <div className=" px-2 border py-2 rounded d-flex justify-content-between align-items-center">
                          <div className='d-flex align-items-center'>
                            <WhereToVoteIcon sx={{ color: 'green', fontSize: '22px', marginBottom: '' }} />
                             <span className='text-[14px]'>{laodGeoLocation?'loading...':selectedLocations[0]?.name ? (<>{`${selectedLocations[0]?.name}, ${selectedLocations[0]?.region ? selectedLocations[0]?.region : ''} `}</>) : 'Location Not Found , Please Edit'}</span>
                            {/* <span>{selectedLocations[0]?.name ? (<>{`${selectedLocations[0]?.name}, ${selectedLocations[0]?.region ? selectedLocations[0]?.region : ''} `}</>) : 'Loading...'}</span> */}
                          </div>
                          <div>
                            <IconButton sx={{ display: showMapContainer ? 'none' : 'block' }} size='small' color="primary" aria-label="add to shopping cart" onClick={() => { setShowMapContainer(true); setShowMaps(true) }}>
                              <EditIcon sx={{ fontSize: '20px', color: '#6622cc' }} /><span className='fw-bold' style={{ fontSize: '14px', color: '#6622cc' }}>Edit</span>
                            </IconButton>
                          </div>

                        </div>
                      </div>
                    </div>


                  </div>
                  <div className={showMapContainer?'border rounded mt-md-5 mt-2':'border-none rounded mt-md-0 mt-0'} style={{ height: showMapContainer ? 'auto' : '0px', overflow: 'hidden' }}>
                    <div className='p-3 '>
                      <p className='text-start m-0 p-0 fw-bold mb-1' style={{ fontWeight: '500' }}>Locations</p>
                      <ul>
                        {selectedLocations?.map((val, idx) => (
                          <>
                            {val?.name && <li className='selectedlocationlist mb-2 border px-3 d-flex justify-content-between align-items-center gap-1 rounded selected-location-box' key={val?.key} style={{ fontWeight: 'bold', background: 'white' }}>
                              <div className=' d-flex justify-content-between align-items-center column-gap-2'>
                                <div className='p-0 m-0' style={{ cursor: 'pointer' }} onClick={() => { HandleCenterLocation(val); setShowMaps(true); }}>
                                  {val?.isInclude === 'include' ? <WhereToVoteIcon sx={{ color: 'green', fontSize: '22px', marginBottom: '' }} /> : val?.isInclude === 'exclude' ? <WrongLocationSharpIcon sx={{ color: 'red', fontSize: '22px', marginBottom: '' }} /> : ''}
                                </div>
                                <div className='d-flex justify-content-start p-0 align-items-center' >
                                  <p onClick={() => { HandleCenterLocation(val); setShowMaps(true) }} className='p-0 m-0 selected-location-text' style={{ cursor: 'pointer' }}>{`${val?.name}, ${val.region ? val.region : ''} `}</p>

                                  <div className='position-relative' style={{}}>
                                    {(val?.type === 'city' || val?.type === 'address' || val?.type === 'place') &&

                                      <>
                                        <Button

                                          sx={{ fontSize: '10px', padding: '0px', boxShadow: 'none', marginLeft: '8px' }}
                                          aria-describedby={id}
                                          variant=""
                                          onClick={(event) => handleClick(event, val)}
                                        >
                                          {val?.radius} km <ArrowDropDownIcon />
                                        </Button>
                                        <Popover
                                          id={id}
                                          open={open && selectedVal?.key === val.key}
                                          anchorEl={anchorEl}
                                          onClose={handleClose}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          }}
                                        >
                                          <Box sx={{ width: 170, padding: '6px' }}>
                                            <p className='text-center mb-3' style={{ fontSize: '12px' }}>{val?.type === 'city' ? 'Cities' : val?.type === 'place' ? 'Place' : val.type === 'address' ? 'Address' : 'Cities'} within radius</p>
                                            <MuiSlider
                                              size="small"
                                              // value={locationRadiusValue}
                                              //value={locationRadiusValue[val?.key] || 20 }
                                              value={val?.radius}
                                              aria-label="Small"
                                              valueLabelDisplay="auto"
                                              sx={{ padding: '0px', margin: '0px', marginTop: '8px' }}
                                              onChange={(event, newValue) => handleLocationRadiusSlider(val, newValue, idx)}
                                              //onClick={()=>handleLocationRadiusId(val?.id)}
                                              // valueLabelFormat={valueLabelFormat}
                                              step={10}
                                              defaultValue={val?.radius ? val?.radius : 2}
                                              min={val?.type === 'city' ? 17 : val?.type === 'place' ? 1 : val.type === 'address' ? 1 : 1}
                                              max={80}
                                            />
                                            <div className='d-flex justify-content-between p-0 m-0'>
                                              <span className='m-0 p-0 ' style={{ fontSize: '10px' }}>{val?.type === 'city' ? 17 : val?.type === 'place' ? 1 : val.type === 'address' ? 1 : 1}km</span>
                                              <span className='m-0 p-0 ' style={{ fontSize: '10px' }}>80km</span>
                                            </div>

                                          </Box>
                                        </Popover>
                                      </>
                                    }


                                  </div>


                                </div>
                              </div>

                              <div >
                                <IconButton className='show-hide-deletedselectLocation' aria-label="delete" size="small" onClick={() => handleRemoveSelectedLocation(val)}>
                                  <CloseIcon sx={{ color: 'black', fontSize: '18px' }} />
                                </IconButton>

                              </div>


                            </li>
                            }

                          </>
                        ))}
                      </ul>
                      <div className="mb-3 p-0 d-flex align-items-enter flex-column flex-md-row" >
                        <div className='include-exclude-width mb-3 mb-md-0'>
                          <div className='border rounded-md-start rounded  py-2 px-2 px-md-0 ' style={{ background: 'white' }}>
                            <div className='d-flex align-items-center '>
                              {selectIncludeExclude === 'include' ? (<>
                                <WhereToVoteIcon sx={{ color: 'green', fontSize: '22px', marginBottom: '' }} />

                              </>) : (
                                <>
                                  <WrongLocationSharpIcon sx={{ color: 'red', fontSize: '22px', marginBottom: '' }} />
                                </>
                              )}
                              <select
                                className='select-include-exclude'
                                name=""
                                id=""
                                style={{
                                  border: 'none',
                                  borderBottom: 'none', // Add bottom border to mimic underline
                                  outline: 'none', // Remove default focus outline
                                  // Change background color when an item is selected
                                }}
                                value={selectIncludeExclude}
                                onChange={handleIncludeExclude}
                              >

                                <option className='py-2' style={{ color: 'black', outline: 'none', border: '1px solid black' }} value="include"><p className='p-4'>✓ Include</p></option>
                                <option className='py-2' style={{ color: 'black' }} value="exclude"><p className='p-4'>Add Exclusions</p></option>


                              </select>

                            </div>


                            {/* <WhereToVoteIcon sx={{color:'green', fontSize:'22px', marginBottom:''}}/>
                           Include <ArrowDropDownIcon sx={{color:'black'}}/> */}
                          </div>
                        </div>
                        <div className='position-relative' style={{ width: '100%' }}>
                          <TextField fullWidth size='small' placeholder="Search location" value={searchString} onChange={searchLocation} onFocus={() => { setTargettingLocation([]) }} />
                          <div className='position-absolute rounded' style={{ top: '44px', zIndex: '9999', backgroundColor: 'white', width: '100%' }} >
                            {targettingLocation?.map((option, idx) => (
                              <>
                                <button key={option?.key} className='autocomplete-option d-flex justify-content-between align-items-center py-1 px-2' style={{ border: 'none', outline: 'none', width: '100%', cursor: isGeoLocationFetch?.includes(option?.key) ? 'none' : 'pointer' }} disabled={isGeoLocationFetch?.includes(option?.key)} onClick={() => handleAddedItem(option, idx + 1)}>
                                  <p className='p-0 m-0' style={{ fontSize: '12px' }}>{option?.name},{" "}{option?.region ? option?.region : ''}, {""}{option?.country_code}</p>
                                  <p className='p-0 m-0' style={{ fontSize: '12px' }}><b style={{ padding: '0px 2px', borderRadius: '' }}>{option?.type}</b> </p>
                                </button>
                              </>
                            ))}


                          </div>
                        </div>


                      </div>
                      {!showMaps ? <button style={{ width: '100%', fontSize: '12px' }} className='border d-flex align-items-center rounded' onClick={() => setShowMaps(true)}><span>Show map</span><ArrowDropDownIcon sx={{ color: 'black', marginLeft: 'auto' }} /></button> : ''}
                    </div>


                    <div style={{ height: showMaps ? 'auto' : '0px' }} className='position-relative'>
                      <TargetingLocaionWithRadius showSearchForMap={showSearchForMap} locationCoordinates={selectedLocations} locationRadiusValue={locationRadiusValue} mapRef={mapRef} singLocationCoordinated={singLocationCoordinated} />
                      <Tooltip title="Hide Map" placement="top" arrow>
                        <button className="hidemap position-absolute" onClick={() => setShowMaps(false)} style={{ top: '8px', right: '8px', zIndex: '999', color: 'rgb(28, 30, 33)', backgroundColor: 'rgba(0, 0, 0, 0.1)', borderColor: 'rgba(0, 0, 0, 0.05)' }}> <KeyboardArrowDownIcon /></button>

                      </Tooltip>
                    </div>
                  </div>

                </div>
                {/* end show map and location */}

               
               
               
               
                <div className="form-card pt-0 rounded">

                  <form>


                    {/* <ManageAdPreview userDetail={userDetail}/> */}
                    {!isUserDetailsLoad ? (<>
                      {connectedPages?.length > 0 ? (
                        <div
                          style={{
                            width: "100%",
                            justifyContent: "flex-start",
                            display: "flex",
                            flexDirection: "column",
                            paddingTop: "2%",
                            paddingBottom: "2%",
                          }}
                        >

                          <label
                            htmlFor="weburl"
                            className="d-block text-start mb-md-2 mt-2"
                          >
                            <b> Facebook page*</b>
                          </label>
                          <Dropdown
                            value={selectFacebookPage}
                            onChange={(e) => {setSelectFacebookPage(e.value); setErrorSelectPages("")}}
                            options={connectedPages}
                            optionLabel="name"
                            className='border'
                            placeholder="Select a Facebook page"
                            style={{ width: "100%", textAlign: "left" }}
                            itemTemplate={itemTemplate}
                          />
                          {selectFacebookPage?.hasOwnProperty('connected')?<>{(selectFacebookPage?.leadgen_tos_accepted === false) && <p className='addpages-p p-0 m-0 mt-[4px] mt-md-0 text-start text-[12px] text-danger'>  TOS Not Accepted For This Page, <a className='m-0 p-0 text-[12px]' href="https://www.facebook.com/ads/leadgen/tos" target='_blank' style={{ color: 'black' }}><b className='p-0 m-0'>click here</b></a> to accept and then <button  onClick={()=>onReferchPage(selectFacebookPage.id)} disabled={loadRefreshPage?true:false} className='p-0 m-0 flex items-center justify-start'><span className='text-black font-semibold text-[12px]'>Refresh</span> <RefreshCw className={`text-black font-semibold h-[14px] ${loadRefreshPage?'refreshpage':''}`} /></button> </p>}</>:''} 
                         {selectFacebookPage?.hasOwnProperty('connected')?<> {((selectFacebookPage?.subscribed_apps?.data[0]?.id !== '1052762895750334') || (!selectFacebookPage?.subscribed_apps?.data[0]?.subscribed_fields.includes("leadgen"))) && <p className='addpages-p text-start text-danger p-0 m-0 mt-[4px] mt-md-0'>  App Subscription, <span onClick={() => handleSubscribeApp(selectFacebookPage?.id)} style={{ color: 'black', cursor: 'pointer' }}><b>click here</b></span> to retry</p>}</>:""} 
                        
                         
                          {errorSelectPages?.length>0 && <p className='text-danger text-start pt-0 mt-0 mb-md-2' style={{fontSize:'12px'}}> Please select facebook pages</p>}
                       
                        </div>
                      ) : (
                        <>

                          <div className='text-start'>
                            <p className='ps-1 text-[14px] md:text-[16px] text-danger '>Sorry, You don't have any successfully connected Facebook pages. Please connect at least one Facebook page to launch your ad.</p>
                          <ButtonShadcn onClick={()=>navigate("/facebook-page")}>Connect Facebook Page ↗</ButtonShadcn>

                          </div>
                          {/* <ConnectToFbAndSelectPages forAccountInfo={2} handleUser={handleUser} forsubmit={2} />
                          <div
                            className='mt-0'
                            style={{
                              display: "flex",
                              justifyContent: "center",

                              marginBottom: "5%",
                            }}
                          >

                          </div> */}
                        </>
                      )}

                    </>) : (<>
                      <div className=' mt-2 text-start'>
                        <b className='m-0 p-0'>Facebook page</b>
                        <Typography sx={{ padding: '0px', margin: '0px' }} variant='h2'> <Skeleton /></Typography>
                      </div>
                    </>)}


                    <div className="mb-3 text-start mt-1 mt-md-3">
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <b style={{ color: "black" }}>
                            Campaign duration
                          </b>
                        </FormLabel>
                        <RadioGroup
                          defaultValue="30"
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={value?.Day}
                          onChange={handleDays}
                        >
                          <div
                            className={
                              value?.Day === "15"
                                ? "checkbox"
                                : "checkbox1"
                            }
                          >
                            <FormControlLabel
                              value="15"
                              control={
                                <Radio style={{ color: " #6622CC" }} />
                              }
                              label="15 Days"
                            />
                          </div>
                          <div
                            className={
                              value?.Day === "30"
                                ? "checkbox"
                                : "checkbox1"
                            }
                          >
                            <FormControlLabel
                              value="30"
                              control={
                                <Radio style={{ color: " #6622CC" }} />
                              }
                              label="30 Days"
                            />
                          </div>
                          <div
                            className={
                              value?.Day === "60"
                                ? "checkbox"
                                : "checkbox1"
                            }
                          >
                            <FormControlLabel
                              value="60"
                              control={
                                <Radio style={{ color: " #6622CC" }} />
                              }
                              label="60 Days"
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="budget-slider-container rounded">
                      <div className='mb-3 mb-md-3'>
                        <div>
                          <label
                            htmlFor="weburl"
                            className="d-block text-start "
                          >
                            <b>Lock your budget</b>
                          </label>
                          <div
                            style={{
                              color: "#262626",
                              fontSize: 25,
                              fontWeight: "700",
                              textAlign: "left",
                            }}
                            className='mb-1'
                          >

                            {/* <div className='py-4'>
                              <span className="">
                               
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                  <span style={{ }}><CurrencyRupeeIcon
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "700",
                                    }}
                                  /></span>
                                  
                                  <input
                                    type="text"
                                    value={lifeTimeBudget}
                                    onChange={handleBudgetChange}
                                    style={{
                                      fontSize: '25px',
                                      fontWeight: '700',
                                      width: '150px',
                                      outline: 'none',
                                      textAlign: 'center',
                                      borderTop: 'none',
                                      borderLeft: 'none',
                                      borderRight: 'none',
                                      paddingLeft: '25px', // Ensure the text doesn't overlap the rupee symbol
                                    }}
                                  />
                                </div>

                              </span>
                            </div> */}

                            {/* <p className='' style={{fontSize:'10px', fontWeight:'600'}}>(Inclusive of GST)</p> */}
                          </div>
                        </div>
                      </div>

                      {/* BUDGET INCREAMENT */}


                      <div className=" budget-increament-container  d-flex flex-column justify-content-center align-items-start" >
                        <div className="budget-increament border-icndec-container  d-flex justify-content-center align-items-center">
                          <button onClick={handleDecreaseBudget} className=' button-inc border-inc'>-</button>
                          <input
                            type="text"
                            value={lifeTimeBudget}
                            onChange={handleBudgetChange}
                            className='input-budget-inc'

                          />
                          <button onClick={handleIncreaseBudget} className='button-inc border-dec '>+</button>
                        </div>
                        {/* slider */}
                        <div className="min-max d-flex  mb-2 mt-2 px-0">
                          <div className='p-0'>
                            <p>Min</p>
                            <p>10,000</p>
                          </div>
                          <div>
                            <h6>Max</h6>
                            <h6>10,00,000</h6>
                          </div>
                        </div>
                      </div>

                      {/* Budget Increament end */}

                      <div className="mb-3 mt-3 px-2 px-md-2 text-center ">
                        {/* <Slider
                          value={sliderValue}
                          onChange={(e) => {
                            handleSliderChange(e.value)
                          }}
                          min={10000}
                          max={1000000}
                          step={2000}
                        
                        /> */}


                        <div className=''>

                          {(Number(lifeTimeBudget) >= 10000 && Number(lifeTimeBudget) <= 1000000) && <span
                            style={{
                              fontSize: "52px",
                              fontWeight: "800",
                              margin: 0,
                            }}
                          >
                            ~{createProMessage?.int_price
                              ? (
                                //  leadsresult
                                Math.round(Number(lifeTimeBudget) / createProMessage?.cpl)

                              )
                              : 0}

                          </span>}

                          <p className='text-center' style={{ margin: 0 }}>
                            <Person2Icon style={{ color: "#000000" }} />
                            {(Number(lifeTimeBudget) >= 10000 && Number(lifeTimeBudget) <= 1000000) ? 'Estimated Leads' : Number(lifeTimeBudget) < 10000 ? <span className='text-danger'>Minimum budget is 10,000</span> : Number(lifeTimeBudget) > 1000000 ? <span className='text-danger'>Maximum budget is 10,00,000</span> : ''}

                          </p>
                        </div>
                      </div>


                      {/* leapX wallet balance */}
                      <div className="container">

                        <div className="row p-0 mb-3">
                          {/* campaign budget */}
                          <div className="col-8 p-0 text-start" style={{ fontSize: '14px', fontWeight: '600' }}>
                            <span>Campaign Budget (Excl. GST) :</span>
                          </div>
                          <div className="col-4 p-0 text-end">
                            <b> <CurrencyRupeeIcon
                              style={{
                                fontSize: "14px",
                                marginBottom: '2px',
                                fontWeight: "700",
                              }}
                            /> {lifeTimeBudget}</b>
                          </div>

                          {/* GST */}

                          <div className="col-8 p-0 text-start" style={{ fontSize: '14px', fontWeight: '600' }}>
                            <b>


                            </b>  <span>GST (18%)</span>
                          </div>
                          <div className="col-4 p-0 text-end">
                            <b>
                              <CurrencyRupeeIcon
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  marginBottom: '2px',
                                }}
                              />
                              {Math.floor(Number(lifeTimeBudget) * (18 / 100))}

                            </b>
                          </div>

                          {/* TOTLAL CAMPAIGN BUDGET */}
                          <div className="col-8 p-0 text-start" style={{ fontSize: '14px', fontWeight: '600' }}>
                            <span>Total Campaign Budget (Incl. GST) :</span>
                          </div>
                          <div className="col-4 p-0 text-end">
                            <b> <CurrencyRupeeIcon
                              style={{
                                fontSize: "14px",
                                marginBottom: '2px',
                                fontWeight: "700",
                              }}
                            /> {Math.floor(Number(lifeTimeBudget) + (Number(lifeTimeBudget) * (18 / 100)))}</b>
                          </div>

                          <div className="col-8 p-0 text-start" style={{ fontSize: '14px', fontWeight: '600' }}>
                            <span>Wallet Balance :</span>
                          </div>
                          <div className="col-4 p-0 text-end">
                            <b> <CurrencyRupeeIcon
                              style={{
                                fontSize: "14px",
                                marginBottom: '2px',
                                fontWeight: "700",
                              }}
                            /> {Math.floor(userDetail?.wallet)}</b>
                          </div>
                          <div className="col-8 p-0 text-start" style={{ fontSize: '14px', fontWeight: '600' }}>
                            <span>Remaining Payable Amount:</span>
                          </div>


                          <div className="col-4 p-0 text-end" >
                            <b>
                              <CurrencyRupeeIcon
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  marginBottom: '2px',
                                }}
                              />{Math.floor(amountPayble)}
                              {

                              }
                            </b>
                          </div>

                        </div>
                      </div>

                      <div className='d-flex flex-start justify-content-start align-items-start'>
                        <Checkbox {...label}
                          checked={privacypolicyCheck}
                          onChange={(e) => setPrivacypolicyChecked(e.target.checked)}
                          sx={{
                            padding: '0px',
                            margin: '0px',
                            color: 'red',
                            '&.Mui-checked': {
                              color: '#6622cc',
                            },
                          }}
                        />
                        <small style={{ fontSize: '12px', fontWeight: '400', textAlign: 'start' }}>By checking this box, you agree to our <a href="https://www.leapx.ai/terms-of-use" target='_blank' style={{ color: '#6622cc' }}>Terms of Use</a> & <a href="https://www.leapx.ai/privacy-policy" target='_blank' style={{ color: '#6622cc' }}>Privacy Policy</a></small>
                      </div>
                      <div className="mt-4  confirmandpay-btn d-flex  flex-column flex-md-row-reverse flex-md-wrap align-items-md-start align-items-center justify-content-md-end">
                        {
                          (privacypolicyCheck && (Number(lifeTimeBudget) >= 10000 && Number(lifeTimeBudget) <= 1000000)) ? (<>
                             <div>
                             <button
                              className="submit-button5 ms-2 btn-confirm-pay"
                              style={{ backgroundColor: '#62c', cursor: isConfirm ? 'not-allowed' : 'pointer' }}
                              type="button"
                              disabled={isConfirm}
                              onClick={() => OnConfirmAndLaunch()}
                            >
                              Confirm & Pay
                            </button>
                              {errorSelectPages?.length>0 && <p className='text-danger text-start pt-0 mt-0 mb-md-2'> Please select facebook pages</p> }
                             
                             </div> 
                            
                            
                          </>) : (<>
                            <button
                              className="submit-button5 ms-2 border btn-confirm-pay"
                              style={{ backgroundColor: '#a799be' }}
                              type="button"

                            >
                              Confirm & Pay
                            </button>
                            
                          </>)
                        }

                        <div className="text-center p-0 "  >
                          <div className='' style={{ width: '100%' }}>

                            <Button variant='outlined' className='d-md-inline d-none' onClick={() => navigate("/review-details", {
                              state: {
                                status: 0,
                                propertyId: state.propertyId,
                                campaignDfId: state?.campaignDfId

                              },
                            })} sx={{ border: '1px solid #6622CC', color: '#6622CC', padding: '10px 20px', width: '100%', }} startIcon={<ArrowBackIcon />}>Back</Button>
                          </div>

                          <span
                            className='d-block d-md-none'
                            onClick={() => navigate("/review-details", {
                              state: {
                                status: 0,
                                propertyId: state.propertyId,
                                campaignDfId: state?.campaignDfId

                              },
                            })}
                            style={{
                              color: "black",

                              marginTop: "8px",
                              display: "",

                              fontWeight: "700",
                              cursor: "pointer",
                              fontSize: 16,
                            }}
                          >
                            <ArrowBackIcon />
                            BACK
                          </span>
                        </div>
                      </div>
                    {!isOnline &&  <div className='mb-2'>
                       <p className="text-danger text-start ps-2 mb-3 mt-0 m-0 p-0 " style={{ fontSize: '14px' }}>You are offline.</p> 
                      </div>}  
                   
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-12 col-md-6 pt-0  pe-4 pe-md-0 order-2 order-md-3">
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none"><BottomNavbar isPressed={true} whichOnePressed={2} /></div>
      </>
    </>
  )
}

export default AdCampaignSummeryAndPay