import React, { useContext, useEffect, useState } from 'react'
import './webhome.css'
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import rupes from "../../asets/rupess.png";
import '../../homepage/home.css'
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WebHomeTotalOverview from './web-homepage-component/WebHomeTotalOverview'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import checkedicon from '../../asets/webimages/home/checked.png'
import drafticon from '../../asets/webimages/home/drafticon.png'
import pausedicon from '../../asets/webimages/home/paused.png'
import WebHomeGraph from './web-homepage-component/WebHomeGraph'
import WebHomeIpressionViewSpentCard from './web-homepage-component/WebHomeIpressionViewSpentCard'
import Axios from '../../Axios';
import { AuthContext } from '../../AuthProvider';
// import RecentTranssection from './web-homepage-component/RecentTranssection';
import HomePageSlider from '../../components/Cards/HomePageSlider';
import { io } from 'socket.io-client';
import { useLocation, useParams } from 'react-router-dom';
import { ProfilePageContext } from '../../context/ProfilePageContext';
import { Toaster, toast as hotTost } from 'react-hot-toast';
// import { useIntercom } from 'react-use-intercom';
// import { useIntercom } from 'react-use-intercom';
// import IntercomProviderPage from '@/src/intercom-chat/IntercomProvider';
// import GradientCircularProgress from './web-homepage-component/CircularProgresh';
// import { useIntercom } from 'react-use-intercom'
// import WebWalletCard from './web-homepage-component/WebWalletCard';

 

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// const socket = io('https://demo.leapxads.com/socket.io',{ transports: ['websocket'], withCredentials: false });
 
function WebHomepage() {
  const { userToken, userDetails, allFbData, isFbDataLoader, isCampLoading, callApiCount, setCallApiCount, handleAllFBApiCall, fetchCampaignInsites, leadFromSocket } = useContext(AuthContext);
  const { getUserProfileData} = useContext(ProfilePageContext);
  const [campaignCardData, setCampaignCardData] = useState([])
  const [walletCardData, setWalletCardData] = useState("")
  const [totalLeadsCardData, setTotalLeadsCardData] = useState("")
  const [graphIpmression, setGraphIpmression] = useState([])
  const [userData, setUserData] = useState("")
  const [datePints, setDatePints] = useState([])
  const [dateStartEnd, setDateStartEnd] = useState({startDate:null, endDate:null})
 

  const query = useQuery();
  const token = query?.get('token');
  // const { boot, shutdown } = useIntercom();

  const formatName = (name) => {

    let trimmedName = name.replace(/\d+$/, '').replace(/_+$/, '');

    trimmedName = trimmedName.replace(/_/g, ' ').trim();

    return trimmedName.replace(/\s+/g, ' ');
  };


  useEffect(() => {
    if (callApiCount === 4) {
      handleAllFBApiCall(userToken)
      fetchCampaignInsites(userToken)
      setCallApiCount(0)
    }


  }, [callApiCount])





  // verifyemail
  const VerifyEmail = async (verifytoken)=>{
       try {
         const res = await Axios.get("/verify-email", {headers:{Authorization:userToken}, params:{token:verifytoken}})

           if(res.status===200 && res?.data?.valid){
            hotTost.success("Email Verified")
              // console.log("Email Verified")
           }
       } catch (error) {
         console.log("error", error)
       }
  }


useEffect(()=>{
    if(token){
      VerifyEmail(token)
    }

},[token])



  const HandleHomePageData = async (allFbData) => {

      


        // console.log("fbdata", allFbData?.slice(0, 4))

  const graphdataArr = []


    let walletbalancecard = {
      wallet_balance: 0,
      total_spend: 0,
      deployed: 0,
      total_spend_till_date: 0,
    };

    let totalleadsCard = {
      total_leads: 0,
      active_campaign: 0,
      complete_campaing: 0,
      under_review_campaing: 0,
      draft_campaign: 0,
      paused_campaign: 0,

    };

    let graphimpression = {
      impression: 0,
      views: 0,
      spend: 0,
      linkclicks:0,
    };

    let graphData1 = [];
    let dateArr = []

    let campigncard = [];

    // console.log("allfbdata", allFbData[0]?.createdAt)
    //    console.log("allfbdata2", allFbData[allFbData.length-1]?.createdAt)

       function formatDate(dateStr) {
        const date = new Date(dateStr);
    
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getUTCDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }


    //  console.log("allFbData",)
   const datestart =  allFbData[0]?.createdAt;
   const dateend = allFbData[allFbData.length-1]?.createdAt

  // console.log("allFbData[allFbData.length-1]", allFbData[allFbData.length-1]?.insights?.data)

    if(datestart && dateend){
            // change date format
        const startingdate = formatDate(datestart)
        const endingdate = formatDate(dateend)
        // console.log("enddate", endingdate)

        setDateStartEnd((prev)=>({...prev, startDate:startingdate, endDate:endingdate}))
    }

   


      
    allFbData.forEach((val) => {
      //  console.log("val", val);
      //leads count
      let leadscount = 0;
      let impressions = 0;
      let clicks = 0;
      let spent = 0;
      let linkClicks = 0;

      //  console.log("missing date", val)

      //parseInt(item?.data?.actions ? item?.data?.actions?.find((action) => action?.action_type === 'lead')?.value : 0);
      val?.insights?.data?.forEach((val) => {
          

        // console.log("leads", val);
        leadscount = leadscount + (parseInt(val?.actions?.length>0 ? val?.actions?.find((action)=>action?.action_type==='lead')?.value || 0 : 0));
        //total leads card -> total leads

        totalleadsCard.total_leads =
          totalleadsCard?.total_leads +
          (parseInt(val?.actions?.length ? val?.actions?.find((action)=>action?.action_type==='lead')?.value || 0 : 0));

          // // impressions
          // console.log("clicks", val?.clicks)
          // console.log("spent", val?.spend)
          // console.log("impressions", val?.impressions)

          graphimpression.impression = graphimpression.impression + (val?.impressions?Number(val?.impressions): 0 );


          impressions = impressions + (Number(val?.impressions) || 0 )

          //clicks

         
        graphimpression.views = graphimpression.views + (val?.clicks?Number(val?.clicks):0 );


          clicks = clicks + (val?.clicks || 0 );

          //spent
          graphimpression.spend = graphimpression.spend + (val?.spend?Number(val?.spend):0  );
          spent = spent + (val?.spend?Number(val?.spend):0  );

          // linkClicks
          linkClicks = linkClicks + (parseInt(val?.actions?.length>0 ? val?.actions?.find((action)=>action?.action_type==='link_click')?.value || 0 : 0));
          graphimpression.linkclicks = graphimpression.linkclicks +  (parseInt(val?.actions?.length>0 ? val?.actions?.find((action)=>action?.action_type==='link_click')?.value || 0 : 0));
        });

      const obj1 = {
        campaign_id: val?.id,
        name: val?.name ? val?.name : '',
        lifetime_budget: (val?.lifetime_budget),
        status: val?.status,
        impressions: impressions,
        spend: Number(spent),
        leads_count: leadscount,
        _id: val?.dbId,
        date: val?.createdAt
      };
      if (obj1?.name) {
        campigncard?.push(obj1);
      }

      // walletcard

      if (val?.status === "ACTIVE" || val?.status === "PAUSED") {
        walletbalancecard.total_spend =
          walletbalancecard?.total_spend + Math.round((Number(spent)));
      }
      if (val?.status && spent) {
        //  console.log("valvv", val?.insights?.data[0]?.spend)

        walletbalancecard.total_spend_till_date =
          walletbalancecard?.total_spend_till_date +
          Math.round((Number(spent)))
      }
      if (val?.status === "ACTIVE" || val?.status === "PAUSED" || val?.status === "UNDER REVIEW") {
        walletbalancecard.deployed =
          walletbalancecard?.deployed + Math.round((Number(val?.lifetime_budget)));
      }
      if (val.status === "STOPPED") {
        walletbalancecard.wallet_balance =
          walletbalancecard.wallet_balance + Math.round((Number(val?.budget_remaining)));
      }

      if (val?.status === "ACTIVE" || val?.status === "UNDER REVIEW" || val?.status === "PAUSED") {
        totalleadsCard.active_campaign = totalleadsCard.active_campaign + 1;
      } if (val?.status === "DRAFT") {
        totalleadsCard.draft_campaign = totalleadsCard?.draft_campaign + 1;
      } if (val?.status === "PAUSED") {
        totalleadsCard.paused_campaign =
          totalleadsCard?.paused_campaign + 1;
      } if (val?.status === "COMPLETED" || val?.status === "STOPPED") {
        totalleadsCard.complete_campaing =
          totalleadsCard?.complete_campaing + 1;
      } if (val?.status === "UNDER REVIEW") {
        totalleadsCard.under_review_campaing =
          totalleadsCard?.under_review_campaing + 1;
      }

      //grapgh data
        //  console.log("val", val)
        
         if (val?.insights?.data?.length) {
            // console.log("val?.insights?.data", val?.insights?.data)
          const datapoints = val?.insights?.data?.map(item => {
            const leadAction = item.actions?.find(action => action.action_type === "lead");
            const leadValue = leadAction ? parseInt(leadAction.value, 10) : 0;
            dateArr?.push(item.date_start)
            const date = new Date(item.date_start).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short'
            }).replace(' ', ' ');
            return { date, value: leadValue };
          });

  
  
          if (datapoints) {

            // console.log("graph", datapoints)
            
            graphdataArr?.push(datapoints)
          }
  
        }



    });
    if (campigncard?.length) {
      setCampaignCardData(campigncard);
     

      // console.log("cama", campigncard);
    }

    if (walletbalancecard) {
      //  console.log("walletbalancecard", walletbalancecard);
      setWalletCardData(walletbalancecard);
    }
    if (totalleadsCard) {
      // console.log("walletbalancecard", totalleadsCard);
      setTotalLeadsCardData(totalleadsCard);
    }
    if(graphimpression){
      setGraphIpmression(graphimpression)
    }
    ///graphdata
    if (graphData1?.length > 0) {



      //  new calculation for graph date 29-3-2-24
      // Function to get the date in the format "dd MMM"
      function getFormattedDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        return date.toLocaleDateString('en-US', options);
      }

      // Function to count occurrences of dates within the last 14 days
      function countOccurrences(dates) {
        const dateCounts = {};
        const currentDate = new Date();

        for (const date of dates) {
          const currentDateObj = new Date(date);
          const diffTime = Math.abs(currentDate - currentDateObj);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (diffDays <= 360) {
            const formattedDate = getFormattedDate(date);
            dateCounts[formattedDate] = (dateCounts[formattedDate] || 0) + 1;
          }
        }

        return dateCounts;
      }

      // Function to generate data points array
      function generateDataPoints(dateCounts) {
        const dataPoints = [];
        for (const date in dateCounts) {
          dataPoints.push({ date, value: dateCounts[date] });
        }
        return dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date));
      }

      // Count occurrences for dates within the current year
      const dateCounts = countOccurrences(graphData1);

      // Generate data points array
      const datapoints = generateDataPoints(dateCounts);

      // Output data points

      if (datapoints?.length) {
          // console.log("dates", datapoints)

       



        
      }
      //end new calculation for graph
  // console.log("graphData1", graphData1)
      //  console.log("grapghdata", graphData1);
      const formattedDates = graphData1?.map((lead) => {
        const date = new Date(lead);
        const formattedDate = date.toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        });
        return formattedDate;
      });
      //console.log("fromatterdate", formattedDates);
      if (formattedDates) {
        const resultArray = [];

        const dateCountMap = new Map();

        formattedDates?.forEach((date) => {
          if (dateCountMap.has(date)) {
            dateCountMap.set(date, dateCountMap.get(date) + 1);
          } else {
            dateCountMap.set(date, 1);
          }
        });

        dateCountMap.forEach((value, key) => {
          const formattedDate = key; // You can customize this if needed
          resultArray.unshift({ date: formattedDate, value });
        });

        if (resultArray.length) {
          // console.log("formats", resultArray);
        
        }
        // console.log("graphIpression", graphIpmression);

        // if (graphimpression) {
        //   console.log("graphIpression", graphIpmression);
        //   setGraphIpmression(graphimpression);
        // }
        //console.log();
      }
    }

    
  //graph
  if(graphdataArr?.length>0){
    //  console.log("graphdataarr",graphdataArr.flat())

    const data = graphdataArr.flat()
    // console.log("datffa", data)
    const aggregatedData = Object.values(data.reduce((acc, curr) => {
      if (acc[curr.date]) {
        acc[curr.date].value += curr.value;
      } else {
        acc[curr.date] = { date: curr.date, value: curr.value };
      }
      return acc;
    }, {}));
     if(aggregatedData){

    //  console.log("aggregatedData", aggregatedData)

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      function parseDate(dateStr) {
        const [day, month] = dateStr.split(" ");
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = monthNames.indexOf(month);
        return new Date(2024, monthIndex, parseInt(day));
    }


    function fillMissingDates(data) {
      // Parse the date strings into Date objects for easier comparison
      data = data.map(entry => ({
          ...entry,
          date: new Date(entry.date + " 2024") // Assuming the year 2024; adjust as needed
      }));
    
      // Sort the data by date
      data.sort((a, b) => a.date - b.date);
    
      const filledData = [];
      let currentDate = new Date(data[0].date);
      const endDate = new Date(data[data.length - 1].date);
    
      while (currentDate <= endDate) {
          const formattedDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
          const existingEntry = data.find(entry => entry.date.getTime() === currentDate.getTime());
      
          if (existingEntry) {
              filledData.push({
                  date: formattedDate,
                  value: existingEntry.value
              });
          } else {
              filledData.push({
                  date: formattedDate,
                  value: null
              });
          }
      
          // Increment the date by one day
          currentDate.setDate(currentDate.getDate() + 1);
      }
    
      return filledData;
  }
    
    function addMissingDates(data) {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        
        // Create a map of existing dates and their values
        const dateMap = new Map(data.map(item => [item.date, item.value]));
        
        // Determine the range of dates to fill
        const startDate = parseDate(data[0].date);
        const endDate = parseDate(data[data.length - 1].date);
        const result = [];
        
        // Iterate through each day in the range
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            const day = String(d.getDate()).padStart(2, '0');
            const month = monthNames[d.getMonth()];
            const formattedDate = `${day} ${month}`;
    
            // Check if the date exists in the original data
            if (dateMap.has(formattedDate)) {
                const originalValue = dateMap.get(formattedDate);
                result.push({ date: formattedDate, value: originalValue });
            } else {
                result.push({ date: formattedDate, value: null }); // Insert null for missing dates
            }
        }
    
        return result;
    }
    
      // console.log("dateArr", dateArr)
        if(dateArr?.length>0){
        const dateorder = dateArr?.sort((a, b) => new Date(a) - new Date(b));
        const enddate = dateorder[dateorder?.length-1]
           if(enddate?.length){
            setDateStartEnd((prev)=>({...prev, endDate:enddate}))
           }
       
        }
      // const filledData = addMissingDates(aggregatedData);

      const filledData = fillMissingDates(aggregatedData);
// console.log("result",result);
    if (filledData) {
      setDatePints(filledData)
      //  console.log("filldata", filledData)
    }





      //   console.log("datsdaa", aggregatedData)
      //  setDatePints(aggregatedData)

       const totalValue = aggregatedData.reduce((sum, item) => sum + item.value, 0);
      //  console.log("totalvalue", totalValue)
     }

  }

  };

  useEffect(() => {
    HandleHomePageData(allFbData) 
  }, [allFbData?.length])


  // console.log("datePints", datePints )

//via socket

  useEffect(()=>{
    if(leadFromSocket?.id){
      if(campaignCardData?.length>0){ 

        setCampaignCardData(prevData =>
         prevData.map(item =>
           item.campaign_id === leadFromSocket?.id ? { ...item, leads_count: (item.leads_count + 1) } : item
          )
        );
       }
        // update total lead card;
            setTotalLeadsCardData((prev) => ({
              ...prev,
              total_leads: (prev.total_leads || 0) + 1,
            }));
    }
  
},[leadFromSocket])
  // useEffect(() => {


    
  //     function onConnect() {
  //       console.log('Connected to server 123');
  //     }
  
  //     function onDisconnect() {
  //       console.log('Disconnected from server');
  //     }
  
  //     function onFooEvent(lead) {
  //       console.log('New lead received:', lead);
  //     }
  
  //     socket.on('connect', onConnect);
  //     socket.on('disconnect', onDisconnect);
  //     socket.on('newLead', onFooEvent);
  
  //     return () => {
  //       socket.off('connect', onConnect);
  //       socket.off('disconnect', onDisconnect);
  //       socket.off('newLead', onFooEvent);
  //     };
         
  // },[]);



  // const getCampaignProperty = async () => {
  //   try {
  //     const res = await Axios.get("/get-properties", {
  //       headers: {
  //         Authorization: userToken 
  //       }

  //     });
  //     if (res.status === 200) {
  //       const data = res?.data?.message
  //       // console.log("drafttttt", data)
  //       setDraftNumber(data.length)
  //     } 
  //   } catch (error) {
  //     console.log(error.message)
  //   }
  // }

  // useEffect(() => {
  //   getCampaignProperty()
  // }, [])

  useEffect(() => {
    Axios.get("/user", {
      headers: {
        Authorization: userToken,
      },
    })
      .then((r) => {
        setUserData(r.data.user);
      })
      .catch((err) => {
        console.log(err.message);
      });



  }, []);

  // profile context
  useEffect(()=>{
     if(userToken){
      getUserProfileData(userToken)
     }
  },[userToken])


  function formatIndianNumber(number) {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(number);
    return formattedNumber;
  }

  // console.log("userdata", userData)
  // tour for homepage
  // const { startTour } = useIntercom();

  // useEffect(() => {
  //   startTour({ tourId: '542162' });
  // }, [startTour]);


  
// console.log("graph imp", graphIpmression)


  return (
    <>
      <div className="container-fluid p-0 m-0"  >
        <div className="row pe-2 pt-3  p-0 m-0">
          <div className="col-12  col-md-12 order-2 order-lg-1 col-lg-8  p-0 m-0" >
            <div className="row  m-0">
              <p style={{ fontSize: '24px', fontWeight: '700' }}>Overview</p>
              <WebHomeTotalOverview data={totalLeadsCardData} isFbDataLoader={isFbDataLoader} />


              <div className="col-4 col-lg-4 m-0">
                <div className='p-0'>
                  <div className='d-flex justify-content-start thired-overviewcard align-items-center'>
                    <div className='d-flex justify-content-center align-items-center overview-card shadowsm' >

                      <div className='home-overview4-card'>
                        <p className='p-0 m-0 overview-number' > <img src={checkedicon} alt="" className='me-1' />Under review campaign {totalLeadsCardData?.under_review_campaing ? totalLeadsCardData?.under_review_campaing : 0} </p>
                        <p className='p-0 m-0 overview-number' > <img src={pausedicon} alt="" className='me-1' />Paused campaign {totalLeadsCardData?.paused_campaign ? totalLeadsCardData?.paused_campaign : 0}</p>
                        <p className='p-0 m-0 overview-number'> <img src={drafticon} alt="" className='me-1' />Completed campaign {totalLeadsCardData.complete_campaing ? totalLeadsCardData.complete_campaing : 0}</p>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>

            <div className="row  p-0 m-0">
              <div className="col-12 col-lg-8 m-0">
                <WebHomeGraph datePints={datePints} dateStartEnd={dateStartEnd} />
              </div>
              <div className="col-12 col-lg-4 m-0  ">
                <WebHomeIpressionViewSpentCard graphIpmression={graphIpmression} isFbDataLoader={isFbDataLoader} />
              </div>
            </div>

            <div className="row p-0 m-0">

              <div className="col-12 mt-3 py-0 " style={{ width: '100%' }} >

                <HomePageSlider data={campaignCardData} />
                {/* <WebHomeSlider data={campaignCardData}/> */}
              </div>


            </div>

          </div>
          {/* Right section */}
          <div className="col-12  col-md-12 order-1 order-lg-2 col-lg-4 m-0 p-0"   >

            <div className="row  p-0 m-0 justify-content-start pe-lg-4">
              <p style={{ fontSize: '24px', fontWeight: '700' }}>Wallet</p>

              <div className="wallet d-flex justify-content-center align-items-start position-relativ " style={{ maxWidth: '500px' }}>

                <div className="balance  " style={{ width: '48%' }}>
                  <div className='d-flex align-items-center gap-1'>
                    <img src={rupes} className='wallet-iconx' alt="" />
                    <h6 className='p-0 m-0'>Wallet Balance</h6>
                  </div>


                  <p className="">
                    <CurrencyRupeeIcon sx={{ fontSize: { xs: '22px', sm: '18px', md: '22px', lg: '22px', xl: '24px' }, paddingBottom: '0px', marginBottom: '5px' }} />
                    {userData?.wallet
                      ? formatIndianNumber(Math.round(Number(userData?.wallet)))
                      : 0}
                  </p >
                  <div className="company-name" style={{ marginTop: '0px' }}>

                    <h6 className='company-name-wallet'>{userData?.business_name}</h6>
                    <p className='mt-1 spent-till-text'>
                      Total spent till date <br />
                      <CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '12px', md: '9px', lg: '11px', xl: '11px' }, paddingBottom: '0px', marginBottom: '2px' }} />
                      {walletCardData?.total_spend_till_date
                        ? formatIndianNumber(walletCardData?.total_spend_till_date)
                        : 0}{" "}
                    </p>

                  </div>
                </div>
                <div className="  position-relative" style={{ width: '52%' }}>
                  <h6 className="position-absolut text-start active-camp-text" style={{ fontWeight: '500', color: 'white', width: '100%' }}>Active Campaigns</h6>
                  <div className='slider'>
                    <div style={{ minWidth: '70%', maxWidth: '90%' }} className=''  >
                      {/* <h6 className="position-absolut text-center active-camp-text" style={{ fontWeight:'400', color:'white'}}>Active Campaigns</h6> */}

                      <CircularProgressbar
                        value={(walletCardData?.total_spend / walletCardData?.deployed) * 100}
                        text={`${(walletCardData?.total_spend / walletCardData?.deployed) * 100
                            ? Math.floor(
                              (walletCardData?.total_spend / walletCardData?.deployed) *
                              100
                            )
                            : 0
                          }%`}
                        styles={{
                          path: {
                            stroke: `#ffffff`,
                            strokeLinecap: "butt",
                            transition: "stroke-dashoffset 0.5s ease 0s",
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          trail: {
                            stroke: "#FFFFFF83",
                            strokeLinecap: "butt",
                            transform: "rotate(0.25turn)",
                            transformOrigin: "center center",
                          },
                          text: {
                            fill: "#ffffff",
                            fontSize: "16px",
                            fontWeight: 500,
                          },
                          background: {
                            fill: "#3e98c7",
                          },
                        }}
                      />
                    </div>
                    <div style={{ minWidth: '40%', maxWidth: '60%' }} className='d-flex justify-content-center' >
                      <div className='ps-3'>
                        <div className="spend">
                          <h6 className="spent-heading">
                            <div className="circle-type"></div>
                            <span>Spent</span>
                          </h6>

                          <p style={{ fontSize: '9px', marginTop: '2px' }} className="p-0">
                            <CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '12px', md: '9px', lg: '11px', xl: '11px' }, paddingBottom: '0px', marginBottom: '2px' }} />
                            {walletCardData?.total_spend ? formatIndianNumber(walletCardData?.total_spend) : 0}
                          </p>
                        </div>
                        <div className="spend">
                          <h6 className="spent-heading">
                            <div className="circle-type" style={{ opacity: 0.8 }}></div>
                            <span>Deployed</span>
                          </h6>

                          <p style={{ fontSize: '9px', marginTop: '2px' }} className="p-0"> <CurrencyRupeeIcon sx={{ fontSize: { xs: '12px', sm: '12px', md: '9px', lg: '11px', xl: '11px' }, paddingBottom: '0px', marginTop: '-2px' }} /> {walletCardData?.deployed ? formatIndianNumber(walletCardData?.deployed) : 0}</p>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>

            {/* <div>
              <WebWalletCard/>
             </div> */}
            {isCampLoading ? <div className="d-flex ps-3 pe-1 justify-content-center align-items-center m-0 mt-3 " >


              <div className=" py-2 px-2  border shadow-sm" style={{ width: '100%', borderRadius: '16px' }}>
                <p className="text-center " style={{ fontSize: '16px' }}>Campaign creation under progress</p>
                <div className="text-center p-0 m-0">
                  <span class="loader-spinner"></span>
                  {/* <GradientCircularProgress sizevalue={30}/> */}
                </div>

              </div>
            </div> : null}

          </div>
        </div>


      </div>

    </>
  )
}

export default WebHomepage