import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../AuthProvider';
import Axios from '../../Axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProfileCompleteCard from './components/ProfileCompleteCard';
import { Input } from '../../components/ui/input';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import { Card } from '../../components/ui/card';
import Navbar2 from '../../navbar/Navbar2';
import { Button } from '../../components/ui/button';
import BottomNavbar from '../../components/bottomNavbar/BottomNavbar';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { ProfilePageContext } from '../../context/ProfilePageContext';
import { imageListClasses } from '@mui/material';

function BusinessDetails() {
  const {getUserProfileData, userData, accountPercentage, userName, fbpagePercentage, businessPercentage, notificationPercentage, overallPercentage} = useContext(ProfilePageContext);
  const { userToken } = useContext(AuthContext);
  const [accountDetailsLoad, setAccountDetailsLoad] = useState(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [businessType, setBusinessType] = useState('');
  const [userLogo, setUserLogo] = useState(false);
  const [apiError, seApiError] = useState("");
  const [logoError, setLogoError] = useState("");

  const validationSchema = Yup.object().shape({
    business_name: Yup.string(),
    gst: Yup.string()
      .nullable()
      .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/i, 'Invalid GST format'),
    pan_no: Yup.string()
      .nullable()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/i, 'Invalid PAN format'),
    business_type: Yup.string(),
    logo: Yup.mixed().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      business_name: '',
      gst: '',
      pan_no: '',
      business_type: '',
      logo: null,
    },
    validationSchema,
    onSubmit: (values) => {
      handleAccountDetails(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      business_name: userProfileData?.business_name || '',
      gst: userProfileData?.gst || '',
      pan_no: userProfileData?.pan_no || '',
      business_type: userProfileData?.business_type || '',
    });
  }, [userProfileData]);

  const handleBusinessTypeChange = (value) => {
    setBusinessType(value);
    formik.setFieldValue('business_type', value);
    formik.setFieldValue('gst', formik.values.gst);
  };

  const handleAccountDetails = async (formData) => {
    setAccountDetailsLoad(true);
    try {
      const data = new FormData();
      for (let key in formData) {
        if (formData[key] !== null && formData[key] !== '') {
          data.append(key, formData[key]);
        }
      }

      const res = await Axios.post("/complete-profile", data, {
        headers: {
          Authorization: userToken,
          'Content-Type': 'multipart/form-data',
        }
      });

      if (res.status === 200 && res.data.valid) {
        console.log("form submitted");
        hotTost.success("Business details updated");
        getUserProfileData(userToken);
      } else {
        seApiError(res?.data?.message);
        hotTost.error(res?.data?.message);
      }
    } catch (error) {
      seApiError("Server error");
      hotTost.error('Server error');
      console.error("Error saving account details", error);
    } finally {
      setAccountDetailsLoad(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      getUserProfileData(userToken);
    }
  }, [userToken]);

  useEffect(() => {
    if (userData) {
      setUserProfileData(userData);
      if (userData?.logo?.length > 0) {
        setUserLogo(true);
      }
    }
  }, [userData]);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB
        setLogoError('File size exceeds 5MB');
        // formik.setFieldValue('logo', null);
      } else {
        setLogoError('');
        formik.setFieldValue('logo', file);
      }
    }
  };

  return (
    <>
      <div className="d-md-none">
        <Navbar2 menu={true} />
      </div>
      <div className="container mx-auto bg-[#FBFBFB]">
        <div className='py-2 d-none d-md-block'>
          <h2 className="ad-main-h mb-2">Business Details</h2>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 mb-5 mb-md-0 order-2 order-md-1">
            <div className='py-0 d-block d-md-none'>
              <h2 className="ad-main-h">Business Details</h2>
            </div>
            <Card className="p-6 shadow-none bg-white">
              <div className='max-w-[400px]'>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-4">
                    <label className="block ad-label">Business Name</label>
                    <Input
                      value={formik.values.business_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      name="business_name"
                      id="business_name"
                      disabled={!!userProfileData?.business_name}
                      placeholder="Enter Your Business Name"
                      className={`w-full h-11 mt-1 shadow-none ${formik.touched.business_name && formik.errors.business_name ? 'border-red-500' : 'border-gray-300'} ${!!userProfileData?.business_name ? 'border-gray-300' : ''}`}
                    />
                    {formik.touched.business_name && formik.errors.business_name && (
                      <p className="text-red-500 text-sm mt-1">{formik.errors.business_name}</p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="block ad-label mb-1">Business Type</label>
                    <Select
                      onValueChange={handleBusinessTypeChange}
                      value={formik.values.business_type}
                      disabled={!!userProfileData?.business_type}
                    >
                      <SelectTrigger className="w-[100%] h-11 shadow-none">
                        <SelectValue placeholder="Select Business Type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="private-limited-company">Private Limited Company</SelectItem>
                          <SelectItem value="public-limited">Public Limited Company</SelectItem>
                          <SelectItem value="limited-liability">Limited Liability Partnership</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {/* {formik.touched.business_type && formik.errors.business_type && (
                      <p className="text-red-500 text-sm mt-1">{formik.errors.business_type}</p>
                    )} */}
                  </div>

                 
                    <div className="mb-4">
                      <label className="block ad-label">GST</label>
                      <Input
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type="text"
                        name="gst"
                        id="gst"
                        disabled={!!userProfileData?.gst}
                        placeholder="Enter Your GST"
                        className={`shadow-none h-11 w-full mt-1 ${formik.touched.gst && formik.errors.gst ? 'border-red-500' : ''}`}
                      />
                      {formik.touched.gst && formik.errors.gst && (
                        <p className="text-red-500 text-sm mt-1">{formik.errors.gst}</p>
                      )}
                    </div>
                

                  <div className="mb-4">
                    <label className="block ad-label">PAN</label>
                    <Input
                      value={formik.values.pan_no}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      name="pan_no"
                      id="pan_no"
                      disabled={!!userProfileData?.pan_no}
                      placeholder="Enter Your PAN Number"
                      style={{ border: '1px solid rgba(228, 228, 231, 1)' }}
                      className={`shadow-none h-11 w-full mt-1 ${formik.touched.pan_no && formik.errors.pan_no ? 'border-red-500' : 'border-input'}`}
                    />
                    {formik.touched.pan_no && formik.errors.pan_no && (
                      <p className="text-red-500 text-sm mt-1">{formik.errors.pan_no}</p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="block ad-label mb-1">Logo (Optional)</label>
                    {userData?.logo?.length > 0 ? (
                      <div className='border max-w-[120px] rounded p-1'>
                        <img src={userData?.logo} className='w-[100%] h-auto' alt="logo" />
                      </div>
                    ) : (
                      <>
                        <Input
                          className="shadow-none w-full cursor-pointer hover:border-[#62c] focus:outline-none"
                          id="logo"
                          type="file"
                          name="logo"
                          accept=".jpg,.jpeg,.png,.svg,.webp"
                          onChange={handleLogoChange}
                        />
                        <p className='text-[10px] p-0 m-0 mt-1 text-gray-400'>Image Format: JPG, JPEG, PNG, SVG, WEBP | Size: Max 5MB</p>
                        {logoError && (
                          <p className="text-red-500 text-sm p-0 m-0 mt-1">{logoError}</p>
                        )}
                        {formik.touched.logo && formik.errors.logo && (
                          <p className="text-red-500 p-0 m-0 text-sm mt-1">{formik.errors.logo}</p>
                        )}
                      </>
                    )}
                  </div>

                  <div className="mb-4">
                    <Button type="submit" className="w-[170px] bg-[#62c] shadow-none hover:bg-[#62c]" disabled={accountDetailsLoad}>
                      Save
                    </Button>

                    {apiError ? <p className='text-start text-sm text-red-500'>{apiError}</p> : ''}
                  </div>
                </form>
              </div>
            </Card>
          </div>

          <div className="col-12 order-md-2 order-1 col-md-4 d-flex justify-content-center align-items-start">
            <ProfileCompleteCard accountPercentage={accountPercentage} userName={userName} fbpagePercentage={fbpagePercentage} businessPercentage={businessPercentage} notificationPercentage={notificationPercentage} overallPercentage={overallPercentage} />
          </div>
        </div>
      </div>
      <div className="d-block d-md-none"><BottomNavbar /></div>
    </>
  );
}

export default BusinessDetails;
