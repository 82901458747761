import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
const ArticleLoader = () => {
  const location = useLocation();
  const { Intercom } = useIntercom();
  useEffect(() => {
    const loadArticle = async () => {
      let articleId = '';
     

      switch (location.pathname) {
        case '/':
          articleId = '9194841'; // Article ID for the home page article
          break;
        case '/login':
          articleId = '9183963'; // Article ID for the login page article
          break;
        default:
          articleId = '9183963'; // Default article ID
      }

      try {
        await Intercom.showMessenger(); // Open the messenger
        await Intercom.showNewMessage(`article:${articleId}`); // Show new message with article ID
      } catch (error) {
        console.error('Error showing article in Intercom:', error);
        // Handle errors gracefully, e.g., display a message to the user
      }
    };

    loadArticle();
  }, [location.pathname]);

  return null;
};

export default ArticleLoader;