import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../AuthProvider";

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Cookies from "js-cookie";
import { ProfilePageContext } from "../../../context/ProfilePageContext";


function ConnectToFacebook({handelClientsPage, setToken, fetchFbPageLoad, configId}) {
   
    const {  setFbToken } = useContext(AuthContext);
   
    // const [fetchFbPageLoad, setFetchFbPageLoad] = useState(false)


    useEffect(() => {
        const loadFacebookSDK = () => {
            // Load the JavaScript SDK asynchronously
            const script = document.createElement("script");
            script.id = "facebook-jssdk";
            script.src = "https://connect.facebook.net/en_US/sdk.js";
            script.async = true;

            const firstScript = document.getElementsByTagName("script")[0];
            firstScript.parentNode.insertBefore(script, firstScript);

            // Optional: Clean up the script on component unmount
            return () => {
                const fbSdkScript = document.getElementById("facebook-jssdk");
                if (fbSdkScript) {
                    fbSdkScript.parentNode.removeChild(fbSdkScript);
                }
            };
        };

        window.fbAsyncInit = () => {
            // FB JavaScript SDK configuration and setup
            window.FB.init({
                appId: "1052762895750334",
                // appId: "700560265020731",
                cookie: true,
                xfbml: true,
                version: "v20.0",
            });
        };

        loadFacebookSDK();
    }, []);



    const launchFBE = () => {
        if (window.FB) {
            window.FB.login(
                function (response) {
                    // console.log(response);
                    if (response.authResponse) {
                        const accessToken = response?.authResponse?.accessToken;
                        // console.log("fbresponse", response)
                        setFbToken(accessToken);
                        Cookies.set("fbToken", accessToken, { expires: 7 });

                        handelClientsPage(accessToken);
                        setToken(accessToken);
                        

                    } else {
                        console.log("User cancelled login or did not fully authorize.");
                       
                    }
                },
                {
                 
                    config_id: configId,
                    
                },
               
            );
        }
    };

    // console.log("config", configId)

    return (
        <>

            <div className="d-flex flex-column justify-content-center align-items-center pt-2">
                <button
                    onClick={launchFBE}
                     className="add-facebook-pages-new"
                   
                >

                 {fetchFbPageLoad?'': <FacebookRoundedIcon sx={{ marginRight: 2 }} />}  

                    {fetchFbPageLoad ? (<>Fetching pages..&nbsp;<span class="loaderfb"></span></>) : 'Add Facebook Pages'}
                </button>
            </div>

        </>
    );
}

export default ConnectToFacebook;
