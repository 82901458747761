import { Button, Card, Menu, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
// import leadicon1 from '../../asets/webimages/leadmark1.png'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from '@mui/icons-material/Add';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import deleteleadicon1 from '../../asets/webimages/deleteleadmark3.png'
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar2 from '../../navbar/Navbar2';
import { Toaster, toast as hotTost } from 'react-hot-toast';
import Axios from '../../Axios';
import { AuthContext } from '../../AuthProvider';

const LeadsPageFormMobile = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showCommet, setShowComment] = useState(false)
    const [ cardShow,  setCardShow] = useState(false)
    const navigate = useNavigate()
    const toast = useRef(null);
   const {userToken}  = useContext(AuthContext)
   const [commentMessage, setCommentMessage] = useState("")
   const [allComment, setAllComment] = useState([])
    const location = useLocation();
    const { singleleads } = location.state;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const excludedKeys = ["email", "full_name", "phone_number", "created_time", "id", 'comment'];
// console.log("sleads", singleleads)



    const handleClose = () => {
      setAnchorEl(null);
    };
    useEffect(()=>{
       setCardShow(false)
    },[singleleads])
    //console.log("singleleads", singleleads)

     const handleComment = async ()=>{
            setShowComment(!showCommet)
     }

       const handleLeadQuality = async (leadid, type)=>{
      handleClose()
          
       const body ={
        leadId:leadid,
        
    }

     if(type){
      body['type'] = type
     }
     if(commentMessage){
      body['comment'] = commentMessage
     }
           

            try {
               const res = await Axios.post("/update-lead", body, {
                  headers:{
                      Authorization:userToken
                  }
               })
                    if(res.status===200){
                        if(res?.data?.valid){
                          hotTost.success(res?.data?.message)
                          setCommentMessage("")
                        }

                    }
            } catch (error) {
                console.log(error)

            }
   }


   const getSingLeadData = async (id)=>{
    try {
     const res = await Axios.get(`/get-lead-data?leadId=${id}`,  {
       headers:{
           Authorization:userToken
       },
       params:{
  
       }
    })
        if(res.status===200){
             const data = res.data.message;
                setAllComment(data?.comment)
              
        }
    } catch (error) {
     
    }
  }
  
  
  useEffect(()=>{
       if(singleleads?.id){
        getSingLeadData(singleleads?.id)
       }
  },[singleleads?.id])
  
  
  const handleAdComment = async (leadid)=>{
    const body ={
      leadId:leadid,
      
  }
   if(commentMessage){
    body['comment'] = commentMessage
   }
  
    try {
       const res = await Axios.post("/update-lead", body, {
          headers:{
              Authorization:userToken
          }
       })
            if(res.status===200){
                        if(res?.data?.valid){
                          getSingLeadData(leadid) 
                          setCommentMessage("")         
                        }             
            }
    } catch (error) {
        console.log(error)
  
    }finally{
      
    }
   }


//format date 
function formatedDate(inputDate) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(inputDate);
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${months[monthIndex]} ${day}, ${year}`;
  return formattedDate;
}






    return (
        <>
             <Toaster
  position="top-right"/>
            <div className="d-md-none"> <Navbar2 menu={true} /></div>
            <div className="d-flex mt-4 ps-2">
            <span
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon />
              <span className='fw-bold'>Back</span>
            </span>
           
          </div>
            <div className="form-contain">
            
            <Card sx={{ width:'100%', paddingBottom:'14px' }}>
                <div className='top-card mt-4 pb-4 px-3 ' style={{borderBottom:'2px solid #C2C1C6'}} >
               {singleleads?.full_name?.length>0? <div className=" d-flex justify-content-between align-items-center">
                        <p className='' style={{fontSize:'1.5rem', fontWeight:'700'}}>{singleleads?.full_name}</p>
                    
                      {/* <p onClick={onShowForm} className='d-flex justify-content-center align-items-center' style={{width:'24px', height:'24px', border:'1px solid #C2C1C6', borderRadius:'6px', cursor:'pointer'}}><CloseIcon sx={{color:'#828B96', fontSize:'14px'}} /></p> */}
                     
                    </div>:null } 
              
                    <div className=' '>
                        <div className='d-flex justify-content-between align-items-center'>
                        {singleleads?.phone_number?.length>0?<div  >
                            <p className='p-0 m-0' style={{color:'#6E6E6E', fontSize:'.92rem', fontWeight:'500'}}>Contact Number :</p>
                           <p className='p-0 m-0' style={{fontSize:'1rem', color:'#6419C2', fontWeight:'500'}}>{singleleads?.phone_number}</p>
                          </div>:null}  
                        
                       
                        {/* <div>
                                <Button
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{textTransform:'capitalize', backgroundColor:'white', fontSize:'10px', width:'120px', color:'black', padding:'4px 8px',  '&:hover':{backgroundColor:'white'} }}
                                    variant='contained'
                                    disableRipple
                                >
                                    Lead Quality{open?<KeyboardArrowUpIcon sx={{padding:'0px', margin:'0px'}}/>:<KeyboardArrowDownIcon sx={{padding:'0px', margin:'0px'}}/>}
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    sx={{marginTop:'2px'}}
                                    onClose={handleClose}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={()=>handleLeadQuality(singleleads?.id, "hot")} sx={{fontSize:'10px'}} >
                                     
                                      
                                   <img src={leadicon1} className='img-fluid me-2' alt="" />
                                      Relevant lead
                                      </MenuItem>
                                   
                                    <MenuItem  sx={{fontSize:'10px'}} onClick={()=>handleLeadQuality(singleleads?.id, "irrelevent")}>
                                    <img src={ deleteleadicon1} className='img-fluid me-2' alt="" />
                                    Irrelevant lead </MenuItem>
                                </Menu>
                       </div> */}

                        </div>

                      
                        {/* <div className='form-dropdown px-1'>
                        <div class="dropdown dropdown-btn shadow">
                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Lead Quality
                                    </button>
                                    <ul className="form-dropdown-menu shadow dropdown-menu dropdown-menu-dark">
                                        <li><Link class="dropdown-item " style={{color:'black'}} href="#">Hot Lead</Link></li>
                                        <li><Link class="dropdown-item" style={{color:'black'}} href="#">Cold Lead</Link></li>
                                        <li><Link class="dropdown-item" style={{color:'black'}} href="#">Irrelevent Lead</Link></li>
                                    </ul>
                                    </div>
                        </div> */}
                    </div>
                  {singleleads?.email?.length>0?<div >
                         <p className='p-0 m-0' style={{color:'#6E6E6E', fontSize:'.92rem', fontWeight:'500'}}>Email :</p>
                        <p className='p-0 m-0' style={{fontSize:'1rem', color:'#6419C2', fontWeight:'500', width:'200px'}}>{singleleads?.email}</p>
                         </div>:null}  
                  
                </div>
                   
                 
                  <div className='border mt-0' style={{}}>
                  <div className="comment-form-card py-3 px-3">
                           <p style={{fontSize:'20px', fontWeight:'700'}} className='text-start'>Form Answer</p>


                           {singleleads && Object.keys(singleleads)?.map(key => (
                           !excludedKeys.includes(key) && (

                                <>
                      {singleleads[key]?.length>0?<div className='' key={key}>
                          <p className='text-start pb-0 mb-0' style={{color:'rgba(110, 110, 110, 1)', fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp; {key && key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, ' ')}</p>
                          <p className='text-start fw-bold '  style={{fontSize:'600'}}><b>Ans.</b>&nbsp;{singleleads[key]?.replace(/_/g, ' ')}</p>
                          </div>:null}    
                      
                                </>



                            // <div key={key}>
                            //     <p><strong>{key.replace(/_/g, ' ')}</strong>: {data[key]}</p>
                            // </div>
        )
      ))}










                           
                          {/* <div className=''>
                          <p className='text-start pb-0 mb-0' style={{color:'rgba(110, 110, 110, 1)', fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp; What are you looking for?</p>
                          <p className='text-start fw-bold '  style={{fontSize:'600'}}>{singleleads['what_are_you_looking_for?']}</p>
                          </div>
                          <div>
                          <p className='text-start mb-0'  style={{color:'rgba(110, 110, 110, 1)' , fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp;What is your budget?</p>
                          <p className='text-start fw-bold' style={{fontSize:'600'}}>{singleleads['what_is_your_budget?']}</p>
                          </div>
                          <div>
                          <p className='text-start mb-0 '  style={{color:'rgba(110, 110, 110, 1)', fontSize:'.92rem', fontWeight:'500', textAlign:'justify', width:'100%'}}><b>Ques.</b>&nbsp;What is your budget for renting the studio apartment?</p>
                          <p className='text-start fw-bold ' style={{fontSize:'600', textAlign:'justify'}}>{singleleads['What is your budget for renting the studio apartment?']}</p>
                          </div> */}
                          {/* <div>
                          <p className='text-start mb-0'  style={{color:'rgba(110, 110, 110, 1)' , fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp;Full Name</p>
                          <p className='text-start fw-bold' style={{fontSize:'600'}}><b>Ans.</b>&nbsp;{singleleads?.full_name}</p>
                          </div>
                          <div>
                          <p className='text-start mb-0'  style={{color:'rgba(110, 110, 110, 1)' , fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp;Email</p>
                          <p className='text-start fw-bold' style={{fontSize:'600' }}><b>Ans.</b>&nbsp;{singleleads?.email}</p>
                          </div>
                          <div>
                          <p className='text-start mb-0'  style={{color:'rgba(110, 110, 110, 1)', fontSize:'.92rem', fontWeight:'500'}}><b>Ques.</b>&nbsp;Phone Number?</p>
                          <p className='text-start fw-bold' style={{fontSize:'600'}}><b>Ans.</b>&nbsp;{singleleads?.phone_number}</p>
                          </div> */}
                    </div>

                    <div className="comment-form px-2" style={{width:'100%'}}>
                        <div className='d-flex justify-content-between'>
                            <span style={{fontSize:'1.2rem', fontWeight:'700'}}>
                                Past Comment
                            </span>
                            <Button sx={{backgroundColor:'#6622cc', width:'10rem', textTransform:'capitalize', '&:hover':{backgroundColor:'#6622cc',   transition: 'display 0.3s ease-in-out'}}} onClick={handleComment} variant='contained'> <AddIcon/>  Add Comment</Button>
                        </div>



                        <div   style={{}}>
                             <div className={`table-comment-container ${showCommet ? 'show-comment-card py-2 mb-3' : ''}`}>
                                <p>Add Comment</p>
                                 <textarea value={commentMessage} onChange={(e)=>setCommentMessage(e.target.value)}  className='comment-box' style={{width:'100%'}} placeholder='type comment...' name="" id="" cols="8" rows="4"></textarea>
                                   <div>
                                    <Button onClick={()=>handleAdComment(singleleads?.id)} sx={{backgroundColor:'#6622cc', textTransform:'capitalize', '&:hover':{backgroundColor:'#6622cc'}}}  variant='contained'>Save</Button>
                                   </div>
                             </div>
                           <table className="table-comment" style={{width:'100%'}}>
                                <tr className='head' style={{background:'rgba(110, 110, 110, 1)'}}>
                                    <th>Date</th>
                                    <th>Comments</th>
                                </tr>
                                {allComment?.length>0?(
                                     allComment?.map((val, idx)=>(
                                         <>
                                           {val?.date?.length>0 && <tr key={val?.date}>
                                        <td style={{fontSize:'14px', fontWeight:'500'}}>{val?.date? formatedDate(val?.date):null}</td>
                                        <td style={{fontSize:'', fontWeight:'500', color:'rgba(110, 110, 110, 1)'}}>{val?.comment}</td>
                                      </tr>}  
                                         </>
                                  
                                     ))
                                    
                                    
                                ):null }
                               
                            </table>
                        </div>
                    </div>
                  </div>
                    
                </Card>
            </div>
        </>
    );
}

export default LeadsPageFormMobile;
